import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Table } from 'src/app/models/table';
import { FullPayment, Payment } from 'src/app/models/bank/payment';
import { BaseComponent } from 'src/app/@base/base.component';
import { Subscription, firstValueFrom, switchMap, tap } from 'rxjs';
import { BankService } from 'src/app/services/bank.service';
import { Contract } from 'src/app/models/bank/contract';
import { DatePipe } from '@angular/common';
import { ButtonColors } from 'src/app/components/button/button.component';
import { orderBy } from 'lodash-es';
import { Parameter } from 'src/app/models/parameter/parameter';
import { CreateFeatureLogRequest } from 'src/app/models/Log/CreateFeatureLog';
import { LogService } from 'src/app/services/log.service';
import { TableMobileComponent } from 'src/app/components/table-mobile/table-mobile.component';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Statement } from '@angular/compiler';
import { BasePayment } from 'src/app/@base/base-payment';

@Component({
  selector: "app-second-payment",
  templateUrl: "./second-payment.component.html",
  styleUrls: ["./second-payment.component.scss"],
})
export class SecondPaymentComponent extends BasePayment {

  @ViewChild(TableMobileComponent) tableMobileComponent: TableMobileComponent
  @ViewChildren('checkboxes') checkboxes: QueryList<MatCheckbox>

  public $subscriptionContract: Subscription;

  public txtBtnGenerateBill: string = "Gerar Boleto";
  public paymentDate: string = "20/10/2023";
  public txtSpanSumInstallment: string = "Valor original";
  public txtSpanSumAddition: string = "Acréscimos";
  public txtSpanSumDiscounts: string = "Descontos";
  public txtSpanSumDifference: string = "Diferença";
  public txtSpanInfoOne: string =
    "Prezado cliente, este boleto pode ser liquidado na rede bancária, locais conveniados ou ainda através do Internet Banking.";
  public txtSpanInfoTwo: string =
    "Lembramos que o prazo para baixa dos pagamentos é de até 3 dias úteis.";
  public txtSpanTotal: string = "Total a Pagar:";
  public barcode: string;
  public txtCopy: string = "Copiar";
  public messageNotAllowed: string = null;
  public table: Table<Payment> = this.createTable();
  public loading: boolean = false;

  public rowsSelected: Payment[] = [];
  public totalRows: Payment[] = this.table.data;
  public allowPayment: boolean = true;
  public date: Date = new Date();
  public firstDate = [];
  public LastDate = [];
  public isSmallScreen: boolean;

  get isAllSelected(): boolean {

    if (this.rowsSelected.length == this.table.data.length) {
      return true;
    }
    return false;
  }

  constructor(private bankService: BankService, private datepipe: DatePipe, private breakpointObserver: BreakpointObserver) {
    super();

    this.subscriptionChangeContract();
  
  }

  ngOnDestroy(): void {
    this.$subscriptionContract.unsubscribe();
  }

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium
    ]).subscribe(result => {
      this.isSmallScreen = result.matches;
    });
  }

  private subscriptionChangeContract(): void {
    this.$subscriptionContract =
      this.bankService.emitContractSelected.subscribe((contract: Contract) => {
        this.table.data = [];
        this.contract = contract;
        this.loading = true;
        this.allowPayment = true;
        this.table.checkbox = true;
        this.rowsSelected = [];
        this.getAllPayment(true);
      });
  }

  private createTable(): Table<Payment> {
    return {
      headers: [
        {
          key: "number",
          value: "Parcela",
        },      
        {
          key: "paymentDay",
          value: "Data do Pagamento"
        },
        {
          key: "dueDate",
          value: "Vencimento",
        },
        {
          key: "originalValue",
          value: "Valor Original",
        },
        {
          key: "discountIncrease",
          value: "Desconto/Acréscimo",
        },
        {
          key: "total",
          value: "Valor Atual"
        },
        {
          key: "paymentValue",
          value: "Valor Pago"
        }	
      ],
      data: [],
      style: {
        data: [
          {
            key: "discountIncrease",
            styles: [
              {
                class: { fontWeight: "500", color: "red" },
                rule: (x) => parseFloat(x) > 0,
              },
              {
                class: { fontWeight: "500", color: "green" },
                rule: (x) => parseFloat(x) < 0,
              },
            ],
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            key: "originalValue",
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            key: "total",
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            key: "paymentValue",
            transform: (value: string) => parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          },
          {
            key: "paymentValue",
            transform: (value: string) => parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          },
          {
            key: "dueDate",
            transform: (value) => this.datepipe.transform(value, 'dd/MM/yyyy')
          },
          {
            key: "paymentDay",
            transform: (value) => this.datepipe.transform(value, 'dd/MM/yyyy')
          }
        ],
        table: {
          height: "400px",
          align: {
            column: [
              {
                key: "originalValue",
                align: "right",
              },
              {
                key: "discountIncrease",
                align: "right",
              },
              {
                key: "paymentValue",
                align: "right"
              },
              {
                key: "total",
                align: "right",
              },
            ],
          },
        },
      },
      checkbox: true,
      messageWithoutDates: "Não há dados para exibir neste momento.",
    };
  }

  disableCheckboxCondition(row): boolean {
    return row.paymentDay != null;
  }


  private updateTable(resp: Payment[]): void {
    this.cleanTable();
    this.table.data = resp;
    this.table = this.table;
  }

  private updateReceiptCode(resp: Payment[]): void {
    this.table.data.forEach((x) => {
      x.receiptCode = resp.find((y) => y.number == x.number).receiptCode;
    });
    this.table.checkbox = this.allowPayment;
  }

  private cleanTable(): void {
    this.table.data = [];
    this.table = this.table;
  }

  public getAllPayment(reloadTable: boolean): void {
    this.table.loading = true;
    this.messageNotAllowed = null;

    firstValueFrom(this.bankService.GetUserInstallments(this.contract.contractNumber))
      .then((resp: Payment[]) => reloadTable ? this.updateTable(resp) : this.updateReceiptCode(resp))
      .then(() => this.checkData())
      .then(() => this.checkDates())
      .finally(() => (this.loading = false))
      .finally(() => (this.table.loading = false));
  }

  public onInstallmentCheck(event: any): void {
    event.row.selected = event.selected;
    if(this.rowsSelected.find(c=> c.number === event.row.number)){
      if (!event.selected) {
        this.rowsSelected = this.rowsSelected.filter(x => x !== event.row);
      } 
      
    }
    else{
      if(event.selected){
        this.rowsSelected.push(event.row);
      }
    }
  }

  public onAllInstallmentsCheck(event: any): void {
    if (event.checked) {
      this.rowsSelected = this.table.data;
    }
    if (!event.checked) {
      this.rowsSelected = [];
    }
  }

  private checkDates(): void {
   
    if (
      this.table.data.find(
        (x) =>
          this.dateDiffInDays(new Date(this.date), new Date(x.dueDate)) <= (this.acceptDueDays * -1)
      )
    ) {
      this.messageNotAllowed =
        this.contract.consultancyInformation ||
        "Favor entrar em contato com a nossa Central de Relacionamento, através do telefone (11) 2088-7700, para obter mais informações do contrato.";
      this.table.checkbox = false;
    }
  }

  private checkData(): void {
    if (this.table.data.length == 0) {
      this.messageNotAllowed =
        "Não há parcelas disponíveis para realizar o pagamento.";
      this.table.checkbox = false;
    }
  }

  

  public rowsSelectedSorted(): any[] {
    return orderBy(this.rowsSelected, ["number"], ["asc"]);
  }

  public getBtnColor(): ButtonColors {
    if (this.isAllSelected) {
      return "accent";
    }

    return "primary";
  }

  public getBtnText(): string {
    if (this.isAllSelected) {
      return "Quitar contrato";
    }

    return "Gerar boleto";
  }

  public getBtnIcon(): string {
    if (this.isAllSelected) {
      return "check_circle_outline";
    }

    return "get_app";
  }

  public onFirstDate(event): void{
    this.firstDate.push({value: event, description:'Primeira parcela'})
  }
  
  public onLastDate(event): void{
    this.LastDate.push({value: event, description:'Última parcela'})
  }

  public onCheckBoxChange(event: MatCheckboxChange): void{
    this.tableMobileComponent.checkall(event)
    this.onAllInstallmentsCheck(event)   
  }


  public deselectAll(): void {
    this.rowsSelected = [];
    this.tableMobileComponent.checkall(false);
    this.checkboxes.forEach(c=> c.checked = false)
}

}
