<app-section [mode]="sectionMode" backgroundColor="#fff">
  <div class="section-title flex-column">
    <span class="estricta title"> ABRIR NOVA SOLICITAÇÃO </span>
    <span class="sub-title">
      Selecione o segmento de produto e o tipo de solicitação que deseja fazer.
    </span>
  </div>

  <div class="section-content">
    <div *ngIf="isClientOf(productBank)">
      <app-bank-buttons-option [showTitle]="true" [showNoContractMessage]="true"></app-bank-buttons-option>
    </div>
    <div *ngIf="isClientOf(productInsurance)">
      <span class="title estricta bolder" [showIfPermission]="insuranceStringOptions">
        SEGUROS
      </span>
      <div class="flex">
        <app-button *ngFor="let option of insuranceOptions" color="primary" width="100%" minWidth="190px"
          [showIfPermission]="option.permission" [datacy]="'Seguros-' + option.description"
          [tooltip]="getTooltip(option)" (onClick)="openModalRequest(option)">
          <span class="button-content estricta bolder">
            {{ option.description }}
          </span>
        </app-button>
      </div>
    </div>
    <div *ngIf="isClientOf(productLiberaCred)">
      <span class="title estricta bolder" [showIfPermission]="creditStringOptions">
        LIBERACRED
      </span>
      <div class="flex">
        <app-button *ngFor="let option of creditOption" color="primary" width="100%" minWidth="190px"
          [showIfPermission]="option.permission" [datacy]="'Liberacred-' + option.description"
          [tooltip]="getTooltip(option)" (onClick)="openModalRequest(option)">
          <span class="button-content estricta bolder">
            {{ option.description }}
          </span>
        </app-button>
      </div>
    </div>
  </div>
</app-section>

<div class="table">
  <app-table [table]="getTable()">
    <div class="table-header">
      <div>
        <span class="estricta title"> MINHAS SOLICITAÇÕES </span>
      </div>
    </div>
  </app-table>
</div>