<app-section backgroundColor="#2548a3" [mode]="'fixed'" [height]="'100px'">
    <div class="section-content blue-content">
        <button (click)="back()" [attr.data-cy]="txtBtnBack" class="estricta button uppercase">
            {{txtBtnBack}}
        </button>
    </div>
</app-section>

<div class="container">

    <div class="panel left-panel">
        <span class="section-title title estricta"> Parcelas </span>

        <app-painel-list-view [table]="table" 
            [disableCondition]="disableCheckboxCondition" 
            [rowSelected]="rowsSelected"
            [customSelect]="customCheck">
        </app-painel-list-view>
    </div>

    <div class="panel right-panel">
        <div *ngIf="!loading; else containerLoading" class="density-lower">

            <div class="right-panel">
                <app-debt-settlement-summary 
                    [debtor]="debtor"
                    [increaseDays]="increaseDays"

                    [installments]="rowsSelected"
                    [totalRows]="totalRows" 
                    
                    [icon]="'check_circle_outline'" 
                    [allSelected]="isAllSelected"
                    (emitDocumentCreated)="getAllLateInstallment(); setDescriptionSettlement()">

                    <div class="debt-summary-header">

                        <div class="recalculate-header">

                            <div class="card-header-info">
                                <mat-icon class="red">info</mat-icon>
                                <div>
                                    <span class="red text">Cálculo para</span>
                                </div>
                            </div>

                            <form [formGroup]="form" class="density-mat-form-3" >
                                <span>
                                    <app-custom-datapicker [label]="'Data Vencimento'" 
                                    [readonly]="paymentSlipGenerated"
                                    formControlName="dueDate"
                                    datacy="dueDate"
                                    [control]="form.get('dueDate')"
                                    [errors]="form.get('dueDate').errors"
                                    [minDate]="dataAtual">
                                    </app-custom-datapicker>
                                </span>
                            </form>
                        </div>

                        <app-button 
                        [color]="'primary'" width="100%" datacy="btn-recalculate" 
                        [disabled]="paymentSlipGenerated"
                        [loading]="loadingRecalculate"
                        (onClick)="recalculate()">
                            <span class="uppercase estricta text-button">
                                <mat-icon> event_repeat </mat-icon>
                                <span> Recalcular Taxas </span>
                            </span>
                        </app-button>

                        <mat-divider></mat-divider>

                        <div class="selected-installments">
                            <span class="text uppercase payment-rows-selected"> Parcelas selecionadas: </span>

                            <mat-chip-listbox *ngIf="rowsSelected.length > 0; else noOneSelect" selectable="false"
                                data-cy="installmentsSelected">
                                <mat-chip-option *ngFor="let row of rowsSelectedSorted()"
                                    (removed)="customCheck(row, null);"
                                    [attr.data-cy]="'installmentsSelected' + row.number">
                                    <span> {{row.number}} </span>
                                    <mat-icon *ngIf="!paymentSlipGenerated" matChipRemove>clear</mat-icon>
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                        
                        <mat-divider></mat-divider>

                        <ng-template #noOneSelect> - </ng-template>
                    </div>

                    <div class="debt-summary-not-allowed">
                        <span>{{messageNotAllowed}}</span>
                    </div>

                </app-debt-settlement-summary>

                <app-card [width]="'100%'" style="margin-top: 20px;">
                    <span class="card-title estricta uppercase">Registro Atendimento</span>
                    <mat-form-field appearance="outline" class="background-white">
                        <textarea
                          matInput
                          name="incidentDescritionControl"
                          data-cy="modal-new-request-description"
                          [(ngModel)]="descritionSettlement"
                          maxlength="1000"
                          required
                        ></textarea>
                      </mat-form-field>
                    <app-button [color]="'primary'" width="100%" datacy="btn-payment" [loading]="loading" 
                        [disabled]="descritionSettlement === '' || descritionSettlement === null"
                        (onClick)="registerEndDebtHandling(true)">
                        <span class="uppercase estricta text-button">
                            <span> Efetuar Registro </span>
                        </span>
                    </app-button>
                </app-card>
            </div>
        </div>
    </div>

</div>

<ng-template #containerLoading>
    <div class="loading">
        <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
</ng-template>