import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import { Observable } from 'rxjs';
import { AttachFileToIncidentRequest, Incident, IncidentRequest, IncidentResponse } from '../models/incident/incident';
import { EnumIncidentProduct } from '../enum/incident';
import { ChangeUserCrmDynamics, UserCrmDynamics } from '../models/user/user';

@Injectable({
  providedIn: 'root'
})
export class IncidentService extends BaseService<Incident> {

  constructor() {
    super()
    this.path = "gateway/incidents"
  }

  public getLastIncidents(): Observable<Incident[]> {
    return this.http.get<Incident[]>(`${this.api}${this.path}`)
  }

  public getIncidentsByProduct(product: EnumIncidentProduct): Observable<Incident[]> {
    return this.http.get<Incident[]>(`${this.api}${this.path}`, { params : { product } })
  }

  public create(incident: IncidentRequest): Observable<IncidentResponse> {
    return this.http.post<IncidentResponse>(`${this.api}${this.path}`, incident)
  }

  public createSync(incident: IncidentRequest): Observable<IncidentResponse> {
    return this.http.post<IncidentResponse>(`${this.api}${this.path}/sync`, incident)
  }

  public attachFileToIncident(attachFileToIncidentRequest: AttachFileToIncidentRequest): Observable<any> {
    return this.http.post<IncidentResponse>(`${this.api}${this.path}/attachFileToIncident`, attachFileToIncidentRequest)
  }
  
  public updateRegistrationData(request: ChangeUserCrmDynamics): Observable<any> {
    return this.http.post(`${this.api}${this.path}/changeRegistrationData`, request);
  }
  
  public getUserCrmDynamicsByDocument(document: string): Observable<UserCrmDynamics> {
    return this.http.get<UserCrmDynamics>(`${this.api}${this.path}/GetUserCrmDynamcs`)
  }
}
