import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BankComponent } from './bank.component';
import { HomeBankComponent } from './bank-categories/home-bank/home-bank.component';
import { PaymentComponent } from './bank-categories/payment/payment.component';
import { ContractComponent } from './bank-categories/contract/contract.component';
import { PaymentBookComponent } from './bank-categories/payment-book/payment-book.component';
import { StatementComponent } from './bank-categories/statement/statement.component';
import { AnualStatementComponent } from './bank-categories/anual-statement/anual-statement.component';
import { SecondPaymentComponent } from './bank-categories/second-payment/second-payment.component';

const routes: Routes = [
  {
    path: "",
    component: BankComponent,
    children: [
      {
        path: "",
        component: HomeBankComponent
      },
      {
        path: "payment",
        component: PaymentComponent
      },
      {
        path: "contract",
        component: ContractComponent
      },
      {
        path: "payment-book",
        component: PaymentBookComponent
      },
      {
        path: "statement",
        component: StatementComponent
      },
      {
        path: "anual-statement",
        component: AnualStatementComponent
      },
      {
        path: "second-payment",
        component: SecondPaymentComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BankRoutingModule { }
