<app-bank-header>

  <div class="bank-header">

    <div class="info">
      <span>
        O <span class="bold">BANCO YAMAHA MOTOR DO BRASIL S.A. - Banco Múltiplo</span>, instituição financeira inscrita
        no CNPJ/MF sob número 10.371.492/0001-85, em atenção ao disposto na Lei 12.007/2009, DECLARA que o cliente acima
        mencionado, quitou os vencimentos relativos ao período do ano em referência, da(s) operação(ões) acima
        descriminada(s), na forma do demonstrativo abaixo:
      </span>
    </div>

  </div>

  <div *ngIf="!loading; else containerLoading" class="bank-content">

    <span class="section-title title estricta" data-cy="anualStatementTitle"> DECLARAÇÃO ANUAL DE QUITAÇÃO DE DÉBITOS
    </span>

    <div class="section-content density-lower">

      <div>
        <mat-form-field appearance="outline" class="density-mat-form-3 background-white">
          <mat-label>Ano Base</mat-label>
          <mat-select [(value)]="selectedYear" (selectionChange)="updateYear($event)" data-cy="baseYear">
            <mat-option *ngFor="let year of years" [value]="year">
              {{year}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <app-table-mobile-financial [table]="table"></app-table-mobile-financial>
      </div>

      <app-card class="card-download">
        <app-button color="primary" width="100%" [disabled]="table.data.length == 0 || downloadFile"
          [datacy]="txtBtnDownload" (onClick)="downloadAnualStatement()" [loading]="downloadFile">
          <span class="estricta text-button uppercase"> {{txtBtnDownload}} </span>
        </app-button>
      </app-card>
    </div>
  </div>

</app-bank-header>

<ng-template #containerLoading>
  <div class="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
</ng-template>