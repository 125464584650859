import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { EnumPath } from 'src/app/enum/path';
import { CustomValidators } from 'src/app/form/custom-validators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-expired-password',
  templateUrl: "./reset-expired-password.component.html",
  styleUrls: ['./reset-expired-password.component.scss'],
})
export class ResetExpiredPasswordComponent extends BaseComponent implements OnInit {
  public form:FormGroup;
  public loading: boolean = false;
  public hideCurrentPassword: boolean = true;
  public hideNewPassword: boolean = true;
  public hideConfirmNewPassword:boolean = true;
  public success: boolean = false;

  get passwordMatchError() {
    return (
      this.form.getError('mismatch') &&
      this.form.get('confirmPassword')?.touched
    );
  }

  get passwordNotMatchError() {
    return (
      this.form.getError('notMismatch') &&
      (this.form.get('confirmPassword')?.touched || this.form.get('newPassword')?.touched)
    );
  }



  public constructor (
    private formBuilder:FormBuilder,
  ) {
    super()
  }

  ngOnInit(): void {
    if(!this.authService.document) {
      this.router.navigate([EnumPath.LOGIN])
    }
    this.createForm()
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      document: [this.authService.document],
      currentPassword: [null, Validators.required],
      newPassword: [null, [Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*\\W)(?!.* ).{8,50}$')]],
      confirmPassword: [null, Validators.required],
    })

    this.form.addValidators(CustomValidators.MatchValidator('newPassword', 'confirmPassword'))
    this.form.addValidators(CustomValidators.notMatchValidator('newPassword', 'currentPassword'))
  }

  public onSubmit() {
    this.loading = true;
    firstValueFrom(this.authService.resetExpiredPassword(this.form.value))
      .then(() => this.success = true)
      .catch((error: HttpErrorResponse) => { if(error.status != 500) this.openDialogError(error); })
      .finally(() => this.loading = false)
  }

  public returnLogin() {
    firstValueFrom(this.openDialogConfirm("Tem certeza que deseja voltar?").afterClosed())
      .then((resp: boolean) => {if(resp) this.router.navigate([EnumPath.LOGIN])})
  }
 }
