import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationStart } from "@angular/router";
import { filter, firstValueFrom } from "rxjs";
import { BaseComponent } from "src/app/@base/base.component";
import { EnumPath } from "src/app/enum/path";
import { ResetPasswordResponse } from "src/app/models/auth/auth";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-expired-password-message",
  templateUrl: "./expired-password-message.component.html",
  styleUrls: ["./expired-password-message.component.scss"],
})
export class ExpiredPasswordMessageComponent
  extends BaseComponent
  implements OnInit
{
  private email: string;
  public userEmail: string;
  public isIntern: string;

  constructor(private activatedRoute: ActivatedRoute) {
    super();
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        const navigation = this.router.getCurrentNavigation();
        this.email = navigation.extras.state
          ? navigation.extras.state["email"]
          : "teste";
        });
  }
      
  ngOnInit(): void {
    this.userEmail = this.email != "" ? this.censorEmail(this.email) : "";
    this.isIntern = this.intern;
  }

  private censorName(str) {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  }

  private censorDomain(str) {
    return str[0] + "*".repeat(str.length - 4) + str.slice(-3);
  }

  private censorEmail(email) {
    var arr = email.split("@");
    return this.censorName(arr[0]) + "@" + this.censorDomain(arr[1]);
  }

  get intern() {
    return this.activatedRoute.snapshot.paramMap.get("isInternal");
  }

  public goToLoginPage(): void {
    this.router.navigate([EnumPath.LOGIN]);
  }
}
