import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent {

  @Input() barcode: string;
  
  public txtCopy: string = "Copiar"  
}
