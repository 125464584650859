<app-modal class="density-lower"
           title="Aviso">

    <div *ngIf="thereIsTitle" class="modal-content">

        <div class="content">
            <mat-icon>done</mat-icon>
            <div>
                <span class="title-success" data-cy="titleModalRequestResponse"> {{titleModalSuccess}} </span>
            </div>
        </div>

        <div>
            <span class="message-success" data-cy="messageModalRequestResponse"> {{messageModalSuccess}} </span>
        </div>

    </div>

    <div *ngIf="!thereIsTitle" class="modal-content">

        <div class="content">
            <mat-icon>done</mat-icon>
            <div>
                <span class="message-success" data-cy="messageModalRequestResponse"> {{messageModalSuccess}} </span>
            </div>
        </div>

    </div>


    <div class="modal-bottom">
        <app-button datacy="btnModalRequestResponse" (onClick)="onClose()" color="primary" minWidth="80px">
            <span class="button-label">{{txtBtnClose}}</span>
        </app-button>
    </div>
</app-modal>
