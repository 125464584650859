import { Component, Inject } from '@angular/core';
import { ModalComponent } from '../modal.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent extends ModalComponent<ModalConfirmComponent> {

  public message: string = "";
  public txtBtnConfirm: string = "Sim";
  public txtBtnCancel: string = "Não";
  public override title: string = "Aviso";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string | { message: string, title: string }
) {
    super()
    if (typeof data === 'string') {
        this.message = data;
    } else {
        this.message = data.message;
        this.title = data.title;
    }
}

}
