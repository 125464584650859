import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription, firstValueFrom } from "rxjs";
import { BaseComponent } from "src/app/@base/base.component";
import { ModalBankChangeRegistrationDataComponent } from "src/app/pages/bank/bank-components/modal-bank-change-registration-data/modal-bank-change-registration-data.component";
import { ModalBankPaymentReversalComponent } from "src/app/pages/bank/bank-components/modal-bank-payment-reversal/modal-bank-payment-reversal.component";
import { ModalBankTravelAuthorizationComponent } from "src/app/pages/bank/bank-components/modal-bank-travel-authorization/modal-bank-travel-authorization.component";
import { OPTIONS_BANK } from "src/app/const/options";
import {
  PERMITION_BANK_CHANGE_REGISTRATION_DATA,
  PERMITION_BANK_PAYMENT_REVERSAL,
  PERMITION_BANK_TRAVEL_AUTHORIZATION,
  PERMITION_BANK_WARRANTY_REPLACEMENT,
} from "src/app/const/permitions";
import { PERMITION_BANK_RIGHTS_ASSIGNMENT } from "src/app/const/permitions";
import { EnumBankOptions } from "src/app/enum/options";
import { Contract } from "src/app/models/bank/contract";
import { Option } from "src/app/models/option";
import { BankService } from "src/app/services/bank.service";
import { ModalRightsAssignmentComponent } from "../../bank-modals/modal-rights-assignment/modal-rights-assignment.component";
import { ModalWarrantySubstitutionComponent } from "../../bank-modals/modal-substituicao-garantia/modal-warranty-substitution.component";
import { ModalSucess } from "src/app/components/modal/modal-success/modal-success.component";
import { HttpErrorResponse } from "@angular/common/http";
import { LOCAL_STORAGE_KEY_CONTRACTS, LOCAL_STORAGE_KEY_USER } from "src/app/const/localStorageKeys";
import { CurrentUser, User } from "src/app/models/user/user";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-bank-buttons-option",
  templateUrl: "./bank-buttons-option.component.html",
  styleUrls: ["./bank-buttons-option.component.scss"],
})
export class BankButtonsOptionComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() showTitle: boolean = false;
  @Input() showNoContractMessage: boolean = false;

  @Output() onSubmit: EventEmitter<void> = new EventEmitter();

  private hasActiveInstallments: boolean | undefined;
  private $hasActiveInstallmentsSubscription: Subscription | null = null;
  private readonly permissions = this.getPermissions();

  public bankOptions: Option[] = OPTIONS_BANK.filter(
    (opt) => opt.description == EnumBankOptions.CHANGE_REGISTRATION_DATA
  );

  public $contractSubscriber: Subscription;
  public contractSelected: Contract;
  public fullUser: User;

  constructor(private readonly bankService: BankService, private readonly userService: UserService) {
    super();
  }

  ngOnInit(): void {
    try {
      this.setContracts();
      let userLocal = this.getLocalStorageItem<CurrentUser>(LOCAL_STORAGE_KEY_USER);
      firstValueFrom(this.userService.getUserById(userLocal.id))
      .then((user) => {
        this.fullUser = user;
      })
    } catch (error) {
      const contracts =
        this.getLocalStorageItem<Contract[]>(LOCAL_STORAGE_KEY_CONTRACTS) ?? [];
      if (contracts.length > 0) {
        this.bankService.createSubject(contracts[0]);
        this.setContracts();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.$hasActiveInstallmentsSubscription) {
      this.$hasActiveInstallmentsSubscription.unsubscribe();
    }
  }

  public createNewRequest(option: string): void {
    switch (option) {
    }
  }

  private shouldShowButton(permission: string) {
    if (!this.permissions.includes(permission)) {
      return false;
    }

    const shouldShowWhenContractActiveIs =
      this.shouldShowIfHasActiveInstallments(permission);

    return shouldShowWhenContractActiveIs == null
      ? true
      : shouldShowWhenContractActiveIs == this.hasActiveInstallments;
  }

  private setContracts() {
    this.$hasActiveInstallmentsSubscription =
      this.bankService.emitContractSelected.subscribe((x: Contract) => {
        this.hasActiveInstallments = x.hasActiveInstalments;
        this.bankOptions = OPTIONS_BANK.filter((opt) =>
          this.shouldShowButton(opt.permission)
        );
      });
  }

  private shouldShowIfHasActiveInstallments(permission: string) {
    switch (permission) {
      case PERMITION_BANK_TRAVEL_AUTHORIZATION:
        return true;
      case PERMITION_BANK_PAYMENT_REVERSAL:
        return true;
      case PERMITION_BANK_WARRANTY_REPLACEMENT:
        return true;
      case PERMITION_BANK_RIGHTS_ASSIGNMENT:
        return true;
    }

    return null;
  }

  private getBankSubjectModal(subject: Option): any {
    switch (subject.description) {
      case EnumBankOptions.CHANGE_REGISTRATION_DATA:
        return ModalBankChangeRegistrationDataComponent;

      case EnumBankOptions.TRAVEL_AUTHORIZATION:
        return ModalBankTravelAuthorizationComponent;

      case EnumBankOptions.PAYMENT_REVERSAL:
        return ModalBankPaymentReversalComponent;

      case EnumBankOptions.RIGHTS_ASSIGNMENT:
        return ModalRightsAssignmentComponent;


      case EnumBankOptions.WARRANTY_REPLACEMENT:
        return ModalWarrantySubstitutionComponent;
    }

    throw new Error(
      `Could not Find a valid modal for Subject: ${subject.description}`
    );
  }

  public getTooltip(option: Option): string {
    return "Clique aqui para fazer a solicitação de " + option.description;
  }

  public openBankSubjectModal(subject: Option): void {
    const modalComponent = this.getBankSubjectModal(subject);
  

    const dialog = this.dialog.open(modalComponent, {
      width: "900px",
      data: { subject: subject, documentNumber: this.fullUser.document },
      disableClose: true,
    });

    firstValueFrom(dialog.afterClosed()).then(
      (resp: ModalSucess | boolean | HttpErrorResponse) => {
        this.showResult(resp);
      }
    );
  }

  private showResult(resp: ModalSucess | boolean | HttpErrorResponse): void {
    if (resp instanceof HttpErrorResponse) {
      this.openDialogError(resp);
      return;
    }

    if (typeof resp === "boolean") {
      if (resp === true) {
        this.openDialogSucess({
          title: "Solicitação enviada com sucesso!",
          message: "",
        });
      }
      return;
    }

    this.openDialogSucess(
      { title: resp.title, message: resp.message },
      resp.style
    );
  }
}
