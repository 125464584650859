export const PERMITION_SETTINGS = 'Settings';
export const PERMITION_REQUESTS = 'Requests';
export const PERMITION_WEBCHAT = 'Webchat';
export const PERMITION_WHATSAPP = 'Whatsapp';
export const PERMITION_NOTIFICATIONS = 'Notifications';
export const PERMITION_USERS = 'Settings.Users';
export const PERMITION_USERS_READ = 'Users.Read';
export const PERMITION_USERS_CREATE = 'Users.Create';
export const PERMITION_USERS_UPDATE = 'Users.Update';
export const PERMITION_USERS_DELETE = 'Users.Delete';
export const PERMITION_USERS_EXPORT = "Users.Export";
export const PERMITION_PROFILE = 'Settings.Profile';
export const PERMITION_PROFILE_READ = 'Profile.Read';
export const PERMITION_PROFILE_CREATE = 'Profile.Create';
export const PERMITION_PROFILE_UPDATE = 'Profile.Update';
export const PERMITION_PROFILE_DELETE = 'Profile.Delete';
export const PERMITION_MESSAGES = 'Settings.Messages';
export const PERMITION_MESSAGES_READ = 'Messages.Read';
export const PERMITION_MESSAGES_CREATE = 'Messages.Create';
export const PERMITION_MESSAGES_UPDATE = 'Messages.Update';
export const PERMITION_MESSAGES_DELETE = 'Messages.Delete';
export const PERMITION_BANNERS = 'Settings.Banners';
export const PERMITION_BANNERS_READ = 'Banners.Read';
export const PERMITION_BANNERS_CREATE = 'Banners.Create';
export const PERMITION_BANNERS_UPDATE = 'Banners.Update';
export const PERMITION_BANNERS_DELETE = 'Banners.Delete';
export const PERMITION_CONSUMPTION_REPORT = 'Settings.ConsumptionReport';
export const PERMITION_CONSUMPTION_REPORT_READ = 'ConsumptionReport.Read';
export const PERMITION_CONSORTIUM = 'Consortium';
export const PERMITION_CONSORTIUM_PORTAL = 'Consortium.Portal';
export const PERMITION_BANK = 'Bank';
export const PERMITION_BANK_BILL = 'Bank.Bill';
export const PERMITION_BANK_PAYMENT = 'Bank.Payment';
export const PERMITION_BANK_PAYMENT_BOOKLET = 'Bank.Payment Booklet';
export const PERMITION_BANK_CONTRACT = 'Bank.Contract';
export const PERMITION_SETTINGS_PARAMETER = 'Settings.Parameters';
export const PERMITION_SETTINGS_PARAMETER_READ = 'Parameters.Read';
export const PERMITION_SETTINGS_PARAMETER_UPDATE = 'Parameters.Update';
export const PERMITION_PAYMENT_BOOK = 'Bank.Payment Book';
export const PERMITION_BANK_STATEMENT = 'Bank.Statement';
export const PERMITION_BANK_ANUAL_STATEMENT = 'Bank.Anual Statement';
export const PERMITION_BANK_CONTRACT_RECEIPT = 'Bank.Contract Receipt';
export const PERMITION_BANK_CONTRACT_BALANCE_DUE = 'Bank.Contract Balance Due';
export const PERMITION_BANK_RIGHTS_ASSIGNMENT = 'Bank.Rights Assignment';
export const PERMITION_BANK_WARRANTY_REPLACEMENT = 'Bank.Warranty Replacement';
export const PERMITION_BANK_CHANGE_REGISTRATION_DATA =
  'Bank.Change Registration Data';
export const PERMITION_BANK_PAYMENT_REVERSAL = 'Bank.Payment Reversal';
export const PERMITION_BANK_LETTER_DEBTOR_BALANCE = 'Bank.Letter Debtor Balance';
export const PERMITION_BANK_TRAVEL_AUTHORIZATION = 'Bank.Travel Authorization';
export const PERMITION_BANK_LIEN_STATUS = 'Bank.Status Lien';
export const PERMITION_INSURANCE = 'Insurance';
export const PERMITION_INSURANCE_CONTRACT = 'Insurance.Contract';
export const PERMITION_INSURANCE_REGISTRATION = 'Insurance.Registration';
export const PERMITION_INSURANCE_REPLACEMENT = 'Insurance.Replacement';
export const PERMITION_INSURANCE_BILL = 'Insurance.Bill';
export const PERMITION_INSURANCE_POLICY = 'Insurance.Policy';
export const PERMITION_INSURANCE_EMIT_BILL = 'SUHAI Integration.Emit bill';
export const PERMITION_INSURANCE_EMIT_POLICY = 'SUHAI Integration.Emit Policy';
export const PERMITION_INSURANCE_SITUATION_SINISTER = 'SUHAI Integration.Situation Sinister';
export const PERMITION_CREDIT = 'Credit';
export const PERMITION_CREDIT_BILL = 'Credit.Bill';
export const PERMITION_CREDIT_DELAYED = 'Credit.Delayed';
export const PERMITION_CREDIT_CANCELLATION = 'Credit.Cancellation';
export const PERMISSION_BILLING_CONSULTANCIES =
  'Settings.Billing Consultancies';
export const PERMISSION_BILLING_CONSULTANCIES_READ =
  'Billing Consultancies.Read';
export const PERMISSION_BILLING_CONSULTANCIES_CREATE =
  'Billing Consultancies.Create';
export const PERMISSION_BILLING_CONSULTANCIES_UPDATE =
  'Billing Consultancies.Update';
export const PERMISSION_BILLING_CONSULTANCIES_DELETE =
  'Billing Consultancies.Delete';
export const PERMITION_FAQ = 'Faq';
export const PERMITION_FAQ_CLIENT = 'Faq.Faq Client';
export const PERMITION_FAQ_ADMIN = 'Faq.Faq Admin';
export const PERMITION_CONTACT = 'Contact';
export const PERMITION_CONSUMPTION_REPORT_EXPORT = "ConsumptionReport.Export";
export const PERMITION_DEALERS = "Dealers";
export const PERMITION_DEALERS_DEBTORS = "Dealers.Consult debtors";
export const PERMITION_DEALERS_HISTORY = "Dealers.Debt treatment history";
export const PERMITION_DEALERS_SETTLEMENT = "Dealers.Treat with debtor";
