import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PwaServiceService {
  public pwaEvent;

  constructor() { 
    if(!this.pwaEvent){
      window.addEventListener('beforeinstallprompt', event => {
        this.pwaEvent = event;
      });
    }
  }
  public installPwa(): void {
    this.pwaEvent.prompt();
    this.pwaEvent.userChoice.then(choiceResult => {
      if(choiceResult.outcome === 'accepted'){
        this.pwaEvent = null;
      }
    });
  }
  public  isPwaInstalled(): boolean {
    return !this.pwaEvent;
  }
}
