import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import {
  ListParametersFilterRequest,
  Parameter,
} from '../models/parameter/parameter';

@Injectable({
  providedIn: 'root',
})
export class ParametersService extends BaseService<any> {
  constructor() {
    super();
    this.path = 'gateway/parameters';
  }

  public getById(id: number) {
    return this.http.get(`${this.api}${this.path}/${id}`);
  }

  public save(parameter: Parameter, id: number) {
    return this.http.put(`${this.api}${this.path}/${id}`, parameter);
  }

  public filterParameters(
    filterRequest: ListParametersFilterRequest,
    pageIndex: number,
    pageSize: number,
    skipModalError: boolean = false
  ) {
    return this.http.get(
      `${this.api}${this.path}/${this.buildFilterParameters(
        filterRequest,
        pageIndex,
        pageSize
      )}`,
      { headers: this.addHeaders(skipModalError), withCredentials: true }
    );
  }

  private buildFilterParameters(
    filterRequest: ListParametersFilterRequest,
    pageIndex: number,
    pageSize: number
  ): string {
    let queryString = `?pageNumber=${pageIndex}&pageSize=${pageSize}&`;

    if (filterRequest.key) {
      queryString += `key=${filterRequest.key}&`;
    }

    if (filterRequest.value) {
      queryString += `value=${filterRequest.value}&`;
    }

    if (
      filterRequest.lastModifiedAt?.startDate &&
      filterRequest.lastModifiedAt?.startDate
    ) {
      queryString += `lastModifiedAt.startDate=${filterRequest.lastModifiedAt.startDate.toISOString()}&lastModifiedAt.endDate=${filterRequest.lastModifiedAt.endDate.toISOString()}&`;
    }

    if (filterRequest.description) {
      queryString += `description=${filterRequest.description}&`;
    }

    if (filterRequest.lastModifiedBy) {
      queryString += `lastModifiedBy=${filterRequest.lastModifiedBy}&`;
    }

    return queryString;
  }
}
