import { ErrorHandler, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { PagesModule } from './pages/pages.module';
import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';

import { HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { AuthenticationGuard } from './guards/authentication.guard';
import { BYMD_AUTOSERVICE_XSRF_COOKIE, HEADER_X_XSRF_TOKEN } from './const/cookiesKeys';
import { AppService } from './services/app.service';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from './components/components.module';
import { AuthModule } from './auth/auth.module';

import { ErrorHandlerInterceptor } from './interceptor/interceptor';
import { CredentialHandlerInterceptor } from './interceptor/xsrfInterceptor';
import { ApplicationInsightsService } from './logger/application-insights-service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot([]),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    LayoutModule,
    PagesModule,
    AuthModule,
    MatDialogModule,
    MatRadioModule,
    ComponentsModule,
    HttpClientXsrfModule.withOptions({
      cookieName: BYMD_AUTOSERVICE_XSRF_COOKIE,
      headerName: HEADER_X_XSRF_TOKEN
    }),
    SocialLoginModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    AuthenticationGuard,
    AppService,
    ApplicationInsightsService,
    { provide: HTTP_INTERCEPTORS, useClass: CredentialHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '810510181043-41hljfkgm9p1v83nhk2ruritm1rcjb05.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('7473473686103289')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
