import {  ChangeDetectorRef, Component, Input, OnDestroy, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit, OnDestroy {
  public safeUrl: SafeResourceUrl;

  @Input() videoUrl?: string;

  get hasVideoToDisplay() {
    return this.videoUrl && this.videoUrl.trim() !== ""
  }

  get isVideoUrlInternalUrl() {
    const [http, _, domain] = this.videoUrl.split("/");
    if (domain != environment.BLOB_INTERNAL_DOMAIN) {
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.videoUrl
      );

      return false;
    }

    return true;
  }

  constructor(
    private readonly sanitizer:DomSanitizer,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.changeDetector.detach();
  }

  ngOnDestroy(): void {
    this.changeDetector.reattach();
  }

}
