import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { ModalErrorComponent } from "../components/modal/modal-error/modal-error.component";
import {
  LOCAL_STORAGE_KEY_CONTRACTS,
  LOCAL_STORAGE_KEY_PERMISSIONS,
  LOCAL_STORAGE_KEY_USER,
} from "../const/localStorageKeys";
import { EnumPath } from "../enum/path";
import { Base } from "../@base/base";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

export const InterceptorSkipModalError = "X-Skip-Interceptor-Error";

@Injectable()
export class ErrorHandlerInterceptor extends Base implements HttpInterceptor {
  constructor(public router: Router) {
    super();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipModalError)) {
      const headers = request.headers.delete(InterceptorSkipModalError);
      return next.handle(request.clone({ headers }));
    }

    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {},
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.removeLocalStorageItems();
             this.goToLoginPage();
          }
          if (error.status === 500) {
            this.openErrorModal();
          }
        },
        () => {}
      )
    );
  }

  private openErrorModal() {
    this.dialog.open(ModalErrorComponent, {
      width: "550px",
      maxHeight: "400px",
    });
  }

  private removeLocalStorageItems(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_KEY_PERMISSIONS);
    this.clearLocalStorageItem(LOCAL_STORAGE_KEY_USER);
    this.clearLocalStorageItem(LOCAL_STORAGE_KEY_CONTRACTS);
  }

  private goToLoginPage(): void { 
    let actualUrl = window.location.href;
    if(!actualUrl.includes("concessionaria")){
      this.router.navigate([EnumPath.LOGIN]);
    } else {
      window.location.href = environment.SiliconURL;
    }
  }
}
