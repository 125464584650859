import { Component } from '@angular/core';
import { ROUTES, FOOTER_ROUTES_OPTIONS, FOOTER_ROUTES_POLITCS, FOOTER_ROUTES_PRODUCTS } from 'src/app/const/routes';
import { EnumPages } from 'src/app/enum/pages';
import { Route } from 'src/app/models/route';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public routes: Route[] = ROUTES

  public routesProducts: Route[] = FOOTER_ROUTES_PRODUCTS
  public routesPolitics: Route[] = FOOTER_ROUTES_POLITCS
  public routesOptions: Route[] = FOOTER_ROUTES_OPTIONS

  public routesMenu(){
    return this.routes.filter(x => x.name != EnumPages.HOME && x.name != EnumPages.SETTINGS && x.name != EnumPages.REQUESTS)
  }
  
  public goToLink(url: string){
    window.open(url, "_blank");
  }

}
