export enum EnumPages {
  LOGIN = "Login",
  NEW_ACCOUNT = "Auto-Cadastro",
  RECOVER = "Recuperar senha",
  HOME = "Home",
  CONSORTIUM = "Consórcio",
  SETTINGS = "Configurações",
  SETTINGS_MESSAGES = "Mensagens",
  SETTINGS_MESSAGES_CREATE = "Mensagens - Criar",
  SETTINGS_MESSAGES_UPDATE = "Mensagens - Alterar",
  SETTINGS_MESSAGES_FILTER = "Mensagens",
  SETTINGS_BANNERS = "Banners",
  SETTINGS_BANNERS_FILTER = "Banners",
  SETTINGS_BANNERS_CREATE = "Banners - Criar",
  SETTINGS_BANNERS_UPDATE = "Banners - Alterar",
  SETTINGS_USERS = "Usuários",
  SETTINGS_USERS_FILTER = "Usuários",
  SETTINGS_USERS_CREATE = "Usuários - Criar",
  SETTINGS_USERS_UPDATE = "Usuários - Alterar",
  SETTINGS_PROFILE = "Perfil de Acesso",
  SETTINGS_PROFILE_FILTER = "Perfil de Acesso",
  SETTINGS_PROFILE_CREATE = "Perfil de Acesso - Criar",
  SETTINGS_PROFILE_UPDATE = "Perfil de Acesso - Alterar",
  SETTINGS_BILLING_CONSULTANCIES = "Assessorias de Cobrança",
  SETTINGS_BILLING_CONSULTANCIES_FILTER = "Assessorias de Cobrança",
  SETTINGS_BILLING_CONSULTANCIES_CREATE = "Assessorias de Cobrança - Criar",
  SETTINGS_BILLING_CONSULTANCIES_UPDATE = "Assessorias de Cobrança - Alterar",
  SETTINGS_PARAMETERS = "Parâmetros",
  SETTINGS_PARAMETERS_FILTER = "Parâmetros",
  SETTINGS_PARAMETER_UPDATE = "Parâmetros - Modificar",
  SETTINGS_CONSUMPTION_REPORT = "Relatórios de consumo",
  SETTINGS_CONSUMPTION_REPORT_FILTER = "Relatórios de consumo",
  BANK = "Banco",
  BANK_PAYMENT = "2ª via de Boleto",
  BANK_BILL = "Antecipar Parcelas",
  BANK_CONTRACT = "Quitação de Contrato",
  BANK_VOUCHER = "2ª via do Carnê",
  BANK_PAYMENT_BOOKLET = "Carnê Digital",
  BANK_STATEMENT = "Extrato Financeiro",
  BANK_ANUAL_STATEMENT = "Declaração Anual de Quitação de Débitos",
  INSURANCE = "Seguros",
  CREDIT = "Liberacred",
  REQUESTS = "Minhas Solicitações",
  FAQ = "Ajuda",
  FAQ_CLIENT = "Ajuda Cliente",
  FAQ_ADMIN = "Ajuda Admin",
  CONTACT = "Entre em contato",
  DEALERS = "Concessionária",
  DEALERS_DEBT_SETTLEMENT = "Tratar Inadimplência",
}
