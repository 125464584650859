import { Component, Input } from '@angular/core';

export declare type SectionModeTypes = 'full' | 'regular' | 'fixed';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent {
  @Input() mode: SectionModeTypes;
  @Input() backgroundColor: string;
  @Input() height: string;
  @Input() minHeight: string;
}
