<button
        [ngStyle]="{'width': width, 'min-width': minWidth}"
        [attr.data-cy]="datacy"
        [disabled]="disabled"
        [type]="type"
        [matTooltip]="tooltip"
        mat-raised-button
        [color]="color"
        (click)="click()"   
>
    <mat-icon *ngIf="icon">{{icon}}</mat-icon>

    <ng-content *ngIf="!loading"></ng-content>
    <mat-spinner diameter="25" *ngIf="loading"></mat-spinner>
</button>