import { AfterContentChecked, Component } from '@angular/core';
import { PERMITION_DEALERS, PERMITION_WEBCHAT, PERMITION_WHATSAPP } from '../const/permitions';
import { BaseComponent } from '../@base/base.component';
import { Robbu } from '../../assets/robbu.js'
import { BannerService } from '../services/banner.service';
import { PageBanner } from '../models/banner/pageBanner';
import { Subscription, filter } from 'rxjs';
import { LOCAL_STORAGE_KEY_BANNERS } from '../const/localStorageKeys';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends BaseComponent implements AfterContentChecked{
  public stateMenu: boolean = false;
  public showFooter: boolean = true;
  
  public banner: PageBanner;

  public onChangeMenuState(): void {
    this.stateMenu = !this.stateMenu
  }


  get marginClass() {
    if(this.banner?.images?.length > 0 && window.screen.width > 900) {
      return ""
    }

    return "margin"
  }


  public $subscriptionRoute: Subscription;

  constructor(
    private bannerService: BannerService
  ){
    super();

    if(Robbu.state == false && (this.getPermissions().includes(PERMITION_WHATSAPP) && this.getPermissions().includes(PERMITION_WEBCHAT))){
      Robbu.initRobbu()
      Robbu.state = true;
    } else {
      if(this.getPermissions().includes(PERMITION_WHATSAPP)){
        this.showWhatApp()
      }
  
      if(this.getPermissions().includes(PERMITION_WEBCHAT)){
        this.showWebChat()
      }
    }

    this.subscriptionChangeBanner()

    this.showFooter = !this.getPermissions().includes(PERMITION_DEALERS);
  }

  ngAfterContentChecked(): void {

    if(!this.getPermissions().includes(PERMITION_WHATSAPP)){
      this.hideWhatsApp()
    }

    if(!this.getPermissions().includes(PERMITION_WEBCHAT)){
      this.hideWebChat()
    }
  }

  
  private changeBanner(): void {
    this.banner = this.getLocalStorageItem<PageBanner[]>(LOCAL_STORAGE_KEY_BANNERS)?.find(x => this.router.url.includes(x.page))
    
    this.bannerService.bannersSubscribe$.subscribe(() => {
      this.banner = this.getLocalStorageItem<PageBanner[]>(LOCAL_STORAGE_KEY_BANNERS)?.find(x => this.router.url.includes(x.page))
    })
  }

  private subscriptionChangeBanner(): void {
    this.changeBanner()

    this.$subscriptionRoute = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.changeBanner()
      });
  }


}
