import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-expansible',
  templateUrl: './card-expansible.component.html',
  styleUrls: ['./card-expansible.component.scss']
})
export class CardExpansibleComponent {

  @Input() labelOpened: string = "";
  @Input() labelClosed: string = "";
  @Input() expansible: boolean = false;

  public isExpanded: boolean = false;
}
