<app-modal class="density-lower" title="Nova Solicitação" data-cy="modal-bank-change-registration-data"
  [disableButtonClose]="isSubmitting">

  <div class="modal-content density-mat-form-3">

    <span class="reason estricta uppercase" data-cy="modal-bank-change-registration-data">
      {{ subject.product + " - " + subject.description }}
    </span>

    <div>
      Caso seja necessário apenas excluir esta informação, é necessário entrar em contato com a nossa Central de
      Relacionamento para esclarecimentos.
    </div>

    <form [formGroup]="form" (keydown.enter)="$event.preventDefault(); onSubmit()">
      <section>

        <section class="text">
          Marque as opções abaixo que deseja alterar:
        </section>

        <div>
          <mat-checkbox color="primary" (change)="toggleShowSection($event, 'email')" [disabled]="isLoadingData"
            data-cy="toggleEmailCheckbox"></mat-checkbox>

          <span>
            <label> E-mail </label>
            <label *ngIf="isLoadingData"> (Carregando) </label>
          </span>
        </div>

        <div *ngIf="showEmail" class="flex">

          <div>
            <mat-label><span class="red">*</span> <span>E-mail Primário </span> </mat-label>
            <mat-form-field appearance="outline" class="background-white">
              <input type="email" matInput placeholder="Digite Aqui"
                [placeholder]="isLoadingData ? 'Carregando' : 'Não informado'" formControlName="newPrimaryEmail"
                data-cy="new-primary-email" maxlength="100" />
              <mat-error *ngIf="
                  form.get('newPrimaryEmail').hasError('required') &&
                  form.get('newPrimaryEmail').touched
                  " data-cy="error-new-primary-email-invalid">
                <strong> Campo obrigatório </strong>
              </mat-error>
              <mat-error *ngIf="
                  form.get('newPrimaryEmail').hasError('email') &&
                  form.get('newPrimaryEmail').touched
                  " data-cy="error-new-primary-email-invalid">
                <strong> E-mail inválido </strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <mat-label> <span *ngIf="requiredSecondaryEmail" class="red">*</span> E-mail Secundário </mat-label>
            <mat-form-field appearance="outline" class="background-white">
              <input type="email" matInput placeholder="Digite Aqui" formControlName="newSecondaryEmail"
                [placeholder]="isLoadingData ? 'Carregando' : 'Não informado'" data-cy="new-secondary-email"
                maxlength="100" />

              <mat-error
                *ngIf="form.get('newSecondaryEmail').hasError('required') && form.get('newSecondaryEmail').touched"
                data-cy="error-new-secondary-email-invalid">
                <strong> Campo obrigatório </strong>
              </mat-error>

              <mat-error
                *ngIf="form.get('newSecondaryEmail').hasError('email') && form.get('newSecondaryEmail').touched"
                data-cy="error-new-secondary-email-invalid">
                <strong> E-mail Inválido </strong>
              </mat-error>

            </mat-form-field>
          </div>

        </div>

      </section>

      <section>

        <div>
          <mat-checkbox color="primary" (change)="toggleShowSection($event, 'phone')" [disabled]="isLoadingData"
            data-cy="togglePhoneCheckbox"></mat-checkbox>

          <span>
            <label> Telefone </label>
            <label *ngIf="isLoadingData"> (Carregando) </label>
          </span>
        </div>

        <div *ngIf="showPhone" class="flex">

          <div>
            <mat-label> <span class="red">*</span> <span>Telefone Primário (Celular)</span> </mat-label>
            <mat-form-field appearance="outline" class="background-white">

              <input matInput type="text" maxlength="20" formControlName="newPrimaryPhone" data-cy="newPrimaryPhone"
                mask="(00) 0000-0000||(00) 00000-0000" [placeholder]="isLoadingData ? 'Carregando' : 'Não informado'" />

              <mat-error *ngIf="form.get('newPrimaryPhone').hasError('required') && form.get('newPrimaryPhone').touched"
                data-cy="error-new-primary-phone-invalid">
                <strong> Campo obrigatório </strong>
              </mat-error>

              <mat-error
                *ngIf="form.get('newPrimaryPhone').hasError('minlength') && form.get('newPrimaryPhone').touched"
                data-cy="error-new-primary-phone-invalid">
                <strong> Telefone inválido </strong>
              </mat-error>

            </mat-form-field>
          </div>

          <div>
            <mat-label> <span *ngIf="requiredSecondaryPhone" class="red">*</span> <span>Telefone Secundário</span>
            </mat-label>
            <mat-form-field appearance="outline" class="background-white">

              <input matInput type="text" maxlength="20" formControlName="newSecondaryPhone" data-cy="newSecondaryPhone"
                mask="(00) 0000-0000||(00) 00000-0000" [placeholder]="isLoadingData ? 'Carregando' : 'Não informado'" />

              <mat-error
                *ngIf="form.get('newSecondaryPhone').hasError('required') && form.get('newSecondaryPhone').touched"
                data-cy="error-new-secondary-phone-invalid">
                <strong> Campo obrigatório </strong>
              </mat-error>

              <mat-error
                *ngIf="form.get('newSecondaryPhone').hasError('minlength') && form.get('newSecondaryPhone').touched"
                data-cy="error-new-secondary-phone-invalid">
                <strong> Telefone inválido </strong>
              </mat-error>

            </mat-form-field>
          </div>

        </div>

      </section>
    </form>

  </div>

  <div class="modal-bottom">

    <app-button width="100px" (onClick)="onClickBtnClose()" [disabled]="isSubmitting" datacy="Cancelar">
      <span class="button-label"> Cancelar </span>
    </app-button>

    <app-button type="submit" color="primary" [disabled]="isInvalid" datacy="update-button" [loading]="isSubmitting"
      datacy="Enviar" (onClick)="onSubmit()">
      <span class="button-label"> Enviar </span>
    </app-button>

  </div>
</app-modal>