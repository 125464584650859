<ng-container *ngIf="!table.loading; else containerLoading">
  <ng-content select=".table-header" class="header"></ng-content>

  <div class="container" [style.maxHeight]="height" [style.maxWidth]="maxWidth">
    <mat-accordion *ngIf="isSmallScreen">
      <mat-expansion-panel class="expansion-class" *ngFor="let row of dataSource.data" [expanded]="false">
        <mat-expansion-panel-header class="custom-expansion-panel">
          <mat-panel-description class="checkbox-expansion" *ngIf="table.checkbox">
            <ng-container>
              <mat-checkbox *ngIf="!shouldDisableCheckbox(row)" #checkboxes (change)="onRowChange(row, $event)"
                color="primary" class="checkbox"
                [attr.data-cy]="getDatacy('bankSelectInstallment', row)" [checked]="row.selected"></mat-checkbox>
            </ng-container>
          </mat-panel-description>

          <mat-panel-description>
            <span [ngClass]="getColor(row.dueDate, row.paymentDay, row.instalmentNumber)"></span>

            <strong style="text-wrap: nowrap;" [attr.data-cy]="getDatacy('bankInstallment', row)">
              {{ row.instalmentNumber || row.number }}
            </strong>
          </mat-panel-description>
          <mat-panel-description *ngIf="showPaymentDay" [attr.data-cy]="getDatacy('bankInstallmentPaymentDate', row)">
            Pagamento <br> {{ row.paymentDay ? (row.paymentDay | date: "dd/MM/yyyy") : '-' }}
          </mat-panel-description>
          <mat-panel-description [attr.data-cy]="getDatacy('bankInstallmentDueDate', row)">
            Vencimento <br> {{ row.dueDate | date: "dd/MM/yyyy" }}
          </mat-panel-description>

          <ng-container *ngIf="table.actions">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onActionChange(row)">
                Editar
              </button>
              <button mat-menu-item (click)="onActionDelete(row)">
                Excluir
              </button>
            </mat-menu>
          </ng-container>
        </mat-expansion-panel-header>
        <div *ngIf="isSmallScreen" class="expansion-border"
          style="display: flex; justify-content: space-between; align-items: center;">
          <span style="flex: 1; text-align: center;">
            <span [attr.data-cy]="getDatacy('bankInstallmentPaymentOriginalValue', row)">
              Original <br> <b>{{ row.originalValue | currency: 'BRL' }}</b></span>
          </span>
          <span style="flex: 1; text-align: center;">
            <span [attr.data-cy]="getDatacy('bankInstallmentPaymentDiscount', row)">
              Desc./Acrésc.
              <br>
              <b [ngClass]="getPaymentColor((row.originalValue - (row.paymentValue || row.total)))">
                {{ calculateAndFormat(row) }}
              </b>
            </span>
          </span>
          <span style="flex: 1; text-align: center;"
            [attr.data-cy]="getDatacy('bankInstallmentPaymentCurrentValue', row)">
            <span>Atual <br> <b>{{ disabledValueActual(row) }}</b></span>
          </span>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div *ngIf="!isSmallScreen" class="card-container">

    <div class="card" *ngFor="let row of dataSource.data">
      <app-card>
        <div class="flex">

          <div class="card-checkbox">
            <mat-checkbox *ngIf="!shouldDisableCheckbox(row)" #checkboxes (change)="onRowChange(row, $event)"
              color="primary" class="checkbox" [attr.data-cy]="getDatacy('bankSelectInstallment', row)" [checked]="row.selected"></mat-checkbox>
          </div>

          <div class="card-content">
            <span class="card-content-payment">
              <span [ngClass]="getColor(row.dueDate, row.paymentDay, row.number)"></span>

              <span [attr.data-cy]="getDatacy('bankInstallment', row)">
                {{ row.instalmentNumber || row.number }}
              </span>
            </span>
            <span *ngIf="showPaymentDay" class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('bankInstallmentPaymentDateLabel', row)">
                Pagamento
              </span>
              <span class="card-content-value" [attr.data-cy]="getDatacy('bankInstallmentPaymentDate', row)">
                {{ row.paymentDay ? (row.paymentDay | date: "dd/MM/yyyy") : '-' }}
              </span>
            </span>
            <span class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('bankInstallmentDueDateLabel', row)">
                Vencimento
              </span>
              <span class="card-content-value" [attr.data-cy]="getDatacy('bankInstallmentDueDate', row)">
                {{ row.dueDate | date: "dd/MM/yyyy" }}
              </span>
            </span>
            <span class="card-content-item">
              <span class="card-content-key"
                [attr.data-cy]="getDatacy('bankInstallmentPaymentOriginalValueLabel', row)">
                Valor original
              </span>
              <span class="card-content-value" [attr.data-cy]="getDatacy('bankInstallmentPaymentOriginalValue', row)">
                {{ row.originalValue | currency: 'BRL' }}
              </span>
            </span>
            <span class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('bankInstallmentPaymentDiscountLabel', row)">
                Desconto / Acréscimo
              </span>
              <span [ngClass]="getPaymentColor((row.originalValue - (row.paymentValue || row.total)))"
                [attr.data-cy]="getDatacy('bankInstallmentPaymentDiscount', row)">
                {{ calculateAndFormat(row) }}
              </span>
            </span>
            <span class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('bankInstallmentPaymentCurrentValueLabel', row)">
                Valor atual
              </span>
              <span class="card-content-value" [attr.data-cy]="getDatacy('bankInstallmentPaymentCurrentValue', row)">
                {{ disabledValueActual(row) }}
              </span>
            </span>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</ng-container>

<ng-template #containerLoading>
  <div class="loading">
    <mat-spinner *ngIf="table.loading"></mat-spinner>
  </div>
</ng-template>
