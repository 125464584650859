import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { BannerImage } from 'src/app/models/banner/pageBanner';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnChanges {

  @Input() images: BannerImage[] = [];
  @Input() fullPage: boolean = false;
  @Input() showButtons: boolean = true;
  @Input() useAuthLayoutButtonsPosition: boolean = false;
  @ViewChildren("next") nextBtns: QueryList<ElementRef>

  public message: string;
  public timeout: any;
  public index: number = 0;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.clearTimeout(0);

    if(changes['images']?.currentValue && changes['images']?.currentValue?.length > 1){
      this.createTimeout()
    }
  }

  private createTimeout(): void {
    this.timeout = setTimeout(() => {
      this.nextBtns.get((this.index + 1) == this.images.length ? 0 : this.index + 1).nativeElement.click()
    }, 5000)
  }

  private clearTimeout(index: number): void {
    this.index = index
    clearTimeout(this.timeout)
  }

  public changeIndex(i: number): void {
    this.index = i
    this.clearTimeout(i)
    this.createTimeout()
  }

  public getId(num: number): string{
    return "carousel-" + (num + 1)
  }

  public getClassPrev(num: number): string{
    return ((num + 1) == this.images.length ? "carousel-control prev control-1" : "carousel-control prev control-" + (num + 2)) + this.getClassIfShowButtons()
  }

  public getClassNext(num: number): string{
    return ((num + 1) == 1 ? "carousel-control next control-" + this.images.length : "carousel-control next control-" + num) + this.getClassIfShowButtons()
  }

  public getClassIfShowButtons(): string {
    return this.showButtons ? "" : "zero-opacity"
  }

  public goToLink(url: string){
    if(url){
      window.open(url, "_blank");
    }
  }

  public detectar_mobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    else {
      return false;
    }
  }

}
