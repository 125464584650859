import { EnumPages } from '../enum/pages';
import { EnumPath } from '../enum/path';
import { EnumProduct } from '../enum/products';
import { Route } from '../models/route';
import {
  PERMITION_BANK,
  PERMITION_BANK_ANUAL_STATEMENT,
  PERMITION_BANK_BILL,
  PERMITION_BANK_CONTRACT,
  PERMITION_BANK_PAYMENT,
  PERMITION_BANK_STATEMENT,
  PERMITION_PAYMENT_BOOK,
  PERMITION_BANNERS,
  PERMITION_CONSORTIUM,
  PERMITION_CREDIT,
  PERMITION_INSURANCE,
  PERMITION_MESSAGES,
  PERMITION_PROFILE,
  PERMITION_REQUESTS,
  PERMITION_SETTINGS,
  PERMITION_USERS,
  PERMISSION_BILLING_CONSULTANCIES,
  PERMITION_FAQ,
  PERMITION_SETTINGS_PARAMETER,
  PERMITION_SETTINGS_PARAMETER_READ,
  PERMITION_SETTINGS_PARAMETER_UPDATE,
  PERMITION_BANNERS_UPDATE,
  PERMITION_BANNERS_CREATE,
  PERMITION_BANNERS_READ,
  PERMITION_PROFILE_READ,
  PERMITION_PROFILE_UPDATE,
  PERMITION_PROFILE_CREATE,
  PERMITION_MESSAGES_UPDATE,
  PERMITION_MESSAGES_READ,
  PERMITION_MESSAGES_CREATE,
  PERMISSION_BILLING_CONSULTANCIES_UPDATE,
  PERMISSION_BILLING_CONSULTANCIES_CREATE,
  PERMISSION_BILLING_CONSULTANCIES_READ,
  PERMITION_CONTACT,
  PERMITION_CONSUMPTION_REPORT,
  PERMITION_CONSUMPTION_REPORT_READ,
  PERMITION_BANK_PAYMENT_BOOKLET,
  PERMITION_DEALERS,
  PERMITION_DEALERS_SETTLEMENT,
} from './permitions';

export const ROUTES: Route[] = [
  {
    path: EnumPath.LOGIN,
    name: EnumPages.LOGIN,
  },
  {
    path: EnumPath.NEW_ACCOUNT,
    name: EnumPages.NEW_ACCOUNT,
  },
  {
    path: EnumPath.RECOVER,
    name: EnumPages.RECOVER,
  },
  {
    path: EnumPath.HOME,
    name: EnumPages.HOME,
  },
  {
    path: EnumPath.SETTINGS,
    name: EnumPages.SETTINGS,
    permissions: [PERMITION_SETTINGS],
    menu: true,
    children: [
      {
        path: EnumPath.SETTINGS_USERS,
        name: EnumPages.SETTINGS_USERS,
        permissions: [PERMITION_USERS],
        children: [
          {
            path: EnumPath.SETTINGS_USERS_CREATE,
            name: EnumPages.SETTINGS_USERS_CREATE,
            permissions: [PERMITION_USERS],
          },
          {
            path: EnumPath.SETTINGS_USERS_UPDATE,
            name: EnumPages.SETTINGS_USERS_UPDATE,
            permissions: [PERMITION_USERS],
          },
          {
            path: EnumPath.SETTINGS_USERS_FILTER,
            name: EnumPages.SETTINGS_USERS_FILTER,
            permissions: [PERMITION_USERS],
          },
        ],
      },
      {
        path: EnumPath.SETTINGS_PROFILE,
        name: EnumPages.SETTINGS_PROFILE,
        permissions: [PERMITION_PROFILE],
        children: [
          {
            path: EnumPath.SETTINGS_PROFILE_CREATE,
            name: EnumPages.SETTINGS_PROFILE_CREATE,
            permissions: [PERMITION_PROFILE_CREATE],
          },
          {
            path: EnumPath.SETTINGS_PROFILE_UPDATE,
            name: EnumPages.SETTINGS_PROFILE_UPDATE,
            permissions: [PERMITION_PROFILE_UPDATE],
          },
          {
            path: EnumPath.SETTINGS_PROFILE_FILTER,
            name: EnumPages.SETTINGS_PROFILE_FILTER,
            permissions: [PERMITION_PROFILE_READ],
          },
        ],
      },
      {
        path: EnumPath.SETTINGS_BANNERS,
        name: EnumPages.SETTINGS_BANNERS,
        permissions: [PERMITION_BANNERS],
        children: [
          {
            path: EnumPath.SETTINGS_BANNERS_CREATE,
            name: EnumPages.SETTINGS_BANNERS_CREATE,
            permissions: [PERMITION_BANNERS_CREATE],
          },
          {
            path: EnumPath.SETTINGS_BANNERS_UPDATE,
            name: EnumPages.SETTINGS_BANNERS_UPDATE,
            permissions: [PERMITION_BANNERS_UPDATE],
          },
          {
            path: EnumPath.SETTINGS_BANNERS_FILTER,
            name: EnumPages.SETTINGS_BANNERS_FILTER,
            permissions: [PERMITION_BANNERS_READ],
          },
        ],
      },
      {
        path: EnumPath.SETTINGS_MESSAGES,
        name: EnumPages.SETTINGS_MESSAGES,
        permissions: [PERMITION_MESSAGES],
        children: [
          {
            path: EnumPath.SETTINGS_MESSAGES_CREATE,
            name: EnumPages.SETTINGS_MESSAGES_CREATE,
            permissions: [PERMITION_MESSAGES_CREATE],
          },
          {
            path: EnumPath.SETTINGS_MESSAGES_UPDATE,
            name: EnumPages.SETTINGS_MESSAGES_UPDATE,
            permissions: [PERMITION_MESSAGES_UPDATE],
          },
          {
            path: EnumPath.SETTINGS_MESSAGES_FILTER,
            name: EnumPages.SETTINGS_MESSAGES_FILTER,
            permissions: [PERMITION_MESSAGES_READ],
          },
        ],
      },
      {
        path: EnumPath.SETTINGS_CONSUMPTION_REPORT,
        name: EnumPages.SETTINGS_CONSUMPTION_REPORT,
        permissions: [PERMITION_CONSUMPTION_REPORT],
        children: [
          {
            path: EnumPath.SETTINGS_CONSUMPTION_REPORT_FILTER,
            name: EnumPages.SETTINGS_CONSUMPTION_REPORT_FILTER,
            permissions: [PERMITION_CONSUMPTION_REPORT_READ]
          }
        ]
      },
      {
        path: EnumPath.SETTINGS_BILLING_CONSULTANCIES,
        name: EnumPages.SETTINGS_BILLING_CONSULTANCIES,
        permissions: [PERMISSION_BILLING_CONSULTANCIES],
        children: [
          {
            path: EnumPath.SETTINGS_BILLING_CONSULTANCIES_CREATE,
            name: EnumPages.SETTINGS_BILLING_CONSULTANCIES_CREATE,
            permissions: [PERMISSION_BILLING_CONSULTANCIES_CREATE],
          },
          {
            path: EnumPath.SETTINGS_BILLING_CONSULTANCIES_UPDATE,
            name: EnumPages.SETTINGS_BILLING_CONSULTANCIES_UPDATE,
            permissions: [PERMISSION_BILLING_CONSULTANCIES_UPDATE],
          },
          {
            path: EnumPath.SETTINGS_BILLING_CONSULTANCIES_FILTER,
            name: EnumPages.SETTINGS_BILLING_CONSULTANCIES_FILTER,
            permissions: [PERMISSION_BILLING_CONSULTANCIES_READ],
          },
        ],
      },
      {
        name: EnumPages.SETTINGS_PARAMETERS,
        path: EnumPath.SETTINGS_PARAMETER,
        permissions: [PERMITION_SETTINGS_PARAMETER],
        children: [
          {
            name: EnumPages.SETTINGS_PARAMETERS_FILTER,
            path: EnumPath.SETTINGS_PARAMETER_FILTER,
            permissions: [PERMITION_SETTINGS_PARAMETER_READ],
          },
          {
            name: EnumPages.SETTINGS_PARAMETER_UPDATE,
            path: EnumPath.SETTINGS_PARAMETER_UPDATE,
            permissions: [PERMITION_SETTINGS_PARAMETER_UPDATE],
          },
        ],
      },
    ],
  },
  {
    path: EnumPath.CONSORTIUM,
    name: EnumPages.CONSORTIUM,
    menu: true,
    permissions: [PERMITION_CONSORTIUM],
  },
  {
    path: EnumPath.BANK,
    name: EnumPages.BANK,
    menu: true,
    permissions: [PERMITION_BANK],
    children: [
      {
        path: EnumPath.BANK_PAYMENT,
        name: EnumPages.BANK_PAYMENT,
        permissions: [PERMITION_BANK_PAYMENT, PERMITION_BANK_BILL],
      },
      {
        path: EnumPath.BANK_BILL,
        name: EnumPages.BANK_BILL,
        permissions: [PERMITION_BANK_BILL, PERMITION_BANK_PAYMENT],
      },
      {
        path: EnumPath.BANK_PAYMENT_BOOKLET,
        name: EnumPages.BANK_PAYMENT_BOOKLET,
        permissions: [PERMITION_BANK_PAYMENT_BOOKLET],
      },
      {
        path: EnumPath.BANK_CONTRACT,
        name: EnumPages.BANK_CONTRACT,
        permissions: [PERMITION_BANK_CONTRACT],
      },
      {
        path: EnumPath.BANK_PAYMENT_BOOK,
        name: EnumPages.BANK_VOUCHER,
        permissions: [PERMITION_PAYMENT_BOOK],
      },
      {
        path: EnumPath.BANK_STATEMENT,
        name: EnumPages.BANK_STATEMENT,
        permissions: [PERMITION_BANK_STATEMENT],
      },
      {
        path: EnumPath.BANK_ANUAL_STATEMENT,
        name: EnumPages.BANK_ANUAL_STATEMENT,
        permissions: [PERMITION_BANK_ANUAL_STATEMENT],
      },
    ],
  },
  {
    path: EnumPath.INSURANCE,
    name: EnumPages.INSURANCE,
    menu: true,
    permissions: [PERMITION_INSURANCE],
  },
  {
    path: EnumPath.CREDIT,
    name: EnumPages.CREDIT,
    menu: true,
    permissions: [PERMITION_CREDIT],
  },
  {
    path: EnumPath.REQUESTS,
    name: EnumPages.REQUESTS,
    menu: true,
    permissions: [PERMITION_REQUESTS],
  },
  {
    path: EnumPath.FAQ,
    name: EnumPages.FAQ,
    menu: true,
    permissions: [PERMITION_FAQ],
  },
  {
    path: EnumPath.CONTACT,
    name: EnumPages.CONTACT,
    menu: true,
    permissions: [PERMITION_CONTACT]
  },
  {
    path: EnumPath.DEALERS,
    name: EnumPages.DEALERS,
    menu: true,
    permissions: [PERMITION_DEALERS],
    children: [
      {
        path: EnumPath.DEALERS_DEBT_SETTLEMENT,
        name: EnumPages.DEALERS_DEBT_SETTLEMENT,
        permissions: [PERMITION_DEALERS_SETTLEMENT],
      },
    ],
  },
];

export const FOOTER_ROUTES_PRODUCTS: Route[] = [
  {
    name: EnumProduct.CONSORTIUM,
    path: EnumPath.FOOTER_CONSORTIUM,
    permissions: [PERMITION_CONSORTIUM],
  },
  {
    name: EnumProduct.BANK,
    path: EnumPath.FOOTER_BANK,
    permissions: [PERMITION_BANK],
  },
  {
    name: EnumProduct.INSURANCE,
    path: EnumPath.FOOTER_INSURANCE,
    permissions: [PERMITION_INSURANCE],
  },
  {
    name: EnumProduct.CREDIT,
    path: EnumPath.FOOTER_CREDIT,
    permissions: [PERMITION_CREDIT],
  },
];

export const FOOTER_ROUTES_POLITCS: Route[] = [
  {
    name: 'Aviso de privacidade',
    path: EnumPath.FOOTER_PRIVACY_ADVERTISING,
  },
  {
    name: 'Politica de segurança Cibernética',
    path: EnumPath.FOOTER_CYBERSECURITY_POLICY,
  },
  {
    name: 'Política de qualidade e ambiental',
    path: EnumPath.FOOTER_QUALITY_ENVIRONMENT,
  },
  {
    name: 'Canal de Denúncia',
    path: EnumPath.FOOTER_REPORTING_CHANNEL,
  },
  {
    name: 'Código de Ética',
    path: EnumPath.FOOTER_CODE_ETHICS,
  },
];

export const FOOTER_ROUTES_OPTIONS: Route[] = [
  {
    name: 'YAMAHA MOTOR',
    path: EnumPath.FOOTER_YAMAHA_MOTOR,
  },
  {
    name: 'YAMAHA MUSICAL',
    path: EnumPath.FOOTER_YAMAHA_MUSICAL,
  },
  {
    name: 'YAMAHA NÁUTICA',
    path: EnumPath.FOOTER_YAMAHA_NAUTICA,
  },
];
