import { Component } from '@angular/core';
import { EnumProduct } from 'src/app/enum/products';
import { Option } from 'src/app/models/option';
import { QUICK_ACCESS_CONSORTIUM } from 'src/app/const/options';

@Component({
  selector: 'app-product-consortium',
  templateUrl: './product-consortium.component.html',
  styleUrls: ['./product-consortium.component.scss']
})
export class ProductConsortiumComponent {
  public type: EnumProduct = EnumProduct.CONSORTIUM
  public options: Option[] = QUICK_ACCESS_CONSORTIUM
  public consortiumOptionsPermition: string[] = QUICK_ACCESS_CONSORTIUM.map(x => x.permission)

  constructor() { }
}
