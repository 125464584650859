import { Component, AfterViewInit, Renderer2, ElementRef, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/@base/base.component';
import { COOKIE_CONSENT, DENY_INSTALL_PWA } from 'src/app/const/cookiesKeys';
import { ElementReadyService } from 'src/app/helpers/elemente-ready';
import { PwaServiceService } from 'src/app/services/pwa-service.service';


@Component({
  selector: 'app-install-pwa',
  templateUrl: './install-pwa.component.html',
  styleUrls: ['./install-pwa.component.scss']
})
export class InstallPwaComponent  extends BaseComponent implements OnInit, AfterViewInit {

  constructor(private renderer: Renderer2, private el: ElementRef, private elementReadyService: ElementReadyService, public pwaService: PwaServiceService) {
    super();
    }

  ngOnInit() {
    this.elementReadyService.elementReady$.subscribe(() => {
      this.positionElements();
    });
  }

  ngAfterViewInit() {
    this.positionElements();
  }

  positionElements() {
    const checkExist = setInterval(() => {
      const elemento1 = document.querySelector('.cookie-consent') as HTMLElement;
      const elemento2 = this.el.nativeElement.querySelector('.pwa-install') as HTMLElement;
  
      if (elemento1 && elemento2) {
        const alturaElemento1 = elemento1.offsetHeight;
        this.renderer.setStyle(elemento2, 'bottom', `${alturaElemento1}px`);
        clearInterval(checkExist);
      }
    }, 100); // verifica a cada 100ms
  }

  public deny(): void {
    this.setCookieItem(DENY_INSTALL_PWA, true)
  }

  public isPwaInstalledOrDenyed(): boolean {
    return this.getCookieItem(DENY_INSTALL_PWA) || this.pwaService.isPwaInstalled();
  }

  public detectar_mobile() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }
}