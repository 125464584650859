<table>
  <tr>
    <th>Criação:</th>
    <td data-cy="user-info-createdAt">{{ createdAt || form?.get("createdAt").value || '' | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
  </tr>
  <tr>
    <th>Criado por:</th>
    <td data-cy="user-info-createdBy">{{ createdBy || form?.get("createdBy").value || ''}}</td>
  </tr>
  <tr>
    <th>Alteração:</th>
    <td data-cy="user-info-lastModifiedAt">{{ lastModifiedAt || form?.get("lastModifiedAt").value || '' | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
  </tr>
  <tr>
    <th>Alterado por:</th>
    <td data-cy="user-info-lastModifiedBy">{{ lastModifiedBy || form?.get("lastModifiedBy").value || ''}}</td>
  </tr>
  <tr *ngIf="form?.get('lastLoginAt')?.value">
    <th>Último Login:</th>
    <td data-cy="user-info-lastLoginAt">{{ form?.get("lastLoginAt").value || '' | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
  </tr>
  <tr *ngIf="form?.get('lastLoginType')?.value">
    <th>Tipo Último Login:</th>
    <td data-cy="user-info-lastLoginType">{{ lastLoginType || form?.get("lastLoginType").value || ''}}</td>
  </tr>
</table>
