import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { SectionModeTypes } from 'src/app/components/section/section.component';
import { BaseComponent } from 'src/app/@base/base.component';
import { BankService } from 'src/app/services/bank.service';
import { Contract } from 'src/app/models/bank/contract';
import { Subscriber, Subscription } from 'rxjs';
import { BankAmountComponent } from '../bank-amount/bank-amount.component';

@Component({
  selector: 'app-bank-header',
  templateUrl: './bank-header.component.html',
  styleUrls: ['./bank-header.component.scss']
})
export class BankHeaderComponent extends BaseComponent implements OnInit, OnDestroy{

  @Input()firstDate = "";
  @Input()LastDate = "";

  public sectionMode: SectionModeTypes = "full";
  public contractSelected: Contract
  public $contractSubscriber: Subscription;
  public BankAmountComponent: BankAmountComponent;
  
  get userAboutInfoClass(){
    return 'user-about-info-itens-1'
  }

  constructor(
    private bankService: BankService
  ){
      super()   
  }

  ngOnInit() {
    this.$contractSubscriber = this.bankService.emitContractSelected.subscribe((x: Contract) => {
      this.contractSelected = x;
    })

  }

  ngOnDestroy(): void {
    this.$contractSubscriber.unsubscribe()
  }

  public detectar_mobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    else {
      return false;
    }
  }

}
