

<app-section backgroundColor="#2548a3" [mode]="'fixed'" [height]="'100px'">
  
  <div class="section-content">
    <div class="align-title">
      <span class="section-title title estricta"> Bem-vindo(a) ao Portal Banco Yamaha! </span>
    </div>
  </div>
  
</app-section>
<div class="section-subtitle">
  <span class="title estricta" data-cy="bankDigitalBookletTitle"> consulta de inadimplentes </span>
</div>

<app-card class="density-lower">
    <div class="section-content">
      <div class="button-container">
        
        <button mat-button class="bt-layout bt-over30" (click)="insertOrRemovePeriodOver30()" [ngClass]="{'bt-layout-active': over30Selected}">
          
          <div class="header">
            <i *ngIf="!over30Selected" class="fa fa-eye-slash"></i>
            <i *ngIf="over30Selected" class="fa fa-eye"></i>
          </div>
          <span *ngIf="!loadingGrouped" class="body"> {{debtsGrouped?.debtCountFromOver30Range ?? 0 }} </span>
          <span *ngIf="!loadingGrouped" class="body"> {{debtsGrouped?.totalAmountDueFromOver30Range ?? 0 | currency }} </span>
          <mat-spinner *ngIf="loadingGrouped" [color]="'#ffffff'"></mat-spinner>
          <span class="footer">Atraso de 1 a 60 dias</span>

        </button>
        <button class="bt-layout bt-over60" mat-flat-button (click)="insertOrRemovePeriodOver60()" [ngClass]="{'bt-layout-active': over60Selected}">
          
          <div class="header">
            <i *ngIf="!over60Selected" class="fa fa-eye-slash"></i>
            <i *ngIf="over60Selected" class="fa fa-eye"></i>
          </div>
          <span *ngIf="!loadingGrouped" class="body"> {{debtsGrouped?.debtCountFromOver60Range ?? 0 }} </span>
          <span *ngIf="!loadingGrouped" class="body"> {{ debtsGrouped?.totalAmountDueFromOver60Range ?? 0 | currency }} </span>
          <mat-spinner *ngIf="loadingGrouped" ></mat-spinner>
          <span class="footer">Atraso de 61 a 90 dias</span>

        </button>
        <button class="bt-layout bt-over90" mat-flat-button (click)="insertOrRemovePeriodOver90()" [ngClass]="{'bt-layout-active': over90Selected}">
          
          <div class="header">
            <i *ngIf="!over90Selected" class="fa fa-eye-slash"></i>
            <i *ngIf="over90Selected" class="fa fa-eye"></i>
          </div>
          <span *ngIf="!loadingGrouped" class="body"> {{debtsGrouped?.debtCountFromOver90Range ?? 0 }} </span>
          <span *ngIf="!loadingGrouped" class="body"> {{debtsGrouped?.totalAmountDueFromOver90Range ?? 0 | currency }} </span>
          <mat-spinner *ngIf="loadingGrouped" ></mat-spinner>
          <span class="footer">Atraso de 91 a 360 dias</span>

        </button>  
      </div>
      <br>
      <form [formGroup]="form" class="density-mat-form-3">
        <section >
          <div class="form-filter">
            <span class="form-field">
              <label> CPF / CNPJ: </label>
              <mat-form-field appearance="outline" class="background-white">
                  <input type="text"
                        matInput
                        formControlName="clientDocument"
                        maxlength="18"
                        mask="000.000.000-00||00.000.000/0000-00"
                        data-cy="document"
                        (keydown.enter)="filter(selected.value)"
                        >
                  <mat-error *ngIf="form.get('clientDocument').hasError('mask')" data-cy="error-document-format-invalid">
                      <strong> {{labelDocument}} informado é inválido </strong>
                  </mat-error>
  
                  <mat-error *ngIf="form.get('clientDocument').hasError('cpfNotValid') || form.get('clientDocument').hasError('cnpjNotValid')" data-cy="error-document-value-invalid">
                      <strong> {{labelDocument}} informado é inválido </strong>
                  </mat-error>
              </mat-form-field>
            </span>
          </div>
          <div class="bt-form-filter">
            <app-button 
                        width="150px"
                        datacy="button-clean"
                        [disabled]="!form.get('clientDocument').value || loadingButton"
                        (onClick)="cleanForm()"
                        >
                <span style="font-size: 16px;"> Limpar </span>
            </app-button >
  
            <app-button type="submit"
                        width="150px"
                        color="primary"
                        color="primary"
                        [disabled]="!form.valid || !form.get('clientDocument').value || loadingButton"
                        [loading]="loadingButton"
                        (onClick)="filter(selected.value, true)"
                        >
                <span style="font-size: 16px;"> Pesquisar </span>
            </app-button >
          </div>
        </section>
      </form>
      <br>

      <mat-tab-group animationDuration="0ms"
        *ngIf="over30Selected || over60Selected || over90Selected; else noCardSelected"
        [selectedIndex]="selected.value"
        (selectedIndexChange)="selected.setValue($event); filter($event, true)">
        
        <mat-tab *ngIf="over30Selected">
          <ng-template mat-tab-label>
            
            <span >Atraso de 1 a 60 dias</span>
          </ng-template>
          <br>
          <div class="table">
            <app-table [table]="table" 
            (emitPaginatorChanged)="pageChange($event)"
            [optionTemplate]="buttonTemplate"></app-table>
          </div>

        </mat-tab>
      
        <mat-tab *ngIf="over60Selected">
          <ng-template mat-tab-label>
            <span >Atraso de 61 a 90 dias</span>
          </ng-template>
          <br>
          <div class="table">
            <app-table [table]="table" 
            (emitPaginatorChanged)="pageChange($event)"
            [optionTemplate]="buttonTemplate"></app-table>
          </div>

        </mat-tab>
      
        <mat-tab *ngIf="over90Selected">
          <ng-template mat-tab-label>
            
            <span >Atraso de 91 a 360 dias</span>
          </ng-template>
          <br>
          <div class="table">
            <app-table [table]="table" 
            (emitPaginatorChanged)="pageChange($event)"
            [optionTemplate]="buttonTemplate"></app-table>
          </div>
        </mat-tab>
      </mat-tab-group>

      <br>

      
    </div>
    <br/>
    
</app-card>

<br>

<!-- <div class="table">
  <app-table [table]="table" 
  (emitPaginatorChanged)="pageChange($event)"
  [optionTemplate]="buttonTemplate"></app-table>
</div> -->

<ng-template #noCardSelected>
  <p >
    Selecione um período para visualização dos dados.
  </p>
</ng-template>


<ng-template #buttonTemplate let-row>
  <button mat-menu-item *ngIf="getPermissionHistory" (click)="onShowDebtBillingHistoriesClick(row)">
    Consultar Histórico
  </button>
  <button mat-menu-item *ngIf="getPermissionDebHandle" (click)="goToDebtSettlement(row)">
    Realizar Atendimento
  </button>
</ng-template>


