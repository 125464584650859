import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDatapickerComponent } from './custom-datapicker/custom-datapicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from './custom-datapicker/custom-data-adapter';
import { Platform } from '@angular/cdk/platform';
import { CustomDatapickerRangeComponent } from './custom-datapicker-range/custom-datapicker-range.component';
import { CustomAutocompleteComponent } from './custom-autocomplete/custom-autocomplete.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [
    CustomDatapickerComponent,
    CustomDatapickerRangeComponent,
    CustomAutocompleteComponent
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatCheckboxModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [
    CustomDatapickerComponent,
    CustomDatapickerRangeComponent,
    CustomAutocompleteComponent
  ],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform]}
  ]
})
export class CustomInputsModule { }
