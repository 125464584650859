import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { firstValueFrom, switchMap } from "rxjs";
import { BaseComponent } from "src/app/@base/base.component";
import { ModalConfirmComponent } from "src/app/components/modal/modal-confirm/modal-confirm.component";
import {
  LOCAL_STORAGE_KEY_CONTRACTS,
  LOCAL_STORAGE_KEY_PERMISSIONS,
  LOCAL_STORAGE_KEY_POLICIES,
  LOCAL_STORAGE_KEY_USER,
} from "src/app/const/localStorageKeys";
import { EnumPath } from "src/app/enum/path";
import { CurrentUser } from "src/app/models/user/user";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent extends BaseComponent {
  public txtBtnExit: string = "Sair";
  public user: CurrentUser;
  private siliconURl: string = environment.SiliconURL;
  ;
  constructor() {
    super();

    this.user = this.getLocalStorageItem<CurrentUser>(LOCAL_STORAGE_KEY_USER);
  }

  public async exit(): Promise<void> {
    firstValueFrom(
      this.dialog
        .open(ModalConfirmComponent, {
          maxWidth: "900px",
          data: "Tem certeza que deseja sair?",
        })
        .afterClosed()
    ).then((resp: boolean) => (resp ? this.logout() : null));
  }

  private async logout(): Promise<any> {
    await firstValueFrom(this.authService.newLogout());
    await firstValueFrom(this.authService.invalidate());

    this.removeLocalStorageItems();
    this.goToLoginPage();
    this.hideWebChat();
    this.hideWhatsApp();
  }

  private removeLocalStorageItems(): void {
    this.clearLocalStorageItem(LOCAL_STORAGE_KEY_PERMISSIONS);
    this.clearLocalStorageItem(LOCAL_STORAGE_KEY_POLICIES);
    this.clearLocalStorageItem(LOCAL_STORAGE_KEY_USER);
    this.clearLocalStorageItem(LOCAL_STORAGE_KEY_CONTRACTS);
  }

  private goToLoginPage(): void { 
    let actualUrl = window.location.href;
    if(!actualUrl.includes("concessionaria")){
      this.router.navigate([EnumPath.LOGIN]);
    } else {
      window.location.href = this.siliconURl;

    }
  }
}
