import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { AvatarComponent } from './header/avatar/avatar.component';
import { FooterComponent } from './footer/footer.component';
import { ContentComponent } from './content/content.component';


import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { NotificationsComponent } from './header/notifications/notifications.component';
import { DirectivesModule } from '../directives/directives.module';
import { ComponentsModule } from '../components/components.module';
import { MatTreeModule } from '@angular/material/tree';


const MaterialModules = [
  MatToolbarModule,
  MatIconModule,
  MatBadgeModule,
  MatMenuModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatTreeModule
]

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    NotificationsComponent,
    MenuComponent,
    AvatarComponent,
    ContentComponent,
    FooterComponent,
  ],
  imports: [
    ...MaterialModules,
    HttpClientModule,
    CommonModule,
    RouterModule,
    DirectivesModule,
    ComponentsModule
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule { }
