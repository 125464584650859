import { Component, Inject, OnInit, inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Option } from "src/app/models/option";
import { CustomValidators } from "src/app/form/custom-validators";
import { Observable, firstValueFrom } from "rxjs";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { BankService } from "src/app/services/bank.service";
import { ContractLetterDebtorBalance } from "src/app/models/bank/contractLetterDebtor";
import { saveAs } from "file-saver";
import { LogService } from "src/app/services/log.service";
import { CreateFeatureLogRequest } from "src/app/models/Log/CreateFeatureLog";
import { ModalConfirmComponent } from "src/app/components/modal/modal-confirm/modal-confirm.component";
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";
import { BankSlipRequest, BankSlipResponse, Payment } from "src/app/models/bank/payment";
import { LOCAL_STORAGE_KEY_CONTRACTS } from "src/app/const/localStorageKeys";
import { Contract } from "src/app/models/bank/contract";

@Component({
  selector: "app-modal-bank-letter-debtor-balance",
  templateUrl: "./modal-bank-letter-debtor-balance.component.html",
  styleUrls: ["./modal-bank-letter-debtor-balance.component.scss"],
})
export class ModalBankLetterDebtorBalanceComponent
  extends ModalComponent<ModalBankLetterDebtorBalanceComponent>
  implements OnInit
{
  public subject: Option;
  public today = new Date();
  public form: FormGroup;

  public readonly todayAsDateOnly = this.getDateAsDateOnly(new Date());
  contractSelected: Contract;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Contract,
    private formBuilder: FormBuilder,
    private bankService: BankService,
    private dialog: MatDialog
  ) {
    super();

    this.contractSelected = data;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      insuranceName: [null, [Validators.required, Validators.maxLength(100)]],
      processNumber: [null, [Validators.required, Validators.maxLength(100)]],
      dataInsurance: [
        null,
        [Validators.required, CustomValidators.isDateGreaterThanToday()],
      ],
      costumer: [null, [Validators.required, Validators.maxLength(100)]],
    });

    Object.keys(this.form.controls).forEach((key) => {
      const control = this.form.get(key);
      control.valueChanges.subscribe((value) => {
        if (typeof value === "string") {
          control.setValue(value.toUpperCase(), { emitEvent: false });
        }
      });
    });
  }

  public override onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    this.downloadContractLetterDebtorBalance();
  }

  private getDateAsDateOnly(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public async downloadContractLetterDebtorBalance(): Promise<void> {
    this.loading = true;

    let contractLetterDebtorBalance = this.form
      .value as ContractLetterDebtorBalance;

    await firstValueFrom(
      this.bankService.GenerateContractLetterDebtorBalancePdf(
        this.contractSelected.contractNumber,
        contractLetterDebtorBalance
      )
    )
      .then((resp: Blob) => {
        this.download(resp, `CartaSaldoDevedor.pdf`, "pdf")
        this.generatePayment();
        this.logFeature(EnumReportIncident.BANK_CARTA_SALDO_DEVEDOR, EnumProduct.BANK);
      })
      .catch(() => this.loading = false)
  }

  public download(
    data: string | Blob,
    name: string,
    contentType: string
  ): void {
    let blob: Blob;

    if (!(data instanceof Blob)) {
      blob = this.base64toBlob(data, contentType);
    } else {
      blob = data;
    }

    var file = new File([blob], name, { type: "application/octet-stream" });
    saveAs(file, name);
  }

  private base64toBlob(base64Data: string, contentType: string) {
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public async onClickBtnClose(): Promise<void> {
    if (this.form.dirty) {
      firstValueFrom(
        this.dialog
          .open(ModalConfirmComponent, {
            maxWidth: "900px",
            data: "Tem certeza que deseja cancelar?",
          })
          .afterClosed()
      ).then((resp: boolean) => (resp ? this.onClose(false) : null));
    } else {
      this.onClose(false);
    }
  
  }

  private generatePayment(): void {
    firstValueFrom(this.bankService.get<Payment[]>(`${this.contractSelected.contractNumber}/instalments/10`))
      .then((resp: Payment[]) => this.downloadPayment(resp))
      .catch(() => this.loading = false)   
  }


  public downloadPayment(installments: Payment[]): void {

    let bankSlip: BankSlipRequest = {
      instalments: installments.map(x => {return {dueDate: x.dueDate, receiptCode: x.receiptCode}}),
      allSelected: true,
      shouldIgnorePeriod: true,
      increaseDays: 10,
      initContractDate: new Date(this.contractSelected.initDate)
    }

    firstValueFrom(this.bankService.GeneratePayment<BankSlipRequest, BankSlipResponse>(bankSlip, this.contractSelected.contractNumber, false))
      .then((bankSlipResponse: BankSlipResponse) => this.download(bankSlipResponse.base64file,`BoletoCartaSaldoDevedor.pdf`, "pdf"))
      .then(() => this.onClose(true))
      .finally(() => {
        this.loading = false  
      })

      
  }

}
