import { inject } from "@angular/core";
import { ROUTES } from "../const/routes";
import { Route } from "../models/route";
import { Base } from "./base";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { FileRequest } from "../models/settings/settings";
import { saveAs } from "file-saver";
import { ApplicationInsightsService } from "../logger/application-insights-service";
import { AuthService } from "../services/auth.service";
import { firstValueFrom } from "rxjs";
import { LogService } from "../services/log.service";
import { EnumProduct } from "../enum/products";
import { EnumReportIncident } from "../enum/report-incident";
import {
  PERMITION_BANK,
  PERMITION_CONSORTIUM,
  PERMITION_CREDIT,
  PERMITION_INSURANCE,
} from "../const/permitions";

export class BaseComponent extends Base {

  public document = inject(DOCUMENT)
  public router: Router = inject(Router)
  public location: Location = inject(Location)
  public logger: ApplicationInsightsService = inject(ApplicationInsightsService);
  public authService = inject(AuthService);
  public logService = inject(LogService);

  protected routes: Route[] = ROUTES

  constructor() {
    super();

    if (!window["beforeunloadAdded"]) {
      window.addEventListener("beforeunload", () => {
        try{
          this.authService.newLogoutFetch();
        }
        catch(e){
          
        }
      });

      window["beforeunloadAdded"] = true;
    }

    

  }



  public openInNewTab(url: string): void {
    var link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public hideWebChat(): void {
    if (this.document.getElementById("cr-trigger")) {
      let fullId = document.getElementById("cr-trigger") as HTMLElement;
      fullId.style.display = "none";
    }
  }

  public hideWhatsApp(): void {
    if (this.document.getElementById("cr-whatsapp-trigger")) {
      let fullId = document.getElementById(
        "cr-whatsapp-trigger"
      ) as HTMLElement;
      fullId.style.display = "none";
    }
  }

  public showWebChat(): void {
    if (this.document.getElementById("cr-trigger")) {
      let fullId = document.getElementById("cr-trigger") as HTMLElement;
      fullId.style.display = "flex";
    }
  }

  public showWhatApp(): void {
    if (this.document.getElementById("cr-whatsapp-trigger")) {
      let fullId = document.getElementById(
        "cr-whatsapp-trigger"
      ) as HTMLElement;
      fullId.style.display = "flex";
    }
  }

  public isClientOf(product: EProduct): boolean {
    const productName = this.getProductName(product);

    return this.getUser().products.includes(productName);
  }

  private getProductName(product: EProduct): string {
    switch (product) {
      case EProduct.Bank:
        return "Banco";
      case EProduct.Consortium:
        return "Consórcio";
      case EProduct.Insurance:
        return "Seguros";
      case EProduct.LiberaCred:
        return "LiberaCred";
    }
  }
  public logFeature(message: EnumReportIncident, product: EnumProduct): void {
    firstValueFrom(this.logService.createLog({
      description: message,
      product: product
    }))
  }
}

export enum EProduct {
  Bank,
  Insurance,
  LiberaCred,
  Consortium,


}
