<app-card [width]="width">

  <div *ngIf="allowPayment; else displayNotAllowed" class="card">

    <ng-content select=".bank-summary-header"></ng-content>

    <div class="payment-values">
      <div class="payment-values-column">
        <span class="text uppercase" data-cy="sumInstallmentsLabel" *ngIf="isExpanded  || !detectar_mobile()">
          {{txtSpanSumInstallment}}:
        </span>
        <span class="text uppercase" data-cy="sumAdditionLabel" *ngIf="isExpanded  || !detectar_mobile()">
          {{txtSpanSumAddition}}:
        </span>
        <span class="text uppercase" data-cy="sumDiscountsLabel" *ngIf="isExpanded  || !detectar_mobile()">
          {{txtSpanSumDiscounts}}:
        </span>
        <span class="text uppercase" data-cy="sumDifferenceLabel" *ngIf="isExpanded  || !detectar_mobile()">
          {{txtSpanSumDifference}}:
        </span>
        <span class="card-title estricta uppercase" data-cy="totalLabel"> {{txtSpanTotal}} </span>
      </div>

      <div class="payment-values-column">
        <span class="right bold" data-cy="sumInstallment" *ngIf="isExpanded  || !detectar_mobile()">
          {{ sumInstallment()| currency }}
        </span>
        <span class="right red" data-cy="sumAddition" *ngIf="isExpanded  || !detectar_mobile()">
          {{sumAddition() | currency}}
        </span>
        <span class="right green" data-cy="sumDiscounts" *ngIf="isExpanded  || !detectar_mobile()">
          {{sumDiscounts() | currency}}
        </span>
        <span class="right bold" data-cy="sumDifference" *ngIf="isExpanded  || !detectar_mobile()">
          {{sumDifference() | currency}}
        </span>
        <span class="right card-title estricta uppercase" data-cy="total"> {{total() | currency}} </span>
      </div>
    </div>

    <app-button [color]="btnColor" width="100%" datacy="btn-payment" (onClick)="generatePayment()"
      [disabled]="installments.length == 0 || loading" [loading]="loading">
      <span class="uppercase estricta text-button">
        <mat-icon> {{icon}} </mat-icon>
        <span> {{txtBtnGenerateBill}} </span>
      </span>
    </app-button>

    <div class="barcode" *ngIf="barcode">
      <app-barcode [barcode]="barcode"></app-barcode>
    </div>



    <div class="card-bottom flex" *ngIf="isExpanded || !detectar_mobile()">
      <div>
        <mat-icon class="icon">info</mat-icon>
      </div>

      <div class="card-bottom-info">
        <span class="text"> {{txtSpanInfoOne}} </span>
        <span class="bold text"> {{txtSpanInfoTwo}} </span>
      </div>
    </div>
    <mat-divider *ngIf="detectar_mobile()" style="margin: 15px -22px 0 -22px"></mat-divider>
  </div>



  <button *ngIf="detectar_mobile()" (click)="toggleExpand()" [hidden]="installments.length == 0" class="expand-button">
    <span class="expand-text">{{ isExpanded ? 'Menos detalhes' : 'Mais detalhes' }}</span>
    <mat-icon>{{ isExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
  </button>

  <ng-template #displayNotAllowed>
    <ng-content select=".bank-summary-not-allowed"></ng-content>
  </ng-template>

</app-card>