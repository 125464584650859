import {
  EnumCreditSubject,
  EnumIncidentProduct,
  EnumInsuranceSubject,
} from '../enum/incident';
import {
  EnumBankOptions,
  EnumBankQuickAccess,
  EnumConsortiumOptions,
  EnumCreditOptions,
  EnumInsuranceOptions,
  EnumRequestInsuranceOptions,
} from '../enum/options';
import { EnumPath } from '../enum/path';
import { EnumProduct } from '../enum/products';
import { Option } from '../models/option';
import {
  PERMITION_BANK_ANUAL_STATEMENT,
  PERMITION_BANK_BILL,
  PERMITION_BANK_CONTRACT,
  PERMITION_BANK_PAYMENT,
  PERMITION_BANK_STATEMENT,
  PERMITION_PAYMENT_BOOK,
  PERMITION_CONSORTIUM_PORTAL,
  PERMITION_CREDIT_BILL,
  PERMITION_CREDIT_CANCELLATION,
  PERMITION_CREDIT_DELAYED,
  PERMITION_INSURANCE_BILL,
  PERMITION_INSURANCE_CONTRACT,
  PERMITION_INSURANCE_POLICY,
  PERMITION_INSURANCE_REGISTRATION,
  PERMITION_INSURANCE_REPLACEMENT,
  PERMITION_BANK_CONTRACT_RECEIPT,
  PERMITION_BANK_CONTRACT_BALANCE_DUE,
  PERMITION_BANK_RIGHTS_ASSIGNMENT,
  PERMITION_BANK_WARRANTY_REPLACEMENT,
  PERMITION_BANK_CHANGE_REGISTRATION_DATA,
  PERMITION_BANK_PAYMENT_REVERSAL,
  PERMITION_BANK_TRAVEL_AUTHORIZATION,
  PERMITION_BANK_LETTER_DEBTOR_BALANCE,
  PERMITION_BANK_LIEN_STATUS,
  PERMITION_INSURANCE_EMIT_BILL,
  PERMITION_INSURANCE_SITUATION_SINISTER,
  PERMITION_INSURANCE_EMIT_POLICY,
  PERMITION_BANK_PAYMENT_BOOKLET,
} from './permitions';

export const QUICK_ACCESS_BANK: Option[] = [
  {
    description: EnumBankQuickAccess.PAYMENT_BOOKLET,
    permission: PERMITION_BANK_PAYMENT_BOOKLET,
    path: EnumPath.BANK_PAYMENT_BOOKLET,
  },
  {
    description: EnumBankQuickAccess.BILL,
    permission: PERMITION_BANK_BILL,
    path: EnumPath.BANK_BILL,
  },
  {
    description: EnumBankQuickAccess.PAYMENT,
    permission: PERMITION_BANK_PAYMENT,
    path: EnumPath.BANK_PAYMENT,
  },
  {
    description: EnumBankQuickAccess.CONTRACT,
    permission: PERMITION_BANK_CONTRACT,
    path: EnumPath.BANK_CONTRACT,
  },
  {
    description: EnumBankQuickAccess.PAYMENT_BOOK,
    permission: PERMITION_PAYMENT_BOOK,
    path: EnumPath.BANK_PAYMENT_BOOK,
  },
  {
    description: EnumBankQuickAccess.STATEMENT,
    permission: PERMITION_BANK_STATEMENT,
    path: EnumPath.BANK_STATEMENT,
  },
  {
    description: EnumBankQuickAccess.ANUAL_STATEMENT,
    permission: PERMITION_BANK_ANUAL_STATEMENT,
    path: EnumPath.BANK_ANUAL_STATEMENT,
  },
  {
    description: EnumBankQuickAccess.CONTRACT_RECEIPT,
    permission: PERMITION_BANK_CONTRACT_RECEIPT
  },
  {
    description: EnumBankQuickAccess.CONTRACT_BALANCE_DUE,
    permission: PERMITION_BANK_CONTRACT_BALANCE_DUE,
    path: EnumPath.BANK_CONTRACT_BALANCE_DUE,
  },
  {
    description: EnumBankQuickAccess.CARTA_SALDO_DEVEDOR,
    permission: PERMITION_BANK_LETTER_DEBTOR_BALANCE,
    path: null,
  },
  {
    description: EnumBankQuickAccess.LIEN_STATUS,
    permission: PERMITION_BANK_LIEN_STATUS,
    path: null,
  },
];

export const OPTIONS_BANK: Option[] = [
  {
    description: EnumBankOptions.RIGHTS_ASSIGNMENT,
    permission: PERMITION_BANK_RIGHTS_ASSIGNMENT,
    product: EnumProduct.BANK,
  },
  {
    description: EnumBankOptions.WARRANTY_REPLACEMENT,
    permission: PERMITION_BANK_WARRANTY_REPLACEMENT,
    product: EnumProduct.BANK,
  },
  {
    description: EnumBankOptions.CHANGE_REGISTRATION_DATA,
    permission: PERMITION_BANK_CHANGE_REGISTRATION_DATA,
    product: EnumProduct.BANK,
  },
  {
    description: EnumBankOptions.PAYMENT_REVERSAL,
    permission: PERMITION_BANK_PAYMENT_REVERSAL,
    product: EnumProduct.BANK,
  },
  {
    description: EnumBankOptions.TRAVEL_AUTHORIZATION,
    permission: PERMITION_BANK_TRAVEL_AUTHORIZATION,
    product: EnumProduct.BANK,
  },
];

export const QUICK_ACCESS_CONSORTIUM: Option[] = [
  {
    description: EnumConsortiumOptions.PORTAL,
    permission: PERMITION_CONSORTIUM_PORTAL,
    path: EnumPath.CONSORTIUM,
  },
];

export const OPTIONS_CREDIT: Option[] = [
  {
    id: EnumCreditSubject.BILL,
    description: EnumCreditOptions.BILL,
    permission: PERMITION_CREDIT_BILL,
    product: EnumProduct.CREDIT,
    incidentProduct: EnumIncidentProduct.CREDIT,
  },
  {
    id: EnumCreditSubject.DELAYED,
    description: EnumCreditOptions.DELAYED,
    permission: PERMITION_CREDIT_DELAYED,
    product: EnumProduct.CREDIT,
    incidentProduct: EnumIncidentProduct.CREDIT,
  },
  {
    id: EnumCreditSubject.CANCELLATION,
    description: EnumCreditOptions.CANCELLATION,
    permission: PERMITION_CREDIT_CANCELLATION,
    product: EnumProduct.CREDIT,
    incidentProduct: EnumIncidentProduct.CREDIT,
  },
];

export const OPTIONS_REQUEST_INSURANCE: Option[] = [
  {
    id: EnumInsuranceSubject.BILL,
    description: EnumRequestInsuranceOptions.BILL,
    permission: PERMITION_INSURANCE_BILL,
    product: EnumProduct.INSURANCE,
    incidentProduct: EnumIncidentProduct.INSURANCE,
  },
  {
    id: EnumInsuranceSubject.POLICY,
    description: EnumRequestInsuranceOptions.POLICY,
    permission: PERMITION_INSURANCE_POLICY,
    product: EnumProduct.INSURANCE,
    incidentProduct: EnumIncidentProduct.INSURANCE,
  },
  {
    id: EnumInsuranceSubject.CONTRACT,
    description: EnumRequestInsuranceOptions.CONTRACT,
    permission: PERMITION_INSURANCE_CONTRACT,
    product: EnumProduct.INSURANCE,
    incidentProduct: EnumIncidentProduct.INSURANCE,
  },
  {
    id: EnumInsuranceSubject.REGISTRATION,
    description: EnumRequestInsuranceOptions.REGISTRATION,
    permission: PERMITION_INSURANCE_REGISTRATION,
    product: EnumProduct.INSURANCE,
    incidentProduct: EnumIncidentProduct.INSURANCE,
  },
  {
    id: EnumInsuranceSubject.REPLACEMENT,
    description: EnumRequestInsuranceOptions.REPLACEMENT,
    permission: PERMITION_INSURANCE_REPLACEMENT,
    product: EnumProduct.INSURANCE,
    incidentProduct: EnumIncidentProduct.INSURANCE,
  },
];

export const OPTIONS_INSURANCE: Option[] = [
  {
    description: EnumInsuranceOptions.EMIT_BILL,
    permission: PERMITION_INSURANCE_EMIT_BILL,
    product: EnumProduct.INSURANCE,
    incidentProduct: EnumIncidentProduct.INSURANCE,
  },
  {
    description: EnumInsuranceOptions.SINISTER,
    permission: PERMITION_INSURANCE_SITUATION_SINISTER,
    product: EnumProduct.INSURANCE,
    incidentProduct: EnumIncidentProduct.INSURANCE,
  },
  {
    description: EnumInsuranceOptions.EMIT_POLICY,
    permission: PERMITION_INSURANCE_EMIT_POLICY,
    product: EnumProduct.INSURANCE,
    incidentProduct: EnumIncidentProduct.INSURANCE,
  },
];
