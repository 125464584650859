import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PagesComponent } from './pages.component';
import { LayoutModule } from '../layout/layout.module';
import { PagesRoutingModule } from './pages-routing.module';
import { HomeModule } from './home/home.module';
import { RequestsModule } from './requests/requests.module';
import { InsuranceModule } from './insurance/insurance.module';
import { CreditModule } from './credit/credit.module';
import { BankModule } from './bank/bank.module';
import { AuthorizationGuard } from '../guards/authorization.guard';
import { FaqModule } from './faq/faq.module';
import { DealerModule } from './dealer/dealer.module';


@NgModule({
  declarations: [
    PagesComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    PagesRoutingModule,
    HomeModule,
    InsuranceModule,
    CreditModule,
    RequestsModule,
    BankModule,
    FaqModule,
    DealerModule
  ],
  exports: [
    PagesComponent
  ],
  providers: [
    AuthorizationGuard,
    DatePipe
  ]
})
export class PagesModule { }
