import {
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Component({
  selector: "app-custom-datapicker-range",
  templateUrl: "./custom-datapicker-range.component.html",
  styleUrls: ["./custom-datapicker-range.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatapickerRangeComponent),
      multi: true,
    },
  ],
})
export class CustomDatapickerRangeComponent implements ControlValueAccessor {
  @ViewChild("inputStart") inputStartElement: ElementRef;
  @ViewChild("inputEnd") inputEndElement: ElementRef;

  @Input("label") label: string;
  @Input("formGroup") formGroup: FormGroup;
  @Input("max") max: Date;
  @Input("min") min: Date;

  @Input("startDateName") startDateName: string = "startDate";
  @Input("endDateName") endDateName: string = "endDate";

  @Input("required") required: boolean;
  @Input("readonly") readonly: boolean = false;

  @Input() datacy: string;

  private innerValueStart: string = "";
  private innerValueEnd: string = "";

  get valueStart() {
    return this.innerValueStart;
  }

  get valueEnd() {
    return this.innerValueEnd;
  }

  set valueStart(v: any) {
    if (v !== this.innerValueStart) {
      this.innerValueStart = v;
      this.onChanged(v);
    }
  }

  set valueEnd(v: any) {
    if (v !== this.innerValueEnd) {
      this.innerValueEnd = v;
      this.onChanged(v);
    }
  }

  onChanged: (value: any) => void = () => {};
  onTouched: (value: any) => void = () => {};

  writeValue(obj: any): void {
    this.valueStart = obj;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  checkMaskStart(event: KeyboardEvent): void {
    if (
      !(
        event.key == "Backspace" ||
        event.key == "ArrowLeft" ||
        event.key == "ArrowRight" ||
        event.key == "Tab" ||
        event.key == "Delete"
      )
    ) {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }

      if (this.inputStartElement.nativeElement.value.length == 2) {
        if (
          parseInt(this.inputStartElement.nativeElement.value.slice(0, 2)) > 31
        ) {
          this.inputStartElement.nativeElement.value =
            this.inputStartElement.nativeElement.value.replace(
              this.inputStartElement.nativeElement.value.slice(0, 2),
              "31"
            );
        }

        if (!/\//.test(event.key)) {
          this.inputStartElement.nativeElement.value =
            this.inputStartElement.nativeElement.value.concat("/");
        }
      }

      if (this.inputStartElement.nativeElement.value.length == 5) {
        if (
          parseInt(this.inputStartElement.nativeElement.value.slice(3, 5)) > 12
        ) {
          if (this.inputStartElement.nativeElement.value.slice(0, 2) == "13") {
            this.inputStartElement.nativeElement.value = this.ReplaceAt(
              this.inputStartElement.nativeElement.value,
              this.inputStartElement.nativeElement.value.slice(3, 5),
              "12",
              3,
              5
            );
          } else {
            this.inputStartElement.nativeElement.value =
              this.inputStartElement.nativeElement.value.replace(
                this.inputStartElement.nativeElement.value.slice(3, 5),
                "12"
              );
          }
        }

        if (!/\//.test(event.key)) {
          this.inputStartElement.nativeElement.value =
            this.inputStartElement.nativeElement.value.concat("/");
        }
      }

      if (this.inputStartElement.nativeElement.value.length == 9) {
        setTimeout(() => {
          this.inputEndElement.nativeElement.focus();
        }, 100);
      }
    }
  }

  checkMaskEnd(event: KeyboardEvent): void {
    if (
      !(
        event.key == "Backspace" ||
        event.key == "ArrowLeft" ||
        event.key == "ArrowRight" ||
        event.key == "Tab" ||
        event.key == "Delete"
      )
    ) {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }

      if (this.inputEndElement.nativeElement.value.length == 2) {
        if (
          parseInt(this.inputEndElement.nativeElement.value.slice(0, 2)) > 31
        ) {
          this.inputEndElement.nativeElement.value =
            this.inputEndElement.nativeElement.value.replace(
              this.inputEndElement.nativeElement.value.slice(0, 2),
              "31"
            );
        }

        if (!/\//.test(event.key)) {
          this.inputEndElement.nativeElement.value =
            this.inputEndElement.nativeElement.value.concat("/");
        }
      }

      if (this.inputEndElement.nativeElement.value.length == 5) {
        if (
          parseInt(this.inputEndElement.nativeElement.value.slice(3, 5)) > 12
        ) {
          if (this.inputEndElement.nativeElement.value.slice(0, 2) == "13") {
            this.inputEndElement.nativeElement.value = this.ReplaceAt(
              this.inputEndElement.nativeElement.value,
              this.inputEndElement.nativeElement.value.slice(3, 5),
              "12",
              3,
              5
            );
          } else {
            this.inputEndElement.nativeElement.value =
              this.inputEndElement.nativeElement.value.replace(
                this.inputEndElement.nativeElement.value.slice(3, 5),
                "12"
              );
          }
        }

        if (!/\//.test(event.key)) {
          this.inputEndElement.nativeElement.value =
            this.inputEndElement.nativeElement.value.concat("/");
        }
      }
    }
  }

  private ReplaceAt(input, search, replace, start, end) {
    return (
      input.slice(0, start) +
      input.slice(start, end).replace(search, replace) +
      input.slice(end)
    );
  }

  public returnDataCy(validation: string): string {
    return this.datacy + "-" + validation;
  }

  public returnDataCyError(validation: string): string {
    return "error-" + this.datacy + "-" + validation;
  }
}
