export enum EnumIncidentProduct {
  BANK = 1,
  INSURANCE = 2,
  CREDIT = 3,
}

export enum EnumInsuranceSubject {
  BILL = 1,
  POLICY = 2,
  REPLACEMENT = 3,
  CONTRACT = 4,
  REGISTRATION = 5,
}

export enum EnumCreditSubject {
  BILL = 1,
  DELAYED = 2,
  CANCELLATION = 3,
}

export enum EnumStatusEnvioFileIncident {
  NAO_INICIADO,
  INICIADO,
  FINALIZADO,
  ERRO
}