<section class="video-container" *ngIf="hasVideoToDisplay">
  <video
    [src]="this.videoUrl"
    autoplay
    class="video-on-content"
    controls
    *ngIf="isVideoUrlInternalUrl; else externalUrlSection"
  ></video>

  <ng-template #externalUrlSection>
    <iframe
      [src]="safeUrl"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope;"
      allowfullscreen
      class="video-on-content"
      bypassSecurityTrustUrl
    ></iframe>
  </ng-template>
</section>
