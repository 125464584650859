<section class="content" [ngClass]="{'': display === 'area', 'hide-element' : display === 'button' }">
  <ngx-file-drop
    (onFileDrop)="dropped($event)"
    dropZoneClassName
    [attr.data-cy]="customDataCy"
  >
    <ng-template
      ngx-file-drop-content-tmp
      let-openFileSelector="openFileSelector"
    >
      <div
        #openFileSelectorElement
        readonly
        *ngIf="!loading; else loadingImage"
        class="upload-area"
        (click)="openFileSelector()"
      >
        <mat-icon class="big-mat-icon">backup</mat-icon>
        <div>
          <span class="bolder estricta uppercase">{{ uploadDescription }}</span>

          <span for="fileDropRef" class="bold search">
            Caso prefira buscar pelo arquivo no seu computador, clique aqui
          </span>
        </div>
      </div>

      <ng-template #loadingImage>
        <mat-spinner diameter="80"></mat-spinner>
      </ng-template>
    </ng-template>
  </ngx-file-drop>
</section>

<div [ngClass]="{'': display === 'button', 'hide-element' : display === 'area' }">
  <div *ngIf="showButtonUpload == true"     
        class="file-flex cursor-pointer blue" 
        (click)="onClickOpenFileSelector()"
  >
    <mat-icon> cloud_upload </mat-icon>
    <span>Anexar arquivo</span>
</div>

  <div *ngIf="showButtonUpload == false" class="file-flex cursor-pointer">
    <span> {{nameDocumentSelected}} </span>
    <button (click)="onClickRemoveFileSelected()" mat-icon-button [disabled]="disabled">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>