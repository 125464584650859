<div *ngIf="showSubOptions">
    <span class="estricta"> {{ subOptionsTitle }} </span>
    <div>
        <app-button *ngFor="let option of subOptions"
                     width="100%" 
                     minWidth="230px" 
                     color="primary" 
                     [datacy]="type +'-'+ option.description" 
                     [showIfPermission]="option.permission"
                     [tooltip]="getTooltip(option)"
                     (onClick)="onClick(option)"
                     [loading]="option.loading"
                     [disabled]="option.loading"
        > 
            <span class="button-content estricta bolder uppercase"> {{option.description}} </span>    
        </app-button>
    </div>
</div>
<div>
    <span class="estricta"> ACESSO RÁPIDO </span>
    <div>
        <app-button *ngFor="let option of options"
                     width="100%" 
                     minWidth="230px" 
                     color="primary" 
                     [datacy]="type +'-'+ option.description" 
                     [showIfPermission]="option.permission"
                     [tooltip]="getTooltip(option)"
                     (onClick)="onClick(option)"
        > 
            <span class="button-content estricta bolder uppercase"> {{option.description}} </span>    
        </app-button>
    </div>
</div>


