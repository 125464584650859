import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from 'src/app/@base/base.component';
import { PERMISSION_BILLING_CONSULTANCIES_CREATE, PERMISSION_BILLING_CONSULTANCIES_DELETE, PERMISSION_BILLING_CONSULTANCIES_READ, PERMISSION_BILLING_CONSULTANCIES_UPDATE, PERMITION_BANK, PERMITION_BANK_ANUAL_STATEMENT, PERMITION_BANK_BILL, PERMITION_BANK_CONTRACT, PERMITION_BANK_STATEMENT, PERMITION_BANNERS_CREATE, PERMITION_BANNERS_DELETE, PERMITION_BANNERS_READ, PERMITION_BANNERS_UPDATE, PERMITION_CREDIT, PERMITION_FAQ_ADMIN, PERMITION_FAQ_CLIENT, PERMITION_INSURANCE, PERMITION_MESSAGES_CREATE, PERMITION_MESSAGES_DELETE, PERMITION_MESSAGES_READ, PERMITION_MESSAGES_UPDATE, PERMITION_PROFILE_CREATE, PERMITION_PROFILE_DELETE, PERMITION_PROFILE_READ, PERMITION_PROFILE_UPDATE, PERMITION_REQUESTS, PERMITION_USERS_CREATE, PERMITION_USERS_DELETE, PERMITION_USERS_READ, PERMITION_USERS_UPDATE } from 'src/app/const/permitions';
import { EnumPath } from 'src/app/enum/path';
import { FaqItem } from 'src/app/models/faq/faq';

const FAQ_CONFIG_USERS = "Configurações - Usuário"
const FAQ_CONFIG_MESSAGES = "Configurações - Mensagens"
const FAQ_CONFIG_BANNERS = "Configurações - Banners"
const FAQ_CONFIG_PROFILE = "Configurações - Perfil de acesso"
const FAQ_CONFIG_BILLING = "Configurações - Assessoria de Cobrança"
const FAQ_BANK = "Banco"
const FAQ_REQUESTS = "Solicitações"
const FAQ_CONSORTIUM = "Consórcio"
const FAQ_CONTACT = "Contato"
const FAQ_SYSTEM = "Sistema"
const FAQ_POLICIES = "Políticas"
const FAQ_LINKS = "Links"

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent extends BaseComponent {

  public faqItemList: FaqItem[] = [
    {
      title: "Como faço para criar um usuário no sistema",
      steps: {
        title: "Para realizar a criação de um usuário no sistema, basta seguir esses passos simples:",
        items: [
          "<strong> Passo 1 </strong> Acesse a tela dedicada à <a>Criação de Usuário</a>",
          "<strong> Passo 2 </strong> Preencha todos os campos com as informações do usuário",
          "<strong> Passo 3 </strong> Selecione a qual(ais) Perfil(is) de acesso esse usuário estará vinculado e em seguida salve"
        ] 
      },
      about: `Ao seguir esses passos, você estará apto a criar usuários no sistema, além de vinculá-los aos perfis de acesso necessários.
Isso permitirá os usuários acessarem o sistema utilizando o documento e a senha inseridos durante sua criação, tendo acesso às permissões definidas pelos perfis vinculados a ele.`,
      rules: [
        "Não é possível criar mais de um usuário com o mesmo Documento (CPF/CNPJ/ID Interno) e nem com o mesmo E-mail",
        "O(s) Perfil(is) de acesso selecionado(s) ditará(ão) quais funcionalidades/permissões esse usuário terá disponível ao acessar o sistema e são configuráveis através da tela de Cadastro de Perfil."
      ],
      video: "assets/faq/Como faço para criar um usuário no sistema.mp4",
      route: EnumPath.SETTINGS_USERS_CREATE,
      permission: [PERMITION_FAQ_ADMIN, PERMITION_USERS_CREATE],
      group: FAQ_CONFIG_USERS
    },
    {
      title: "Como faço para pesquisar/excluir/editar um usuário do sistema",
      steps: {
        title: "Para realizar uma dessas 3 operações no sistema, basta seguir esses passos simples:",
        items: [
          "<strong>Passo 1: </strong> Acesse a tela dedicada à <a>Pesquisa de Usuário</a>",
          "<strong>Passo 2: </strong> Preencha os campos de filtro com informações do usuário desejado",
          "<strong>Passo 3: </strong> Na coluna de ação, clique no ícone de <u>“3 pontinhos”</u> e selecione a operação desejada"
        ] 
      },
      about: `Ao seguir esses passos, você estará apto a manipular usuários no sistema, como editá-los ou excluí-los.`,
      route: EnumPath.SETTINGS_USERS_FILTER,
      permission: [PERMITION_FAQ_ADMIN, PERMITION_USERS_READ, PERMITION_USERS_UPDATE, PERMITION_USERS_DELETE],
      group: FAQ_CONFIG_USERS
    },
    {
      title: "Como faço para criar um perfil de acesso no sistema",
      steps: {
        title: "Para realizar a criação de um perfil de acesso no sistema, basta seguir esses passos simples:",
        items: [
          "<strong>Passo 1: </strong> Acesse a tela dedicada à <a>Criação de Perfil de Acesso</a>",
          "<strong>Passo 2: </strong> Preencha sua <u>Descrição</u> e selecione se ele será um perfil <u>“Cliente”</u>",
          "<strong>Passo 3: </strong> Selecione qual(ais) <u>Funcionalidade(s)</u> esse perfil terá permissão e em seguida salve"
        ] 
      },
      about: `Ao seguir esses passos, você estará apto a criar perfis de acesso no sistema, além de atribuir funcionalidades a eles.
Esses perfis serão exibidos durante a criação dos usuários e estarão disponíveis para seleção, definindo quais permissões os usuários com o perfil em questão terá ao acessar o sistema.`,
      rules: [
        "Não é possível criar mais de um perfil com a mesma descrição.",
        "Campo <u>“Cliente”</u>: Ao selecionar <u>“Sim”</u> nesse campo, esse perfil será automaticamente vinculado a usuários no momento em que eles realizarem o auto cadastro no sistema (tela onde o usuário realiza seu próprio cadastro no sistema).",
        "A(s) funcionalidade(s) selecionada(s) determinarão quais permissões os usuários que posteriormente forem vinculados a esse perfil terão."
      ],
      video: "assets/faq/Como faço para criar um perfil de acesso no sistema.mp4",
      route: EnumPath.SETTINGS_PROFILE_CREATE,
      permission: [PERMITION_FAQ_ADMIN, PERMITION_PROFILE_CREATE],
      group: FAQ_CONFIG_PROFILE
    },
    {
      title: "Como faço para pesquisar/excluir/editar um perfil de acesso do sistema",
      steps: {
        title: "Para realizar uma dessas 3 operações no sistema, basta seguir esses passos simples:",
        items: [
          "<strong>Passo 1: </strong> Acesse a tela dedicada à <a>Pesquisa de Perfil de Acesso</a>",
          "<strong>Passo 2: </strong> Preencha os campos de filtro com informações do perfil de acesso desejado",
          "<strong>Passo 3: </strong> Na coluna de ação, clique no ícone de <u>“3 pontinhos”</u> e selecione a operação desejada"
        ] 
      },
      about: `Ao seguir esses passos, você estará apto a manipular perfis de acesso no sistema, como editá-los ou excluí-los.`,
      route: EnumPath.SETTINGS_PROFILE_FILTER,
      permission: [PERMITION_FAQ_ADMIN, PERMITION_PROFILE_READ, PERMITION_PROFILE_UPDATE, PERMITION_PROFILE_DELETE],
      group: FAQ_CONFIG_PROFILE
      
    },
    {
      title: "Como faço para criar um banner no sistema",
      steps: {
        title: "Para realizar a criação de um banner no sistema, basta seguir esses passos simples:",
        items: [
          "<strong>Passo 1: </strong> Acesse a tela dedicada à <a>Criação de Banners</a>",
          "<strong>Passo 2: </strong> Preencha sua <u>Descrição</u>",
          "<strong>Passo 3: </strong> Selecione em quais <u>Páginas</u> esse banner deverá ser exibido",
          "<strong>Passo 4: </strong> Preencha qual será o <u>Período de Vigência</u> desse banner",
          "<strong>Passo 5: </strong> Selecione uma ou mais <u>Imagens</u> para ele",
          "<strong>Passo 6 (opcional): </strong> Preencha a <u>Mensagem</u> que ele irá exibir e a <u>URL</u> que ele irá abrir em uma nova guia ao ser clicado",
          "<strong> Passo 7 (opcional): </strong> Você pode também clicar em <u>“+ Nova imagem”</u> e adicionar outras imagens para serem exibidas em conjunto com esse mesmo banner",
        ] 
      },
      observation: [
        "É possível visualizar uma imagem selecionada clicando no <u>ícone de olho</u> em sua coluna de <u>“Ações”</u>",
        "É possível excluir uma imagem selecionada clicando no <u>ícone de lixeira</u> em sua coluna de <u>“Ações”</u>"
      ],
      about: `Ao seguir esses passos, você estará apto a criar banners no sistema, além de atribuir imagens que serão exibidas neles.
Esses banners poderão ter N imagens e as exibirão abaixo do cabeçalho das telas às quais eles foram vinculados.
Junto com as imagens, eles exibirão também suas respectivas mensagens e abrirão em uma nova guia do navegador a URL escolhida, caso sejam clicados pelo usuário.`,
      rules: [
        "Não é possível criar mais de um banner com a mesma <strong>Descrição</strong>.",
        "Banners com mais de uma imagem selecionada as exibirão em forma de carrossel de imagens."
      ],
      video: "assets/faq/Como faço para criar um banner no sistema.mp4",
      route: EnumPath.SETTINGS_BANNERS_CREATE,
      permission: [PERMITION_FAQ_ADMIN, PERMITION_BANNERS_CREATE],
      group: FAQ_CONFIG_BANNERS
    },
    {
      title: "Como faço para pesquisar/excluir/editar um banner do sistema",
      steps: {
        title: "Para realizar uma dessas 3 operações no sistema, basta seguir esses passos simples:",
        items: [
          "<strong>Passo 1: </strong> Acesse a tela dedicada à <a>Pesquisa de Banners</a>",
          "<strong>Passo 2: </strong> Preencha os campos de filtro com informações do banner desejado",
          "<strong>Passo 3: </strong> Na coluna de ação, clique no ícone de <u>“3 pontinhos”</u> e selecione a operação desejada"
        ] 
      },
      about: `Ao seguir esses passos, você estará apto a manipular banners no sistema, como editá-los ou excluí-los.`,
      route: EnumPath.SETTINGS_BANNERS_FILTER,
      permission: [PERMITION_FAQ_ADMIN, PERMITION_BANNERS_READ, PERMITION_BANNERS_UPDATE, PERMITION_BANNERS_DELETE],
      group: FAQ_CONFIG_BANNERS
    },
    {
      title: "Como faço para criar uma mensagem no sistema",
      steps: {
        title: "Para realizar a criação de um perfil de acesso no sistema, basta seguir esses passos simples:",
        items: [
          "<strong>Passo 1: </strong> Acesse a tela dedicada à <a>Criação de Mensagem</a>",
          "<strong>Passo 2: </strong> Preencha seu <u>Título</u> e sua <u>Mensagem</u>",
          "<strong>Passo 3 (opcional): </strong> Selecione uma <u>Imagem</u> para ser exibida com essa mensagem",
          "<strong>Passo 4: </strong> Preencha qual será o período de vigência desse banner",
          "<strong>Passo 5 (opcional): </strong> Marque <u>“Exibir na entrada?”</u> ou <u>“Exibir no pré-login?”</u> (você pode também deixar as duas opções desmarcadas)",
          "<strong>Passo 6: </strong> Caso nenhuma opção acima tenha sido selecionada ou a opção escolhida for <u>“Exibir na entrada?”</u>, selecione para quais <u>Segmentos</u> será essa mensagem."
        ] 
      },
      about: `Ao seguir esses passos, você estará apto a criar mensagens no sistema, além de configurar em que momento elas serão exibidas.
Essas mensagens serão exibidas automaticamente na tela de login, caso marque <u>“Exibir no pré-login?”</u>, ou na tela pós login, caso marque <u>“Exibir na entrada?”</u>. Caso nenhuma das opções seja marcada, a mensagem será exibida apenas no menu de mensagens, sem abrir automaticamente para o usuário.
No caso onde há a seleção de <u>Segmentos</u>, somente os usuários que possuírem os segmentos escolhidos conseguirão visualizar essa mensagem, seja automaticamente em tela ou no menu de mensagens (ícone de <u>sininho</u> no cabeçalho)`,
      rules: [
        "Não é possível criar mais de uma mensagem com o mesmo <u>Título</u>.",
        "Campo <u>“Exibir na entrada?”</u>: Ao marcar esse campo, será necessário selecionar os segmentos que visualizarão essa mensagem. Essa opção representa que essa mensagem será automaticamente exibida na tela para o usuário assim que ele abrir a tela do sistema pós login.",
        "Campo <u>“Exibir no pré-login?”</u>: Ao marcar esse campo, não será possível selecionar segmentos. Essa opção representa que essa mensagem será automaticamente exibida na tela de login assim que ela."
      ],
      video: "assets/faq/Como faço para criar uma mensagem no sistema.mp4",
      route: EnumPath.SETTINGS_MESSAGES_CREATE,
      permission: [PERMITION_FAQ_ADMIN, PERMITION_MESSAGES_CREATE],
      group: FAQ_CONFIG_MESSAGES
    },
    {
      title: "Como faço para pesquisar/excluir/editar uma mensagem do sistema",
      steps: {
        title: "Para realizar uma dessas 3 operações no sistema, basta seguir esses passos simples:",
        items: [
          "<strong>Passo 1: </strong> Acesse a tela dedicada à <a>Pesquisa de Mensagem</a>",
          "<strong>Passo 2: </strong> Preencha os campos de filtro com informações da mensagem desejada",
          "<strong>Passo 3: </strong> Na coluna de ação, clique no ícone de <u>“3 pontinhos”</u> e selecione a operação desejada"
        ] 
      },
      about: `Ao seguir esses passos, você estará apto a manipular mensagens no sistema, como editá-las ou excluí-las.`,
      route: EnumPath.SETTINGS_MESSAGES_FILTER,
      permission: [PERMITION_FAQ_ADMIN, PERMITION_MESSAGES_READ, PERMITION_MESSAGES_UPDATE, PERMITION_MESSAGES_DELETE],
      group: FAQ_CONFIG_MESSAGES
    },
    {
      title: "Como faço para criar uma assessoria de cobrança no sistema",
      steps: {
        title: "Para realizar a criação de uma assessoria de cobrança no sistema, basta seguir esses passos simples:",
        items: [
          "<strong>Passo 1: </strong> Acesse a tela dedicada à <a>Criação de Assessoria de Cobrança </a>",
          "<strong>Passo 2: </strong> Preencha os campos com seu <u> Identificador, Telefones </u> e <u> Horário de atendimento </u>",
          "<strong>Passo 3: </strong> Na coluna de ação, clique no ícone de <u>“3 pontinhos”</u> e selecione a operação desejada"
        ] 
      },
      about: `Ao seguir esses passos, você estará apto a criar assessorias de cobrança no sistema.`,
      rules: [
        "Não é possível criar mais de uma assessoria de cobrança com o mesmo <u>Identificador</u>."
      ],
      video: "assets/faq/Como faço para criar uma assessoria de cobrança no sistema.mp4",
      route: EnumPath.SETTINGS_BILLING_CONSULTANCIES_CREATE,
      permission: [PERMITION_FAQ_ADMIN, PERMISSION_BILLING_CONSULTANCIES_CREATE],
      group: FAQ_CONFIG_BILLING
    },
    {
      title: "Como faço para pesquisar/excluir/editar uma assessoria de cobrança do sistema",
      steps: {
        title: "Para realizar uma dessas 3 operações no sistema, basta seguir esses passos simples:",
        items: [
          "<strong>Passo 1: </strong> Acesse a tela dedicada à <a> Pesquisa de Assessorias de Cobrança </a>",
          "<strong>Passo 2: </strong> Preencha os campos de filtro com informações da assessoria de cobrança desejada",
          "<strong>Passo 3: </strong> Na coluna de ação, clique no ícone de <u>“3 pontinhos”</u> e selecione a operação desejada"
        ] 
      },
      rules: [
        "Não é possível criar mais de uma assessoria de cobrança com o mesmo <u>Identificador</u>."
      ],
      route: EnumPath.SETTINGS_BILLING_CONSULTANCIES_FILTER,
      permission: [PERMITION_FAQ_ADMIN, PERMISSION_BILLING_CONSULTANCIES_READ, PERMISSION_BILLING_CONSULTANCIES_UPDATE, PERMISSION_BILLING_CONSULTANCIES_DELETE],
      group: FAQ_CONFIG_BILLING
    },
    {
      title: "Como faço para gerar a 2ª via de boleto ou antecipar parcelas",
      steps: {
        title: "Para gerar a 2ª via de boleto ou antecipar parcelas, siga estes passos simples:",
        items: [
          "<strong> Passo 1 </strong> Acesse a tela dedicada à <a>2ª via / Antecipação de parcelas</a>.",
          "<strong> Passo 2 </strong> Selecione as parcelas que você deseja incluir no boleto ou antecipar.",
          "<strong> Passo 3 </strong> Clique no botão <u>“Gerar Boleto”</u>."
        ] 
      },
      about: `Ao seguir esses passos, você terá a facilidade de gerar um boleto para o pagamento das parcelas desejadas, proporcionando maior flexibilidade e controle sobre suas transações. `,
      video: "assets/faq/Como faço para gerar a 2ª via de boleto ou antecipar parcelas.mp4",
      route: EnumPath.BANK_PAYMENT,
      permission: [PERMITION_FAQ_CLIENT, PERMITION_BANK, PERMITION_BANK_BILL],
      group: FAQ_BANK
    },
    {
      title: "Como faço para quitar um contrato",
      steps: {
        title: "Quitar um contrato é fácil e conveniente. Siga estes passos simples para gerar um boleto com todas as parcelas em aberto e realizar a quitação do contrato: ",
        items: [
          "<strong> Passo 1 </strong> Acesse a tela dedicada à <a>Quitação de Contrato</a>.",
          "<strong> Passo 2 </strong> Clique no botão <u>“Gerar Boleto”</u>."
        ] 
      },
      about: `Ao seguir esses passos, você poderá gerar um boleto contendo todas as parcelas em aberto, permitindo a quitação eficiente do contrato. Isso proporciona praticidade e controle total sobre o processo de encerramento do acordo.`,
      video: "assets/faq/Como faço para quitar um contrato.mp4",
      route: EnumPath.BANK_CONTRACT,
      permission: [PERMITION_FAQ_CLIENT, PERMITION_BANK, PERMITION_BANK_CONTRACT],
      group: FAQ_BANK
    },
    {
      title: "Como faço para gerar a 2ª via do carnê",
      steps: {
        title: "Gerar a 2ª via do carnê é simples e rápido. Siga estes passos para obter a 2ª via contendo todas as parcelas do contrato:",
        items: [
          "<strong> Passo 1 </strong> Acesse a tela específica para a <a>Geração da 2ª via do carnê.</a>",
          "<strong> Passo 2 </strong> Clique no botão <u>“Baixar Carnê”</u>."
        ] 
      },
      about: `Ao seguir esses passos, você terá a praticidade de obter a 2ª via do carnê com todas as informações necessárias sobre as parcelas do contrato. Isso facilita o acompanhamento e pagamento das obrigações de forma organizada e eficiente.`,
      video: "assets/faq/Como faço para gerar a 2ª via do carnê.mp4",
      route: EnumPath.BANK_PAYMENT_BOOK,
      permission: [PERMITION_FAQ_CLIENT, PERMITION_BANK, PERMITION_BANK_BILL],
      group: FAQ_BANK
    },
    {
      title: "Como faço para gerar o extrato financeiro",
      steps: {
        title: "Gerar o extrato financeiro referente ao contrato é simples. Siga estes passos para obter todas as informações financeiras necessárias:",
        items: [
          "<strong> Passo 1 </strong> Acesse a tela dedicada à <a>Extrato Financeiro</a>.",
          "<strong> Passo 2 </strong> Clique no botão <u>“Baixar</u>."
        ] 
      },
      about: `Ao seguir esses passos, você terá acesso ao extrato financeiro, que oferece uma visão abrangente das transações e movimentações relacionadas ao contrato. Essa ferramenta proporciona uma gestão financeira mais eficiente e organizada. `,
      video: "assets/faq/Como faço para gerar o extrato financeiro.mp4",
      route: EnumPath.BANK_STATEMENT,
      permission: [PERMITION_FAQ_CLIENT, PERMITION_BANK, PERMITION_BANK_STATEMENT],
      group: FAQ_BANK
    },
    {
      title: "Como faço para gerar a declaração anual",
      steps: {
        title: "Gerar a declaração anual é um procedimento descomplicado. Siga estas etapas para obter o documento necessário:",
        items: [
          "<strong> Passo 1 </strong> Acesse a tela dedicada à <a>Declaração Anual</a>.",
          "<strong> Passo 2 </strong> Selecione o <u>“Ano Base”</u> que deseja gerar a declaração.",
          "<strong> Passo 3 </strong> Clique no botão <u>“Baixar</u>."
        ] 
      },
      about: `Ao seguir esses passos simples, você poderá baixar a declaração anual de pagamentos. Este documento fornece uma visão consolidada e clara de todas as transações ao longo do período, proporcionando praticidade e documentação essencial para suas necessidades de controle e prestação de contas.`,
      video: "assets/faq/Como faço para gerar a declaração anual.mp4",
      route: EnumPath.BANK_ANUAL_STATEMENT,
      permission: [PERMITION_FAQ_CLIENT, PERMITION_BANK, PERMITION_BANK_ANUAL_STATEMENT],
      group: FAQ_BANK
    },
    {
      title: "Como faço para alterar meu contrato",
      steps: {
        title: "Se você possui mais de um contrato, é fácil realizar a alteração para aproveitar as funcionalidades do sistema. Siga estas etapas simples:",
        items: [
          "<strong> Passo 1 </strong> Acesse a página desejada.",
          "<strong> Passo 2 </strong> No campo <u>“Selecione seu contrato“</u>, escolha qual contrato deseja utilizar.",
        ] 
      },
      observation: [
        "Se você tiver apenas um contrato, ele será selecionado automaticamente"
      ],
      about: `Com esses passos, você terá a flexibilidade de mudar entre contratos, permitindo o uso conveniente das diferentes funcionalidades do sistema de acordo com suas necessidades`,
      video: "assets/faq/Como faço para alterar meu contrato.mp4",
      permission: [PERMITION_FAQ_CLIENT, PERMITION_BANK],
      group: FAQ_BANK
    },
    {
      title: "Como faço para abrir uma nova solicitação de Seguros",
      steps: {
        title: "Abertura de uma nova solicitação de seguros é simples e prático, possibilitando diversas opções, como 2ª via de boleto, 2ª via de apólice, endosso alteração de perfil, endosso cadastral e endosso troca de bem. Siga estes passos: ",
        items: [
          "<strong> Passo 1 </strong> Acesse a página dedicada aos <a>Seguros</a>.",
          "<strong> Passo 2 </strong> Selecione a opção desejada (2ª via de boleto, 2ª via de apólice, endosso alteração de perfil, endosso cadastral ou endosso troca de bem).",
          "<strong> Passo 3 </strong> Adicione os detalhes da sua solicitação.",
          "<strong> Passo 4 </strong> Clique em <u>Enviar</u>."
        ] 
      },
      about: `Após o envio, você poderá visualizar facilmente sua solicitação no quadro de "Minhas Solicitações". Essa abordagem simplificada proporciona praticidade e eficiência ao lidar com diferentes necessidades relacionadas aos seus seguros. `,
      video: "assets/faq/Como faço para abrir uma nova solicitação de Seguros.mp4",
      route: EnumPath.INSURANCE,
      permission: [PERMITION_FAQ_CLIENT, PERMITION_INSURANCE],
      group: FAQ_REQUESTS
    },
    {
      title: "Como faço para abrir uma nova solicitação de Liberacred",
      steps: {
        title: "Abrir uma nova solicitação de Liberacred é um processo fácil e versátil, permitindo opções como 2ª via de boleto, boleto em atraso e pedido de cancelamento. Siga estes passos simples:",
        items: [
          "<strong> Passo 1 </strong> Acesse a página dedicada aos <a>Liberacred</a>.",
          "<strong> Passo 2 </strong> Selecione a opção desejada (2ª via de boleto, boleto em atraso ou pedido de cancelamento).",
          "<strong> Passo 3 </strong> Adicione os detalhes da sua solicitação.",
          "<strong> Passo 4 </strong> Clique em <u>Enviar</u>."
        ] 
      },
      about: `Após o envio, você poderá visualizar facilmente sua solicitação no quadro de "Minhas Solicitações". Essa abordagem prática oferece rapidez e eficiência ao lidar com diversas necessidades relacionadas à sua conta Liberacred. `,
      route: EnumPath.CREDIT,
      video: "assets/faq/Como faço para abrir uma nova solicitação de Liberacred.mp4",
      permission: [PERMITION_FAQ_CLIENT, PERMITION_CREDIT],
      group: FAQ_REQUESTS
    },
    {
      title: "Como faço para abrir uma nova solicitação em Minhas Solicitações",
      steps: {
        title: "Abertura de uma nova solicitação em Minhas Solicitações é fácil e abrange tanto Seguros quanto Liberacred. Siga estes passos simples:",
        items: [
          "<strong> Passo 1 </strong> Acesse a página dedicada aos <a>Minhas Solicitações</a>.",
          "<strong> Passo 2 </strong> Selecione a opção desejada, seja para Seguros (2ª via de boleto, 2ª via de apólice, endosso alteração de perfil, endosso cadastral ou endosso troca de bem) ou Liberacred (2ª via de boleto, boleto em atraso ou pedido de cancelamento)",
          "<strong> Passo 3 </strong> Adicione os detalhes da sua solicitação.",
          "<strong> Passo 4 </strong> Clique em <u>Enviar</u>."
        ] 
      },
      about: `Após o envio, você poderá visualizar facilmente sua solicitação no quadro de "Minhas Solicitações". Essa abordagem unificada oferece praticidade ao lidar com diferentes necessidades relacionadas a Seguros e Liberacred, tudo em um único local. `,
      video: "assets/faq/Como faço para abrir uma nova solicitação em Minhas Solicitações.mp4",
      route: EnumPath.REQUESTS,
      permission: [PERMITION_FAQ_CLIENT, PERMITION_REQUESTS],
      group: FAQ_REQUESTS
    },
    {
      title: "Como faço para acessar o meu consórcio",
      steps: {
        title: "Acessar o seu consórcio é simples e direto. Siga este passo a passo:",
        items: [
          "<strong> Passo 1 </strong> Acesse a página dedicada aos <a>Minhas Solicitações</a>."
        ] 
      },
      about: `Com apenas esse clique, você terá acesso rápido e fácil à página do seu consórcio, onde poderá encontrar todas as informações e funcionalidades necessárias.`,
      video: "assets/faq/Como faço para acessar o meu consórcio.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_CONSORTIUM
    },
    {
      title: "Como faço para visualizar as minhas mensagens",
      steps: {
        title: "Visualizar suas mensagens é fácil e conveniente, utilizando a central de notificações. Siga estes passos simples: ",
        items: [
          "<strong> Passo 1 </strong> Clique no botão do <u>“sininho“</u> para acessar a central de notificações.",
          "<strong> Passo 2 </strong> Dentro da central, clique na mensagem que deseja visualizar.",
          "<strong> Passo 3 </strong> Após visualizar a mensagem, para fechá-la, basta clicar em <u>“Fechar“</u>."
        ] 
      },
      video: "assets/faq/Como faço para visualizar as minhas mensagens.mp4",
      about: `Com esses passos, você terá acesso rápido e organizado às suas mensagens na central de notificações. Essa abordagem simplificada facilita a gestão e visualização das informações importantes.`,
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_CONTACT
    },
    {
      title: "Como faço para acessar o atendimento via WhatsApp",
      steps: {
        title: "Acessar nosso atendimento online via WhatsApp é simples e eficiente. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> Clique no botão com o ícone do WhatsApp. Você será automaticamente redirecionado para o WhatsApp da Yamaha Serviços Financeiros.",
        ] 
      },
      about: `Ao seguir esse passo, você terá acesso direto ao nosso canal de atendimento, onde poderá receber assistência de forma rápida e conveniente. Estamos aqui para ajudar! `,
      video: "assets/faq/Como faço para acessar o atendimento via WhatsApp.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_CONTACT
    },
    {
      title: "Como faço para acessar o atendimento via WebChat",
      steps: {
        title: "Acessar nosso atendimento online via WebChat é fácil e conveniente. Siga estes passos simples: ",
        items: [
          "<strong> Passo 1 </strong> Clique no botão com o ícone de chat.",
          "<strong> Passo 2 </strong> Clique em <u>Iniciar Atendimento</u>",
          "<strong> Passo 3 </strong> Envie uma mensagem para iniciar o atendimento</u>.",
          "<strong> Passo 4 </strong> Após concluir o atendimento, clique em <u>Finalizar Atendimento</u>."
        ] 
      },
      about: `Ao seguir essas etapas, você terá acesso imediato ao nosso serviço de atendimento via WebChat, proporcionando uma experiência interativa e eficaz. Estamos prontos para ajudar e responder às suas perguntas. `,
      video: "assets/faq/Como faço para acessar o atendimento via WebChat.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_CONTACT
    },
    {
      title: "Como faço para sair do sistema",
      steps: {
        title: "Realizar o logout do sistema é simples e seguro. Siga estes passos: ",
        items: [
          "<strong> Passo 1 </strong> Clique no Menu do Usuário.",
          "<strong> Passo 2 </strong> Selecione a opção <u>“Sair“</u>",
          "<strong> Passo 3 </strong> Na mensagem de confirmação, clique em <u>“Sim“</u>. Após isso, você será redirecionado para a tela de login.</u>."
        ] 
      },
      video: "assets/faq/Como faço para sair do sistema.mp4",
      about: `Com essas etapas, você pode sair do sistema de maneira rápida e eficiente, garantindo a segurança de sua sessão. Se precisar acessar novamente, basta efetuar o login na tela correspondente.`,
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_SYSTEM
    },
    {
      title: "Como faço para acessar o aviso de privacidade",
      steps: {
        title: "Acessar nosso aviso de privacidade é simples e transparente. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de todas as páginas, clique no botão <u>“Aviso de Privacidade“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será automaticamente redirecionado para a tela que contém nosso aviso de privacidade. Esta abordagem garante fácil acesso às informações essenciais sobre a proteção de seus dados e privacidade.`,
      video: "assets/faq/Como faço para acessar o aviso de privacidade.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_POLICIES
    },
    {
      title: "Como faço para acessar a política de segurança cibernética",
      steps: {
        title: "Acessar nossa política de segurança cibernética é simples e vital para a proteção dos seus dados. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Política de Segurança Cibernética“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a tela que contém nossa política de segurança cibernética. Essa prática garante fácil acesso às informações cruciais sobre como protegemos e gerenciamos a segurança dos seus dados online.`,
      video: "assets/faq/Como faço para acessar a política de segurança cibernética.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_POLICIES
    },
    {
      title: "Como faço para acessar a política de qualidade e ambiental",
      steps: {
        title: "Acessar nossa política de qualidade e ambiental é fácil e fundamental para entender nossos compromissos. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Qualidade e Ambiental“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a tela que contém nossa política de qualidade e ambiental. Essa abordagem assegura um acesso rápido e direto às informações essenciais sobre nossas práticas relacionadas à qualidade e ao meio ambiente.`,
      video: "assets/faq/Como faço para acessar a política de qualidade e ambiental.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_POLICIES
    },
    {
      title: "Como faço para acessar o canal de denúncia",
      steps: {
        title: "Acessar nosso canal de denúncia é simples e crucial para garantir a transparência e integridade. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Canal de Denúncia“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a tela do nosso canal de denúncia. Essa abordagem assegura um acesso fácil e direto, permitindo que você faça sua contribuição de forma segura e confidencial. Estamos comprometidos em ouvir e agir em relação a qualquer preocupação que você possa ter.`,
      video: "assets/faq/Como faço para acessar o canal de denúncia.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_POLICIES
    },
    {
      title: "Como faço para acessar o código de ética",
      steps: {
        title: "Acessar nosso código de ética é uma maneira importante de compreender nossos valores e princípios. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Código de Ética“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a tela do nosso código de ética. Essa abordagem assegura um acesso fácil e direto às informações cruciais que orientam nossas práticas éticas. Estamos comprometidos com a transparência e integridade em tudo o que fazemos.`,
      video: "assets/faq/Como faço para acessar o código de ética.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_POLICIES
    },
    {
      title: "Como faço para acessar o site da Yamaha Motor",
      steps: {
        title: "Acessar o nosso site oficial da Yamaha Motor é fácil e rápido. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Yamaha Motor“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a página oficial da Yamaha Motor. Essa abordagem assegura um acesso fácil e direto às informações mais recentes e relevantes relacionadas aos produtos e serviços da Yamaha Motor. Estamos aqui para oferecer a melhor experiência possível. `,
      video: "assets/faq/Como faço para acessar o site da Yamaha Motor.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_LINKS
    },
    {
      title: "Como faço para acessar o site da Yamaha Musical",
      steps: {
        title: "Acessar o nosso site oficial da Yamaha Musical é fácil e rápido. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Yamaha Musical“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a página oficial da Yamaha Musical. Essa abordagem assegura um acesso fácil e direto às informações mais recentes e relevantes sobre os produtos e serviços musicais da Yamaha. Estamos aqui para proporcionar a melhor experiência musical possível. `,
      video: "assets/faq/Como faço para acessar o site da Yamaha Musical.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_LINKS
    },
    {
      title: "Como faço para acessar o site da Yamaha Náutica",
      steps: {
        title: "Acessar o nosso site oficial da Yamaha Náutica é fácil e rápido. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Yamaha Náutica“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a página oficial da Yamaha Náutica. Essa abordagem garante um acesso fácil e direto às informações mais recentes e relevantes sobre os produtos e serviços náuticos da Yamaha. Estamos aqui para proporcionar a melhor experiência na água possível.`,
      video: "assets/faq/Como faço para acessar o site da Yamaha Náutica.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_LINKS
    },
    {
      title: "Como faço para acessar o site de Consórcio Yamaha",
      steps: {
        title: "Acessar o nosso site oficial de Consórcio Yamaha é simples e rápido. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Consórcio“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a página oficial de Consórcio Yamaha. Essa abordagem assegura um acesso fácil e direto às informações mais recentes e relevantes sobre nossas opções de consórcio. Estamos aqui para tornar seu processo de consórcio ainda mais fácil e acessível.`,
      video: "assets/faq/Como faço para acessar o site de Consórcio Yamaha.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_LINKS
    },
    {
      title: "Como faço para acessar o site de Banco da Yamaha",
      steps: {
        title: "Acessar o nosso site oficial do Banco Yamaha é fácil e rápido. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Banco“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a página oficial do Banco Yamaha. Essa abordagem assegura um acesso fácil e direto às informações mais recentes e relevantes sobre nossos serviços bancários. Estamos aqui para oferecer soluções financeiras confiáveis e convenientes. `,
      video: "assets/faq/Como faço para acessar o site de Banco da Yamaha.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_LINKS
    },
    {
      title: "Como faço para acessar o site de Seguros da Yamaha",
      steps: {
        title: "Acessar o nosso site oficial do Seguros Yamaha é fácil e rápido. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Seguros“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a página oficial de Seguros Yamaha. Essa abordagem assegura um acesso fácil e direto às informações mais recentes e relevantes sobre nossos serviços de seguro. Estamos aqui para oferecer proteção confiável para sua tranquilidade.`,
      video: "assets/faq/Como faço para acessar o site de Seguros da Yamaha.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_LINKS
    },
    {
      title: "Como faço para acessar o site de Liberacred da Yamaha",
      steps: {
        title: "Acessar o nosso site oficial do Liberacred Yamaha é fácil e rápido. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no botão <u>“Liberacred“</u>",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para a página oficial de Liberacred Yamaha. Essa abordagem assegura um acesso fácil e direto às informações mais recentes e relevantes sobre nossos serviços de financiamento. Estamos aqui para facilitar suas opções de pagamento e tornar seus planos mais acessíveis.`,
      video: "assets/faq/Como faço para acessar o site de Liberacred da Yamaha.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_LINKS
    },
    {
      title: "Como faço para acessar as redes sociais da Yamaha",
      steps: {
        title: "Acompanhar a Yamaha nas redes sociais é fácil e interativo. Siga este passo a passo: ",
        items: [
          "<strong> Passo 1 </strong> No rodapé de qualquer página do site, clique no ícone do Facebook para acessar a página do Consórcio Yamaha",
        ] 
      },
      about: `Ao realizar esse clique, você será imediatamente redirecionado para o Facebook do Consórcio Yamaha. Essa abordagem assegura um acesso fácil e direto para se manter atualizado sobre as novidades, promoções e informações relevantes da Yamaha. Estamos aqui para compartilhar momentos emocionantes e importantes com nossa comunidade online.`,
      video: "assets/faq/Como faço para acessar as redes sociais da Yamaha.mp4",
      permission: [PERMITION_FAQ_CLIENT],
      group: FAQ_LINKS
    }
  ]  
  public filteredFaqItemList: FaqItem[] = this.faqItemListWithPermission

  public filter: FormControl = new FormControl('');

  get faqItemListWithPermission(){ 
    let list = []
    
    if(this.getPermissions().includes(PERMITION_FAQ_ADMIN)){
      list = list.concat(this.faqItemList.filter(item => item.permission.includes(PERMITION_FAQ_ADMIN)))
    }

    if(this.getPermissions().includes(PERMITION_FAQ_CLIENT)){
      list = list.concat(this.faqItemList.filter(item => item.permission.includes(PERMITION_FAQ_CLIENT)))
    }

    return list
  }

  get listConfigUsers(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_CONFIG_USERS)
  }
  get listConfigMessages(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_CONFIG_MESSAGES)
  }
  get listConfigBanners(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_CONFIG_BANNERS)
  }  
  get listConfigProfile(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_CONFIG_PROFILE)
  }
  get listConfigBilling(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_CONFIG_BILLING)
  }
  get listBank(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_BANK)
  }
  get listRequests(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_REQUESTS)
  }
  get listConsortium(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_CONSORTIUM)
  }
  get listContact(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_CONTACT)
  }
  get listSystem(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_SYSTEM)
  }
  get listPolicies(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_POLICIES)
  }
  get listLinks(){
    return this.filteredFaqItemList.filter(x => x.group == FAQ_LINKS)
  }    

  constructor(){
    super()

    this.faqItemList = this.faqItemList.map(item => {
      return {
        ...item,
        concat: this.concatString(item)
      }
    })

    this.filter.valueChanges.subscribe(v => {
      this.filteredFaqItemList = this.faqItemListWithPermission.filter(item => item.concat.includes(this.filter.value.toLowerCase()))
    })

  }

  private concatString(item: FaqItem): string {
    let concat = ""

     
    if(item.title){
      concat = concat.concat(item.title)
    }

    if(item.steps?.title) {
      concat = concat.concat(" " + item.steps?.title)
    }

    if(item.steps?.items){
      concat = concat.concat(" " + item.steps?.items.join(" ")) 
    }

    if(item.observation){
      concat = concat.concat(" " + item.observation.join(" "))
    }

    if(item.about){
      concat = concat.concat(" " + item.about)
    }

    if(item.rules){
      concat = concat.concat(item.rules?.join(" "))
    }
    
    return concat.replaceAll("<strong>", "").replaceAll("</strong>", "").toLowerCase();
  }



  


}
