<div class="footer">
    <div class="yamaha-image">
        <img class="imagem" src="assets/yamaha-servicos-financeiros/yamaha-servicos-financeiros-1.png" alt="YAMAHA SERVIÇOS FINANCEIROS (1) (2).png" >
        <img (click)="goToLink('https://www.facebook.com/ConsorcioNacionalYamaha')" class="facebook-logo" src="assets/facebook/facebook-logo.gif">           
    </div>

    <div class="yamaha-links">
        <ul class="yamaha-products">
            <li *ngFor="let route of routesProducts">
                <a class="estricta uppercase bolder"
                   (click)="goToLink(route.path)"
                > {{route.name}} </a>
            </li>
        </ul>

        <ul class="yamaha-politcs">
            <li *ngFor="let route of routesPolitics">
                <a (click)="goToLink(route.path)"> {{route.name}} </a>
            </li>
        </ul>

        <ul class="yahama-options">
            <ng-container *ngFor="let route of routesOptions">
                <li class="estricta uppercase bolder"> <a (click)="goToLink(route.path)"> {{route.name}} </a> </li>
            </ng-container>
        </ul>

    </div>
</div>
