import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import { BehaviorSubject, Observable, Subject, catchError, of, tap, throwError } from 'rxjs';
import { Contract } from '../models/bank/contract';
import { BankSlipRequest, BankSlipResponse, FullPayment, Payment, PaymentBook, PaymentInversionRequest, PaymentInversionResponse, StatusLienResponse } from '../models/bank/payment';
import { AnualStatement, Statement } from '../models/bank/statement';
import { HttpHeaders } from '@angular/common/http';
import { ContractLetterDebtorBalance } from '../models/bank/contractLetterDebtor';

@Injectable({
  providedIn: 'root'
})
export class BankService extends BaseService<Contract | Payment | BankSlipResponse> {

  public emitContractSelected: BehaviorSubject<Contract>
  public emitContractsLoaded: BehaviorSubject<Contract[]> = new BehaviorSubject([]);
  private statementSelected: Statement[];

  private pathLien = "gateway/liens"

  constructor() {
    super();

    this.path = "gateway/contracts";
  }

  public createSubject(contract: Contract): void {
    this.emitContractSelected = new BehaviorSubject(contract)
  }

  public bankSlip(bankSlip: BankSlipRequest, contract: string): Observable<BankSlipResponse> {
    return this.http.post<BankSlipResponse>(`${this.api}${this.path}/${contract}/bank-slip`, bankSlip, {withCredentials: true})
  }

  public statement(contract: string, createCrmIncident: boolean): Observable<Statement[]> {
    return this.http.get<Statement[]>(`${this.api}${this.path}/${contract}/${createCrmIncident}/financial-statement`, {withCredentials: true})
  }

  public fullStatement(contract: string): Observable<FullPayment[]> {
    return this.http.get<FullPayment[]>(`${this.api}${this.path}/${contract}/full-instalments`, {withCredentials: true})
  }

  public statementPdf(contract: string): Observable<Blob> {
    return this.http.post(`${this.api}${this.path}/${contract}/financial-statement/export`, null, {responseType: 'blob', withCredentials: true})
  }

  public getAnualPaymentExtract(contract: string, year: number): Observable<AnualStatement[]> {
    return this.http.get<AnualStatement[]>(`${this.api}${this.path}/${contract}/annual-payment-extract/${year}`, {withCredentials: true})
  }

  public AnualPaymentExtractPdf(contract: string, year: number): Observable<Blob> {
    return this.http.post(`${this.api}${this.path}/${contract}/annual-payment-extract/${year}/export`, null, {responseType: 'blob', withCredentials: true})
  }

  public PaymentBookFile(contract: string): Observable<PaymentBook> {
    return this.http.post<PaymentBook>(`${this.api}${this.path}/${contract}/payment-book`, null, { withCredentials: true})
  }
  

  public GeneratePayment<BankSlipRequest, BankSlipResponse>(bankSlip: BankSlipRequest, contract: string, createCrmIncident: boolean = true): Observable<BankSlipResponse> {

    const httpOptions = {
      headers: new HttpHeaders({
        Accept : 'application/octet-stream',
        'Content-Disposition': 'attachment',
        'Response-Type': "arraybuffuer"
      })
    };

    return this.http.post<BankSlipResponse>(`${this.api}${this.path}/${contract}/bank-slip/${createCrmIncident}`, bankSlip, {
      ...httpOptions,
      withCredentials: true
    })

  }

  public GenerateContractReceiptPdf(contract: string): Observable<Blob> {
    return this.http.post(`${this.api}${this.path}/${contract}/contract-receipt/export`, null, {responseType: 'blob', withCredentials: true})
    .pipe(
      catchError(error => {
        // Se o erro for um erro HTTP, tenta ler o corpo da resposta como texto
        if (error.error instanceof Blob) {
          return new Promise<any>((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (e: Event) => {
              // Aqui você pode tratar a string de erro ou apenas rejeitar a promessa com ela
              reject(reader.result);
            };
            reader.onerror = (e) => {
              reject(error);
            };
            reader.readAsText(error.error);
          });
        }
        // Se não for um Blob, apenas retorna o erro original
        return throwError(error);
      })
    );
  }

  public GenerateContractLetterDebtorBalancePdf(contract: string, contractLetterDebtorBalance: ContractLetterDebtorBalance): Observable<Blob> {
    return this.http.post(`${this.api}${this.path}/${contract}/contract-letter-debtor-balance`, contractLetterDebtorBalance, {responseType: 'blob', withCredentials: true})
  }

  public RequestPaymentInversion(request: PaymentInversionRequest): Observable<PaymentInversionResponse> {
    return this.http.post<PaymentInversionResponse>(`${this.api}${this.path}/payment-reversion`, request, {withCredentials: true})
  }

  public GetUserInstallments(contract: string): Observable<Payment[]> {
    return this.http.get<Payment[]>(`${this.api}${this.path}/${contract}/instalments`, {withCredentials: true})
  }
  
  public DownloadRightAssingmentFile(): Observable<any> {
    return this.http.get(`${this.api}${this.path}/download-right-assignment-file`, {responseType: 'blob', withCredentials: true})
      .pipe(
        tap(x => this.download(x, `Cessão de Direitos.pdf`, "pdf")
      )
    )
  }

  public DownloadWarrantySubstitutionFile(): Observable<any> {
    return this.http.get(`${this.api}${this.path}/download-warranty-substitution-file`, {responseType: 'blob', withCredentials: true})
      .pipe(
        tap(x => this.download(x, `Substituição de Garantia_modelo.pdf`, "pdf")
      )
    )
  }

  public GetLienStatus(contract: string): Observable<StatusLienResponse> {
    return this.http.get<any>(`${this.api}${this.pathLien}/${contract}`, {withCredentials: true})
  }
}
