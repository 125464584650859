var appDynamics = {
  init: function(key) {
    if(!key) {
      return;
    }

    window["adrum-start-time"] = new Date().getTime();

    (function(config){
        config.appKey = key;
        config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
        config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
        config.beaconUrlHttp = "http://gru-col.eum-appdynamics.com";
        config.beaconUrlHttps = "https://gru-col.eum-appdynamics.com";
        config.useHTTPSAlways = true;
        config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
        config.maxUrlLength = 512;
    })(window["adrum-config"] || (window["adrum-config"] = {}));
  }
};

export {appDynamics};
