import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Statement } from 'src/app/models/bank/statement';
import { BaseComponent } from 'src/app/@base/base.component';
import { BankService } from 'src/app/services/bank.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { Contract } from 'src/app/models/bank/contract';
import { FullPayment } from 'src/app/models/bank/payment';

@Component({
  selector: 'app-bank-amount',
  templateUrl: './bank-amount.component.html',
  styleUrls: ['./bank-amount.component.scss']
})
export class BankAmountComponent extends BaseComponent implements OnInit {

  @Output() emitFirstDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitLastDate: EventEmitter<string> = new EventEmitter<string>();

  public $subscriptionContract: Subscription;
  public statementSelected: FullPayment[];

  public loading: boolean = false;
  public contract: Contract;
  public showChart: boolean = false
  public totalStallments: number;
  public totalPaidStallments: number;

  constructor(private bankService: BankService) {
    super();
  }

  ngOnInit(): void {
    this.subscriptionChangeContract();    
  }

  public getTotalPaidStallments(): number {
    return this.statementSelected.filter(statement => statement.paymentDay).length;
  
  }

  public getTotalStallments(): number {
    return this.statementSelected.length;
  
  }

  ngOnDestroy(): void {
    this.$subscriptionContract.unsubscribe();
  }

  private subscriptionChangeContract(): void {
    this.$subscriptionContract = this.bankService.emitContractSelected.subscribe((contract: Contract) => {
      this.contract = contract;
      this.loading = true;
      this.showChart = false;
      this.loadStatement();
    })
  }
  private loadStatement(): void {
    this.statementSelected = [];
    this.loading = true;
    firstValueFrom(this.bankService.fullStatement(this.contract.contractNumber))
      .then((resp: FullPayment[]) => {
        this.statementSelected = resp;
        this.FirstDueDate();
        this.LastDueDate();
        this.sumValue();
        this.amountPaid();
        this.totalPaidStallments = this.getTotalPaidStallments();
        this.totalStallments = this.getTotalStallments();
        this.showChart = true;
      })
      .catch((error) => {
        console.error('Error loading annual statement:', error);
      })
      .finally(() => {
        this.loading = false;
      });
  }


  sumValue(): number {
    const total = this.statementSelected.reduce((total, statement) => {
      return total + parseFloat(statement.originalValue);
    }, 0);
    return total;
  }

  countInstallments(): string {
    const totalParcelas = this.statementSelected.length;
    const parcelasNaoPagas = this.statementSelected.filter(statement => !statement.paymentDay).length;

    return `${parcelasNaoPagas} de ${totalParcelas}`;
  }

  amountPaid(): number {
    const totalPago = this.statementSelected
      .filter(statement => statement.paymentDay)
      .reduce((total, statement) => total + parseFloat(statement.originalValue), 0);
    return totalPago;
  }

  debtBalance(): string {
    const valorTotal = this.statementSelected
      .reduce((total, statement) => total + parseFloat(statement.originalValue), 0);

    const totalPago = this.statementSelected
      .filter(statement => statement.paymentDay)
      .reduce((total, statement) => total + parseFloat(statement.originalValue), 0);

    const saldoDevedor = valorTotal - totalPago;

    return saldoDevedor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  discountIncreaseValue(): string {
    const total = this.statementSelected.reduce((total, statement) => {
      return total + parseFloat(statement.discountIncrease);
    }, 0);
    return total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  FirstDueDate(): void {
    const FirstDueDate = this.statementSelected.reduce((minDueDate, statement) => {
      return statement.dueDate < minDueDate ? statement.dueDate : minDueDate;
    }, this.statementSelected[0].dueDate);

    this.emitFirstDate.emit(new Date(FirstDueDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' }))
  }

  LastDueDate(): void {
    const LastDueDate = this.statementSelected.reduce((maxDueDate, statement) => {
      return new Date(statement.dueDate) > new Date(maxDueDate) ? statement.dueDate : maxDueDate;
    }, this.statementSelected[0].dueDate);

    this.emitLastDate.emit(new Date(LastDueDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' }));
  }




}