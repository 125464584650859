<app-bank-header>
  <div class="bank-content">
    <div class="section-content">
      <app-bank-summary
        *ngIf="!loading; else containerLoading"
        [width]="allowPayment ? '400px' : '800px'"
        [contract]="contract.contractNumber"
        [installments]="installments"
        [allowPayment]="allowPayment"
        [allSelected]="true"
      >
        <div class="bank-summary-header">
          <span class="uppercase estricta summary-title">
            Quitação de contrato
          </span>
          <div class="data">
            <span> Data: </span> <span> {{ date | date : "dd/MM/yyyy" }} </span>
          </div>
          <div class="installments-info">
            <span> Número de parcelas a pagar: </span>
            <span class="installments-number"> {{ installments.length }} </span>
          </div>
        </div>

        <div class="bank-summary-not-allowed">
          <span>{{
            contract.consultancyInformation || messageNotAllowed
          }}</span>
        </div>
      </app-bank-summary>
    </div>
  </div>
</app-bank-header>

<ng-template #containerLoading>
  <div class="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
</ng-template>
