import { Component, Inject, Sanitizer } from "@angular/core";
import { ModalComponent } from "../modal.component";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-modal-video",
  templateUrl: "./modal-video.component.html",
  styleUrls: ["./modal-video.component.scss"],
})
export class ModalVideoComponent extends ModalComponent<ModalVideoComponent> {
  public url: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    super();
    this.url = data;
  }
}
