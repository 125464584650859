export enum EnumReportIncident {
    BANK_BILL = '2ª via de Boleto',
    BANK_PAYMENT = 'Antecipação de Pagamento',
    BANK_CONTRACT = 'Quitação de Contrato',
    BANK_PAYMENT_BOOK = '2ª via do Carnê',
    BANK_STATEMENT = 'Extrato Financeiro',
    BANK_DOWNLOAD_STATEMENT = 'Download do Extrato Financeiro',
    BANK_ANUAL_STATEMENT = 'Declaração Anual',
    BANK_DOWNLOAD_ANUAL_STATEMENT = 'Download da Declaração Anual',
    BANK_CONTRACT_RECEIPT = 'Recibo de Quitação contratual',
    BANK_CARTA_SALDO_DEVEDOR = 'Carta de Saldo Devedor',
    BANK_RIGHTS_ASSIGNMENT = 'Solicitação de Cessão de Direitos',
    BANK_WARRANTY_REPLACEMENT = 'Solicitação de Substituição de Garantia',
    BANK_CHANGE_REGISTRATION_DATA = 'Solicitação de Alteração de Dados Cadastrais',
    BANK_PAYMENT_REVERSAL = 'Solicitação de Inversão de Pagamento',
    BANK_TRAVEL_AUTHORIZATION = 'Solicitação de Autorização de Viagem',
    BANK_LIEN_STATUS = 'Status do Gravame',
    CONSORTIUM_PORTAL = 'Acesso ao Portal do Consórcio',
    CREDIT_BILL = '2ª via de Boleto',
    CREDIT_DELAYED = 'Boleto em Atraso',
    CREDIT_CANCELLATION = 'Pedido de Cancelamento',
    INSURANCE_BILL = '2ª via de Boleto',
    INSURANCE_POLICY = '2ª via de Apólice',
    INSURANCE_CONTRACT = 'Endosso Alteração de Perfil',
    INSURANCE_REGISTRATION = 'Endosso Cadastral',
    INSURANCE_REPLACEMENT = 'Endosso Troca de Bem',
    INSURANCE_GET_POLICY = 'SUHAI - Consulta de Apólices',
    INSURANCE_EMIT_BILL = 'SUHAI - Emissão de Boleto',
    INSURANCE_SINISTER = 'SUHAI - Consulta de Status de Sinistro',
    INSURANCE_EMIT_POLICY = 'SUHAI - Download de Apólice'
}