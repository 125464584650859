import { Component, Input, QueryList, ViewChildren, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-custom-autocomplete',
  templateUrl: './custom-autocomplete.component.html',
  styleUrls: ['./custom-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomAutocompleteComponent),
      multi: true,
    }
  ]
})
export class CustomAutocompleteComponent implements ControlValueAccessor {

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  @ViewChildren("checkboxes") checkboxes: QueryList<MatCheckbox>;

  @Input() list: any[] = [];
  @Input() displayKey: string;
  @Input() valueKey: string;
  @Input() label: string;
  @Input() chips: boolean = false;
  @Input() datacy: string;

  public filteredItems: Observable<any[]>;
  public noResults: boolean = false;

  public control = new FormControl();
 
  public lastFilter: string = '';

  private innerValue: any[] = [];
  get value() {
    return this.innerValue;
  }
  set value(v: any[]) {    
    if(v !== this.innerValue) { 
      this.innerValue = v.map(x => x[this.valueKey])
      this.onChanged(this.innerValue)
    }
  }

  private innerSelectedItems: any[] = [];
  get selectedItems() {
    return this.innerSelectedItems
  }
  set selectedItems(v: any[]){
    this.value = v
    this.innerSelectedItems = v
  }

  onChanged: (value: any) => void = () => {};
  onTouched: (value: any) => void = () => {};

  ngOnInit() {
    this.filteredItems = this.control.valueChanges.pipe(
      startWith<string | any[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filter(filter))
    );

  }

  writeValue(obj: any): void {
    this.value = obj;
    this.selectedItems = obj
  }
  
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState?(isDisabled: boolean): void {

  }

  public getDisplay(item: any): string {
    if(typeof item === "object"){
      return item[this.displayKey]
    }

    return item
  }

  public getValue(item: any): string {
    if(typeof item === "object"){
      return item[this.valueKey]
    }

    return item
  }

  private filter(filter: string): any[] {

    this.noResults = false

    this.lastFilter = filter;
    if (filter) {
      let results = this.list.filter(option => option[this.displayKey].toLowerCase().indexOf(filter.toLowerCase()) >= 0)

      if(results.length == 0){
        this.noResults = true
      }

      return results
    } else {
      return this.list.slice();
    }
  }

  public toggleSelection(item: any, checked: boolean): void {

    if (checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter(x => x !== item)
    }

    this.value = this.selectedItems
  }

  public toggleAll(event: MatCheckboxChange): void {
    if(event instanceof MatCheckboxChange){
      if(event.checked){
        this.selectedItems = this.list
      }
  
      if(!event.checked){
        this.selectedItems = []
      }
    }
  }

  public remove(item: any): void {
    const index = this.selectedItems.indexOf(item);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.toggleSelection(item, false)
    }
  }

  public itemSelected(item: any): boolean{
    return this.selectedItems.includes(item)
  }

  public allItemsSelected(): boolean {
    return this.selectedItems.length == this.list.length
  }

  public displaySelectedItems(): string {
    return this.selectedItems.map(x => typeof x === "string" ? x : x[this.displayKey]).join(", ")
  }

  optionClicked(event: Event, item: any) {
    event.stopPropagation();
    this.toggleSelection(item, !this.itemSelected(item));
  }

  public returnDataCy(validation: string): string {
    return this.datacy + "-" + validation
  }

}
