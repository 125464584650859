import { Component, AfterViewInit, Input, OnChanges } from '@angular/core';
import { Chart } from 'chart.js/auto'; 

@Component({
  selector: 'app-bank-chart',
  templateUrl: './bank-chart.component.html',
  styleUrls: ['./bank-chart.component.scss']
})
export class BankChartComponent implements AfterViewInit {

  @Input() total: number;
  @Input() valorPago: number;
  @Input() totalInstallments: number;
  @Input() totalPaidInstallments: number;
    
  constructor() { }

  ngAfterViewInit(): void {
    const percent = this.calculatePercentage();

    const ctx = document.getElementById('chartProgress') as HTMLCanvasElement;

    if (ctx) {
      const myChartCircle = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Valor Pago'],
          datasets: [{
            data: [percent, 100 - percent],
            backgroundColor: ['#365ABA', '#D9D9D9'],
            
          }]
        },
        plugins: [{
            id: 'beforeDrawPlugin',
            beforeDraw: (chart) => {
              const width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
              ctx.restore();
              const fontSize = (height / 150).toFixed(2);
              ctx.font = fontSize + "em sans-serif";
              ctx.fillStyle = "#000000";
              ctx.textBaseline = "middle";
              const text = percent + "%",
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;
              ctx.fillText(text, textX, textY);
              ctx.save();
            }
          }
        ],
        options: {
          maintainAspectRatio: false,
          cutout: '85%',
          rotation: Math.PI / 2,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              filter: tooltipItem => tooltipItem.dataIndex === 0
            }
          }
        }
      });
    }
  }

  calculatePercentage(): number {    
    let percentage;
    if (this.totalPaidInstallments > 0) {
        percentage = Math.floor((this.totalPaidInstallments / this.totalInstallments) * 100);
    } else {
        percentage = 0;
    }

    return percentage;
}

}
