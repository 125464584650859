<app-forgot-password class="density-lower teste">

  <section class="backdrop-blur">
    <div *ngIf="!success; else successMessage">
      <div class="content-header">
        <span class="uppercase estricta bolder content-header-title"> Recuperação de senha </span>
        <span class="content-header-subtitle"> Informe a nova senha duas vezes e clique em "Alterar senha". </span>
      </div>

      <form [formGroup]="form" class="content-form-fields density-mat-form-3">
        <div class="form-field">
          <label> Senha </label>
          <mat-form-field appearance="outline" class="background-white">

            <input formControlName="newPassword" matInput [type]="hidePassword ? 'password' : 'text'" maxlength="50"
              data-cy="newPassword">

            <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword"
              data-cy="button-hide-newPassword">
              <mat-icon class="grey">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>

            <mat-error *ngIf="form.get('newPassword').hasError('required')" data-cy="error-newPassword-required">
              <strong> A senha é obrigatória </strong>
            </mat-error>

          </mat-form-field>
        </div>

        <div class="form-field">
          <label> Repita sua Senha </label>

          <mat-form-field appearance="outline" class="background-white">

            <input formControlName="confirmPassword" matInput [type]="hideConfirmPassword ? 'password' : 'text'"
              maxlength="50" data-cy="confirmPassword">

            <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
              [attr.aria-pressed]="hideConfirmPassword" data-cy="button-hide-password">
              <mat-icon class="grey">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>

            <mat-error *ngIf="form.get('confirmPassword').hasError('required')"
              data-cy="error-confirmPassword-required">
              <strong> A confirmação da senha é obrigatória </strong>
            </mat-error>

          </mat-form-field>

        </div>

        <mat-error *ngIf="passwordMatchError" data-cy="error-passowrd-match">
          <strong> As senhas não coincidem </strong>
        </mat-error>

        <mat-error *ngIf="form.get('newPassword').hasError('pattern') && form.get('newPassword').touched"
          data-cy="error-passowrd-pattern">
          <strong> A senha deve conter: </strong>
          <ul>
            <li> Mínimo de 8 caracteres </li>
            <li> Máximo de 50 caracteres </li>
            <li> Ao menos 1 letra </li>
            <li> Ao menos 1 número </li>
            <li> Ao menos 1 caracter especial <strong> (@#$%&, etc) </strong> </li>
          </ul>
        </mat-error>
      </form>

      <div class="content-buttoms">

        <app-button width="100%" color="primary" [disabled]="!form.valid || loading" [loading]="loading"
          (onClick)="change()" datacy="confirm-button">
          <span class="text-size"> Alterar senha </span>
        </app-button>

        <button mat-button (click)="goToLoginPage()" data-cy="back-button-form" class="white-bolder">
          <mat-icon>keyboard_backspace</mat-icon>
          <span class="text-size">Voltar para o login</span>
        </button>
      </div>
    </div>
  </section>

</app-forgot-password>

<ng-template #successMessage>

  <div class="content-success" data-cy="message-success-paragraph">
    <span class="uppercase estricta bolder content-header-title"> Feito! </span>
    <span class="content-header-subtitle"> A partir de agora, utilize sua nova senha para acessar a plataforma. </span>
    <button mat-button (click)="goToLoginPage()" data-cy="back-button-result" class="white-bolder">
      <mat-icon>keyboard_backspace</mat-icon>
      <span class="text-size">Voltar para o login</span>
    </button>
  </div>

</ng-template>