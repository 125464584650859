import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import { PageBanner } from '../models/banner/pageBanner';
import { Observable } from 'rxjs';
import { Consortium } from '../models/consortium/consortium';


@Injectable({
  providedIn: 'root'
})
export class ConsortiumService extends BaseService<Consortium> {

  constructor() {
    super()
    this.path = "gateway/consortium";
  }

  public getRedirectUrl(): Observable<Consortium> {
    return this.http.get<Consortium>(`${this.api}${this.path}/redirect`)
  }


}
