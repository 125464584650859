import { Component, ElementRef, Injector, Input, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

class CustomFieldErrorMatcher implements ErrorStateMatcher {
  constructor(private customControl: FormControl | AbstractControl,private errors:any) { }
  isErrorState(): boolean {   
    return this.customControl && this.customControl.touched &&(this.customControl.invalid || this.errors);
  }
}

@Component({
  selector: 'app-custom-datapicker',
  templateUrl: './custom-datapicker.component.html',
  styleUrls: ['./custom-datapicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatapickerComponent),
      multi: true,
    }
  ]
})
export class CustomDatapickerComponent implements ControlValueAccessor {

  @ViewChild("input") inputElement: ElementRef

  @Input("label") label;
  @Input("control") control: AbstractControl;
  @Input("required") required: boolean;
  @Input("readonly") readonly: boolean = false;
  @Input() datacy: string;
  @Input() errors:any=null;

  @Input() maxDate: Date; 
  @Input() minDate: Date; 

  private innerValue: string = "";

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if(v !== this.innerValue) {
      this.innerValue = v
      this.onChanged(v)
    }
  }

  get errorMatcher() {
    return new CustomFieldErrorMatcher(this.control,this.errors)
  }
  
  onChanged: (value: any) => void = () => {};
  onTouched: (value: any) => void = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }
  
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    
  }

  checkMask(event: KeyboardEvent): void {

    if(!(event.key == "Backspace" || event.key == "ArrowLeft" || event.key == "ArrowRight" || event.key == "Tab" || event.key == "Delete")){

      if(!/[0-9]/.test(event.key)) {
        event.preventDefault()
      } 

      if(this.inputElement.nativeElement.value.length == 2) {

        if(parseInt(this.inputElement.nativeElement.value.slice(0,2)) > 31) {
          this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.replace(this.inputElement.nativeElement.value.slice(0,2), "31")
        }

        if(!/\//.test(event.key)){
          this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.concat("/")
        }
      }
  
      if(this.inputElement.nativeElement.value.length == 5) {

        if(parseInt(this.inputElement.nativeElement.value.slice(3,5)) > 12) {

          if(this.inputElement.nativeElement.value.slice(0,2) == "13"){
            this.inputElement.nativeElement.value = this.ReplaceAt(this.inputElement.nativeElement.value, this.inputElement.nativeElement.value.slice(3,5), "12",3, 5)
          } else {
            this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.replace(this.inputElement.nativeElement.value.slice(3,5), "12")
          }

        }

        if(!/\//.test(event.key)){
          this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.concat("/")
        }        

      }   

    }

 
  }
   
  private ReplaceAt(input, search, replace, start, end) {
    return input.slice(0, start)
        + input.slice(start, end).replace(search, replace)
        + input.slice(end);
  }

  public markAsTouched(): void {
    this.control.markAllAsTouched()
  }

  public returnDataCy(validation: string): string {
    return "error-" + this.datacy + "-" + validation
  }

}