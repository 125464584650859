import { Component, OnInit } from "@angular/core";
import { BaseComponent, EProduct } from "src/app/@base/base.component";
import { ModalNewRequestComponent } from "src/app/components/modal/modal-new-request/modal-new-request.component";
import { SectionModeTypes } from "src/app/components/section/section.component";
import { OPTIONS_CREDIT } from "src/app/const/options";
import { EnumIncidentProduct } from "src/app/enum/incident";
import { Incident } from "src/app/models/incident/incident";
import { Option } from "src/app/models/option";
import { Table } from "src/app/models/table";
import { IncidentService } from "src/app/services/incident.service";
import { DatePipe } from "@angular/common";
import { firstValueFrom } from "rxjs";
import { EnumPath } from "src/app/enum/path";

@Component({
  selector: "app-credit",
  templateUrl: "./credit.component.html",
  styleUrls: ["./credit.component.scss"],
})
export class CreditComponent extends BaseComponent implements OnInit {
  public readonly product = EProduct.LiberaCred;

  public sectionMode: SectionModeTypes = "full";

  public creditOption: Option[] = OPTIONS_CREDIT;

  private incidents: Incident[] = [];
  public hasLoadedIncidents = false;

  constructor(
    private incidentsService: IncidentService,
    private datepipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.getIncidents();
  }

  private getIncidents(): void {
    this.hasLoadedIncidents = false;

    firstValueFrom(
      this.incidentsService.getIncidentsByProduct(EnumIncidentProduct.CREDIT)
    )
      .then((incidents) => (this.incidents = incidents))
      .finally(() => (this.hasLoadedIncidents = true));
  }

  public getTable(): Table<Incident> {
    return {
      headers: [
        {
          key: "incidentNumber",
          value: "N° da Solicitação",
        },
        {
          key: "openingDate",
          value: "Data de abertura",
        },
        {
          key: "product",
          value: "Segmento",
        },
        {
          key: "subject",
          value: "Tipo",
        },
        {
          key: "status",
          value: "Status",
        },
      ],
      data: this.incidents,
      style: {
        table: {
          height: "500px",
        },
        data: [
          {
            key: "status",
            styles: [
              {
                class: { color: "#fa8c16", fontWeight: 500 },
                rule: (x) => x === "Em andamento",
              },
              {
                class: { color: "#52c41a", fontWeight: 500 },
                rule: (x) => x === "Resolvido",
              },
            ],
          },
          {
            key: "openingDate",
            transform: (value) =>
              this.datepipe.transform(value, "dd/MM/yyyy HH:mm"),
          },
        ],
      },
      checkbox: false,
      loading: !this.hasLoadedIncidents,
    };
  }

  public openModalRequest(subject: Option): void {
    const request = this.dialog.open(ModalNewRequestComponent, {
      width: "90vw",
      height: "80vh",
      maxWidth: "800px",
      maxHeight: "440px",
      data: subject,
    });

    request.afterClosed().subscribe({
      next: (reload) => {
        if (reload) {
          this.openDialogSucess({
            title: "Registramos sua solicitação.",
            message: "Em breve ela será processada e será incluída no sistema.",
          });
          this.getIncidents();
        }
      },
    });
  }

  public getTooltip(option: Option): string {
    return "Clique aqui para fazer a solicitação de " + option.description;
  }

  public goToLiberaCredExternalLink() {
    this.openInNewTab(EnumPath.INSTITUTIONAL_LIBERACRED);
  }
}
