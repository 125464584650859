import { Component, Inject, OnInit } from "@angular/core";
import { BaseComponent, EProduct } from "src/app/@base/base.component";
import { LOCAL_STORAGE_KEY_BANNERS, LOCAL_STORAGE_KEY_POLICIES } from "src/app/const/localStorageKeys";
import { OPTIONS_INSURANCE } from "src/app/const/options";
import {
  PERMITION_BANK,
  PERMITION_CONSORTIUM,
  PERMITION_CREDIT,
  PERMITION_INSURANCE,
} from "src/app/const/permitions";
import { BannerImage, PageBanner } from "src/app/models/banner/pageBanner";
import { Policies } from "src/app/models/insurance/policies";
import { SelectOption } from "src/app/models/select";
import { Option } from "src/app/models/option";
import { InsuranceService } from "src/app/services/insurance.service";
import { firstValueFrom } from "rxjs";
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";
import { HttpErrorResponse } from "@angular/common/http";


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends BaseComponent implements OnInit {
  public readonly bankProduct: EProduct = EProduct.Bank;
  public readonly consortiumProduct: EProduct = EProduct.Consortium;
  public readonly insuranceProduct: EProduct = EProduct.Insurance;
  public readonly creditProduct: EProduct = EProduct.LiberaCred;

  public insurancePermition: string = PERMITION_INSURANCE;
  public consortiumPermition: string = PERMITION_CONSORTIUM;
  public creditPermition: string = PERMITION_CREDIT;
  public bankPermition: string = PERMITION_BANK;


  public showProducts: boolean = false;


  public policies: Policies;
  public policieChosed: number;
  public insuranceOptions: Option[] = OPTIONS_INSURANCE;
  public isLoadingSuhai: boolean = false;
  


  get showSUHAI() {
    return (
      this.insuranceOptions.length > 0 && this.policies?.policies?.length > 0
    );
  }
  public onContractChanged(evento: SelectOption<number>): void {
    this.policieChosed = evento.t;
  }

  constructor(private insuranceService: InsuranceService) {
    super();
    const user = this.getUser();
    this.showProducts = user && user.products && user.products.length > 0;
  }

  ngOnInit(): void {
    if(this.isClientOf(this.insuranceProduct)){
      this.getPolicies();
      if(!this.showSUHAI){
        this.requestAllPolicies();
      }
    }
    
  }

  private requestAllPolicies() {
    this.isLoadingSuhai = true;
    firstValueFrom(this.insuranceService.policies())
      .then((resp: Policies) => {
        this.setLocalStorageItem(LOCAL_STORAGE_KEY_POLICIES, JSON.stringify(resp))
        this.getPolicies();
      })
      .catch((error: HttpErrorResponse) => {
        this.openDialogError(
          error.error.message ? error.error.message : error
        );
      })
      .finally(() => (
        this.isLoadingSuhai = false
      ))
  }

  private getPolicies(): void {
    if (this.getLocalStorageItem(LOCAL_STORAGE_KEY_POLICIES) === null) {
      return;
    }

    if (
      typeof this.getLocalStorageItem(LOCAL_STORAGE_KEY_POLICIES) === "string"
    ) {
      this.openDialogMessage(
        this.getLocalStorageItem<string>(LOCAL_STORAGE_KEY_POLICIES)
      );
      return;
    }

    this.policies = this.getLocalStorageItem<Policies>(
      LOCAL_STORAGE_KEY_POLICIES
    );
    this.policieChosed = this.policies.policies[0];
  }
}
