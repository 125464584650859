<div *ngIf="!isConsent()" class="cookie-consent">

    <div class="cookie-consent-text">
        <div>
            <span> 1. Utilizamos cookies para o correto funcionamento deste site, bem como cookies para personalizar conteúdo e anúncios, fornecer recursos de mídia social e para obter informações estatísticas e analíticas do site. Se quiser saber mais sobre cookies e sobre a possibilidade de você bloqueá-los ou se quiser informações sobre como usamos dados, acesse nossa  Aviso de Privacidade. </span>
            <a class="link" (click)="goToLink('https://www.yamahaservicosfinanceiros.com.br/aviso-de-privacidade')"> Aviso de Privacidade. </a>
        </div>
    
        <div *ngIf="!readMore; else all">
            <span> 2. AVISO DE FRAUDE: Através de denúncias recebidas em nosso SAC, a YAMAHA tomou conhecimento da possibilidade de emissão de 2ª via de boleto, através de página falsa na internet, para pagamento de parcelas ou para regularização de débitos. </span>
            <span class="link" (click)="readMore = true" data-cy="read-more"> Clique Aqui. </span>
        </div>

        <ng-template #all>        
            
            <div class="read-more-content">
                <span>
                    2. AVISO DE FRAUDE: Através de denúncias recebidas em nosso SAC, a YAMAHA tomou conhecimento da possibilidade de emissão de 2ª via de boleto, através de página falsa na internet, para pagamento de parcelas ou para regularização de débitos.
                    A fim de dar maior credibilidade à fraude, os responsáveis falsificam documentos, e-mails, utilizam indevidamente a imagem de pessoas famosas e a logomarca YAMAHA.
                    Para evitar esse tipo de fraude, insira o endereço eletrônico da empresa YAMAHA a ser consultada diretamente na barra do seu navegador de internet e nunca acesse através de sites de pesquisa ou anúncios.
                    Para a consulta aos dados do seu contrato e emissão de boletos através da internet sempre será necessário que você insira seu login e senha. Desconfie se o site em que você estiver navegando não exigir esses dados.
                    Além disso, nunca forneça seus dados pessoais, dados do seu contrato, senhas ou códigos de segurança pelo telefone ou por aplicativos de mensagens, mesmo quando a fonte parecer confiável. A YAMAHA nunca solicita dados dessa forma.
                    Para mais informações, entre em contato com os canais oficiais da YAMAHA ou consulte o nosso SAC (Serviço de Atendimento ao Cliente) pelos telefones abaixo, exceto feriados, ou por e-mail:    
                </span>
    
                <div class="read-more-text">
                    <span> https://www.yamahaservicosfinanceiros.com.br/consorcio </span>
                    <span> SAC Consórcio (0800 774 3233): Segunda a sexta-feira, das 8h às 20h e aos sábados, das 8h às 14h – e-mail: sac.consorcio@yamaha-motor.com.br </span>    
                </div>
    
                <div class="read-more-text">
                    <span> https://www.yamahaservicosfinanceiros.com.br/banco </span>
                    <span> SAC Banco (0800 774 8283): Segunda a sexta-feira, das 8h às 20h e aos sábados, das 8h às 14h – e-mail: sac.banco@yamaha-motor.com.br </span>
                </div>
    
                <div class="read-more-text">
                    <span> https://www.yamahaservicosfinanceiros.com.br/liberacred </span>
                    <span> CRC Liberacred (11 2088-7700): Segunda à sexta das 8h às 18h – e-mail: liberacred@yamaha-motor.com.br </span>
                </div>
    
                <div class="read-more-text">
                    <span> https://www.yamahaservicosfinanceiros.com.br/seguros </span>
                    <span> CRC Corretora (11 2088-7700): Segunda à sexta das 8h às 18h – e-mail: sac.corretora@yamaha-motor.com.br </span>
                    <span> CAS - Central de Atendimento ao Surdo - 0800-774-1415 </span>
                </div>

                <div class="read-more-text">
                    <span>BANCO YAMAHA MOTOR DO BRASIL S.A.</span>
                    <span>YAMAHA ADMINISTRADORA DE CONSÓRCIO LTDA.</span>
                    <span>YAMAHA MOTOR DO BRASIL CORRETORA DE SEGUROS LTDA.</span>
                </div>

                <span class="link" (click)="readMore = false" data-cy="read-less"> Ler menos </span>
            </div>          
            
        </ng-template>
    </div>

    <div>
        <button (click)="consent()" data-cy="agreed-cookies"> Entendi </button>
    </div>
    

</div>