import { Injectable, inject } from "@angular/core";
import { BaseService } from "../@base/base.service";
import { CreateFeatureLogRequest } from "../models/Log/CreateFeatureLog";
import { Observable, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LogService {

  public http = inject(HttpClient)
  public api = environment.API;
  protected path: string;
 
  constructor() {
    this.path = "gateway/reports";
  }

  public createLog(incident: CreateFeatureLogRequest): Observable<any> {
    return this.http.post<CreateFeatureLogRequest>(
      `${this.api}${this.path}`,
      incident
    );
  }
}
