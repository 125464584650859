<app-forgot-password class="density-lower">

    <section class="backdrop-blur">
        <div *ngIf="!requestDoneWithSucess; else successMessage">
            <div class="content-header">
                <span class="uppercase estricta bolder content-header-title"> Esqueceu a senha? </span>
                <span class="content-header-subtitle"> Sem problemas. Preencha os campos abaixo e te enviaremos as instruções. </span>
            </div>

            <form [formGroup]="form" class="content-form-fields density-mat-form-3">
                <div class="form-field">
                    <label> CPF / CNPJ </label>
                    <mat-form-field appearance="outline" class="background-white">
                        <input type="text"
                               matInput
                               maxlength="18"
                               formControlName="document"
                               data-cy="document"
                               mask="000.000.000-00||00.000.000/0000-00">

                        <mat-error *ngIf="form.get('document').hasError('required')" data-cy="error-document-required">
                            <strong> O CPF/CNPJ é obrigatório </strong>
                        </mat-error>

                        <mat-error *ngIf="form.get('document').hasError('mask')" data-cy="error-document-mask">
                            <strong> {{labelDocument}} informado é inválido </strong>
                        </mat-error>

                        <mat-error *ngIf="form.get('document').hasError('cpfNotValid') || form.get('document').hasError('cnpjNotValid')" data-cy="error-document-cpfCnpjNotValid">
                            <strong> {{labelDocument}} informado é inválido </strong>
                        </mat-error>

                    </mat-form-field>
                </div>

                <app-custom-datapicker [label]="labelDate"
                                       formControlName="birthDate"
                                       [control]="form.get('birthDate')"
                                       datacy="birthDate"
                                       [maxDate]="today">
                </app-custom-datapicker>

            </form>

            <div class="content-buttoms">
                <app-button width="100%"
                            color="primary"
                            [disabled]="!form.valid || loading"
                            [loading]="loading"
                            (onClick)="recover()"
                            datacy="button-recover-password">
                    <span class="text-size"> Recuperar minha senha </span>
                </app-button>

                <button mat-button (click)="goToLoginPage()" data-cy="back-button-form" class="white-bolder">
                    <mat-icon>keyboard_backspace</mat-icon>
                    <span class="text-size">Voltar para o login</span>
                </button>
            </div>
        </div>

        <ng-template #successMessage>

            <div class="message" [ngSwitch]="requestDoneWithSucess">

                <div *ngSwitchCase="1" class="content-success" data-cy="message-success-paragraph-form-match">
                    <span class="uppercase estricta bolder content-header-title"> Tudo certo! </span>
                    <span class="content-header-subtitle"> Enviaremos as instruções para o e-mail <strong data-cy="message-success-paragraph-form-match">{{email}}</strong> </span>
                    <span class="content-header-subtitle"> Cheque sua caixa de entrada para continuar o processo de recuperação de senha. </span>
                </div>

                <div *ngSwitchCase="2" class="content-success" data-cy="message-success-paragraph-form-not-match">
                    <span class="uppercase estricta bolder content-header-title"> Tudo certo! </span>
                    <span class="content-header-subtitle"> Se os dados informados estiverem corretos, as informações para a troca de senha serão enviadas para o e-mail cadastrado. </span>
                </div>

            </div>

            <button mat-button color="primary" (click)="goToLoginPage()" data-cy="back-button-result" class="white-bolder">
                <mat-icon>keyboard_backspace</mat-icon>
                <span class="text-size">Voltar para o login</span>
            </button>

        </ng-template>


    </section>

</app-forgot-password>


