import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {
  @Input() form: FormGroup;

  @Input() createdAt: string | Date;
  @Input() createdBy: string;
  @Input() lastModifiedAt: string | Date;
  @Input() lastModifiedBy: string;
  @Input() lastLoginAt: string | Date;
  @Input() lastLoginType: string;
}
