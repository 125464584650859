import { Component, Inject } from "@angular/core";
import { ModalComponent } from "../modal.component";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Notification } from "src/app/models/notification/notification";

@Component({
  selector: "app-modal-notification",
  templateUrl: "./modal-notification.component.html",
  styleUrls: ["./modal-notification.component.scss"],
})
export class ModalNotificationComponent extends ModalComponent<ModalNotificationComponent> {
  public images: string[] = [
    "https://www.yamaha-motor.com.br/file/v1950793510205756737/products/acao-supersport-r15-abs-30089-fx01-img-01-v02-desktop.jpg",
  ];
  public txtBtnClose: string = "Fechar";

  public notification: Notification;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Notification,
  ) {
    super();
    this.notification = data;
  }
}
