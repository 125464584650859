import { EnumPath } from "../enum/path";
import {
  PERMITION_BANK,
  PERMITION_CONSORTIUM,
  PERMITION_CREDIT,
  PERMITION_INSURANCE,
} from "./permitions";

export const NO_CLIENT_PERMISSIONS: string[] = [
  PERMITION_INSURANCE,
  PERMITION_CREDIT,
  PERMITION_BANK,
  PERMITION_CONSORTIUM,
];

export const NO_CLIENT_PAGES: string[] = [
  EnumPath.INSURANCE,
  EnumPath.CREDIT,
  EnumPath.BANK,
  EnumPath.CONSORTIUM,
];
