import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { InterceptorSkipModalError } from '../interceptor/interceptor';
import { Base } from './base';
import { environment } from 'src/environments/environment';

declare type HttpMethod = 'GET' | 'POST' | "PUT" | "DELETE";

export class BaseService<T> extends Base{

  public http = inject(HttpClient)
  public api = environment.API;
  protected path: string;


  constructor() {
    super()
  }

  public get<T>(id: string | number, skipModalError: boolean = false): Observable<T>{
    return this.http.get<T>(this.api + this.path + "/" + id, {headers: this.addHeaders(skipModalError), withCredentials: true})
  }

  public post<T1, T2 = T1>(t: T1, id: string | number = "", skipModalError: boolean = false, withCredentials: boolean = true): Observable<T2>{
    return this.http.post<T2>(this.api + this.path + (id ? "/" : ''  + id) + id, t, {headers: this.addHeaders(skipModalError), withCredentials: withCredentials})
  }

  public put<T>(t: T, id: string | number, skipModalError: boolean = false): Observable<T>{
    return this.http.put<T>(this.api + this.path + `/${id}`, t, {headers: this.addHeaders(skipModalError), withCredentials: true})
  }

  public delete<T>(id: string | number, skipModalError: boolean = false): Observable<T>{
     return this.http.delete<T>(this.api + this.path + `/${id}`, {headers: this.addHeaders(skipModalError), withCredentials: true})
  }

  public getAll<T>(param: string = "", skipModalError: boolean = false): Observable<T[]>{
    return this.http.get<T[]>(this.api + this.path + param, {headers: this.addHeaders(skipModalError), withCredentials: true})
  }

  public fetchRequest(endpoint: string, httpMethod: HttpMethod, headers: any): void {
    fetch(endpoint, {
      headers: headers,
      method: httpMethod,
      body: null,
      credentials: 'include',
      keepalive: true,
    })
  }

  public addHeaders(skipModalError: boolean): HttpHeaders {
    if(skipModalError){
      return new HttpHeaders().set(InterceptorSkipModalError, "")
    }

    return new HttpHeaders()
  }

}
