import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import { Observable } from 'rxjs';
import { DebtHandlingRequest, Debtor, DebtsGroupedResponse, LateInstallmentsRequest, DebtorBankSlipRequest, DebtorBankSlipResponse, DebtServiceHistoryResponse, ListDebtorsRequest, RegisterServiceHistoryRequest } from '../models/dealer/dealer';
import { Payment } from '../models/bank/payment';
import { HttpHeaders } from '@angular/common/http';
import { PaginatorItems } from '../models/table';


@Injectable({
  providedIn: 'root'
})
export class DealersService extends BaseService<Debtor> {

  constructor() {
    super()
    this.path = "gateway/dealers";
  }

  public getDebtors(dados: ListDebtorsRequest): Observable<PaginatorItems<Debtor>> {

    var queryParam = "";

    dados.periodRanges.forEach(period => {
      queryParam += `periodsRange=${period}&`
    });

    queryParam += `&pageNumber=${dados.pageNumber}`;

    queryParam += `&pageSize=${dados.pageSize}`;

    queryParam += `&clientDocument=${dados.clientDocument}`;

    return this.http.get<PaginatorItems<Debtor>>(`${this.api}${this.path}/debts?${queryParam}`)
  }

  public getDebtorsGrouped(): Observable<DebtsGroupedResponse> {
    return this.http.get<DebtsGroupedResponse>(`${this.api}${this.path}/debts/grouped-by-range`)
  }

  public registerStartDebtHandling(dados: DebtHandlingRequest) : Observable<any> {
    return this.http.post(`${this.api}${this.path}/register-start-debt-handling`, dados)
  }

  public registerEndDebtHandling(dados: DebtHandlingRequest) : Observable<any> {
    return this.http.post(`${this.api}${this.path}/register-end-debt-handling`, dados)
  }

  public insertHistory(dados: RegisterServiceHistoryRequest) : Observable<any> {
    return this.http.post(`${this.api}${this.path}/debtor/${dados.clientDocument}/debts/${dados.contractNumber}/serviceStories`, dados)
  }

  public verifyDebtInServiceHandler(clientDocument: string, contractNumber: string) : Observable<any> {
    return this.http.get(`${this.api}${this.path}/debtor/${clientDocument}/debts/${contractNumber}/inService`)
  }

  public getLateInstallment(dados: LateInstallmentsRequest): Observable<Payment[]> {
    return this.http.get<Payment[]>(`${this.api}${this.path}/debtor/${dados.clientDocument}/debts/${dados.contractNumber}/lateInstalments?increaseDays=${dados.increaseDays}`)
  }

  public generateBankSlip(dados: DebtorBankSlipRequest): Observable<Payment[]> {
    return this.http.get<Payment[]>(`${this.api}${this.path}/debtor/${dados.clientDocument}/debts/${dados.contractNumber}/bank-slip`)
  }

  public GeneratePayment(dados: DebtorBankSlipRequest): Observable<DebtorBankSlipResponse> {
    
    const httpOptions = {
      headers: new HttpHeaders({
        Accept : 'application/octet-stream',
        'Content-Disposition': 'attachment',
        'Response-Type': "arraybuffuer"
      })
    };

    return this.http.post<DebtorBankSlipResponse>(`${this.api}${this.path}/debtor/${dados.clientDocument}/debts/${dados.contractNumber}/bank-slip`, dados, {
      ...httpOptions,
      withCredentials: true
    })    
  }

  public getDebtServiceHistories(clientDocument: string, contractNumber: string): Observable<DebtServiceHistoryResponse[]> {
    return this.http.get<DebtServiceHistoryResponse[]>(`${this.api}${this.path}/debtor/${clientDocument}/debts/${contractNumber}/serviceStories`)
  }
}
