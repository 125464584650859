<div [matMenuTriggerFor]="menu" data-cy="Expand more">
    <span class="estricta uppercase bolder"> {{user.name}} </span>
    <mat-icon> expand_more </mat-icon>
</div>

<mat-menu #menu="matMenu" xPosition="before">
    <input mat-menu-item cdkFocusInitial type='text' class="hidden"/>

    <button mat-menu-item [attr.data-cy]="txtBtnExit" (click)="exit()">
        <mat-icon class="exit">exit_to_app</mat-icon>
        <span class="estricta uppercase bolder">{{txtBtnExit}}</span>
    </button>
</mat-menu>
