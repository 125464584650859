<div class="info card-amount">
  <div class="title">
    <h2 data-cy="bankAmountTitle">PAGAMENTO</h2>
  </div>
  <div class="content">
    <div class="chart">
      <app-bank-chart *ngIf="showChart" [total]="sumValue()" [valorPago]="amountPaid()" [totalPaidInstallments]="totalPaidStallments"
      [totalInstallments]="totalStallments" >
      </app-bank-chart>
    </div>

    <div class="details">
      <ul>
        <li>
          <span class="label">VALOR TOTAL:</span>
          <span class="value" data-cy="total-value">{{ sumValue() | currency:'BRL':'symbol':'1.2-2':'pt-BR' }}</span>
        </li>
        <li>
          <span class="label"><span class="bullet bullet-blue"></span>Valor pago:</span>
          <span class="value" data-cy="paid-value">{{amountPaid() | currency:'BRL':'symbol':'1.2-2':'pt-BR'}}</span>
        </li>
        <li>
          <span class="label"><span class="bullet bullet-grey"></span>Valor restante:</span>
          <span class="value" data-cy="installments-value">{{debtBalance()}}</span>
        </li>
        <li>
          <span class="label">Desconto para quitação hoje:</span>
          <span class="value discount" data-cy="descont-value">{{discountIncreaseValue()}}</span>
        </li>
        <li>
          <span class="label">Quantidade de parcelas pendentes:</span>
          <span class="value" data-cy="installments-count">{{countInstallments()}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>