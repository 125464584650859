import { Component, OnInit } from "@angular/core";
import { BaseComponent, EProduct } from "src/app/@base/base.component";
import { Table } from "src/app/models/table";
import { SectionModeTypes } from "src/app/components/section/section.component";
import { ModalNewRequestComponent } from "src/app/components/modal/modal-new-request/modal-new-request.component";
import {
  OPTIONS_CREDIT,
  OPTIONS_REQUEST_INSURANCE,
} from "src/app/const/options";
import { Option } from "src/app/models/option";
import { Incident } from "src/app/models/incident/incident";
import { IncidentService } from "src/app/services/incident.service";
import { DatePipe } from "@angular/common";
import { firstValueFrom } from "rxjs";
import { EnumBankOptions } from "src/app/enum/options";
import { ModalBankChangeRegistrationDataComponent } from "src/app/pages/bank/bank-components/modal-bank-change-registration-data/modal-bank-change-registration-data.component";
import { ModalBankTravelAuthorizationComponent } from "src/app/pages/bank/bank-components/modal-bank-travel-authorization/modal-bank-travel-authorization.component";
import { ModalBankPaymentReversalComponent } from "src/app/pages/bank/bank-components/modal-bank-payment-reversal/modal-bank-payment-reversal.component";
import { ModalWarrantySubstitutionComponent } from "../bank/bank-modals/modal-substituicao-garantia/modal-warranty-substitution.component";
import { CurrentUser } from "src/app/models/user/user";
import { LOCAL_STORAGE_KEY_USER } from "src/app/const/localStorageKeys";

@Component({
  selector: "app-requests",
  templateUrl: "./requests.component.html",
  styleUrls: ["./requests.component.scss"],
})
export class RequestsComponent extends BaseComponent implements OnInit {
  public insuranceOptions: Option[] = OPTIONS_REQUEST_INSURANCE;
  public insuranceStringOptions: string[] = this.insuranceOptions.map(
    (x) => x.permission
  );
  public creditOption: Option[] = OPTIONS_CREDIT;
  public creditStringOptions: string[] = this.creditOption.map(
    (x) => x.permission
  );
  public sectionMode: SectionModeTypes = "full";
  public incidents: Incident[] = [];
  public hasLoadedIncidents = false;

  public readonly productBank = EProduct.Bank;
  public readonly productInsurance = EProduct.Insurance;
  public readonly productLiberaCred = EProduct.LiberaCred;

  constructor(
    private incidentService: IncidentService,
    private datepipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.getIncidents();
  }

  private getIncidents(): void {
    this.hasLoadedIncidents = false;

    firstValueFrom(this.incidentService.getLastIncidents())
      .then((incidents) => (this.incidents = incidents))
      .finally(() => (this.hasLoadedIncidents = true));
  }

  public getTable(): Table<any> {
    return {
      headers: [
        {
          key: "incidentNumber",
          value: "N° da Solicitação",
        },
        {
          key: "openingDate",
          value: "Data de abertura",
        },
        {
          key: "product",
          value: "Segmento",
        },
        {
          key: "subject",
          value: "Tipo",
        },
        {
          key: "status",
          value: "Status",
        },
      ],
      data: this.incidents,
      style: {
        table: {
          height: "500px",
        },
        data: [
          {
            key: "status",
            styles: [
              {
                class: { color: "#fa8c16", fontWeight: 500 },
                rule: (x) => x === "Em andamento",
              },
              {
                class: { color: "#52c41a", fontWeight: 500 },
                rule: (x) => x === "Resolvido",
              },
            ],
          },
          {
            key: "openingDate",
            transform: (value) =>
              this.datepipe.transform(value, "dd/MM/yyyy HH:mm"),
          },
        ],
      },
      checkbox: false,
      loading: !this.hasLoadedIncidents,
    };
  }

  public openModalRequest(subject: Option): void {
    const request = this.dialog.open(ModalNewRequestComponent, {
      width: "90vw",
      height: "80vh",
      maxWidth: "800px",
      maxHeight: "440px",
      data: subject,
    });

    request.afterClosed().subscribe({
      next: (reload) => {
        if (reload) {
          this.openDialogSucess({
            title: "Registramos sua solicitação.",
            message: "Em breve ela será processada e será incluída no sistema.",
          });
          this.getIncidents();
        }
      },
    });
  }

  private getBankSubjectModal(subject: Option): any {
    switch (subject.description) {
      case EnumBankOptions.CHANGE_REGISTRATION_DATA:
        return ModalBankChangeRegistrationDataComponent;

      case EnumBankOptions.TRAVEL_AUTHORIZATION:
        return ModalBankTravelAuthorizationComponent;

      case EnumBankOptions.PAYMENT_REVERSAL:
        return ModalBankPaymentReversalComponent;

      case EnumBankOptions.WARRANTY_REPLACEMENT:
        return ModalWarrantySubstitutionComponent;
    }

    throw new Error(
      `Could not Find a valid modal for Subject: ${subject.description}`
    );
  }

  public openBankSubjectModal(subject: Option): void {
    const modalComponent = this.getBankSubjectModal(subject);
    let user = this.getLocalStorageItem<CurrentUser>(LOCAL_STORAGE_KEY_USER)
    const request = this.dialog.open(modalComponent, {
      width: "90vw",
      height: "80vh",
      maxWidth: "800px",
      maxHeight: "440px",
      data: { subject: subject, documentNumber: user.documentNumber },
    });

    request.afterClosed().subscribe({
      next: (reload) => {
        if (reload) {
          this.openDialogSucess({
            title: "Atualizamos seus dados cadastrais",
            message: "",
          });
        }
      },
    });
  }

  public getTooltip(option: Option): string {
    return "Clique aqui para fazer a solicitação de " + option.description;
  }
}
