import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() select: Select<any>;

  @Input() opcoes: any[] = []
  @Input() label: string = "";

  @Output() emitOnChange: EventEmitter<any> = new EventEmitter();

  @ViewChild("selectButton") selectBtn: ElementRef
  @ViewChild("customSelect") customSelect: ElementRef

  private index: number = 0;


  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      if(this.selectBtn.nativeElement.getAttribute("aria-expanded") === "true") {
        this.onClick()
      }
    }
  }

  constructor(private eRef: ElementRef) {
 
  }

  ngOnInit(): void {
    this.select.options = this.select.options.map((x, i) => {
      return {
        ...x,
        index: i
      }
    })
  }


  public onClick(): void{
    this.customSelect.nativeElement.classList.toggle("active");
    this.selectBtn.nativeElement.setAttribute(
      "aria-expanded",
      this.selectBtn.nativeElement.getAttribute("aria-expanded") === "true" ? "false" : "true"
    );
  }

  public onSelect(index: number): void{

    if(this.index != index){
      const selectTitle = document.querySelector(".selected-title");      
      selectTitle.innerHTML = this.select.options[index].title
  
      this.index = index
      this.emitOnChange.emit(this.select.options[index]);
    }

  }

  public filteredOptions(): any[] {
    return this.select.options.filter(x => x.index !== this.index);
  }

}
