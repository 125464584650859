<app-product [type]="type"
             [options]="options"
             [subOptions]="subOptions"
             [subOptionsTitle]="subOptionsTitle"
             [showIfPermission]="insuranceOptionsPermition"
             [showSubOptions]="showSUHAI"
             [policieChosed]="policieChosed"

>
</app-product>

