import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FaqComponent } from './faq.component';
import { FaqPanelComponent } from './faq-panel/faq-panel.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    FaqComponent,
    FaqPanelComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ]
})
export class FaqModule { }
