import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { Base } from "../@base/base";
import { EnumPath } from "../enum/path";
import { NO_CLIENT_PAGES } from "../const/noClient";

@Injectable()
export class AuthenticationGuard extends Base {
  constructor(private router: Router) {
    super();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.getPermissions() || this.getPermissions()?.length == 0) {
      this.router.navigate([EnumPath.LOGIN]);
    }

    return true;
  }
}
