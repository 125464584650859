import { RouterModule, Routes } from "@angular/router";
import { DealerComponent } from "./dealer.component";
import { NgModule } from "@angular/core";
import { DebtSettlementComponent } from "./debt-settlement/debt-settlement.component";
import { canDeactivateGuard } from "src/app/guards/can-deactivate.guard";

const routes: Routes = [
    {
      path: "",
      component: DealerComponent,
    },
    {
      path: "debt-settlement",
      component: DebtSettlementComponent,
      canDeactivate: [canDeactivateGuard]
    }
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class DealerRoutingModule { }
  