<app-modal class="density-lower" title="Nova Solicitação" data-cy="modal-bank-change-registration-data"
  [disableButtonClose]="isSubmitting">
  <div class="modal-content density-mat-form-3">
    <span class="reason estricta uppercase" data-cy="modal-bank-change-registration-data">{{ subject.product + " - " +
      subject.description }}</span>

    <section class="text">
      Informe o destino e o período de sua viagem. Nossa equipe entrará em
      contato para realizar sua autorização.
    </section>

    <form [formGroup]="form" (keydown.enter)="$event.preventDefault(); onSubmit($event)">
      <section class="flex">

        <div>
          <mat-label class="inline-flex"><span class="red">*</span> País de Destino: </mat-label>
          <mat-form-field appearance="outline" class="background-white">
            <mat-select formControlName="destination" placeholder="Escolha o destino"
              (selectionChange)="createOrDeleteInputDestinationManually($event)" data-cy="DestinationCountryInput">
              <mat-option *ngFor="let destinationCountry of destinationCountries" [value]="destinationCountry">{{
                destinationCountry }}</mat-option>
            </mat-select>
            <mat-error *ngIf="
                form.get('destination').hasError('required') &&
                form.get('destination').touched
              " data-cy="error-destination-required">
              <strong> Campo obrigatório </strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="showIfOutroActive">
          <mat-label class="inline-flex"><span class="red">*</span> Qual? </mat-label>
          <mat-form-field appearance="outline" class="background-white chip-grid-form-field">
            <input type="text" formControlName="destination-manually" maxlength="100" data-cy="city-other"
              placeholder="Digite Aqui" matInput>
            <mat-error *ngIf="
              form.get('destination-manually').hasError('required') &&
              form.get('destination-manually').touched
            " data-cy="error-destination-required">
              <strong> Campo obrigatório </strong>
            </mat-error>
          </mat-form-field>
        </div>

      </section>
      <section class="flex">

        <div>
          <mat-label class="inline-flex"> Cidade: </mat-label>
          <mat-form-field appearance="outline" class="background-white chip-grid-form-field">
            <input type="text" formControlName="city" maxlength="100" data-cy="city" placeholder="Digite Aqui" matInput>
          </mat-form-field>
          <p class="city-help-description">
            Adicione as cidades que deseja visitar, separando-as por vírgulas.
          </p>
        </div>

        <div>
          <mat-label><span class="red">*</span> Período: </mat-label>
          <span class="form-field">
            <app-custom-datapicker-range class="background-white-datetime-picker-range" [formGroup]="travelDuration"
              datacy="travel-duration-datetime-input" [min]="todayAsDateOnly">
            </app-custom-datapicker-range>
          </span>
        </div>


      </section>
    </form>
  </div>

  <div class="modal-bottom">
    <app-button width="100px" (onClick)="onClickBtnClose()" [disabled]="isSubmitting" [datacy]="btnCancelarTxt">
      <span class="button-label"> {{btnCancelarTxt}} </span>
    </app-button>
    <app-button type="submit" color="primary" [loading]="isSubmitting"
      [disabled]="!form.valid || !travelDuration.valid || isSubmitting" (onClick)="onSubmit()" [datacy]="btnEnviarTxt">
      <span class="button-label"> {{btnEnviarTxt}} </span>
    </app-button>
  </div>
</app-modal>