import { Directive, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Route } from '../models/route';
import { ROUTES } from '../const/routes';
import { Base } from '../@base/base';
import { LOCAL_STORAGE_KEY_PERMISSIONS } from '../const/localStorageKeys';

@Directive({
  selector: '[showIfPermission]'
})
export class ShowIfPermissionDirective extends Base implements OnInit {

  @HostBinding("style.display") display: string;

  @Input() set showIfPermission(role: string | string[]) {
    this.role = role;
  }

  private permissions: string[]

  private role: string | string[];

  public routes: Route[] = ROUTES

  constructor(private elementRef: ElementRef){
    super()  
  }

  ngOnInit(): void {

    this.permissions = this.getPermissions()

    if(Array.isArray(this.role)){
      if(!this.role.some(r=> this.permissions.includes(r))){
        this.elementRef.nativeElement.remove()
      }
    } 

    if(!Array.isArray(this.role)){
      if(!this.permissions.includes(this.role)){
        this.elementRef.nativeElement.remove()
      }
    } 



  }

}
