import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalComponent } from '../modal.component';
import { Option } from 'src/app/models/option';
import { IncidentService } from 'src/app/services/incident.service';

@Component({
  selector: 'app-modal-new-request',
  templateUrl: './modal-new-request.component.html',
  styleUrls: ['./modal-new-request.component.scss']
})
export class ModalNewRequestComponent extends ModalComponent<ModalNewRequestComponent>{

  public submited = false;
  public subject: Option

  public txtBtnCancel: string = "Cancelar";
  public txtBtnSendRequest: string = "Enviar";

  incidentDescrition: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Option,
    private incidentsService: IncidentService,
    private snackBar: MatSnackBar
  ) {
    super()

    this.subject = data
  }

  override onSubmit(description: string): void {
    
    if(!description) {
      return;
    }
    
    this.submited = true;

    this.incidentsService.create({
      subject: this.subject.id ?? 0,
      description: description,
      product: this.subject.incidentProduct ?? 0
    }).subscribe({
      next: response => {
        this.logFeature(this.subject.description, this.subject.product);
        this.onClose(true)
      },
      error: error => {
        this.snackBar.open("Não conseguimos atender sua solicitação. Tente novamente mais tarde", "", {
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['error']
        });
        this.submited = false;
      }
    })
  }

  getErrorMessage() {
    if(!this.incidentDescrition) {
      return 'Descrição é obrigatória';
    }

    if(this.incidentDescrition.length > 1000) {
      return 'Descrição deve ter no máximo 1000 caracteres';
    }

    return '';
  }
}
