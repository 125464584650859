import { Directive } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';

export function multipleEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const emails = control.value.split(';').map(email => email.trim());
    const allValid = emails.every(email => Validators.email({ value: email } as any) === null);
    return allValid ? null : { 'multipleEmail': { value: control.value } };
  };
}

@Directive({
  selector: '[appMultipleEmailValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MultipleEmailValidatorDirective, multi: true }]
})
export class MultipleEmailValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null; // Permitir valores vazios para que outras validações de required possam funcionar
    }
    const emails = control.value.split(';').map(email => email.trim());
    const invalidEmails = emails.filter(email => email.trim().length > 0 && Validators.email(new FormControl(email.trim())));
    return invalidEmails.length > 0 ? { multipleEmail: true } : null;
  }
}