import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { ComponentsModule } from '../components/components.module';

import { AuthComponent } from './auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewAccountComponent } from './new-account/new-account.component';
import { LoginComponent } from './login/login.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatNativeDateModule } from '@angular/material/core';
import { ForgotPasswordRecoverComponent } from './forgot-password/forgot-password-recover/forgot-password-recover.component';
import { ForgotPasswordNewPasswordComponent } from './forgot-password/forgot-password-new-password/forgot-password-new-password.component';
import { GuidGuard } from '../guards/guid.guard';
import { ResetExpiredPasswordComponent } from './reset-expired-password/reset-expired-password.component';
import { CustomInputsModule } from '../form/custom-inputs/custom-inputs.module';
import { ExpiredPasswordMessageComponent } from './reset-expired-password/expired-password-message/expired-password-message.component';
import { GoogleSigninButtonDirective, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';


@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ExternalLoginComponent,
    NewAccountComponent,
    ForgotPasswordComponent,
    ForgotPasswordRecoverComponent,
    ForgotPasswordNewPasswordComponent,
    ResetExpiredPasswordComponent,
    ExpiredPasswordMessageComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    CustomInputsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ComponentsModule,
    RecaptchaModule,
    NgxMaskDirective,
    NgxMaskPipe,
    GoogleSigninButtonModule
  ],
  providers: [
    provideNgxMask(),
    MatDatepickerModule,
    GuidGuard,
    GoogleSigninButtonDirective
  ]

})
export class AuthModule { }
