<div>
  <span class="title estricta bolder" *ngIf="showTitle"> Banco </span>
  <div class="flex" *ngIf="bankOptions.length > 0; else noContracts">
    <app-button *ngFor="let option of bankOptions" color="primary" width="100%" minWidth="190px"
      [showIfPermission]="option.permission" [datacy]="'Seguros-' + option.description" [tooltip]="getTooltip(option)"
      (click)="openBankSubjectModal(option)">
      <span class="button-content estricta bolder uppercase">
        {{ option.description }}
      </span>
    </app-button>
  </div>
</div>

<ng-template #noContracts>
  <div class="flex" *ngIf="showNoContractMessage"> Não foi encontrado um contrato para banco</div>
</ng-template>