import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { ModalNewRequestComponent } from 'src/app/components/modal/modal-new-request/modal-new-request.component';
import { LOCAL_STORAGE_KEY_CONSORTIUM_REQUEST } from 'src/app/const/localStorageKeys';
import { EnumInsuranceOptions } from 'src/app/enum/options';
import { EnumProduct } from 'src/app/enum/products';
import { EnumReportIncident } from 'src/app/enum/report-incident';
import { Consortium } from 'src/app/models/consortium/consortium';
import { Option } from 'src/app/models/option';
import { ConsortiumService } from 'src/app/services/consortium.service';
import { InsuranceService } from 'src/app/services/insurance.service';
import { ModalSituationSinisterComponent } from '../../insurance/modal-situation-sinister/modal-situation-sinister.component';
import { Sinister } from 'src/app/models/insurance/policies';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends BaseComponent {

  @Input() type: EnumProduct;
  @Input() options: Option[];
  @Input() subOptionsTitle: string;
  @Input() subOptions: Option[];
  @Input() showSubOptions: boolean = false;
  @Input() policieChosed: number;

  public buttonDownloadInsuranceBillLoading: boolean = false;
  public buttonDownloadInsurancePolicyLoading: boolean = false;
  public buttonGetSinisterLoading: boolean = false;

  constructor(
    private readonly consortiumService: ConsortiumService,
    private readonly insuranceService: InsuranceService
  ) {
    super();
  }

  public image(): string {
    return "assets/products-logo/" + this.type + ".png";
  }

  public onClick(option: Option): void {
    switch(this.type){
      case EnumProduct.INSURANCE:  this.onClickInsuranceOptionButton(option)
                                   break;
      case EnumProduct.CREDIT:     this.openModalRequest(option)
                                   break;
      case EnumProduct.BANK:       this.navigate(option.path);
                                   break;
      case EnumProduct.CONSORTIUM: this.redirectConsortium();
                                    break;
    }
  }

  private redirectConsortium(): void {
    this.setLocalStorageItem(LOCAL_STORAGE_KEY_CONSORTIUM_REQUEST, true);

    const modalLoading = this.openDialogLoading()

    firstValueFrom(this.consortiumService.getRedirectUrl())
    .then((data: Consortium) => {
      if(data){
        this.openInNewTab(data.redirectUrl);
      }
    })
    .catch((error) => {
      if(error.status !== 500){
        this.openDialogError(error, "Atenção");
      }
    })
    .finally(() => {
      this.logFeature(EnumReportIncident.CONSORTIUM_PORTAL, EnumProduct.CONSORTIUM);
      modalLoading.close()
      this.setLocalStorageItem(LOCAL_STORAGE_KEY_CONSORTIUM_REQUEST, false)
    });
  }

  public openModalRequest(subject: Option): void{
    
    const request = this.dialog.open(ModalNewRequestComponent, {
      width: "90vw",
      height: "80vh",
      maxWidth: "800px",
      maxHeight: "440px",
      data: subject
    });

    request.afterClosed().subscribe({
      next: (reload) => {
        if(reload) {
          this.openDialogSucess({
            title: "Registramos sua solicitação.",
            message: "Em breve ela será processada e será incluída no sistema."
          })
        }
      }
    });
  }

  public onClickInsuranceOptionButton(option: Option): void {
    switch (option.description) {
      case EnumInsuranceOptions.SINISTER:
        this.openModalSituationSinister(option);
        return;
      case EnumInsuranceOptions.EMIT_BILL:
        this.downloadBill(option);
        return;
      case EnumInsuranceOptions.EMIT_POLICY:
        this.downloadPolicy(option)
        return;
      default:
        this.openModalRequest(option);
        return;

    }
  }

  private downloadBill(option: Option): void {
    option.loading = true;

    firstValueFrom(
      this.insuranceService.downloadInsuranceBill(this.policieChosed + "")
    )
      .then((resp: Blob) => this.download(resp, "BoletoSuhai.pdf", "pdf"))
      .catch((error: HttpErrorResponse) =>
        this.openDialogError(
          error
        )
      )
      .finally(() => {
        option.loading = false;
        this.logFeature(
          EnumReportIncident.INSURANCE_EMIT_BILL,
          EnumProduct.INSURANCE
        );
      });
  }

  private downloadPolicy(option: Option): void {
    option.loading = true;

    firstValueFrom(
      this.insuranceService.downloadPolicy(this.policieChosed + "")
    )
      .then((resp: Blob) => this.download(resp, "ApoliceSuhai.pdf", "pdf"))
      .catch((error: HttpErrorResponse) =>
        this.openDialogError(
          error
        )
      )
      .finally(() => {
        option.loading = false;
        this.logFeature(
          EnumReportIncident.INSURANCE_EMIT_POLICY,
          EnumProduct.INSURANCE
        );
      });
  }

  private async openModalSituationSinister(option: Option): Promise<void> {
    option.loading = true;

    await firstValueFrom(
      this.insuranceService.getSinisters(this.policieChosed + "")
    )
      .then((resp: Sinister[]) => {
        this.dialog.open(ModalSituationSinisterComponent, { data: resp,   width: '800px' });
        this.logFeature(
          EnumReportIncident.INSURANCE_SINISTER,
          EnumProduct.INSURANCE
        );
      })
      .catch((error: HttpErrorResponse) =>
        this.openDialogError(
          error.error["message"] ? error.error["message"] : error.message
        )
      )
      .finally(() => (option.loading = false));
  }

  private navigate(path: string): void {
    this.router.navigate([path])
  }

}
