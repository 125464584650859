import { Component } from "@angular/core";

@Component({
  selector: "app-pages",
  template: `<app-layout>
    <router-outlet (activate)="onActivate()"></router-outlet>
  </app-layout>`,
})
export class PagesComponent {
  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
}
