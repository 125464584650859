import { Component, EventEmitter, Input, Output } from "@angular/core";

export declare type ButtonColors = "primary" | "accent" | "primary-reverse";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() color: ButtonColors;
  @Input() width: string;
  @Input() minWidth: string;
  @Input() datacy: string;
  @Input() disabled: boolean;
  @Input() type: string = "button";
  @Input() tooltip: string = "";
  @Input() loading: boolean = false;
  @Input() icon: string;


  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  public click(): void {
    this.onClick.emit();
  }
}
