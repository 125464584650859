import { Component, Inject } from '@angular/core';
import { ModalComponent } from '../modal.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent extends ModalComponent<ModalMessageComponent>{

  public txtBtnClose: string = "Fechar";
  public message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    super()

    this.message = data
  }


}
