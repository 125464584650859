import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnumProduct } from 'src/app/enum/products';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'app-product-options',
  templateUrl: './product-options.component.html',
  styleUrls: ['./product-options.component.scss']
})
export class ProductOptionsComponent {

  @Output() emitOption: EventEmitter<Option> = new EventEmitter<Option>();

  @Input() options: Option[] = [];
  @Input() type: EnumProduct;


  @Input() subOptionsTitle: string = "";
  @Input() subOptions: Option[] = [];
  @Input() showSubOptions: boolean = false;

  public onClick(option: Option): void {
    this.emitOption.emit(option)
  }

  public getTooltip(option: Option): string {
    if(this.type == EnumProduct.CREDIT || this.type == EnumProduct.INSURANCE){
      return "Clique aqui para fazer a solicitação de " + option.description
    }

    return "Clique aqui para acessar a "+ option.description;
  }

}
