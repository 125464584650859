<ng-container *ngIf="!table.loading; else containerLoading">
  <ng-content select=".table-header" class="header"></ng-content>

  <div class="container" [style.maxHeight]="height" [style.maxWidth]="maxWidth">
    <mat-accordion *ngIf="isSmallScreen">
      <mat-expansion-panel class="expansion-class" *ngFor="let row of dataSource.data" [expanded]="false">
        <mat-expansion-panel-header class="custom-expansion-panel">
          <mat-panel-description class="checkbox-expansion" *ngIf="table.checkbox">
            <ng-container>
              <mat-checkbox *ngIf="!shouldDisableCheckbox(row)" #checkboxes (change)="onRowChange(row, $event.checked)"
                color="primary" class="checkbox" [attr.data-cy]="getDatacy('installmentSelect', row)"></mat-checkbox>
            </ng-container>
          </mat-panel-description>

          <mat-panel-description>

            <strong style="text-wrap: nowrap;" [attr.data-cy]="getDatacy('bankInstallment', row)">
              {{ row.instalmentNumber || row.number }}
            </strong>
          </mat-panel-description>
          <mat-panel-description [attr.data-cy]="getDatacy('installmentDueDate', row)">
            Vencimento <br> {{ row.dueDate | date: "dd/MM/yyyy" }}
          </mat-panel-description>
          <mat-panel-description [attr.data-cy]="getDatacy('installmentPaidValue', row)">
            Valor Pago <br> {{ row.paidValue ? (row.paidValue | currency: 'BRL') : '-' }}
          </mat-panel-description>

          <ng-container *ngIf="table.actions">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onActionChange(row)">
                Editar
              </button>
              <button mat-menu-item (click)="onActionDelete(row)">
                Excluir
              </button>
            </mat-menu>
          </ng-container>
        </mat-expansion-panel-header>
        <div *ngIf="isSmallScreen" class="expansion-border"
          style="display: flex; justify-content: space-between; align-items: center;">
          <span style="flex: 1; text-align: center;">
            <span [attr.data-cy]="getDatacy('installmentOriginalValue', row)">Original <br>
              <b>{{ row.originalValue | currency: 'BRL' }}</b></span>
          </span>
          <span style="flex: 1; text-align: center;">
            <span [attr.data-cy]="getDatacy('installmentPaymentDate', row)">Pagamento</span>
            <br> {{ row.paymentDate ? (row.paymentDate | date: "dd/MM/yyyy") : '-' }}
          </span>
          <span style="flex: 1; text-align: center;">
            <span [attr.data-cy]="getDatacy('installmentStatus', row)">
              Status <br>
              <b [ngClass]="getStatus(row.status)">
                {{ row.status ? row.status : 'Liquidado' }}
              </b>

            </span>
          </span>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div *ngIf="!isSmallScreen" class="card-container">

    <div class="card" *ngFor="let row of dataSource.data">
      <app-card>
        <div class="flex">


          <div class="card-content">
            <span class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('bankInstallmentLabel', row)">

                Parcela
              </span>
              <span class="card-content-value" [attr.data-cy]="getDatacy('bankInstallment', row)">
                <span>
                  {{ row.instalmentNumber || row.number }}
                </span>
              </span>
            </span>
            <span class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('installmentDueDateLabel', row)">
                Vencimento
              </span>
              <span class="card-content-value" [attr.data-cy]="getDatacy('installmentDueDate', row)">
                {{ row.dueDate | date: "dd/MM/yyyy" }}
              </span>
            </span>
            <span class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('installmentOriginalValueLabel', row)">
                Valor original
              </span>
              <span class="card-content-value" [attr.data-cy]="getDatacy('installmentOriginalValue', row)">
                {{ row.originalValue | currency: 'BRL' }}
              </span>
            </span>
            <span class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('installmentPaidValueLabel', row)">
                Valor Pago
              </span>
              <span class="card-content-value" [attr.data-cy]="getDatacy('installmentPaidValue', row)">
                {{ row.paidValue || row.total | currency: 'BRL' }}
              </span>
            </span>
            <span class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('installmentPaymentDateLabel', row)">
                Pagamento
              </span>
              <span class="card-content-value" [attr.data-cy]="getDatacy('installmentPaymentDate', row)">
                {{ row.paymentDate ? (row.paymentDate | date: "dd/MM/yyyy") : '-' }}
              </span>
            </span>

            <span class="card-content-item">
              <span class="card-content-key" [attr.data-cy]="getDatacy('installmentStatusLabel', row)">
                Status
              </span>
              <span class="card-content-value" [ngClass]="getStatus(row.status)"
                [attr.data-cy]="getDatacy('installmentStatus', row)">
                {{ row.status ? row.status : 'Liquidado' }}
              </span>
            </span>

            <!--
            <span class="card-content-item">
              <span class="card-content-key">
                Desconto / Acréscimo
              </span>
              <span [ngClass]="getPaymentColor((row.originalValue - (row.paymentValue || row.total)))">
                {{ calculateAndFormat(row) }}
              </span>
            </span> -->

          </div>
        </div>

      </app-card>
    </div>

  </div>
</ng-container>

<ng-template #containerLoading>
  <div class="loading">
    <mat-spinner *ngIf="table.loading"></mat-spinner>
  </div>
</ng-template>