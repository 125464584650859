<mat-toolbar #navigation color="primary" [ngClass]="navbarClass">
  <div class="flex">
    <mat-icon (click)="onClickMenu()" class="menu-lateral">menu</mat-icon>
    <img data-cy="Home" routerLink="/home" class="logo-one"
      src="assets/yamaha-servicos-financeiros/yamaha-servicos-financeiros-1.png"
      alt="YAMAHA SERVIÇOS FINANCEIROS (1) (2).png">
    <img data-cy="Home" routerLink="/home" class="logo-two"
      src="assets/yamaha-servicos-financeiros/yamaha-servicos-financeiros-3.png"
      alt="YAMAHA SERVIÇOS FINANCEIROS (1) (2).png">
  </div>

  <div>
    <app-menu class="app-menu" eixo="horizontal"></app-menu>
  </div>

  <div>
    <app-notifications [showIfPermission]="notificationPermition" class="notifications"></app-notifications>
    <app-avatar class="avatar"></app-avatar>
  </div>

  <!-- <ng-template #noClient>
    <div>
      <button datacy="login-button-label" (onClick)="goToLoginPage()" class="button-label estricta uppercase bolder">
        Login
      </button>
    </div>
  </ng-template> -->
</mat-toolbar>