<app-modal class="density-lower"
           title="Status do Gravame"
           data-cy="modal-lien-status"
           [buttons]="false"
>
  
    <div class="modal-content">
        <div class="status-lien"><span>Status: </span><span>{{ lienStatus }}</span></div>
    </div>

</app-modal>
