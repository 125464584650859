import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatToolbar } from "@angular/material/toolbar";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription, filter } from "rxjs";
import { BaseComponent } from "src/app/@base/base.component";
import { LOCAL_STORAGE_KEY_BANNERS } from "src/app/const/localStorageKeys";
import { PERMITION_NOTIFICATIONS } from "src/app/const/permitions";
import { EnumPath } from "src/app/enum/path";
import { BannerImage, PageBanner } from "src/app/models/banner/pageBanner";
import { BannerService } from "src/app/services/banner.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent extends BaseComponent {
  @ViewChild("navigation") el: MatToolbar;

  @Output() emitChangeMenu: EventEmitter<any> = new EventEmitter();

  public notificationPermition: string = PERMITION_NOTIFICATIONS;
  public banner: PageBanner;

  get navbarClass() {
    if (this.banner?.images?.length > 0 && window.screen.width > 900) {
      return "navigation transparent";
    }

    return "navigation";
  }

  public $subscriptionRoute: Subscription;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    if (window.screen.width > 900) {
      if (window.scrollY !== 0) {
        this.renderer.addClass(
          this.el._elementRef.nativeElement,
          "background-primary"
        );
      }

      if (window.scrollY == 0) {
        this.renderer.removeClass(
          this.el._elementRef.nativeElement,
          "background-primary"
        );
      }
    }
  }

  constructor(
    private renderer: Renderer2,
    private bannerService: BannerService
  ) {
    super();

    this.subscriptionChangeBanner();
  }

  public onClickMenu(): void {
    this.emitChangeMenu.emit();
  }

  private changeBanner(): void {
    this.banner = this.getLocalStorageItem<PageBanner[]>(
      LOCAL_STORAGE_KEY_BANNERS
    )?.find((x) => this.router.url.includes(x.page));

    this.bannerService.bannersSubscribe$.subscribe(() => {
      this.banner = this.getLocalStorageItem<PageBanner[]>(
        LOCAL_STORAGE_KEY_BANNERS
      )?.find((x) => this.router.url.includes(x.page));
    });
  }

  private subscriptionChangeBanner(): void {
    this.changeBanner();

    this.$subscriptionRoute = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.changeBanner();
      });
  }

  public goToLoginPage() {
    this.router.navigate([EnumPath.LOGIN]);
  }
}
