<mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title [attr.data-cy]="'title-' + index">
          <span> {{faqItem.title}} </span> <span> ? </span>
        </mat-panel-title>

      </mat-expansion-panel-header>

      <article class="faq-panel-content">
      
        <div #steps class="faq-panel-content-steps" [attr.data-cy]="'steps-' + index">
          <span [innerHtml]="faqItem.steps.title"></span>
          <div>
            <span *ngFor="let step of faqItem.steps.items; let i = index" [innerHtml]="step"></span>
          </div>
        </div>

        <div *ngIf="faqItem.video" [attr.data-cy]="'video-' + index" class="video">
          <video controls (click)="toggleVideo()" #videoPlayer>
            <source [src]="faqItem.video" type="video/mp4" />
            Browser not supported
          </video>
        </div>

        <div *ngIf="faqItem.observation" class="faq-panel-content-observation" [attr.data-cy]="'observation-' + index">
          <strong> Observações:  </strong>
          <ul>
            <li *ngFor="let observation of faqItem.observation" [innerHtml]="observation"></li>
          </ul>
        </div>

        <div *ngIf="faqItem.about" class="faq-panel-content-about" [attr.data-cy]="'about-' + index">
          <strong>Sobre a funcionalidade: </strong>
          <span [innerHtml]="faqItem.about"></span>
        </div>

        <div *ngIf="faqItem.rules" [attr.data-cy]="'rules-' + index">
          <strong> Regras da operação:  </strong>
          <ul>
            <li *ngFor="let rule of faqItem.rules" [innerHtml]="rule"></li>
          </ul>
        </div>

       </article>

    </mat-expansion-panel>
  </mat-accordion>