<app-bank-header>

  <div *ngIf="!loading; else containerLoading" class="bank-content">
    <span class="section-title title estricta" data-cy="bankStatementTitle"> EXTRATO FINANCEIRO </span>
    <div class="total-values flex" style="flex-wrap: wrap;">
      <span class="total-original-value" data-cy="totalOriginalValue">
        <b>VALOR ORIG. :</b> {{ totalOriginalValue | currency: 'BRL' }}
      </span> <br />
      <span class="total-paid-value" data-cy="totalPaidValue">
        <b> VALOR PAGO: </b>{{ totalPaidValue | currency: 'BRL' }}
      </span>
    </div>

    <div class="section-content">
      <app-table-mobile-financial [table]="table"></app-table-mobile-financial>

      <app-card>
        <div>
          <app-button width="100%" color="primary" [disabled]="table.data.length == 0 || loadingDownload"
            [datacy]="txtBtnDownload" [loading]="loadingDownload" (click)="downloadStatement()">
            <span class="estricta text-button uppercase"> {{txtBtnDownload}} </span>
          </app-button>
        </div>
      </app-card>
    </div>
  </div>

</app-bank-header>

<ng-template #containerLoading>
  <div class="loading">
    <mat-spinner *ngIf="loading"></mat-spinner>
  </div>
</ng-template>