import { inject } from "@angular/core";
import { AppService } from "../services/app.service";
import { CurrentUser } from "../models/user/user";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import {
  ModalSuccessComponent,
  ModalSucess,
} from "../components/modal/modal-success/modal-success.component";
import { CookieService } from "ngx-cookie-service";
import { ModalErrorComponent } from "../components/modal/modal-error/modal-error.component";
import { HttpErrorResponse } from "@angular/common/http";
import {
  LOCAL_STORAGE_KEY_PERMISSIONS,
  LOCAL_STORAGE_KEY_USER,
} from "../const/localStorageKeys";
import { ModalConfirmComponent } from "../components/modal/modal-confirm/modal-confirm.component";
import { ModalLoadingComponent } from "../components/modal/modal-loading/modal-loading.component";
import { ModalNotificationComponent } from "../components/modal/modal-notification/modal-notification.component";
import { Notification } from "src/app/models/notification/notification";
import { ModalMessageComponent } from "../components/modal/modal-message/modal-message.component";
import { FileRequest } from "../models/settings/settings";
import { saveAs } from "file-saver";
import { AuthService } from "../services/auth.service";

export class Base {
  public static appStarted: boolean = false;

  private appService: AppService = inject(AppService);
  public dialog = inject(MatDialog);

  private storage: Storage = window.localStorage;
  private cookieService: CookieService = inject(CookieService);

  constructor() {
    this.setPermissions(
      this.getLocalStorageItem<string[]>(LOCAL_STORAGE_KEY_PERMISSIONS)
    );
    this.setUser(this.getLocalStorageItem<CurrentUser>(LOCAL_STORAGE_KEY_USER));
  }

  public setLocalStorageItem(key: string, value: any): void {
    this.clearLocalStorageItem(key);

    if (typeof value === "string") {
      this.storage.setItem(key, value);
      return;
    }

    this.storage.setItem(key, JSON.stringify(value));
  }

  public getLocalStorageItem<T>(key: string): T {
    try {
      let t: T = JSON.parse(this.storage.getItem(key));
      return t;
    } catch (e) {
      return null;
    }
  }

  public clearLocalStorageItem(key: string): void {
    this.storage.removeItem(key);
  }

  public clearLocalStorage(): void {
    this.storage.clear();
  }

  public setCookieItem(key: string, value: any): void {
    if (this.cookieService.check(key)) {
      this.cookieService.delete(key);
    }

    if (typeof value === "string") {
      this.cookieService.set(key, value, 30);
      return;
    }

    this.cookieService.set(key, JSON.stringify(value), 30);
  }

  public getCookieItem<T>(key: string): T {
    if (this.cookieService.check(key)) {
      let t: T = JSON.parse(this.cookieService.get(key));
      return t;
    }

    return null;
  }

  public getPermissions(): string[] {
    return this.appService.getPermissions();
  }

  public setPermissions(permissions: string[]): void {
    this.appService.setPermissions(permissions);
  }

  public getUser(): CurrentUser {
    return this.appService.getUser();
  }

  public setUser(user: CurrentUser): void {
    this.appService.setUser(user);
  }

  public openDialog(component: any, config: MatDialogConfig): void {
    this.dialog.open(component, config);
  }

  public openDialogNotification(
    notification: Notification
  ): MatDialogRef<ModalNotificationComponent> {
    return this.dialog.open(ModalNotificationComponent, {
      maxWidth: "900px",
      data: notification,
    });
  }

  public openDialogConfirm(
    message: string
  ): MatDialogRef<ModalConfirmComponent> {
    return this.dialog.open(ModalConfirmComponent, {
      data: message,
    });
  }

  public openDialogMessage(
    message: string
  ): MatDialogRef<ModalMessageComponent> {
    return this.dialog.open(ModalMessageComponent, {
      data: message,
    });
  }

  public openDialogSucess(
    message: string | ModalSucess,
    style?: object
  ): MatDialogRef<ModalSuccessComponent> {
    if (style) {
      return this.dialog.open(ModalSuccessComponent, {
        ...style,
        data: message,
      });
    }

    return this.dialog.open(ModalSuccessComponent, {
      width: "400px",
      maxHeight: "400px",
      data: message,
    });
  }

  public openDialogError(
    message: string | string[] | HttpErrorResponse,
    title: string = "Atenção"
  ): MatDialogRef<ModalErrorComponent> {
    if (message === null) {
      return this.openErrorDialog(null, null);
    }

    if (Array.isArray(message)) {
      return this.openErrorDialog(title, message);
    } else {
      if (typeof message == "object") {
        if (message.status == 400) {
          if (typeof message.error.message == "string") {
            return this.openErrorDialog(title, message.error.message);
          } else {
            let messages: string[] = [];

            for (let key in message.error?.errors) {
              messages.push(
                message.error.errors[key].map((e: string) => e).join("\n")
              );
            }
            return this.openErrorDialog(title, messages);
          }
        }

        return this.openErrorDialog(title, null);
      }
    }

    return this.openErrorDialog(title, message);
  }

  public openDialogLoading(
    message?: string
  ): MatDialogRef<ModalLoadingComponent> {
    return this.dialog.open(ModalLoadingComponent, {
      data: message || "",
      disableClose: true,
    });
  }

  protected openErrorDialog(
    title: string,
    message: string | string[]
  ): MatDialogRef<ModalErrorComponent> {
    return this.dialog.open(ModalErrorComponent, {
      minWidth: "300px",
      maxWidth: "550px",
      maxHeight: "400px",
      data: {
        title: title,
        message: message,
      },
    });
  }

  public download(
    data: string | Blob,
    name: string,
    contentType: string
  ): void {
    let blob: Blob;

    if (!(data instanceof Blob)) {
      blob = this.base64toBlob(data, contentType);
    } else {
      blob = data;
    }

    var file = new File([blob], name, { type: "application/octet-stream" });
    saveAs(file, name);
  }

  private base64toBlob(base64Data: string, contentType: string) {
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public async returnFileRequestList(files: File[]): Promise<FileRequest[]> {
    return await Promise.all(
      [...files].map((f) => {
        return this.returnFileRequest(f);
      })
    );
  }

  private returnFileRequest(file: File): Promise<FileRequest> {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = function () {
        const fileRequest = {
          fileName: file.name,
          contentType: file.type,
          fileBase64: String(fileReader.result)
            .replace("data:", "")
            .replace(/^.+,/, ""),
        } as FileRequest;

        return resolve(fileRequest);
      };
      fileReader.readAsDataURL(file);
    });
  }
}
