import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Sinister } from 'src/app/models/insurance/policies';
import { Table } from 'src/app/models/table';

@Component({
  selector: 'app-modal-situation-sinister',
  templateUrl: './modal-situation-sinister.component.html',
  styleUrls: ['./modal-situation-sinister.component.scss']
})
export class ModalSituationSinisterComponent extends ModalComponent<ModalSituationSinisterComponent>  {

  public sinister: Sinister[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Sinister[],
    private datepipe: DatePipe){
    super()

    this.sinister = data
  }

  public getTable(): Table<any> {
    return {
      headers: [
        {
          key: "sinisterNumber",
          value: "Número",
        },
        {
          key: "communicationDate",
          value: "Data da Comunicação",
        },
        {
          key: "situation",
          value: "Situação",
        }
      ],
      data: this.sinister,
      style: {
        data: [
          {
            key: "communicationDate",
            transform: (value) => this.datepipe.transform(value, "dd/MM/yyyy"),
            styles: [{ class: { display: "flex", justifyContent: "center" } }]
            
          },
        ],
      },
      checkbox: false
    };
  }

}
