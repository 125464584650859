import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { EnumProduct } from 'src/app/enum/products';
import { EnumReportIncident } from 'src/app/enum/report-incident';
import { Contract } from 'src/app/models/bank/contract';
import { PaymentBook } from 'src/app/models/bank/payment';
import { BankService } from 'src/app/services/bank.service';
@Component({
  selector: 'app-payment-book',
  templateUrl: './payment-book.component.html',
  styleUrls: ['./payment-book.component.scss']
})
export class PaymentBookComponent extends BaseComponent implements OnDestroy {
  public title: string = "2ª via do carnê";
  public txtBtnDownloadVoucher: string = "Baixar Carnê"
  public errorMessage: string = "";

  public loading: boolean = false;
  private contract: Contract;
  public $subscriptionContract: Subscription;

  constructor(
    private bankService: BankService,
    private snackBar: MatSnackBar
  ){
    super();
  }

  ngOnInit(): void {
    this.subscriptionChangeContract();
  }

  ngOnDestroy(): void {
    this.$subscriptionContract.unsubscribe();
  }

  private subscriptionChangeContract(): void {
    this.$subscriptionContract = this.bankService.emitContractSelected.subscribe((contract: Contract) => {
      this.contract = contract
      this.errorMessage = "";
    })
  }

  public downloadVoucher(): void {
    this.loading = true;
    firstValueFrom(this.bankService.PaymentBookFile(this.contract.contractNumber))
      .then((resp: PaymentBook) => {
        this.download(resp.base64File, resp.fileName, "pdf")
        this.logFeature(EnumReportIncident.BANK_PAYMENT_BOOK, EnumProduct.BANK)
      })
      .then(() => this.openDialogSucess("Para abrir seu carnê, basta digitar somente os 5 primeiros dígitos do CPF ou CNPJ do titular."))
      .catch((err: any) => this.errorMessage = err.status === 400 ? err.error : "")
  }

}
