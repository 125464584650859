import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { EnumPath } from 'src/app/enum/path';
import { CustomValidators } from 'src/app/form/custom-validators';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot-password-new-password',
  templateUrl: './forgot-password-new-password.component.html',
  styleUrls: ['./forgot-password-new-password.component.scss']
})
export class ForgotPasswordNewPasswordComponent extends BaseComponent implements OnInit {

  get passwordMatchError() {
    return (
      this.form.getError('mismatch') &&
      this.form.get('confirmPassword')?.touched
    );
  }

  get guid(){
    return this.activatedRoute.snapshot.paramMap.get("guid")
  }

  public hidePassword: boolean = true;
  public hideConfirmPassword: boolean = true;
  public form: FormGroup;
  public loading: boolean = false;
  public success: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute : ActivatedRoute,
  ){
    super()
  }

  ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      newPassword: [null, [Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*\\W)(?!.* ).{8,50}$')]],
      confirmPassword: [null, Validators.required],
      guid: this.guid
    })

    this.form.setValidators(CustomValidators.MatchValidator('newPassword', 'confirmPassword'))
  }

  public goToLoginPage(): void {
    this.router.navigate([EnumPath.LOGIN]);
  }

  public change(): void {
    this.loading = true;

    firstValueFrom(this.authService.newPassword(this.form.value))
      .then(() => this.success = true)
      .catch((error: HttpErrorResponse) => { if(error.status != 500) this.openDialogError(error); })
      .finally(() => this.loading = false)

  }

}
