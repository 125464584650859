<div *ngIf="!isPwaInstalledOrDenyed() && detectar_mobile()" class="pwa-install">

    <div class="pwa-install-text">
        <div>
            <span> Para uma melhor experiência instale o aplicativo. Deseja instalar agora? </span>
        </div>
    
    
    </div>

    <div>
        <button data-cy="install-pwa-any-screen" (click)="pwaService.installPwa()"> Sim </button>
        <button data-cy="deny-pwa-any-screen" (click)="deny()"> Não </button>
    </div>
    

</div>