<div class="content density-lower">

    <div class="form-content backdrop-blur">
        <div>
            <div *ngIf="!success">
                <h1 class="font-title">Senha expirada</h1>
                <p class="font-text white">
                    A sua senha foi expirada e precisa ser trocada por uma nova. Para isso, informe a senha atual, digite duas vezes a nova senha e clique em "Alterar senha".
                </p>
            </div>

            <div *ngIf="success">
                <h1 class="font-title">Feito!</h1>
                <p class="font-text">
                   A partir de agora, utilize sua nova senha para acessar a plataforma!
                </p>
            </div>
        </div>

        <form [formGroup]="form" class="density-mat-form-3">

            <div *ngIf="!success" class="form-fields">
              <div class="form-field">
                 <label> Senha atual </label>
                 <mat-form-field appearance="outline" class="background-white">

                     <input formControlName="currentPassword"
                            matInput
                            [type]="hideCurrentPassword ? 'password' : 'text'"
                            maxlength="50"
                            data-cy="currentPassword"
                            (keydown.enter)="onSubmit()">

                     <button mat-icon-button matSuffix
                            (click)="hideCurrentPassword = !hideCurrentPassword"
                            [attr.aria-pressed]="hideCurrentPassword"
                            data-cy="button-hide-currentPassword"
                    >
                         <mat-icon class="grey">{{hideCurrentPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>

                    <mat-error *ngIf="form.get('currentPassword').hasError('required')" data-cy="error-currentPassword-required">
                         <strong> A senha atual é obrigatória </strong>
                    </mat-error>

                 </mat-form-field>
             </div>

                 <div class="form-field">
                     <label> Nova senha </label>
                     <mat-form-field appearance="outline" class="background-white">

                         <input formControlName="newPassword"
                                matInput
                                [type]="hideNewPassword ? 'password' : 'text'"
                                maxlength="50"
                                (keydown.enter)="onSubmit()"
                                data-cy="newPassword">

                         <button mat-icon-button matSuffix
                                (click)="hideNewPassword = !hideNewPassword"
                                [attr.aria-pressed]="hideNewPassword"
                                data-cy="button-hide-newPassword"
                        >
                             <mat-icon class="grey">{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>

                        <mat-error *ngIf="form.get('newPassword').hasError('required')" data-cy="error-newPassword-required">
                             <strong> A nova senha é obrigatória </strong>
                        </mat-error>

                     </mat-form-field>
                 </div>

                 <div class="form-field">
                     <label> Repita a nova senha </label>
                     <mat-form-field appearance="outline" class="background-white">

                         <input formControlName="confirmPassword"
                                matInput
                                [type]="hideConfirmNewPassword ? 'password' : 'text'"
                                maxlength="50"
                                (keydown.enter)="onSubmit()"
                                data-cy="confirmPassword">

                         <button mat-icon-button matSuffix
                                (click)="hideConfirmNewPassword = !hideConfirmNewPassword"
                                [attr.aria-pressed]="hideConfirmNewPassword"
                                data-cy="button-hide-confirmPassword"
                        >
                             <mat-icon class="grey">{{hideConfirmNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>

                        <mat-error *ngIf="form.get('confirmPassword').hasError('required')" data-cy="error-confirmPassword-required">
                             <strong> A confirmação da senha é obrigatória</strong>
                        </mat-error>

                     </mat-form-field>

                     <mat-error *ngIf="passwordMatchError" data-cy="error-passowrd-confirmPassword-passwordMatchError">
                        <strong> A nova senha e a confirmação da senha não coincidem </strong>
                    </mat-error>

                    <mat-error *ngIf="passwordNotMatchError" data-cy="error-passowrd-confirmPassword-passwordNotMatchError">
                        <strong> A nova senha não pode ser igual a senha atual </strong>
                    </mat-error>

                    <mat-error *ngIf="form.get('newPassword').hasError('pattern') && form.get('newPassword').touched" data-cy="error-passowrd-pattern">
                        <strong> A senha deve conter: </strong>
                        <ul>
                            <li> Mínimo de 8 caracteres </li>
                            <li> Máximo de 50 caracteres </li>
                            <li> Ao menos 1 letra </li>
                            <li> Ao menos 1 número </li>
                            <li> Ao menos 1 caracter especial <strong> (@#$%&, etc) </strong> </li>
                        </ul>
                   </mat-error>
                 </div>
             </div>

             <div class="form-buttons">

                 <app-button *ngIf="!success" type="submit"
                             (onClick)="onSubmit()"
                             width="100%"
                             [loading]="loading"
                             [disabled]="!form.valid || loading"
                             datacy="button-change-password"
                             color="primary">
                             <span style="font-size: 16px;"> Alterar senha </span>
                 </app-button >

                 <button mat-button type="button" (click)="returnLogin()" data-cy="back-button" class="white-bolder">
                             <mat-icon>keyboard_backspace</mat-icon>
                             <span class="text-size">Voltar para o login</span>
                 </button>
             </div>

        </form>


    </div>

    <div class="img">
        <img src="assets/yamaha-servicos-financeiros/yamaha-servicos-financeiros-4.jpg">
    </div>

</div>


