import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Base } from "../@base/base";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ROUTES } from "../const/routes";
import { EnumPath } from "../enum/path";
import { NO_CLIENT_PERMISSIONS } from "../const/noClient";

@Injectable()
export class AuthorizationGuard extends Base {
  constructor(private router: Router) {
    super();
  }

  verify(url: string): boolean {
    return ROUTES.find((x) => x.path == url).permissions.some(
      (r) =>
        this.getPermissions().includes(r) || NO_CLIENT_PERMISSIONS.includes(r)
    );
  }

  verifyChild(url: string): boolean {
    const route = ROUTES.find((x) => url.includes(x.path))?.children?.find(
      (x) =>
        url.includes(x.path) || x.children?.some((y) => url.includes(y.path))
    );
    if (route?.children) {
      return route?.children
        .find((r) => url.includes(r.path))
        .permissions?.some((r) => this.getPermissions().includes(r));
    } else {
      return route?.permissions?.some((r) => this.getPermissions().includes(r));
    }

    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.verify("/" + route.routeConfig.path)) {
      return true;
    }
    this.router.navigate([EnumPath.LOGIN]);

    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!route.routeConfig.path || this.verifyChild(state.url)) {
      return true;
    }

    this.router.navigate([EnumPath.LOGIN]);

    return true;
  }
}
