import { Component, OnDestroy, OnInit } from '@angular/core';
import { QUICK_ACCESS_BANK } from 'src/app/const/options';
import { EnumProduct } from 'src/app/enum/products';
import { EnumBankQuickAccess } from 'src/app/enum/options';
import { Option } from 'src/app/models/option';
import { Contract } from 'src/app/models/bank/contract';
import { LOCAL_STORAGE_KEY_CONTRACTS } from 'src/app/const/localStorageKeys';
import { BankService } from 'src/app/services/bank.service';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';

@Component({
  selector: 'app-product-bank',
  templateUrl: './product-bank.component.html',
  styleUrls: ['./product-bank.component.scss']
})
export class ProductBankComponent extends BaseComponent implements OnDestroy {
  public type: EnumProduct = EnumProduct.BANK;
  public options: Option[];
  public bankOptionsPermition: string[];
  private $subscriptionContract: Subscription;

  constructor(private bankService: BankService) {
    super();
    this.$subscriptionContract = this.bankService.emitContractsLoaded.subscribe((contracts) => this.verifyContractsHasActiveInstalments(contracts));
  }

  ngOnDestroy(): void {
    this.$subscriptionContract.unsubscribe();
  }

  private verifyContractsHasActiveInstalments(contracts: Contract[]): void {
    if(contracts.length === 0) contracts = this.getLocalStorageItem<Contract[]>(LOCAL_STORAGE_KEY_CONTRACTS) ?? [];

    if(contracts.some(x => x.hasActiveInstalments)) {
      return this.setAllOptions();
    }

    return this.setStatementsOptions();
  }

  private setAllOptions(): void {
    this.options = QUICK_ACCESS_BANK.filter(x => x.description !== EnumBankQuickAccess.CONTRACT_RECEIPT 
                                         && x.description !== EnumBankQuickAccess.CARTA_SALDO_DEVEDOR
                                         && x.description !== EnumBankQuickAccess.LIEN_STATUS);
    return this.setOptionsPermissions(this.options);
  }

  private setStatementsOptions(): void {
    this.options = QUICK_ACCESS_BANK.filter(x => x.description === EnumBankQuickAccess.STATEMENT ||
                                            x.description === EnumBankQuickAccess.ANUAL_STATEMENT);

    return this.setOptionsPermissions(this.options);
  }

  private setOptionsPermissions(options: Option[]): void {
    this.bankOptionsPermition = options.map(x => x.permission);
  }
}
