<div *ngIf="isClientOf(product); else noClientPage">
  <app-section [mode]="sectionMode" backgroundColor="#fff">


    <span class="section-title title estricta bolder"> liberacred </span>

    <div class="section-content">

      <span class="title estricta"> ABRIR NOVA SOLICITAÇÃO </span>

      <div class="flex">
        <app-button *ngFor="let option of creditOption" color="primary" width="100%" minWidth="190px"
          [showIfPermission]="option.permission" [datacy]="'Liberacred-' + option.description"
          [tooltip]="getTooltip(option)" (onClick)="openModalRequest(option)">
          <span class="button-content estricta bolder"> {{option.description}} </span>
        </app-button>
      </div>
    </div>
  </app-section>

  <div class="table">
    <app-table [table]="getTable()">
      <div class="table-header">
        <span class="estricta title"> MINHAS SOLICITAÇÕES </span>
      </div>
    </app-table>
  </div>

</div>

<ng-template #noClientPage>
  <app-section [mode]="sectionMode" backgroundColor="#fff">
    <section class="section-content section-no-client">

      <span class="section-title section-title-no-client title estricta bolder" data-cy="creditNoClientTitle">
        Com o LiberaCred é muito mais fácil conseguir o seu financiamento
      </span>

      <article class="section-content section-content-no-client">
        <p class="focus-content-paragraph uppercase" data-cy="creditNoClientMessage"> É simples chegar até sua YAMAHA
          0km com o liberacred. </p>
      </article>
    </section>

    <section class="section-content section-content-no-client">
      <section class="credit-card" data-cy="creditNoClientCardImageContainer">
        <section class="credit-card-content">
          <article>
            <span class="estricta bolder uppercase"> LiberaCred </span>
            <p>
              O jeito mais fácil e inovador de ter sua YAMAHA.
            </p>
          </article>
          <app-button tooltip="Clique aqui para conhecer mais sobre o LiberaCred" class="insurance-card-button"
            width="100%" (click)="goToLiberaCredExternalLink()" data-cy="creditNoClientGetToKnowButton">
            <span class="bolder estricta uppercase">Conheça o LiberaCred</span>
          </app-button>
        </section>
      </section>
    </section>
  </app-section>
</ng-template>