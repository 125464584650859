<div *ngIf="isClientOf(product); else noClient">
  <app-bank-header></app-bank-header>

  <app-section [mode]="sectionMode" backgroundColor="#fff">
    <div class="section-content">

      <div>
        <span class="title estricta bolder"> ACESSO RÁPIDO </span>
        <div class="flex">
          <ng-container *ngFor="let option of bankQuickAccess; let i = index">
            <app-button *ngIf="showIfHasActiveInstalments(option.description)" color="primary" width="100%"
              minWidth="260px" [datacy]="option.description" [showIfPermission]="option.permission"
              [disabled]="!contractSelected || buttonOptionLoading(option)" (onClick)="onOptionClicked(option)"
              [tooltip]="getTooltip(option)" [loading]="buttonOptionLoading(option)">
              <span class="estricta uppercase bolder"> {{option.description}} </span>
            </app-button>
          </ng-container>
        </div>
      </div>

      <div>
        <span class="title estricta bolder"> ABRIR NOVA SOLICITAÇÃO </span>
        <app-bank-buttons-option (onSubmit)="getIncidents()"></app-bank-buttons-option>
      </div>

    </div>

  </app-section>

  <div class="table">
    <app-table [table]="table">
      <div class="table-header">
        <span class="estricta title"> MINHAS SOLICITAÇÕES </span>
      </div>
    </app-table>
  </div>
</div>

<ng-template #noClient>
  <app-section [mode]="sectionMode" backgroundColor="#fff">
    <section class="section-content section-no-client">
      <span class="section-title section-title-no-client title estricta bolder" data-cy="bankNoClientTitle">
        O Banco que aproxima você dos seus sonhos
      </span>

      <article class="section-content section-content-no-client">
        <p class="focus-content-paragraph" data-cy="bankNoClientMessage">
          O Banco Yamaha atua como agente financeiro direto da montadora.
        </p>
      </article>
    </section>

    <section class="section-content section-content-no-client">
      <section class="bank-card" data-cy="bankNoClientCardImageContainer">
        <section class="bank-card-content">
          <article>
            <span class="estricta bolder uppercase"> Banco </span>
            <p>
              Sua opção YAMAHA para Financiamentos.
            </p>
          </article>
          <app-button tooltip="Clique aqui para conhecer mais sobre o Banco YAMAHA" class="bank-card-button"
            width="100%" (click)="goToBankExternalLink()" data-cy="bankNoClientGetToKnowButton">
            <span class="bolder estricta"> Conheça o Banco </span>
          </app-button>
        </section>
      </section>
    </section>
  </app-section>
</ng-template>