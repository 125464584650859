<div [ngClass]="fullPage ? 'carousel' : 'carousel full'" data-cy="carousel">
    <div class="carousel-inner">

        <ng-container *ngFor="let image of images; let i = index">
            <input #ip class="carousel-open" type="radio" [attr.id]="getId(i)" name="carousel" aria-hidden="true" hidden="" [checked]="i === 0">
            <div [ngClass]="image.url ? 'pointer carousel-item' : 'carousel-item'" (click)="goToLink(image.url)">
                <div class="image"
                     [style.height]="(fullPage ? '100vh' : (detectar_mobile() ? '300px' : '450px'))"
                     [style.backgroundSize]="fullPage ? '100% 100%' : 'cover'"
                     [style.backgroundImage]="'url(' + image.path + ')'"
                     [attr.data-cy]="'image-' + i"></div>

                <div class="image-message">
                    <p class="estricta uppercase" [attr.data-cy]="'message-' + i"> {{image.message}} </p>
                </div>
            </div>
        </ng-container>

        <ng-container *ngFor="let image of images; let i = index">
            <label *ngIf="images.length > 1"
                    [htmlFor]="getId(i)"
                    [ngClass]="getClassPrev(i) + (useAuthLayoutButtonsPosition ? ' use-auth-prev-button-position' : '')"
                    [attr.data-cy]="'previous-' + i"
                    (click)="changeIndex(i)"
            >‹</label>


            <label *ngIf="images.length > 1"
                    #next
                   [id]="'next-button-' + i"
                   [htmlFor]="getId(i)"
                   [ngClass]="getClassNext(i) + (useAuthLayoutButtonsPosition ? ' use-auth-next-button-position' : '')"
                   [attr.data-cy]="'next-' + i"
                   (click)="changeIndex(i)"
            >›</label>
        </ng-container>

        <ol *ngIf="images.length > 1" [ngClass]="'carousel-indicators ' + getClassIfShowButtons() + (useAuthLayoutButtonsPosition ? ' use-auth-indicators-button-position' : '')">
            <li *ngFor="let image of images; let i = index">
                <label (click)="changeIndex(i)" [attr.for]="getId(i)" [attr.data-cy]="'bullet-' + i" class="carousel-bullet">•</label>
            </li>
        </ol>

    </div>
</div>

