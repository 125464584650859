import { Table } from "src/app/models/table";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from "src/app/components/modal/modal.component";
import { DebtServiceHistoryResponse } from "src/app/models/dealer/dealer";
import { DatePipe } from "@angular/common";

@Component({
    selector: 'app-modal-debt-billing-histories-modal',
    templateUrl: './debt-billing-histories-modal.component.html',
    styleUrls: ['./debt-billing-histories-modal.component.scss']
})
export class DebtBillingHistoriesModalComponent extends ModalComponent<DebtBillingHistoriesModalComponent> {

    public table: Table<DebtServiceHistoryResponse>;
    public txtBtnClose: string = "Fechar";

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {contractNumber: string, clientDocument: string, clientName: string, histories: DebtServiceHistoryResponse[]},
        private datepipe: DatePipe) {
        super();

        this.table = {
            headers: [
                {
                    key: "date",
                    value: "Data",
                },
                {
                    key: "userPersonName",
                    value: "Nome"
                },
                {
                    key: "commentary",
                    value: "Comentário"
                }
            ],
            data: data.histories,
            style: {
                table: {
                    height: "400px",
                },
                data: [
                    {
                        key: "date",
                        transform: (value) => this.datepipe.transform(value, "dd/MM/yyyy  HH:mm:ss")
                    }
                ]
            }
        } as Table<DebtServiceHistoryResponse>;
    }
}