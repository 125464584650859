<app-modal class="density-lower" title="Nova solicitação" [disableButtonClose]="isLoadingButtonSend">
  <div class="modal-content density-mat-form-3">
    <h3 class="uppercase estricta">Banco - {{ subject.description }}</h3>

    <div class="modal-content-items">
      <span> Para essa solicitação, efetue os seguintes passos: </span>
      <span>
        1 - Faça o download do arquivo de modelo clicando em "Baixar arquivo
        modelo";</span>
      <span> 2 - Preencha o arquivo de modelo em seu computador e salve;</span>
      <span>
        3 - Faça o upload dos arquivos preenchidos e dos demais arquivos necessários na sessão abaixo: "Upload de
        arquivos"; </span>
      <span> 4 - Clique em "Enviar";</span>
    </div>

    <div class="flex observation">
      <span class="bold"> Observação: </span>
      <span> "Cessionário" é a pessoa que deseja assumir o financiamento. </span>
    </div>

    <app-button datacy="DownloadTemplateFile" [disabled]="isLoadingButtonDownload" [loading]="isLoadingButtonDownload"
      (onClick)="download()" minWidth="260px" color="primary">
      <div class="modal-content-button">
        <mat-icon>get_app</mat-icon>
        <span class="button-label">Baixar arquivo modelo</span>
      </div>
    </app-button>

    <div class="flex-add-button">
      <span class="estricta uppercase bolder file-input-title">Upload de Arquivos</span>
    </div>

    <div *ngFor="let file of files">
      <div class="file-input" [ngStyle]="file.style">
        <span class="file-input-text">{{file.text}}</span>

        <div [ngSwitch]="file.statusEnvio">

          <app-file-upload #fileComponent *ngSwitchCase="EnumStatusEnvioFileIncident.NAO_INICIADO"
            [fileTypes]="fileTypes" [display]="'button'" [sizeLimit]="5" [disabled]="isLoadingButtonSend"
            (emitFileUploaded)="onFileUploaded(file.text, $event)" [customDataCy]="file.text + 'Input'">
          </app-file-upload>

          <div *ngSwitchCase="EnumStatusEnvioFileIncident.INICIADO" class="flex-sending">
            <span> Enviando </span> <mat-spinner diameter="20"></mat-spinner>
          </div>

          <div *ngSwitchCase="EnumStatusEnvioFileIncident.FINALIZADO">
            Enviado!
          </div>

          <div *ngSwitchCase="EnumStatusEnvioFileIncident.ERRO">
            Ocorreu um erro
          </div>

        </div>

      </div>
    </div>

    <div class="button-add-more">
      <button [disabled]="disableButtonClose" class="file-input" mat-button color="primary"
        [attr.data-cy]="'OptionalFilesInput'" (click)="createNewFile(); openLastFileSelector()"
        [disabled]="qtdExtraFileAdded == qtdExtraFile">
        <mat-icon> cloud_upload </mat-icon> <span class="button-label">Anexar arquivo opcional</span>
      </button>
    </div>



  </div>

  <div class="modal-bottom">
    <app-button [datacy]="txtBtnCancel" [disabled]="isLoadingButtonSend" (onClick)="onClickBtnClose()"><span
        class="button-label">{{ txtBtnCancel }}</span></app-button>
    <app-button [datacy]="txtBtnSendRequest" [disabled]="disableButtonSend()" [loading]="isLoadingButtonSend"
      (onClick)="send()" width="100px" color="primary">
      <span class="button-label">{{ txtBtnSendRequest }}</span>
    </app-button>
  </div>
</app-modal>