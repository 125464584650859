<div class="content">
  <section class="backdrop-blur">
    <div *ngIf="intern != 'true'" class="message">
      <div
        class="content-success"
        data-cy="message-success-paragraph-form-match"
      >
        <span class="uppercase estricta bolder content-header-title">
          TROCA DE SENHA NECESSÁRIA!
        </span>
        <span class="content-header-subtitle">
          Devido à política de segurança informamos que é necessária a sua troca
          de senha. Enviamos um link para troca de senha para seu e-mail
          cadastrado:

          <strong data-cy="message-success-paragraph-form-match">{{
            userEmail
          }}</strong>
        </span>
        <span class="content-header-subtitle">
          Acesse seu e-mail para ter acesso à troca de senha.<br /><br />
          Caso não tenha recebido o e-mail entre em contato com nossa Central de
          Relacionamento pelo telefone (11) 2088-7700.
        </span>
      </div>
    </div>
    <div *ngIf="intern == 'true'" class="message">
      <div
        class="content-success"
        data-cy="message-success-paragraph-form-match"
      >
        <span class="uppercase estricta bolder content-header-title">
          TROCA DE SENHA NECESSÁRIA!
        </span>
        <span class="content-header-subtitle">
          Devido à política de segurança informamos que é necessária a sua troca
          de senha.
        </span>
        <span class="content-header-subtitle">
          Como seu acesso ao portal é de um usuário interno,<br> solicite sua troca
          de senha via chamado de TI.
        </span>
      </div>
    </div>
    <button
      mat-button
      (click)="goToLoginPage()"
      data-cy="back-button-result"
      class="white-bolder"
    >
      <mat-icon>keyboard_backspace</mat-icon>
      <span class="text-size">Voltar para o login</span>
    </button>
    <div class="flex-horizontal-center">
      <img
        src="assets/yamaha-servicos-financeiros/yamaha-servicos-financeiros-4.jpg"
      />
    </div>
  </section>
</div>
