import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from '../modal.component';

export interface ModalSucess {
  message: string;
  title: string;
  style?: object;
}

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss']
})
export class ModalSuccessComponent extends ModalComponent<ModalSuccessComponent>{

  public messageModalSuccess: string;
  public titleModalSuccess: string = "";
  
  public txtBtnClose: string = "Ok";

  get thereIsTitle() {
    return this.titleModalSuccess !== ""
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string | ModalSucess,
  ) {
    super()
    
    if(typeof(data) === "string") {
      this.messageModalSuccess = data 
    } else {
      this.messageModalSuccess = data.message,
      this.titleModalSuccess = data.title
    }

  }




}
