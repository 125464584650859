import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/@base/base.component';
import { FullPayment, Payment } from 'src/app/models/bank/payment';
import { Table } from 'src/app/models/table';
import { DealersService } from 'src/app/services/dealers.service';
import { Observable, firstValueFrom } from 'rxjs';
import { orderBy } from 'lodash-es';
import { ActivatedRoute } from '@angular/router';
import { Debtor, LateInstallmentsRequest } from 'src/app/models/dealer/dealer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from "src/app/form/custom-validators";
import { DAYS } from 'src/app/const/time';
import { HttpErrorResponse } from '@angular/common/http';
import { LOCAL_STORAGE_KEY_DEALERS_DEBT_HANDLE } from 'src/app/const/localStorageKeys';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChip, MatChipEvent } from '@angular/material/chips';


@Component({
  selector: 'app-debt-settlement',
  templateUrl: './debt-settlement.component.html',
  styleUrls: ['./debt-settlement.component.scss']
})
export class DebtSettlementComponent extends BaseComponent {

  hasRegisteredDebtHandling: boolean = true;

  public dataAtual = new Date();

  public form: FormGroup;
  public validity: FormGroup;
  
  public txtBtnBack: string = "Voltar";
  public table: Table<Payment> = this.createTable();
  // public rowsSelected: Payment[] = [];
  
  public totalRows: Payment[] = this.table.data;
  public date: string | Date = new Date();
  public messageNotAllowed: string = null;
  public loading: boolean = false;
  public loadingRecalculate: boolean = false;
  public lateInstallmentRequest: LateInstallmentsRequest;
  public debtor: Debtor;
  public paymentSlipGenerated: boolean = false;
  
  descritionSettlement: string = '';

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (this.hasRegisteredDebtHandling) {
      this.openDialogMessage("Efetue o registro da negociação com o cliente.");
      return false;
    }

    return true;
  }
  get rowsSelected(): Payment[] {
    return this.table.data.filter(i => i['selected'] === true)
  }
  
  get isAllSelected(): boolean {
    if (this.rowsSelected.length == this.table.data.length) {
      return true;
    }

    return false;
  }
  
  get increaseDays(): number {
    return this.setIncreaseDays(this.form.get("dueDate").value);
  }

  constructor(private formBuilder: FormBuilder, private dealerService: DealersService, private datepipe: DatePipe, private activatedRoute : ActivatedRoute) {
    super();

    this.dataAtual.setHours(0, 0, 0, 0);

    this.debtor = this.getLocalStorageItem<Debtor>(LOCAL_STORAGE_KEY_DEALERS_DEBT_HANDLE);
    this.lateInstallmentRequest = {...this.debtor, increaseDays: 0};
    
    this.createForm();

    this.getAllLateInstallment();
    
  }

  private createForm(): void {

    this.form = this.formBuilder.group({
      dueDate: [this.dataAtual, [Validators.required, CustomValidators.isDatePriorToToday()]]
    });
  }

  public back(): void {
    this.hasRegisteredDebtHandling = false;

    let request = {
      clientDocument: this.lateInstallmentRequest.clientDocument, 
      contractNumber: this.lateInstallmentRequest.contractNumber,
      commentary: this.descritionSettlement, 
      insertHistory: false
    }

    firstValueFrom(this.dealerService.registerEndDebtHandling(request))
    this.location.back();
  }

  public getAllLateInstallment(): void {
    this.table.loading = true;

    firstValueFrom(this.dealerService.getLateInstallment(this.lateInstallmentRequest))
      .then((resp: Payment[]) => this.table.data = resp)
      .catch((error: HttpErrorResponse) => {
        if(error.status != 500) {
          this.openDialogError(error)
        }
      })
      .finally(() => (this.table.loading = false));
  }

  public setDescriptionSettlement(){
    this.paymentSlipGenerated = true;
    this.table.checkbox = false;
    this.table.checkboxAll = false;
  }

  setIncreaseDays(dateSelected: Date){
    let dateToday = new Date();
    dateToday.setHours(0, 0, 0, 0); 

    let daysPassed = (dateSelected.getTime() - dateToday.getTime()) / DAYS;
    return Math.round(daysPassed);
  }

  recalculate() {
    this.lateInstallmentRequest.increaseDays = this.increaseDays;
    this.table.loading = true;
    this.loadingRecalculate = true;

    firstValueFrom(this.dealerService.getLateInstallment(this.lateInstallmentRequest))
      .then((resp: Payment[]) => {
        const selectedInstallments = new Set(this.rowsSelected.map(item => item.number));

        resp = resp.map(item => {
          if (selectedInstallments.has(item.number)) {
            return {
              ...item,
              selected: true
            };
          }
          item['selected'] = false;
          return item;
        });
        
        this.table.data = resp;
        // this.rowsSelected = this.table.data.filter(i => i['selected'] === true)

      })
      .catch((error: HttpErrorResponse) => {
        if(error.status != 500) {
          this.openDialogError(error)
        }
      })
      .finally(() => {
        this.table.loading = false;
        this.loadingRecalculate = false;
      });
  }

  registerEndDebtHandling(insertHistory:boolean = false) {
    let request = {
        clientDocument: this.lateInstallmentRequest.clientDocument, 
        contractNumber: this.lateInstallmentRequest.contractNumber,
        commentary: this.descritionSettlement, 
        insertHistory: insertHistory
    }

    firstValueFrom(this.dealerService.registerEndDebtHandling(request))
    .then(() => {
      firstValueFrom(
        this.openDialogSucess('Registrado com sucesso!').afterClosed()
        ).then(() => { this.hasRegisteredDebtHandling = false; this.location.back(); } );
      })
      .catch((error: HttpErrorResponse) => {
        if(error.status != 500) {
          this.openDialogError(error)
        }
      });
  }


  customCheck(row: FullPayment, event: MatCheckboxChange ){
    let hasPreviousInstallment = this.table.data.filter(x => x.number < row.number && !x.selected).length > 0;
    if(hasPreviousInstallment && row.selected){
      row.selected = false;
      if(event && event instanceof MatCheckboxChange) event.source.checked = false;
      
      this.openDialogError("É necessário escolher primeiro a(s) parcela(s) anterior(es).");
      return;
    }  
    let hasFutureInstallmentChecked = this.table.data.filter(x => x.number > row.number && x.selected).length > 0; 
    if (hasFutureInstallmentChecked) {
      this.table.data = this.table.data.map(x => {
        if (x.number >= row.number) {
            return { ...x, selected: false };
        }
        return x;
      });
      return;
    }
    if(!event){
      row.selected = false;
    }
  }


  disableCheckboxCondition(row): boolean {
    return this.paymentSlipGenerated;
  }

  private createTable(): Table<FullPayment> {
    return {
      headers: [
        {
          key: "number",
          value: "Parcela",
        },      
        {
          key: "paymentDay",
          value: "Data do Pagamento"
        },
        {
          key: "dueDate",
          value: "Vencimento",
        },
        {
          key: "originalValue",
          value: "Valor Original",
        },
        {
          key: "discountIncrease",
          value: "Desconto/Acréscimo",
        },
        {
          key: "total",
          value: "Valor Atual"
        },
        {
          key: "paymentValue",
          value: "Valor Pago"
        }	
      ],
      data: [],
      style: {
        data: [
          {
            key: "discountIncrease",
            styles: [
              {
                class: { fontWeight: "500", color: "red" },
                rule: (x) => parseFloat(x) > 0,
              },
              {
                class: { fontWeight: "500", color: "green" },
                rule: (x) => parseFloat(x) < 0,
              },
            ],
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            key: "originalValue",
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            key: "total",
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            key: "paymentValue",
            transform: (value: string) => parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          },
          {
            key: "paymentValue",
            transform: (value: string) => parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          },
          {
            key: "dueDate",
            transform: (value) => this.datepipe.transform(value, 'dd/MM/yyyy')
          },
          {
            key: "paymentDay",
            transform: (value) => this.datepipe.transform(value, 'dd/MM/yyyy')
          }
        ],
        table: {
          height: "400px",
          align: {
            column: [
              {
                key: "originalValue",
                align: "right",
              },
              {
                key: "discountIncrease",
                align: "right",
              },
              {
                key: "paymentValue",
                align: "right"
              },
              {
                key: "total",
                align: "right",
              },
            ],
          },
        },
      },
      checkbox: true,
      checkboxAll: true,
      messageWithoutDates: "Não há dados para exibir neste momento.",
    };
  }

  public rowsSelectedSorted(): any[] {
    return orderBy(this.rowsSelected, ["number"], ["asc"]);
  }


  public getBtnIcon(): string {
    if (this.rowsSelected.length == this.table.data.length) {
      return "check_circle_outline";
    }

    return "get_app";
  }
}
