<div mat-dialog-title>
    <span class="estricta modal-title">
        {{title}}
    </span>

    <button (click)="onClose()" class="btnClose" [disabled]="disableButtonClose">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div>

<mat-dialog-content>  
    <ng-content select=".modal-content"></ng-content>
</mat-dialog-content>

<mat-dialog-actions *ngIf="buttons" align="end">
    <ng-content select=".modal-bottom"></ng-content>
</mat-dialog-actions>


