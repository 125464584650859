import { Component } from "@angular/core";
import { BaseComponent, EProduct } from "src/app/@base/base.component";

@Component({
  selector: "app-bank",
  templateUrl: "./bank.component.html",
  styleUrls: ["./bank.component.scss"],
})
export class BankComponent extends BaseComponent {
  public readonly product: EProduct = EProduct.Bank;

  onActivate() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }
}
