<p><b>Segmentos:</b></p>

<div class="card">
    <div class="grid-item">
        <p>Banco</p>
        <mat-icon class="green" *ngIf="form?.get('products').value.includes('Banco')">check_circle_outline</mat-icon>
        <mat-icon class="red" *ngIf="!form?.get('products').value.includes('Banco')">highlight_off</mat-icon>
    </div>
    <div class="grid-item">
        <p>LiberaCred</p>
        <mat-icon class="green" *ngIf="form?.get('products').value.includes('LiberaCred')">check_circle_outline</mat-icon>
        <mat-icon class="red" *ngIf="!form?.get('products').value.includes('LiberaCred')">highlight_off</mat-icon>
    </div>
    <div class="grid-item">
        <p>Consórcio</p>
        <mat-icon class="green" *ngIf="form?.get('products').value.includes('Consórcio')">check_circle_outline</mat-icon>
        <mat-icon class="red" *ngIf="!form?.get('products').value.includes('Consórcio')">highlight_off</mat-icon>
    </div>
    <div class="grid-item">
        <p>Seguros</p>
        <mat-icon class="green" *ngIf="form?.get('products').value.includes('Seguros')">check_circle_outline</mat-icon>
        <mat-icon class="red" *ngIf="!form?.get('products').value.includes('Seguros')">highlight_off</mat-icon>
    </div>
</div>
