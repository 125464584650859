<span class="form-field">
    <label *ngIf="!required"> {{label}} </label>
    <label *ngIf="required && label != '' && label != null"> <span class="red">*</span> {{label}}: </label>
    <mat-form-field appearance="outline" class="background-white">
      
        <input #input
               matInput 
               [matDatepicker]="picker" 
               [ngModel]="value" 
               (ngModelChange)="value=$event;onChanged($event)"
               [max]="maxDate"
               [min]="minDate"
               maxlength="10"
               appDateMask
               [readonly]="readonly"
               [attr.data-cy]="label"
               [errorStateMatcher]="errorMatcher"
               (click)="markAsTouched()"
               (keydown)="checkMask($event)"
               [attr.data-cy]="datacy">

        <mat-datepicker-toggle *ngIf="!readonly" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

        <mat-error *ngIf="control.hasError('dateGreaterThanToday')" [attr.data-cy]="returnDataCy('invalid')">
          <strong> Data inválida </strong>
        </mat-error>

        <mat-error *ngIf="control.hasError('required')" [attr.data-cy]="returnDataCy('required')">
          <strong> A data é obrigatória </strong>
        </mat-error>
        <mat-error *ngIf="control.hasError('ageLessThan14Years')" [attr.data-cy]="returnDataCy('is-age-at-least-14-years')" class="age-validation" >
          <strong> Idade mínima aceita é de 14 anos. </strong> 
        </mat-error>
      </mat-form-field>
</span> 

