<app-modal title="Histórico de Atendimentos">
    <div class="modal-content">
        <div class="user-about-info">
            <div>
                <span class="user-about-info-itens-2 grey"> Contrato: </span>
                <span> {{data.contractNumber}} </span>
            </div>

            <div>
                <span class="user-about-info-itens-2 grey"> CPF / CNPJ: </span>
                <span> {{data.clientDocument}} </span>
            </div>

            <div>
                <span class="user-about-info-itens-2 grey"> Nome Cliente: </span>
                <span> {{data.clientName}} </span>
            </div>
        </div>
        <div class="table-container">
            <app-table [table]="table"></app-table>
        </div>
    </div>
    <div class="modal-bottom">
        <app-button [datacy]="txtBtnClose" color="primary" (onClick)="onClose()">
            <span class="button-label">{{ txtBtnClose }}</span>
        </app-button>
    </div>
</app-modal>