<app-modal class="density-lower">

    <div class="modal-content">

        <div class="content">
            <div>
                <mat-icon>error</mat-icon>
                <span class="bolder title-error" data-cy="modal-error-title"> {{ messageTitle || 'Serviço indisponível no momento!'}} </span>
            </div>
            
            <div>
                <span *ngIf="!isArray(error); else messages" class="title-error" data-cy="modal-error-message">{{ error || 'Pedimos a gentileza que tente novamente mais tarde.' }}</span>

                <ng-template #messages>
                    <div *ngFor="let err of error" style="display: flex; flex-direction: column;">
                        <span class="title-error">{{ err }} </span>
                    </div>
                </ng-template>
            </div>
        </div>

    </div>


    <div class="modal-bottom">
        <app-button [datacy]="txtBtnClose" (onClick)="onClose()" color="primary" minWidth="80px"><span class="button-label">{{txtBtnClose}}</span></app-button>
    </div>
</app-modal>
