import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import { CurrentUser, User, UsersFilterRequest, UsersFilterResponse } from '../models/user/user';
import { Observable } from 'rxjs';
import { NewAccount, NewPassword, ResetExpiredPassword, ResetPassword, ResetPasswordResponse } from '../models/auth/auth';
import { PaginatorItems } from '../models/table';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<any> {

  constructor(
    private datePipe: DatePipe
  ) {
    super()
    this.path = "gateway/users"
  }

  private createParam(usersFilterRequest: UsersFilterRequest, pageNumber?: number, pageSize?: number): string {
    let query: string = "";

    query += `status=${usersFilterRequest.status}`

    if(usersFilterRequest.name !== null && usersFilterRequest.name !== ""){
      query += `&name=${usersFilterRequest.name}`
    }

    if(usersFilterRequest.document !== null && usersFilterRequest.document !== ""){
      query += `&document=${usersFilterRequest.document}`
    }

    if(usersFilterRequest.email !== null && usersFilterRequest.email !== ""){
      query += `&email=${usersFilterRequest.email}`
    }

    if(usersFilterRequest.phone !== null && usersFilterRequest.phone !== ""){
      query += `&phone=${usersFilterRequest.phone}`
    }

    if(usersFilterRequest.profiles.length > 0){
      query += "&Profiles=" + `${usersFilterRequest.profiles.join("&Profiles=")}`
    }

    if(usersFilterRequest.createdBy !== null){
      query += `&createdBy=${usersFilterRequest.createdBy}`
    }

    if(usersFilterRequest.lastModifiedBy !== null){
      query += `&lastModifiedBy=${usersFilterRequest.lastModifiedBy}`
    }

    if(usersFilterRequest.lastModifiedAt.startDate !== null){
      query += `&lastModifiedAt.startDate=${this.datePipe.transform(usersFilterRequest.lastModifiedAt.startDate, 'yyyy-MM-dd')}`
    }

    if(usersFilterRequest.lastModifiedAt.endDate !== null){
      query += `&lastModifiedAt.endDate=${this.datePipe.transform(usersFilterRequest.lastModifiedAt.endDate, 'yyyy-MM-dd')}`
    }

    if(usersFilterRequest.createdAt.startDate !== null){
      query += `&createdAt.startDate=${this.datePipe.transform(usersFilterRequest.createdAt.startDate, 'yyyy-MM-dd')}`
    }

    if(usersFilterRequest.createdAt.endDate !== null){
      query += `&createdAt.endDate=${this.datePipe.transform(usersFilterRequest.createdAt.endDate, 'yyyy-MM-dd')}`
    }

    if(usersFilterRequest.birthDate !== null){
      query += `&birthDate=${this.datePipe.transform(usersFilterRequest.birthDate, 'yyyy-MM-dd')}`
    }

    if(pageNumber){
      query += `&pageNumber=${pageNumber}`
    }

    if(pageSize){
      query += `&pageSize=${pageSize}`
    }

    return query;
  }



  public filter(usersFilterRequest: UsersFilterRequest, pageNumber?: number, pageSize?: number): Observable<PaginatorItems<UsersFilterResponse>> {
    return this.http.get<PaginatorItems<UsersFilterResponse>>(`${this.api}${this.path}?${this.createParam(usersFilterRequest, pageNumber, pageSize)}`)
  }

  public getInternalUsers(): Observable<PaginatorItems<UsersFilterResponse>> {
    return this.http.get<PaginatorItems<UsersFilterResponse>>(`${this.api}gateway/users?type=internal&pageSize=100`)
  }

  public downloadExcel(usersFilterRequest: UsersFilterRequest, pageNumber?: number, pageSize?: number): Observable<string> {
    return this.http.get(`${this.api}${this.path}/download?${this.createParam(usersFilterRequest, pageNumber, pageSize)}`, {responseType: 'text'})
  }

  public getUserById(id: number): Observable<User> {
    return this.http.get<User>(`${this.api}${this.path}/${id}`)
  }


}
