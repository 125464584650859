<app-insurance-contracts *ngIf="showIfAnyOption" [policies]="policies"
  (emitContract)="onContractChanged($event)"></app-insurance-contracts>

<section *ngIf="isClientOf(product); else noClient">
  <app-section [mode]="sectionMode" backgroundColor="#fff">
    <span class="section-title title estricta bolder"> SEGUROS </span>

    <div class="section-content">
      <div *ngIf="isLoadingSuhai">
        <div class="loading">
          <mat-spinner class="small-spinner" data-cy="suhaiSpinner"></mat-spinner> Verificando Seguradora
        </div>

      </div>
      <div *ngIf="showIfAnyOption">
        <span class="title estricta"> SEGURADORA SUHAI </span>
        <div class="flex">
          <app-button *ngFor="let option of insuranceOptions" color="primary" width="100%" minWidth="190px"
            [showIfPermission]="option.permission" [datacy]="'Seguros-' + option.description"
            [tooltip]="getTooltip(option)" [disabled]="buttonOptionLoading(option)"
            [loading]="buttonOptionLoading(option)" (onClick)="onClickInsuranceOptionButton(option)">
            <span class="button-content estricta bolder"> {{option.description}} </span>
          </app-button>
        </div>
      </div>
    </div>

    <div class="section-content">
      <span class="title estricta"> ABRIR NOVA SOLICITAÇÃO </span>
      <div class="flex">
        <app-button *ngFor="let option of insuranceRequestOptions" color="primary" width="100%" minWidth="190px"
          [showIfPermission]="option.permission" [datacy]="'Seguros-' + option.description"
          [tooltip]="getTooltip(option)" (onClick)="openModalRequest(option)">
          <span class="button-content estricta bolder"> {{option.description}} </span>
        </app-button>
      </div>
    </div>
  </app-section>

  <div class="table">
    <app-table [table]="getTable()" class="table">
      <div class="table-header">
        <span class="estricta title"> MINHAS SOLICITAÇÕES </span>
      </div>
    </app-table>
  </div>
</section>

<ng-template #noClient>
  <app-section [mode]="sectionMode" backgroundColor="#fff">
    <section class="section-content section-no-client">
      <span class="section-title section-title-no-client title estricta bolder" data-cy="insuranceNoClientTitle">
        A Yamaha também é corretora de seguros
      </span>

      <article class="section-content section-content-no-client">
        <p class="focus-content-paragraph" data-cy="insuranceNoClientDescription">
          Completando o time de serviços financeiros, a Yamaha seguros atua em todos os segmentos sempre buscando
          oferecer as melhores condições de cobertura, serviços e custo aos clientes.
        </p>
      </article>
    </section>

    <section class="section-content section-content-no-client">
      <section class="insurance-card" data-cy="insuranceNoClientCardImageContainer">
        <section class="insurance-card-content">
          <article>
            <span class="estricta bolder uppercase"> Seguros </span>
            <p>
              Proteção para você e seu patrimônio, com condições diferenciadas e a segurança da YAMAHA.
            </p>
          </article>
          <app-button tooltip="Clique aqui para conhecer mais sobre a YAMAHA Seguros" class="insurance-card-button"
            width="100%" (click)="goToInsuranceExternalLink()" data-cy="insuranceNoClientGetToKnowButton">
            <span class="bolder estricta">Conheça a Corretora</span>
          </app-button>
        </section>
      </section>
    </section>
  </app-section>
</ng-template>
