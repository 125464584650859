import { SelectionModel } from "@angular/cdk/collections";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortModule, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, map, shareReplay } from "rxjs";
import { Paginator, Table } from "src/app/models/table";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements DoCheck, AfterViewInit {
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  @ViewChild("checkall") checkall: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() table: Table<any>;
  @Input() disableCondition: any = false;
  @Input() noDataMessageCy: string = "tableNoDataMessageCy";
  @Input() optionTemplate: TemplateRef<any>;

  @Output() emitSelectChanged: EventEmitter<any> = new EventEmitter();
  @Output() emitSelectAllChanged: EventEmitter<any> = new EventEmitter();
  @Output() emitActionChange: EventEmitter<any> = new EventEmitter();
  @Output() emitActionDelete: EventEmitter<any> = new EventEmitter();
  @Output() emitActionView: EventEmitter<any> = new EventEmitter();
  @Output() emitPaginatorChanged: EventEmitter<Paginator> = new EventEmitter();
  @Output() emitRowDoubleClick: EventEmitter<any> = new EventEmitter();
  @Output() emitDataChange: EventEmitter<any> = new EventEmitter();
  @Output() emitButtonExcelClick: EventEmitter<any> = new EventEmitter();

  public maxWidth: string;
  public height: string;

  public keys: string[];
  public values: string[];

  public selection = new SelectionModel<any>(true, []);

  public dataSource: MatTableDataSource<any>;
  sortedData: any[];

  constructor(private breakpointObserver: BreakpointObserver) {}
  ngDoCheck(): void {
    document.documentElement.style.setProperty(
      "--quantidade",
      this.table.headers.length + ""
    );

    this.dataSource = new MatTableDataSource<any>(this.table.data);

    this.keys = this.table.headers.map((x) => x.key);
    this.values = this.table.headers.map((x) => x.value);

    this.height = this.table.style?.table?.height
      ? this.table.style.table.height
      : "100%";
    this.maxWidth = this.table.style?.table?.maxWidth
      ? this.table.style.table.maxWidth
      : "100%";

    if (this.table.checkbox) {
      this.putCheckbox();
    }

    if (this.table.actions) {
      this.putActions();
    }

    if (this.table.buttons) {
      this.putButtons();
    }

    if (this.table.data?.length == 0) {
      this.selection.clear();
    }

    if (this.paginator) {
      if (this.table.paginator.index == undefined) {
        this.table.paginator.index = 1;
        return;
      }

      this.paginator.pageIndex = this.table.paginator.index - 1;
    }

    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel = "Itens por página";
      this.paginator._intl.nextPageLabel = "Próxima página";
      this.paginator._intl.previousPageLabel = "Página anterior";
      this.paginator._intl.firstPageLabel = "Primeira página";
      this.paginator._intl.lastPageLabel = "Última página";
      this.paginator._intl.getRangeLabel = this.displayText;
    }

    this.dataSource.paginator = this.paginator;
  }

  private displayText(page: number, pageSize: number, length: number): string {
    if (length == 0 || pageSize == 0) {
      return "Sem resultados";
    }

    return `Página: ${page + 1} de ${Math.ceil(length / pageSize)}`;
  }

  shouldDisableCheckbox(row: any): boolean {
    // A checkbox será desabilitada se a condição for verdadeira
    return this.disableCondition(row);
  }
  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.onAllRowsChange(false);
      return;
    }

    this.onAllRowsChange(true);
    this.selection.select(...this.dataSource.data);
  }

  public footerKeys(): string[] {
    if (!this.table.checkbox) {
      if (!this.table.footer.amount.display) {
        return this.keys;
      }

      return this.keys.filter((x) => x !== this.table.footer.amount.column);
    }

    return this.keys;
  }

  public footerValue(key: string): string {
    if (this.table.footer) {
      let footer = this.table.footer.columns.find((x) => x.column == key);
      let prefix = footer.prefix ? footer.prefix : "";
      let transform = footer.transform ? footer.transform : null;

      if (footer.operation == "sum") {
        let sum =
          prefix +
          " " +
          this.table.data
            .map((x) => parseFloat(x[key]))
            .reduce((partialSum, a) => {
              if (!Number.isNaN(a)) {
                return partialSum + a;
              }
              return partialSum;
            }, 0)
            .toFixed(2);

        if (transform) {
          return transform(prefix + " " + sum);
        }
        return prefix + " " + sum;
      }

      if (footer.operation == "quantity") {
        if (transform) {
          return transform(
            prefix + " " + footer.prefix + this.table.data.length
          );
        }
        return prefix + " " + footer.prefix + this.table.data.length;
      }
    }

    return "";
  }

  public showAmount(index: number): boolean {
    if (!this.table.checkbox) {
      if (index == 0) {
        return true;
      }
    }

    return false;
  }

  public showFooterData(key: string): boolean {
    if (this.table.footer.columns.map((x) => x.column).includes(key)) {
      return true;
    }

    return false;
  }

  public headerStyle(key: string): object {
    let style = this.table.style?.headers?.find((x) => x.key == key);

    if (!style?.styles) {
      return {};
    }

    return style.styles[0].class;
  }

  public dataStyle(key: string, element: any): object {
    let style = this.table.style?.data.find((x) => x.key == key);

    if (!style?.styles) {
      return {};
    }

    const rule = style.styles.find((x) => x.rule && x.rule(element[key]));

    if (rule) {
      return rule.class;
    }

    return style.styles[0].class;
  }

  public dataChildStyle(key: string, child: any): object {
    let style = this.table.style?.data.find((x) => x.key == key);

    if (!(style?.styles?.filter((x) => x.childClass).length > 0)) {
      return {};
    }

    const rule = style?.styles
      ?.filter((x) => x.childClass)
      .find((x) => x.rule && x.rule(child));

    if (rule) {
      return rule.class;
    }

    return style.styles[0].childClass.class;
  }

  public dataPrefix(key: string): string {
    let style = this.table.style?.data.find((x) => x.key == key);

    if (style) {
      return this.table.style.data.find((x) => x.key == key).prefix;
    }

    return "";
  }

  public dataTransform(key: string): Function {
    let style = this.table.style?.data.find((x) => x.key == key);

    if (style) {
      return this.table.style.data.find((x) => x.key == key).transform;
    }

    return null;
  }

  public render(value, key: string): string {
    if (value !== null && value !== "") {
      var transform = this.dataTransform(key);

      if (transform == null) {
        return value;
      }

      return transform(value);
    }

    return "-";
  }

  public isArray(value): boolean {
    return Array.isArray(value);
  }

  public isEditable(key: string): boolean {
    return this.table.style?.data?.find((x) => x.key == key)?.editable;
  }

  public align(key?: string): string {
    if (this.table.style?.table?.align) {
      if (
        key &&
        this.table.style.table.align.column.find((x) => x.key === key)
      ) {
        return this.table.style?.table?.align.column.find((x) => x.key === key)
          .align;
      }

      if (this.table.style.table.align.default) {
        return this.table.style?.table?.align.default;
      }
    }

    return "left";
  }

  public putCheckbox(): void {
    this.values.unshift("select");
    this.keys.unshift("select");
  }

  public putActions(): void {
    this.values.unshift("actions");
    this.keys.unshift("actions");
  }

  public putButtons(): void {
    this.values.unshift("buttons");
    this.keys.unshift("buttons");
  }

  public dataClass(disCol, element): string {
    return this.selection.isSelected(element)
      ? "active " + this.align(disCol)
      : this.align(disCol);
  }

  public onDataChange(event: any, row: any, key: string): void {
    this.emitDataChange.emit({ value: event.target.value, key, row });
  }

  public onRowChange(row: any, selected: boolean): void {
    this.emitSelectChanged.emit({ row, selected });
  }

  public onAllRowsChange(selected: boolean): void {
    this.emitSelectAllChanged.emit({ selected });
  }

  public onActionChange(event: object): void {
    this.emitActionChange.emit(event);
  }

  public onActionDelete(event: object): void {
    this.emitActionDelete.emit(event);
  }

  public onActionView(event: object): void {
    this.emitActionView.emit(event);
  }

  public onButtonExcelClicked(): void {
    this.emitButtonExcelClick.emit();
  }

  public handlePageEvent(evento): void {
    this.emitPaginatorChanged.emit({
      index: evento.pageIndex + 1,
      pageSize: evento.pageSize,
    });
  }

  preventSingleClick = false;
  timer: any;
  delay: Number;

  singleClick(event) {
    this.preventSingleClick = false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.preventSingleClick) {
        return;
      }
    }, delay);
  }

  doubleClick(event, row) {
    this.preventSingleClick = true;
    clearTimeout(this.timer);
    this.emitRowDoubleClick.emit(row);
  }

  public returnLine(row: any): number {
    return this.table.data.indexOf(row) + 1;
  }

  public getActionPermissionUpdate(): boolean {
    if (typeof this.table.actions !== "boolean") {
      return this.table.actions?.update;
    }

    return true;
  }

  public getActionPermissionDelete(): boolean {
    if (typeof this.table.actions !== "boolean") {
      return this.table.actions?.delete;
    }

    return true;
  }

  public verifyCustomButtons(): boolean {
    if (typeof this.table.actions !== "boolean") {
      return this.table.actions?.custom;
    }

    return false;
  }

  public getButtonPermissionDelete(): boolean {
    if (typeof this.table.buttons !== "boolean") {
      return this.table.buttons.delete;
    }

    return true;
  }

  public getButtonPermissionRead(): boolean {
    if (typeof this.table.buttons !== "boolean") {
      return this.table.buttons?.read;
    }

    return true;
  }

  public getActionPermission(): boolean {
    return !(
      this.getActionPermissionUpdate() || this.getActionPermissionDelete() || this.verifyCustomButtons()
    );
  }

  public getReadOnly(key: string): boolean {
    return this.table.style.data.find((x) => x.key == key).readonly;
  }

  public detectar_mobile(): boolean {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
