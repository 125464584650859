export const LOCAL_STORAGE_KEY_PERMISSIONS = "permissions"
export const LOCAL_STORAGE_KEY_BANNERS = "banners"
export const LOCAL_STORAGE_KEY_USER = "users"
export const LOCAL_STORAGE_KEY_CONTRACTS = "contracts";
export const LOCAL_STORAGE_KEY_POLICIES = "policies";
export const LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_BEFORE_CUT_DATE = "payment_days_before_cut_date";
export const LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_AFTER_CUT_DATE = "payment_days_after_cut_date";
export const LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_CUT_DATE = "payment_cut_date";

export const LOCAL_STORAGE_KEY_CONSORTIUM_REQUEST = "Consortium_Request";
export const LOCAL_STORAGE_KEY_DEALERS_DEBT_HANDLE = "debt_handle"