<span *ngIf="list.length > 0" class="form-field">

    <label> {{label}} </label>

    <mat-form-field *ngIf="chips" appearance="outline" 
                    (click)="$event.stopPropagation(); trigger.openPanel();"
                    data-cy="custom-autocomplete"
    >

        <mat-chip-grid #chipGrid>

            <mat-chip-row *ngFor="let item of selectedItems"
                          (removed)="remove(item)"
            >            
              {{getDisplay(item)}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            

            <input readonly
                   matInput
                   type="text" 
                   #trigger="matAutocompleteTrigger"  
                   [matAutocomplete]="auto" 
                   [matChipInputFor]="chipGrid"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   data-cy="custom-autocomplete-chip-values">
        </mat-chip-grid>

    </mat-form-field>

    <mat-form-field *ngIf="!chips" appearance="outline" (click)="$event.stopPropagation(); trigger.openPanel();">
        <input readonly
               matInput
               readonly
               type="text" 
               #trigger="matAutocompleteTrigger"
               [value]="displaySelectedItems()"        
               [matAutocomplete]="auto"
               [attr.data-cy]="returnDataCy('custom-autocomplete-values')">
    </mat-form-field>

    <mat-autocomplete class="density-lower" #autoName="matAutocomplete" panelOpen="true" #auto="matAutocomplete" hideSingleSelectionIndicator>

        <mat-form-field appearance="outline" class="density-mat-form-3">
           
            <mat-checkbox [checked]="allItemsSelected()" color="primary" class="check-all" (change)="toggleAll($event)">
                <input [attr.data-cy]="returnDataCy('custom-autocomplete-input')" type="text" matInput [formControl]="control">
            </mat-checkbox>
            
        </mat-form-field>

        <mat-option *ngFor="let item of filteredItems | async" (click)="toggleSelection(item, !itemSelected(item))"  [attr.data-cy]="returnDataCy('custom-autocomplete-options')">
            <mat-checkbox #checkboxes color="primary" [checked]="itemSelected(item)" (change)="toggleSelection(item, $event.checked)" (click)="$event.stopPropagation()">
                {{ getDisplay(item) }}
            </mat-checkbox>
        </mat-option>

        <mat-option *ngIf="noResults" data-cy="returnDataCy('custom-autocomplete-no-results')">
            Sem resultados
        </mat-option>

    </mat-autocomplete>
</span>

<span *ngIf="list.length == 0" class="form-field">
    <label> {{label}} </label>
    <mat-form-field appearance="outline">
        <input type="text"
               readonly
               [attr.data-cy]="returnDataCy('custom-autocomplete-values')"
               matInput>
    </mat-form-field>
</span>
