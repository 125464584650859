<div class="card">
    <div class="content">
        <ng-content select=".content"></ng-content>
    </div>

    <div *ngIf="isExpanded">
        <ng-content select=".expanded-content"></ng-content>
    </div>


    <div *ngIf="expansible" class="bottom">
        <div class="text" (click)="isExpanded = !isExpanded">
            <div *ngIf="!isExpanded" class="flex">
                <mat-icon>expand_more</mat-icon> <span> {{labelClosed}} </span>
            </div>
            <div *ngIf="isExpanded" class="flex">
                <mat-icon>expand_less</mat-icon> <span> {{labelOpened}} </span>
            </div>
        </div>
    </div>
</div>
