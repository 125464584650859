<app-modal class="density-lower" title="Nova Solicitação" data-cy="modal-bank-warranty-replacement"
  [disableButtonClose]="isLoadingButtonSend">
  <div class="modal-content density-mat-form-3">
    <span class="reason estricta uppercase" data-cy="modal-bank-warranty-replacement-data">{{ subject.product + " - " +
      subject.description }}</span>

    <section class="text">
      Para essa solicitação, efetue os seguintes passos:
      <br />
      1 - Faça o download do arquivo de modelo clicando em "Baixar arquivo
      modelo";<br />
      2 - Preencha o arquivo de modelo em seu computador e salve;<br />
      3 - Faça o upload dos arquivos preenchidos e dos demais arquivos
      necessários na sessão abaixo: "Upload de arquivos";<br />
      4 - Clique em "Enviar".
    </section>

    <app-button [datacy]="txtBtnDownload" [disabled]="isLoadingButtonDownload" [loading]="isLoadingButtonDownload"
      (onClick)="download()" minWidth="260px" color="primary">
      <div class="modal-content-button">
        <mat-icon>get_app</mat-icon>
        <span class="button-label">Baixar arquivo modelo</span>
      </div>
    </app-button>

    <div>
      <form (keydown.enter)="$event.preventDefault(); send()" [formGroup]="form">
        <div class="form-section">
          <div>
            <mat-label><span class="red">*</span> Relacionado a um sinistro?
            </mat-label>
            <div>
              <mat-radio-group formControlName="isSinistro">
                <mat-radio-button [value]="true" data-cy="SinisterRelated">Sim</mat-radio-button>
                <mat-radio-button [value]="false" data-cy="SinisterNotRelated">Não (Simples
                  substituição)</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div>
            <mat-label><span class="red">*</span> O bem é novo ou usado?</mat-label>
            <mat-radio-group formControlName="isNew">
              <mat-radio-button [value]="true" data-cy="PropertyNew">Novo</mat-radio-button>
              <mat-radio-button [value]="false" data-cy="UsedProperty">Usado</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </form>
    </div>
    <div class="files">
      <span class="reason estricta uppercase" data-cy="modal-bank-warranty-replacement-data">UPLOAD DE ARQUIVOS
      </span>
      <div *ngFor="let file of files">
        <div class="file-input" [ngStyle]="file.style">
          <span class="file-input-text">{{ file.text }}</span>

          <div [ngSwitch]="file.statusEnvio">
            <app-file-upload #fileComponent *ngSwitchCase="EnumStatusEnvioFileIncident.NAO_INICIADO"
              [fileTypes]="fileTypes" [display]="'button'" [sizeLimit]="5" [disabled]="isLoadingButtonSend"
              [loading]="isLoadingButtonDownload" (emitFileUploaded)="onFileUploaded(file.text, $event)"
              [customDataCy]="file.text + 'Input'">
            </app-file-upload>

            <div *ngSwitchCase="EnumStatusEnvioFileIncident.INICIADO">
              <span> Enviando </span> <mat-spinner diameter="20"></mat-spinner>
            </div>

            <div *ngSwitchCase="EnumStatusEnvioFileIncident.FINALIZADO">
              Enviado!
            </div>

            <div *ngSwitchCase="EnumStatusEnvioFileIncident.ERRO">
              Ocorreu um erro
            </div>
          </div>
        </div>
      </div>
      <div class="button-add-more">
        <button class="file-input" mat-button color="primary" [attr.data-cy]="'OptionalFileInput'"
          (click)="createNewFile(); openLastFileSelector()" [disabled]="qtdExtraFileAdded == qtdExtraFile">
          <mat-icon> cloud_upload </mat-icon>
          <span class="button-label">Anexar arquivo opcional</span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-bottom">
    <app-button width="100px" (onClick)="onClickBtnClose()" [disabled]="isLoadingButtonSend" [datacy]="btnCancelarTxt">
      <span class="button-label"> {{btnCancelarTxt}} </span>
    </app-button>

    <app-button [disabled]="isFilesIncomplete || isLoadingButtonSend" [loading]="isLoadingButtonSend" (onClick)="send()"
      width="100px" color="primary" [datacy]="btnEnviarTxt">
      <span class="button-label"> {{btnEnviarTxt}}</span>
    </app-button>
  </div>
</app-modal>