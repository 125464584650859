import { Component, Inject, inject } from '@angular/core';
import { ModalComponent } from '../../../../components/modal/modal.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Option } from 'src/app/models/option';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { delay, firstValueFrom } from 'rxjs';
import { ChangeUserCrmDynamics, UserCrmDynamics } from 'src/app/models/user/user';
import { IncidentService } from 'src/app/services/incident.service';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";

@Component({
  selector: "app-modal-bank-change-registration-data",
  templateUrl: "./modal-bank-change-registration-data.component.html",
  styleUrls: ["./modal-bank-change-registration-data.component.scss"],
})
export class ModalBankChangeRegistrationDataComponent extends ModalComponent<ModalBankChangeRegistrationDataComponent> {

  public currentUser: UserCrmDynamics;
  private dialog = inject(MatDialog);
  public subject: Option;
  public form: FormGroup;

  public showEmail: boolean = false;
  public showPhone: boolean = false;

  public isSubmitting: boolean = false;
  public isLoadingData: boolean = false;

  get requiredSecondaryPhone() {
    return (this.currentUser.secondaryDDD !== null && this.currentUser.secondaryDDD !== "") && 
           (this.currentUser.secondaryMobilePhone !== null && this.currentUser.secondaryMobilePhone !== "")
  }

  get requiredSecondaryEmail() {
    return this.currentUser.secondaryEmailAddress !== null && this.currentUser.secondaryEmailAddress !== ""
  }

  get isInvalid() {

    if(this.isSubmitting){
      return true;
    }

    if(this.form.status == 'INVALID') {
      return true;
    }

    if(!this.showEmail && !this.showPhone) {
      return true;
    }

    if(this.showEmail && this.showPhone) {
      if(this.form.get('newPrimaryEmail').value === this.currentUser.primaryEmailAddress &&
         this.form.get('newSecondaryEmail').value === this.currentUser.secondaryEmailAddress &&
         this.form.get('newPrimaryPhone').value === (this.currentUser.primaryDDD + this.currentUser.primaryMobilePhone) &&
         this.form.get('newSecondaryPhone').value === (this.currentUser.secondaryDDD + this.currentUser.secondaryMobilePhone)
      ){
        return true;
      } else {
        return false;
      }
    }

    if(this.showEmail){
      if(this.form.get('newPrimaryEmail').value === this.currentUser.primaryEmailAddress && this.form.get('newSecondaryEmail').value === this.currentUser.secondaryEmailAddress){
        return true;
      }
    }

    if(this.showPhone){
      if(this.form.get('newPrimaryPhone').value === (this.currentUser.primaryDDD + this.currentUser.primaryMobilePhone) && this.form.get('newSecondaryPhone').value === (this.currentUser.secondaryDDD + this.currentUser.secondaryMobilePhone)){
        return true;
      }
    }    

    return false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {subject: Option, documentNumber: string},
    private formBuilder: FormBuilder,
    private readonly incidentService: IncidentService
  ) {
    super();

    this.subject = data.subject;

    this.form = formBuilder.group({});

    this.getUser();
  }

  public override onSubmit(event?: any): void {
    if (this.isUnableToSendForm()) {
      return;
    }

    let form: ChangeUserCrmDynamics = {
      newPrimaryPhone: this.form.get("newPrimaryPhone")?.value?.slice(2) || this.currentUser?.primaryMobilePhone,
      newPrimaryDDD: this.form.get("newPrimaryPhone")?.value?.slice(0,2) || this.currentUser?.primaryDDD,
      newSecondaryPhone: this.form.get("newSecondaryPhone")?.value?.slice(2) || this.currentUser?.secondaryMobilePhone,
      newSecondaryDDD: this.form.get("newSecondaryPhone")?.value?.slice(0,2) || this.currentUser?.secondaryDDD,
      newPrimaryEmail: this.form.get("newPrimaryEmail")?.value || this.currentUser?.primaryEmailAddress,
      newSecondaryEmail: this.form.get("newSecondaryEmail")?.value || this.currentUser?.secondaryEmailAddress
    }

    this.isSubmitting = true;
    firstValueFrom(this.incidentService.updateRegistrationData(form))
      .then(() => {
        this.logFeature(EnumReportIncident.BANK_CHANGE_REGISTRATION_DATA, EnumProduct.BANK);
        this.dialogRef.close(true);
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  public isUnableToSendForm() {
    const shouldNotAllowToSendForm =
      this.form.invalid ||
      (!this.showEmail && !this.showPhone && this.form.valid) ||
      this.isSubmitting ||
      (this.showEmail &&
        !this.form.get("newPrimaryEmail").value &&
        !this.form.get("newSecondaryEmail").value);

    return shouldNotAllowToSendForm;
  }

  public toggleShowSection(event: MatCheckboxChange, section: string): void {
    let inputNames = this.getInputNamesBySection(section);

    if (event.checked) {
      switch (section) {
        case 'email':
          this.form.addControl(inputNames[0], new FormControl(this.currentUser.primaryEmailAddress, {
            validators: [
              Validators.email,
              Validators.required,
              Validators.maxLength(100),
            ]
          }));

          this.form.addControl(inputNames[1], new FormControl(this.currentUser.secondaryEmailAddress, {
            validators: this.returnSecondaryEmailValidators()
          }));
        break;

        case 'phone':
          this.form.addControl(inputNames[0], new FormControl(this.currentUser.primaryDDD + this.currentUser.primaryMobilePhone, {
            validators: [
              Validators.required,
              Validators.maxLength(11),
              Validators.minLength(10)
            ]
          }));

          this.form.addControl(inputNames[1], new FormControl(this.currentUser.secondaryDDD + this.currentUser.secondaryMobilePhone, {
            validators: this.returnSecondaryPhoneValidators()
          }));
      }
    } else {
      inputNames.forEach((name) => {
        this.form.removeControl(name);
      });
    }

    this.toggleCheckSection(section, event.checked);
  }

  public returnSecondaryPhoneValidators(): ValidatorFn[] {

    var validators = [
      Validators.maxLength(11),
      Validators.minLength(10)
    ]

    if(this.requiredSecondaryPhone)
    {
      validators.push(Validators.required)
    }

    return validators

  }

  public returnSecondaryEmailValidators(): ValidatorFn[] {

    var validators = [
      Validators.email,
      Validators.maxLength(100)
    ]

    if(this.requiredSecondaryEmail)
    {
      validators.push(Validators.required)
    }

    return validators

  }

  private getInputNamesBySection(section: string): string[] {
    switch (section) {
      case 'email':
        return ['newPrimaryEmail', 'newSecondaryEmail'];
      case 'phone':
        return ['newPrimaryPhone', 'newSecondaryPhone'];
    }

    throw new Error(`Could not get Names for Section ${section}`);
  }

  private toggleCheckSection(section: string, value: boolean) {
    switch (section) {
      case "email":
        this.showEmail = value;
        return;

      case "phone":
        this.showPhone = value;
        return;
    }
  }

  private async getUser() {
    this.isLoadingData = true;

    this.currentUser =  await firstValueFrom(this.incidentService.getUserCrmDynamicsByDocument(this.incidentService.getUser().documentNumber))
                              .finally(() => this.isLoadingData = false)


  }

  public async onClickBtnClose(): Promise<void> {
    if (this.form.dirty) {
      firstValueFrom(
        this.dialog
          .open(ModalConfirmComponent, {
            maxWidth: "900px",
            data: "Tem certeza que deseja cancelar?",
          })
          .afterClosed()
      ).then((resp: boolean) => (resp ? this.onClose(false) : null));
    } else {
      this.onClose(false);
    }
  }
}
