import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableComponent } from "./table/table.component";
import { MatIconModule } from "@angular/material/icon";
import { CarouselComponent } from "./carousel/carousel.component";
import { ButtonComponent } from "./button/button.component";
import { SectionComponent } from "./section/section.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { ModalComponent } from "./modal/modal.component";
import { SelectComponent } from "./select/select.component";
import { CardComponent } from "./card/card.component";
import { CardExpansibleComponent } from "./card-expansible/card-expansible.component";
import { ModalNotificationComponent } from "./modal/modal-notification/modal-notification.component";
import { ModalNewRequestComponent } from "./modal/modal-new-request/modal-new-request.component";
import { ModalErrorComponent } from "./modal/modal-error/modal-error.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatMenuModule } from "@angular/material/menu";
import { ModalConfirmComponent } from "./modal/modal-confirm/modal-confirm.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BarcodeComponent } from "./barcode/barcode.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { PipesModule } from "../pipes/pipes.module";
import { ModalSuccessComponent } from "./modal/modal-success/modal-success.component";
import { CookieConsentComponent } from "./cookie-consent/cookie-consent.component";
import { ModalLoadingComponent } from "./modal/modal-loading/modal-loading.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { DirectivesModule } from "../directives/directives.module";
import { NgxFileDropModule } from "ngx-file-drop";
import { ModalImageComponent } from "./modal/modal-image/modal-image.component";
import { UserInfoComponent } from "./user-info/user-info.component";
import { ModalMessageComponent } from "./modal/modal-message/modal-message.component";
import { ModalVideoComponent } from "./modal/modal-video/modal-video.component";
import { CustomInputsModule } from "../form/custom-inputs/custom-inputs.module";
import { UserProductsComponent } from './user-products/user-products.component';
import { VideoPlayerComponent } from "./video-player/video-player.component";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { InsuranceContractsComponent } from "../pages/insurance/insurance-contracts/insurance-contracts.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDividerModule } from "@angular/material/divider";
import { TableMobileComponent } from "./table-mobile/table-mobile.component";
import { InstallPwaComponent } from './install-pwa/install-pwa.component';
import { ElementReadyService } from "../helpers/elemente-ready";
import { MatCardModule } from '@angular/material/card';
import { TableMobileFinancialComponent } from "./table-mobile-financial/table-mobile-financial.component";
import { MatSortModule } from "@angular/material/sort";


@NgModule({
  declarations: [
    TableComponent,
    TableMobileComponent,
    TableMobileFinancialComponent,
    CardExpansibleComponent,
    CarouselComponent,
    ButtonComponent,
    SectionComponent,
    ModalComponent,
    SelectComponent,
    CardComponent,
    ModalNotificationComponent,
    ModalNewRequestComponent,
    ModalErrorComponent,
    ModalConfirmComponent,
    BarcodeComponent,
    ModalSuccessComponent,
    CookieConsentComponent,
    ModalLoadingComponent,
    FileUploadComponent,
    ModalImageComponent,
    UserInfoComponent,
    ModalMessageComponent,
      ModalVideoComponent,
    UserProductsComponent,
    VideoPlayerComponent,
    InstallPwaComponent,
    InsuranceContractsComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    MatExpansionModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatIconModule,
    ClipboardModule,
    FormsModule,
    MatTooltipModule,
    DirectivesModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    CustomInputsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatCardModule,
    MatSortModule
  ],
  exports: [
    TableComponent,
    TableMobileComponent,
    TableMobileFinancialComponent,
    CardExpansibleComponent,
    CarouselComponent,
    ButtonComponent,
    SectionComponent,
    ModalComponent,
    SelectComponent,
    CardComponent,
    ModalNewRequestComponent,
    BarcodeComponent,
    FileUploadComponent,
    CookieConsentComponent,
    UserInfoComponent,
    UserProductsComponent,
    InstallPwaComponent,
    InsuranceContractsComponent,
  ],
})
export class ComponentsModule {}
