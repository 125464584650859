import { parse } from "date-fns";
import { LOCAL_STORAGE_KEY_CONTRACTS, LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_CUT_DATE, LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_AFTER_CUT_DATE, LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_BEFORE_CUT_DATE } from "../const/localStorageKeys";
import { Parameter } from "../models/parameter/parameter";
import { Base } from "./base";
import { Contract } from "../models/bank/contract";

export class BasePayment extends Base {
  private acceptDueDaysAfterDueDate =
    parseInt(
      this.getLocalStorageItem<Parameter>(
        LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_AFTER_CUT_DATE
      ).value
    );

  private acceptDueDaysBeforeDueDate =
    parseInt(
      this.getLocalStorageItem<Parameter>(
        LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_BEFORE_CUT_DATE
      ).value
    );

  private cutDate = parse(this.getLocalStorageItem<Parameter>(
    LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_CUT_DATE
  ).value, 'dd/MM/yyyy', new Date());

  public contract = this.getLocalStorageItem<Contract>(
    LOCAL_STORAGE_KEY_CONTRACTS
  );

  get acceptDueDays(): number {
    let date = this.contract.initDate;
    if(date == undefined){
      date = this.contract[0].initDate;
    }
    if (new Date(date) > this.cutDate) {
      return this.acceptDueDaysAfterDueDate;
    } else {
      return this.acceptDueDaysBeforeDueDate;
    }
  }

  public dateDiffInDays(a: Date, b: Date): number {
    a.setHours(0, 0, 0, 0);
    b.setHours(0, 0, 0, 0);
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

}