import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Policies, Sinister } from '../models/insurance/policies';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService extends BaseService<any> {

  constructor() {
    super()
    this.path = "gateway/insurance"
  }
  
  public policies(): Observable<Policies> {
    return this.http.get<Policies>(`${this.api}${this.path}/policies`, {headers: this.addHeaders(true)})
  }

  public getSinisters(contract: string): Observable<Sinister[]> {
    return this.http.get<Sinister[]>(`${this.api}${this.path}/sinisters/${contract}`, {headers: this.addHeaders(true)})
  }

  public downloadInsuranceBill(contract: string): Observable<Blob> {
    return this.http.get(`${this.api}${this.path}/policies/${contract}/slip/export`, {headers: this.addHeaders(true),  responseType: 'blob', withCredentials: true})
    .pipe(
      map(response => {
        return new Blob([response], { type: 'application/pdf' });
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => 'Ocorreu um erro ao tentar recuperar boleto da apólice. O serviço pode estar indisponível.');
      })
    );
  }
  
  public downloadPolicy(contract: string): Observable<Blob> {
    return this.http.get(`${this.api}${this.path}/policies/${contract}/document/export`, {headers: this.addHeaders(true), responseType: 'blob', withCredentials: true})
      .pipe(
        map(response => {
          return new Blob([response], { type: 'application/pdf' });
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => 'Ocorreu um erro ao tentar recuperar a apólice. O serviço pode estar indisponível.');
        })
      );
    
  }
}
