import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Subscription, firstValueFrom, switchMap } from "rxjs";
import { BaseComponent } from "src/app/@base/base.component";
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";
import { Contract } from "src/app/models/bank/contract";
import { Statement } from "src/app/models/bank/statement";
import { Table } from "src/app/models/table";
import { BankService } from "src/app/services/bank.service";

@Component({
  selector: "app-statement",
  templateUrl: "./statement.component.html",
  styleUrls: ["./statement.component.scss"],
})
export class StatementComponent extends BaseComponent implements OnInit {
  public $subscriptionContract: Subscription;

  public txtBtnDownload: string = "Baixar";
  public table: Table<Statement> = this.createTable();
  public contract: Contract;
  public rowsSelected: Statement[];
  public loading: boolean = false;
  public loadingDownload: boolean = false;
  totalOriginalValue: number;
  totalPaidValue: number;

  constructor(private bankService: BankService, private datepipe: DatePipe) {
    super();
  }

  ngOnInit(): void {
    this.createTable();
    this.subscriptionChangeContract();
    this.logFeature(EnumReportIncident.BANK_STATEMENT, EnumProduct.BANK);
  }

  ngOnDestroy(): void {
    this.$subscriptionContract.unsubscribe();
  }

  private subscriptionChangeContract(): void {
    this.$subscriptionContract =
      this.bankService.emitContractSelected.subscribe((contract: Contract) => {
        this.table.data = [];
        this.contract = contract;
        this.loading = true;
        this.getAllPayment();
      });
  }

  private getAllPayment(): void {
    firstValueFrom(
      this.bankService.statement(this.contract.contractNumber, true)
    )
      .then((resp: Statement[]) => this.updateTable(resp))
      .then(() => {
        this.table = this.table;
        this.totalOriginalValue = this.table.data.reduce(
          (sum, row) => sum + parseFloat(row.originalValue),
          0
        );
        this.totalPaidValue = this.table.data
          .filter((x) => x.paidValue != null)
          .reduce((sum, row) => sum + parseFloat(row.paidValue), 0);
      })
      .finally(() => (this.loading = false));
  }

  private createTable(): Table<Statement> {
    return {
      headers: [
        {
          key: "instalmentNumber",
          value: "Parcela",
        },
        {
          key: "dueDate",
          value: "Vencimento",
        },
        {
          key: "originalValue",
          value: "Valor Original",
        },
        {
          key: "paidValue",
          value: "Valor Pago",
        },
        {
          key: "paymentDate",
          value: "Pagamento",
        },
        {
          key: "status",
          value: "Status",
        },
      ],
      data: [],
      checkbox: false,
      footer: {
        amount: {
          display: true,
          description: "Total",
          column: "total",
        },
        columns: [
          {
            column: "paidValue",
            operation: "sum",
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            column: "originalValue",
            operation: "sum",
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
        ],
      },
      style: {
        headers: [
          {
            key: "buttons",
            styles: [
              {
                class: { width: "20px" },
              },
            ],
          },
        ],
        data: [
          {
            key: "originalValue",
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            key: "paidValue",
            transform: (value: string) =>
              parseFloat(value).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            key: "status",
            styles: [
              {
                class: { color: "#000", fontWeight: 500 },
                rule: (x) => x === "Em aberto",
              },
              {
                class: { color: "#f5222d", fontWeight: 500 },
                rule: (x) => x === "Em atraso",
              },
              {
                class: { color: "#52c41a", fontWeight: 500 },
                rule: (x) => x === "Liquidado",
              },
            ],
          },
          {
            key: "paymentDate",
            transform: (value) => this.datepipe.transform(value, "dd/MM/yyyy"),
          },
          {
            key: "dueDate",
            transform: (value) => this.datepipe.transform(value, "dd/MM/yyyy"),
          },
        ],
        table: {
          height: "400px",
          align: {
            column: [
              {
                key: "instalmentNumber",
                align: "right",
              },
              {
                key: "dueDate",
                align: "right",
              },
              {
                key: "dueDate",
                align: "right",
              },
              {
                key: "paidValue",
                align: "right",
              },
              {
                key: "originalValue",
                align: "right",
              },
              {
                key: "paymentDate",
                align: "right",
              },
            ],
          },
        },
      },
    };
  }

  private updateTable(resp: Statement[]): void {
    this.table.data = resp;
    this.rowsSelected = [];
  }

  public downloadStatement() {
    this.loadingDownload = true;

    firstValueFrom(this.bankService.statementPdf(this.contract.contractNumber))
      .then((resp: Blob) => {
        this.download(
          resp,
          `Extrato_Financeiro_Yamaha_${this.contract.contractNumber}.pdf`,
          "pdf"
        );
        this.logFeature(
          EnumReportIncident.BANK_DOWNLOAD_STATEMENT,
          EnumProduct.BANK
        );
      })
      .finally(() => (this.loadingDownload = false));
  }
}
