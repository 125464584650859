import { AfterContentChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseComponent } from '../@base/base.component';
import { LOCAL_STORAGE_KEY_BANNERS } from '../const/localStorageKeys';
import { PageBanner } from '../models/banner/pageBanner';
import { AuthService } from '../services/auth.service';
import { COOKIE_MESSAGE } from '../const/cookiesKeys';
import { Notification } from 'src/app/models/notification/notification';
import { NotificationService } from '../services/notification.service';
import { Base } from '../@base/base';
import { BannerService } from '../services/banner.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends BaseComponent implements OnInit, AfterViewInit {

  public banner: PageBanner;
  public notifications: Notification[] = []
  
  constructor(
    private notificationService: NotificationService,
    private bannerService: BannerService
  ){
    super();
  }
  
  ngOnInit(): void {

    this.banner = this.getLocalStorageItem<PageBanner[]>(LOCAL_STORAGE_KEY_BANNERS).find(x => x.page == "login")

    this.bannerService.bannersSubscribe$.subscribe(() => {
      this.banner = this.getLocalStorageItem<PageBanner[]>(LOCAL_STORAGE_KEY_BANNERS).find(x => x.page == "login")
    })    
    
  }

  ngAfterViewInit(): void {
    if(!Base.appStarted){
      this.showPreLoginNotifications();
    }

    Base.appStarted = true
  }

  public showBanner(): boolean {
    if(this.banner?.images && this.banner?.images.length > 0){
      return true;
    }

    return false;
  }

  public async showPreLoginNotifications(): Promise<void> {
    var notifications: Notification[] = [];
    let idsRead: number[] = this.getCookieItem<number[]>(COOKIE_MESSAGE) || [];

    await this.getNotifications();

    if(idsRead && idsRead.length > 0){
      notifications = this.notifications.filter(x => !this.getCookieItem<number[]>(COOKIE_MESSAGE).includes(x.id));
    } else {
      notifications = this.notifications;
    }

    for(let i = 0; i < notifications.length; i++){    
      await firstValueFrom(this.openDialogNotification(notifications[i]).afterClosed())
        .then(() => {
          idsRead.push(notifications[i].id)
          this.setCookieItem(COOKIE_MESSAGE, idsRead)
        })
    }
  }

  public async getNotifications(): Promise<void> {
    await firstValueFrom(this.notificationService.getAll<Notification>("/pre-signin"))
      .then((x => this.notifications = x))
  }
 
}
