import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { RecaptchaComponent } from "ng-recaptcha";
import { Observable, Subscription, firstValueFrom, map, take } from "rxjs";
import { BaseComponent } from "src/app/@base/base.component";
import { ModalConfirmComponent } from "src/app/components/modal/modal-confirm/modal-confirm.component";
import { ModalLoadingComponent } from "src/app/components/modal/modal-loading/modal-loading.component";
import {
  LOCAL_STORAGE_KEY_CONTRACTS,
  LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_CUT_DATE,
  LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_AFTER_CUT_DATE,
  LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_BEFORE_CUT_DATE,
  LOCAL_STORAGE_KEY_PERMISSIONS,
  LOCAL_STORAGE_KEY_POLICIES,
  LOCAL_STORAGE_KEY_USER,
} from "src/app/const/localStorageKeys";
import {
  DATA_CORTE_GERACAO_BOLETO,
  LOGIN_FACEBOOK,
  LOGIN_GOOGLE,
  QTD_DIAS_GERACAO_BOLETO_ATE_DATA_CORTE,
  QTD_DIAS_GERACAO_BOLETO_POSTERIOR_DATA_CORTE,
} from "src/app/const/parameters";
import { PERMITION_BANK, PERMITION_DEALERS, PERMITION_INSURANCE } from "src/app/const/permitions";
import { EnumPath } from "src/app/enum/path";
import { EnumProduct } from "src/app/enum/products";
import { Contract } from "src/app/models/bank/contract";
import { Parameter } from "src/app/models/parameter/parameter";
import { CurrentUser } from "src/app/models/user/user";
import { BankService } from "src/app/services/bank.service";
import { ParametersService } from "src/app/services/parameters.service";
import { Auth, CheckDateExpire } from "../../models/auth/auth";
import { ModalSuccessComponent } from "src/app/components/modal/modal-success/modal-success.component";
import {
  FacebookLoginProvider,
  SocialAuthService,
} from "@abacritt/angularx-social-login";
import { InsuranceService } from "src/app/services/insurance.service";
import { Policies } from "src/app/models/insurance/policies";
import { EnumReportIncident } from "src/app/enum/report-incident";
import { WebAuthnService } from "src/app/services/web-authn.service";
import { PwaServiceService } from "src/app/services/pwa-service.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("recaptcha") captchaElem: RecaptchaComponent;

  public $subscriptionCaptcha: Subscription;
  public $subscriptionLoginGoogle: Subscription;

  public form: FormGroup;

  public username: string = "";
  public loading: boolean = false;
  public hidePassword: boolean = true;
  public recaptchaResolved: boolean = false;
  public facebookLoginEnabled = false;
  public googleLoginEnabled = false;
  public modalLoading: MatDialogRef<ModalLoadingComponent>;
  public loginType: string = "";
  public hasBiometric: boolean = this.webAuthN.hasBiometric();

  constructor(
    private bankService: BankService,
    private parametersService: ParametersService,
    private formBuilder: FormBuilder,
    private socialAuthService: SocialAuthService,
    private insuranceService: InsuranceService,
    private webAuthN: WebAuthnService,
    public pwaService: PwaServiceService
  ) {
    super();
  }

  public detectar_mobile() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    if (this.webAuthN.hasBiometric()) {
      this.onSumitBio();
    }
    this.createForm();
    this.socialAuthService.signOut();
    this.getSocialLoginParameters();
  }

  ngAfterViewInit(): void {
    this.subscriptionChangeCaptcha();
  }

  ngOnDestroy(): void {
    this.unsubscriptionCaptcha();
    this.unsubscriptionLoginGoogle();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  private subscriptionChangeCaptcha(): void {
    this.$subscriptionCaptcha = this.captchaElem.resolved.subscribe((x) => {
      if (x === null) {
        this.recaptchaResolved = false;
      } else {
        this.recaptchaResolved = true;
      }
    });
  }

  private unsubscriptionCaptcha(): void {
    this.$subscriptionCaptcha.unsubscribe();
  }
  private unsubscriptionLoginGoogle(): void {
    this.$subscriptionLoginGoogle?.unsubscribe();
  }

  private async requestAllContracts(): Promise<void> {
    await firstValueFrom(this.bankService.getAll<Contract>("", true)).then(
      (contracts: Contract[]) => {
        this.setLocalStorageItem(
          LOCAL_STORAGE_KEY_CONTRACTS,
          JSON.stringify(contracts)
        );
        this.bankService.emitContractsLoaded.next(contracts);
      }
    );
  }

  private async requestBankParameters(): Promise<void> {
    await firstValueFrom(
      this.parametersService.get(QTD_DIAS_GERACAO_BOLETO_ATE_DATA_CORTE)
    ).then((x: Parameter) =>
      this.setLocalStorageItem(
        LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_BEFORE_CUT_DATE,
        JSON.stringify(x)
      )
    );
    await firstValueFrom(this.parametersService.get(DATA_CORTE_GERACAO_BOLETO))
    .then((x: Parameter) =>
      this.setLocalStorageItem(
        LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_CUT_DATE,
        JSON.stringify(x)
      )
    );
    await firstValueFrom(this.parametersService.get(QTD_DIAS_GERACAO_BOLETO_POSTERIOR_DATA_CORTE))
    .then((x: Parameter) =>
      this.setLocalStorageItem(
        LOCAL_STORAGE_KEY_PARAMETER_BANK_PAYMENT_DAYS_AFTER_CUT_DATE,
        JSON.stringify(x)
      )
    );
  }

  public getSocialLoginParameters() {
    firstValueFrom(this.parametersService.get(LOGIN_FACEBOOK)).then(
      (param: Parameter) => (this.facebookLoginEnabled = param.value == "1")
    );

    firstValueFrom(this.parametersService.get(LOGIN_GOOGLE)).then(
      (param: Parameter) => {
        this.googleLoginEnabled = param.value == "1";
        if (this.googleLoginEnabled) {
          this.$subscriptionLoginGoogle =
            this.socialAuthService.authState.subscribe((user) => {
              if (user && user.provider == "GOOGLE") {
                this.loginType = "GOOGLE";
                this.login(null, null, user.idToken);
              }
            });
        }
      }
    );
  }

  public async requestGetPermissions(): Promise<void> {
    await firstValueFrom(this.authService.getCurrentUser())
      .then((resp: CurrentUser) => {
        this.setLocalStorageItem(
          LOCAL_STORAGE_KEY_PERMISSIONS,
          resp.permissions
        );
        this.setLocalStorageItem(LOCAL_STORAGE_KEY_USER, resp);

        return resp;
      })
      .then((resp: CurrentUser) => {
        this.setPermissions(resp.permissions);
        this.setUser(resp);
      });
  }

  public async login(
    username: string,
    facebookAccessToken?: string,
    googleAccessToken?: string
  ): Promise<void> {
    let auth: Auth = {
      ...this.form.value,
      username: this.documentFormated(this.form.get("username").value),
      facebookAccessToken,
      googleAccessToken,
    };

    await this.loginBase(await this.authService.login(auth));
  }

  public async loginWebAuthN(): Promise<void> {
    //console.log(this.webAuthN.authenticate());
    await this.loginBase(await this.webAuthN.authenticate());
  }

  private async loginBase(request: Observable<any>): Promise<void> {
    this.loading = true;
    await firstValueFrom(request)
      .then(async (res: any) => {
        if (res.hasOtherSession) {
          const confirmed = await firstValueFrom(
            this.openModalOtherSession().afterClosed()
          );

          if (!confirmed) {
            this.authService.newLogout().subscribe(() => {
              this.loading = false;
            });
            return;
          }
          await firstValueFrom(this.authService.invalidate());
        }

        if (this.detectar_mobile() && !this.webAuthN.hasBiometric()) {
          const response = await firstValueFrom(
            this.dialog
              .open(ModalConfirmComponent, {
                maxWidth: "600px",
                data: "Agora você pode configurar a sua biometria para acessar no próximo login. Deseja configurar agora?",
              })
              .afterClosed()
          );

          if (response) {
            try {
              const registerResponse = await firstValueFrom(
                this.webAuthN.register(this.form.value)
              );
              //console.log('Registration successful', registerResponse);
            } catch (error) {
              this.openDialogError(
                "Ocorreu um erro ao registrar, tente novamente no seu próximo acesso!",
                "Não foi possível entrar"
              );
              //console.error('Registration failed', error);
            }
          }
        }

        if (this.form.value.username && this.form.value.password) {
          let auth: Auth = {
            ...this.form.value,
            username: this.documentFormated(this.form.get("username").value),
          };
          await this.getDaysToExpirePassword(auth);
        }

        await firstValueFrom(this.authService.createCache());
        await this.requestGetPermissions();
      })
      .then(() => {
        this.openModalLoadingIfHasAnyContract();

        if (
          this.getPermissions().includes(PERMITION_BANK) &&
          this.getUser().products.includes(EnumProduct.BANK)
        ) {
          this.requestAllContracts();
          this.requestBankParameters();
        }
        // if(this.getPermissions().includes(PERMITION_INSURANCE) && this.getUser().products.includes(EnumProduct.INSURANCE)) {
        //   this.requestAllPolicies();
        // }
      })
      .then(() => {
        if(this.getUser().profiles.some(p => p.dealer))
          this.router.navigate([EnumPath.DEALERS]);
        else 
          this.router.navigate([EnumPath.HOME]);
      })
      .catch(async (error: HttpErrorResponse) => {
        if (error.error.message === "Password expired") {
          this.authService.document = this.form.value.username;
          await this.authService
            .GetUserEmail(this.form.value.username)
            .pipe(take(1)) //this will limit the observable to only one value
            .subscribe((res: any) => {
              this.router.navigate(
                [EnumPath.EXPIRED_PASSWORD_MESSAGE, "false"],
                { state: { email: res.email } }
              );
            });
        } else if (error.error.message === "Password expired intern") {
          this.authService.document = this.form.value.username;
          this.router.navigate([EnumPath.EXPIRED_PASSWORD_MESSAGE, "true"]);
        } else if (error.status !== 500) {
          this.openDialogError(error, "Não foi possível entrar");
        }
      })
      .finally(() => {
        this.closeModalLoading();
        this.loading = false;
        this.captchaElem.reset();
        this.recaptchaResolved = false;
      });
  }

  public onSubmit(): void {
    if (!this.form.valid || this.loading || !this.recaptchaResolved) return;
    this.loginType = "NORMAL";
    this.login(this.form.get("username").value);
  }

  public async onSumitBio() {
    await this.loginWebAuthN();
  }

  public getDaysToExpirePassword(auth: Auth): void {
    firstValueFrom(this.authService.getDaysToExpirePassword(auth)).then(
      (resp: CheckDateExpire) => this.showModalDaysToExpirePassword(resp)
    );
  }

  public showModalDaysToExpirePassword(checkDateExpire: CheckDateExpire): void {
    if (checkDateExpire.shouldShowMessage) {
      firstValueFrom(
        this.dialog
          .open(ModalSuccessComponent, {
            maxWidth: "900px",
            data: {
              message:
                "Sua senha vence em " +
                checkDateExpire.daysToExpire +
                " dias.\r\n Após este prazo, a troca de senha será obrigatória para entrar no sistema.",
              title: "Aviso de Expiração de Senha",
            },
          })
          .afterClosed()
      );
    }
  }

  private openModalLoadingIfHasAnyContract(): void {
    if (
      (this.getPermissions().includes(PERMITION_BANK) &&
        this.getUser().products.includes(EnumProduct.BANK)) ||
      (this.getPermissions().includes(PERMITION_INSURANCE) &&
        this.getUser().products.includes(EnumProduct.INSURANCE))
    ) {
      this.modalLoading = this.openDialogLoading("");
    }
  }

  private closeModalLoading(): void {
    if (this.modalLoading !== undefined && this.modalLoading !== null)
      this.modalLoading.close();
  }

  public documentFormated(document: string): string {
    if (
      /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/.test(
        document
      )
    ) {
      document = document
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", "");
    }
    return document;
  }

  public loginWithFacebook() {
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((socialUser) => {
        this.loginType = "FACEBOOK";
        this.login(null, socialUser.authToken);
      });
  }
  private openModalOtherSession(): MatDialogRef<ModalConfirmComponent> {
    return this.dialog.open(ModalConfirmComponent, {
      maxWidth: "900px",
      data: {
        message:
          "Detectamos que existe um outro acesso ativo no momento. \r\nAo confirmar sua entrada, os acessos anteriores serão encerrados.\r\nDeseja prosseguir?",
        title: "Confirmação de Login",
      },
    });
  }

  public OpenLearnMore() {
    this.openInNewTab(EnumPath.HOME_LEARN_MORE);
  }
}
