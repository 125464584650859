import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { ProductModule } from './product/product.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { InsuranceContractsComponent } from '../insurance/insurance-contracts/insurance-contracts.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    ProductModule,
    ComponentsModule,
    DirectivesModule,
    MatProgressSpinnerModule,    
  ], 
  exports: [
    HomeComponent
  ]
})
export class HomeModule { }
