import { Component } from '@angular/core';
import { firstValueFrom, of, switchMap } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { NotificationService } from 'src/app/services/notification.service';
import { Notification } from 'src/app/models/notification/notification';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends BaseComponent{

  public qtdUnRead: number;

  public notifications: Notification[] = []

  constructor(
    private notificationService: NotificationService
  ){
    super()

    this.getNotifications();
  }


  public async getNotifications(): Promise<void> {
    await firstValueFrom(this.notificationService.getAll<Notification>("/current"))
      .then((x => this.notifications = x))
      .then(() => this.qtdUnRead = this.notifications.filter(x => !x.read).length)
      .then(() => this.openAutomaticaly())
  }

  public async openNotification(id: number): Promise<void> {

    let notification: Notification;

    await firstValueFrom(this.notificationService.get<Notification>(id))
      .then(x => notification = x)

    await firstValueFrom(
      this.openDialogNotification(notification)
      .afterClosed()
      .pipe(
        switchMap(x => {
          if(!notification.read){
            return this.notificationService.readMessage(notification.id, "66270441021")
          }
          return of(null)
        })
      )
    )
    .then(() => this.notifications[this.notifications.findIndex(x => x.id == notification.id)].read = true)
    .then(() => this.qtdUnRead = this.notifications.filter(x => !x.read).length)
  }

  public async openAutomaticaly(): Promise<void> {
    while(this.notifications.find(x => x.showOnSignIn && !x.read)){
      await this.openNotification(this.notifications.find(x => x.showOnSignIn && !x.read).id)
    }
  }
}
