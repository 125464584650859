import { Component, Inject } from '@angular/core';
import { ModalComponent } from '../modal.component';
import { Error, ErrorMessage } from 'src/app/models/error';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent extends ModalComponent<ModalErrorComponent>{

  public messageTitle: string;
  public error: string | string[];
  public txtBtnClose: string = "Ok";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string | ErrorMessage,
  ) {
    super()

    if(data != null){

      if(typeof data == "string") {
        this.error = data
      } else {
        this.error = data.message
        this.messageTitle = data.title
      }  
    }
  }

  public isArray(messages: string | string[]): boolean {
    return Array.isArray(messages);
  }

}
