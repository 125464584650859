import { Component, OnInit } from "@angular/core";
import { BaseComponent, EProduct } from "src/app/@base/base.component";
import { ModalNewRequestComponent } from "src/app/components/modal/modal-new-request/modal-new-request.component";
import { SectionModeTypes } from "src/app/components/section/section.component";
import {
  OPTIONS_INSURANCE,
  OPTIONS_REQUEST_INSURANCE,
} from "src/app/const/options";
import { EnumIncidentProduct } from "src/app/enum/incident";
import { Incident } from "src/app/models/incident/incident";
import { Option } from "src/app/models/option";
import { Table } from "src/app/models/table";
import { IncidentService } from "src/app/services/incident.service";
import { DatePipe } from "@angular/common";
import { firstValueFrom } from "rxjs";
import { InsuranceService } from "src/app/services/insurance.service";
import { EnumInsuranceOptions } from "src/app/enum/options";
import { ModalSituationSinisterComponent } from "./modal-situation-sinister/modal-situation-sinister.component";
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";
import { Policies, Sinister } from "src/app/models/insurance/policies";
import { LOCAL_STORAGE_KEY_POLICIES } from "src/app/const/localStorageKeys";
import { Select, SelectOption } from "src/app/models/select";
import { HttpErrorResponse } from "@angular/common/http";
import { EnumPath } from "src/app/enum/path";

@Component({
  selector: "app-insurance",
  templateUrl: "./insurance.component.html",
  styleUrls: ["./insurance.component.scss"],
})
export class InsuranceComponent extends BaseComponent implements OnInit {
  public insuranceRequestOptions: Option[] = OPTIONS_REQUEST_INSURANCE;
  public insuranceOptions: Option[] = OPTIONS_INSURANCE;
  public readonly product = EProduct.Insurance;

  public sectionMode: SectionModeTypes = "full";

  public policies: Policies;
  private policieChosed: number;
  private incidents: Incident[] = [];
  public hasLoadedIncidents = false;
  public isLoadingSuhai: boolean = false;

  public buttonDownloadInsuranceBillLoading: boolean = false;
  public buttonDownloadInsurancePolicyLoading: boolean = false;
  public buttonGetSinisterLoading: boolean = false;

  get showIfAnyOption() {
    return (
      this.insuranceOptions.length > 0 && this.policies?.policies?.length > 0
    );
  }

  constructor(
    private incidentsService: IncidentService,
    private insuranceService: InsuranceService,
    private datepipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    if(this.isClientOf(this.product)){
      this.getPolicies();
      if(!this.showIfAnyOption){
        this.requestAllPolicies();
      }
      this.getIncidents();
    }
  }

  private requestAllPolicies() {
    this.isLoadingSuhai = true;
    firstValueFrom(this.insuranceService.policies())
      .then((resp: Policies) => {
        this.setLocalStorageItem(
          LOCAL_STORAGE_KEY_POLICIES,
          JSON.stringify(resp)
        );
      
        this.getPolicies();
      })
      .catch((error: HttpErrorResponse) => {
        this.openDialogError(error.error.message ? error.error.message : error);
      })
      .finally(() => (this.isLoadingSuhai = false));
  }

  private getPolicies(): void {
    if (this.getLocalStorageItem(LOCAL_STORAGE_KEY_POLICIES) === null) {
      return;
    }

    if (
      typeof this.getLocalStorageItem(LOCAL_STORAGE_KEY_POLICIES) === "string"
    ) {
      this.openDialogMessage(
        this.getLocalStorageItem<string>(LOCAL_STORAGE_KEY_POLICIES)
      );
      return;
    }

    this.policies = this.getLocalStorageItem<Policies>(
      LOCAL_STORAGE_KEY_POLICIES
    );
    this.policieChosed = this.policies.policies[0];
  }

  private getIncidents(): void {
    this.hasLoadedIncidents = false;
    firstValueFrom(
      this.incidentsService.getIncidentsByProduct(EnumIncidentProduct.INSURANCE)
    )
      .then((incidents) => (this.incidents = incidents))
      .finally(() => (this.hasLoadedIncidents = true));
  }

  public onContractChanged(evento: SelectOption<number>): void {
    this.policieChosed = evento.t;
  }

  public getTable(): Table<any> {
    return {
      headers: [
        {
          key: "incidentNumber",
          value: "N° da Solicitação",
        },
        {
          key: "openingDate",
          value: "Data de abertura",
        },
        {
          key: "product",
          value: "Segmento",
        },
        {
          key: "subject",
          value: "Tipo",
        },
        {
          key: "status",
          value: "Status",
        },
      ],
      data: this.incidents,
      style: {
        table: {
          height: "500px",
        },
        data: [
          {
            key: "status",
            styles: [
              {
                class: { color: "#fa8c16", fontWeight: 500 },
                rule: (x) => x === "Em andamento",
              },
              {
                class: { color: "#52c41a", fontWeight: 500 },
                rule: (x) => x === "Resolvido",
              },
            ],
          },
          {
            key: "openingDate",
            transform: (value) =>
              this.datepipe.transform(value, "dd/MM/yyyy HH:mm"),
          },
        ],
      },
      checkbox: false,
      loading: !this.hasLoadedIncidents,
    };
  }

  public onClickInsuranceOptionButton(option: Option): void {
    switch (option.description) {
      case EnumInsuranceOptions.SINISTER:
        this.openModalSituationSinister();
        return;
      case EnumInsuranceOptions.EMIT_BILL:
        this.downloadBill();
        return;
      case EnumInsuranceOptions.EMIT_POLICY:
        this.downloadPolicy();
        return;
    }
  }

  private downloadBill(): void {
    this.buttonDownloadInsuranceBillLoading = true;

    firstValueFrom(
      this.insuranceService.downloadInsuranceBill(this.policieChosed + "")
    )
      .then((resp: Blob) => this.download(resp, "BoletoSuhai.pdf", "pdf"))
      .catch((error: HttpErrorResponse) => this.openDialogError(error))
      .finally(() => {
        this.buttonDownloadInsuranceBillLoading = false;
        this.logFeature(
          EnumReportIncident.INSURANCE_EMIT_BILL,
          EnumProduct.INSURANCE
        );
      });
  }

  private downloadPolicy(): void {
    this.buttonDownloadInsurancePolicyLoading = true;

    firstValueFrom(
      this.insuranceService.downloadPolicy(this.policieChosed + "")
    )
      .then((resp: Blob) => this.download(resp, "ApoliceSuhai.pdf", "pdf"))
      .catch((error: HttpErrorResponse) => this.openDialogError(error))
      .finally(() => {
        this.buttonDownloadInsurancePolicyLoading = false;
        this.logFeature(
          EnumReportIncident.INSURANCE_EMIT_POLICY,
          EnumProduct.INSURANCE
        );
      });
  }

  private async openModalSituationSinister(): Promise<void> {
    this.buttonGetSinisterLoading = true;

    await firstValueFrom(
      this.insuranceService.getSinisters(this.policieChosed + "")
    )
      .then((resp: Sinister[]) => {
        this.dialog.open(ModalSituationSinisterComponent, {
          data: resp,
          width: "800px",
        });
        this.logFeature(
          EnumReportIncident.INSURANCE_SINISTER,
          EnumProduct.INSURANCE
        );
      })
      .catch((error: HttpErrorResponse) =>
        this.openDialogError(
          error.error["message"] ? error.error["message"] : error.message
        )
      )
      .finally(() => (this.buttonGetSinisterLoading = false));
  }

  public openModalRequest(subject: Option): void {
    const request = this.dialog.open(ModalNewRequestComponent, {
      width: "90vw",
      height: "80vh",
      maxWidth: "800px",
      maxHeight: "440px",
      data: subject,
    });

    request.afterClosed().subscribe({
      next: (reload) => {
        if (reload) {
          this.openDialogSucess({
            title: "Registramos sua solicitação.",
            message: "Em breve ela será processada e será incluída no sistema.",
          });
          this.getIncidents();
        }
      },
    });
  }

  public getTooltip(option: Option): string {
    return "Clique aqui para fazer a solicitação de " + option.description;
  }

  public goToInsuranceExternalLink() {
    this.openInNewTab(EnumPath.INSTITUTIONAL_INSURANCE);
  }

  public buttonOptionLoading(option: Option): boolean {
    switch (option.description) {
      case EnumInsuranceOptions.EMIT_BILL:
        return this.buttonDownloadInsuranceBillLoading;
      case EnumInsuranceOptions.EMIT_POLICY:
        return this.buttonDownloadInsurancePolicyLoading;
      case EnumInsuranceOptions.SINISTER:
        return this.buttonGetSinisterLoading;
      default:
        return false;
    }
  }
}
