import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElementReadyService {
  elementReady$ = new Subject<void>();

  elementReady() {
    this.elementReady$.next();
  }
}