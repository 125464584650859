import { Component, Inject, QueryList, ViewChildren } from "@angular/core";
import { ModalComponent } from "../../../../components/modal/modal.component";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Option } from "src/app/models/option";
import { IncidentService } from "src/app/services/incident.service";
import { firstValueFrom } from "rxjs";
import {
  AttachFileToIncidentRequest,
  FileIncident,
  IncidentRequest,
  IncidentResponse,
} from "src/app/models/incident/incident";
import { FileRequest } from "src/app/models/settings/settings";
import { BankService } from "src/app/services/bank.service";
import { EnumStatusEnvioFileIncident } from "src/app/enum/incident";
import { FileUploadComponent } from "src/app/components/file-upload/file-upload.component";
import { LogService } from "src/app/services/log.service";
import { CreateFeatureLogRequest } from "src/app/models/Log/CreateFeatureLog";
import { HttpErrorResponse } from "@angular/common/http";
import { ModalConfirmComponent } from "src/app/components/modal/modal-confirm/modal-confirm.component";
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";

const QTD_EXTRA_FILE: number = 10;

@Component({
  selector: "app-modal-rights-assignment",
  templateUrl: "./modal-rights-assignment.component.html",
  styleUrls: ["./modal-rights-assignment.component.scss"],
})
export class ModalRightsAssignmentComponent extends ModalComponent<ModalRightsAssignmentComponent> {
  @ViewChildren("fileComponent") fileComponents: QueryList<FileUploadComponent>;

  public subject: Option;
  public fileTypes: string[] = [];

  public isLoadingButtonSend: boolean = false;
  public isLoadingButtonDownload: boolean = false;

  public EnumStatusEnvioFileIncident = EnumStatusEnvioFileIncident;

  public txtBtnCancel: string = "Cancelar";
  public txtBtnSendRequest: string = "Enviar";

  public qtdExtraFile: number = QTD_EXTRA_FILE;
  get qtdExtraFileAdded(): number {
    return this.files.filter((x) => x.style == null && x.extra).length;
  }

  public files: FileIncident[] = [
    {
      text: "Carta de solicitação devidamente assinada: ",
      required: true,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
    },
    {
      text: "Ficha cadastral do cessionário devidamente assinada:",
      required: true,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
    },
    {
      text: "Ficha cadastral do avalista devidamente assinada (se aplicável):",
      required: false,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
    },
    {
      text: "Cópia do RG do cessionário:",
      required: true,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
    },
    {
      text: "Cópia do CPF do cessionário:",
      required: true,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
    },
    {
      text: "Cópia do comprovante de residência do cessionário:",
      required: true,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
    },
    {
      text: "Cópia do comprovante de rendimentos do cessionário:",
      required: true,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {subject: Option, documentNumber: string},
    private incidentService: IncidentService,
    private bankService: BankService,
    private dialog: MatDialog
  ) {
    super();

    this.subject = data.subject;
  }

  public async onFileUploaded(
    text: string,
    files: FileRequest[]
  ): Promise<void> {
    let file = null;

    if (files !== null) {
      file = files[0];
    }

    this.files.find((f) => f.text == text).file = file?.fileBase64 || null;
    this.files.find((f) => f.text == text).name = file?.fileName || null;

    this.files.find((x) => x.text == text).style = null;
  }

  public disableButtonSend(): boolean {
    if (this.isLoadingButtonSend) {
      return true;
    }

    if (this.files.filter((f) => f.required).find((f) => f.file == null)) {
      return true;
    }

    return false;
  }

  public download(): void {
    this.isLoadingButtonDownload = true;

    firstValueFrom(this.bankService.DownloadRightAssingmentFile()).finally(
      () => (this.isLoadingButtonDownload = false)
    );
  }

  public async send(): Promise<void> {
    this.isLoadingButtonSend = true;
    let creteIncidentResponse: IncidentResponse;

    await firstValueFrom(
      this.incidentService.createSync(this.createIncidentObject())
    )
      .then((resp: IncidentResponse) => {
        creteIncidentResponse = resp;
        this.logFeature(
          EnumReportIncident.BANK_RIGHTS_ASSIGNMENT,
          EnumProduct.BANK
        );
      })
      .catch((error: HttpErrorResponse) => {
        this.isLoadingButtonSend = false;
        this.onClose(error);
      });

    if (creteIncidentResponse.statusCode == 200) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].file) {
          this.files[i].statusEnvio = EnumStatusEnvioFileIncident.INICIADO;

          await firstValueFrom(
            this.incidentService.attachFileToIncident(
              this.createAttachFileObject(
                this.files[i],
                creteIncidentResponse.incidentId
              )
            )
          )
            .then((resp: IncidentResponse) => {
              this.files[i].statusEnvio =
                EnumStatusEnvioFileIncident.FINALIZADO;
            })
            .catch(
              () =>
                (this.files[i].statusEnvio = EnumStatusEnvioFileIncident.ERRO)
            );
        }
      }
      this.isLoadingButtonSend = false;
    }

    this.onSubmit(true);
  }

  private createIncidentObject(): IncidentRequest {
    return {
      subject: 12,
      description:
        "SOLICITACAO REALIZADA VIA SITE TRANSACIONAL - Cessão de direitos solicitada",
      product: 1,
    };
  }

  private createAttachFileObject(
    file: FileIncident,
    incidentId: string
  ): AttachFileToIncidentRequest {
    return {
      entity: "",
      incidentId: incidentId,
      fileBase64: file.file,
      fileName: file.name,
    };
  }

  public createNewFile(): void {
    if (this.qtdExtraFileAdded == QTD_EXTRA_FILE) {
      return;
    }

    if (this.qtdExtraFileAdded > 0) {
      if (
        this.files[this.files.length - 1].file === null &&
        this.files[this.files.length - 1].style === null
      ) {
        return;
      }
    }

    if (
      this.files.find(
        (x) => x.text === "Arquivo (" + (this.qtdExtraFileAdded + 1) + "):"
      )
    ) {
      return;
    }

    this.files.push({
      text: "Arquivo (" + (this.qtdExtraFileAdded + 1) + "):",
      required: false,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
      style: { display: "none" },
      extra: true,
    });
  }

  public openLastFileSelector(): void {
    setTimeout(() => {
      this.fileComponents.last.onClickOpenFileSelector();
    }, 1);
  }

  public async onClickBtnClose(): Promise<void> {
    firstValueFrom(
      this.dialog
        .open(ModalConfirmComponent, {
          maxWidth: "900px",
          data: "Tem certeza que deseja cancelar?",
        })
        .afterClosed()
    ).then((resp: boolean) => (resp ? this.onClose(false) : null));
  }
}
