import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Select, SelectOption } from "src/app/models/select";
import { BankService } from "src/app/services/bank.service";
import { Contract } from "src/app/models/bank/contract";
import { BaseComponent } from "src/app/@base/base.component";
import { InsuranceService } from "src/app/services/insurance.service";
import { Policies } from "src/app/models/insurance/policies";

@Component({
  selector: "app-insurance-contracts",
  templateUrl: "./insurance-contracts.component.html",
  styleUrls: ["./insurance-contracts.component.scss"],
})
export class InsuranceContractsComponent extends BaseComponent implements OnInit {
  
  @Input() policies: Policies;
  @Output() emitContract: EventEmitter<any> = new EventEmitter<any>();

  public select: Select<number> = {
    icon: "insert_drive_file",
    options: [],
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.select.options = this.policies.policies.map(
      p => {
        return {
          title: p + "",
          t: p
        }
      }
    )
  }

  public onContractChange(evento: number): void {
    this.emitContract.emit(evento)
  }

}
