<span class="label"> {{label}} </span>

<div #customSelect class="custom-select">
  <button #selectButton
          class="select-button"
          role="combobox"
          aria-labelledby="select button"
          aria-haspopup="listbox"
          aria-expanded="false"
          aria-controls="select-dropdown"
          data-cy="Select"
          (click)="onClick()"
  >

    <div class="selected-item">
      <mat-icon>{{select.icon}}</mat-icon>

      <div class="option" data-cy="Selected Option">
        <span class="selected-title bolder"> {{select.options[0].title}} </span>
      </div>
    </div>

    <span class="arrow" *ngIf="select.options.length > 1"></span>
  
  </button>
  
  <ul class="select-dropdown" role="listbox" id="select-dropdown">

    <li *ngFor="let option of filteredOptions(); let i = index" (click)="onSelect(option.index)">
      <div class="selected-item">
        <mat-icon>{{select.icon}}</mat-icon>
  
        <div class="option" [attr.data-cy]="'Option' + i">
          <span class="option-title"> {{option.title}} </span>
        </div>
      </div>
    </li>

  </ul>
</div>
  