<app-modal
  class="density-lower"
  title="Nova solicitação"
  data-cy="modal-new-request"
>
  <div class="modal-content density-mat-form-3">
    <span class="reason estricta uppercase" data-cy="modal-new-request-title">{{
      subject.product + " - " + subject.description
    }}</span>

    <span class="description">Descreva sua solicitação abaixo:</span>
    <mat-form-field appearance="outline" class="background-white">
      <mat-label>Digite aqui</mat-label>
      <textarea
        matInput
        name="incidentDescritionControl"
        data-cy="modal-new-request-description"
        #incidentDescritionControl="ngModel"
        [(ngModel)]="incidentDescrition"
        maxlength="1000"
        autofocus
        required
      ></textarea>
      <mat-error *ngIf="incidentDescritionControl.control.invalid">{{
        getErrorMessage()
      }}</mat-error>
    </mat-form-field>
  </div>

  <div class="modal-bottom">
    <app-button
      [datacy]="txtBtnCancel"
      [disabled]="submited"
      width="100px"
      (onClick)="onClose(false)"
    >
      <span class="button-label">{{ txtBtnCancel }}</span>
    </app-button>

    <app-button
      type="submit"
      color="primary"
      width="100px"
      [loading]="submited"
      [disabled]="incidentDescritionControl.control.invalid || submited"
      [datacy]="txtBtnSendRequest"
      (onClick)="onSubmit(incidentDescrition)"
    >
      <span class="button-label">{{ txtBtnSendRequest }}</span>
    </app-button>
  </div>
</app-modal>
