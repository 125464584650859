<app-modal class="density-lower"
           title="Aviso">

    <div class="modal-content">   
        <span class="message" data-cy="modal-confirm-message"> {{message}} </span>        
    </div>        

    <div class="modal-bottom">
        <app-button [datacy]="txtBtnClose" (onClick)="onClose(true)" color="primary"><span class="button-label">{{txtBtnClose}}</span></app-button>
    </div>
</app-modal>