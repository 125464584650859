import { NgModule } from "@angular/core";
import { DealerComponent } from "./dealer.component";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "src/app/components/components.module";
import { DirectivesModule } from "src/app/directives/directives.module";
import { DealerRoutingModule } from "./dealer-routing.module";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";
import { CustomInputsModule } from "src/app/form/custom-inputs/custom-inputs.module";
import { MatInputModule } from "@angular/material/input";
import { DebtSettlementComponent } from './debt-settlement/debt-settlement.component';
import { MatDividerModule } from "@angular/material/divider";
import { MatChipsModule } from "@angular/material/chips";
import { DebtSettlementSummaryComponent } from './debt-settlement-summary/debt-settlement-summary.component';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DebtBillingHistoriesModalComponent } from "./debt-billing-histories-modal/debt-billing-histories-modal.component";
import { NgxMaskDirective } from 'ngx-mask';
import { PainelListViewComponent } from "./painel-list-view/painel-list-view.component";
import { MatExpansionModule } from "@angular/material/expansion";
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
    declarations: [
        PainelListViewComponent,
        DealerComponent,
        DebtBillingHistoriesModalComponent,
        DebtSettlementComponent,
        DebtSettlementSummaryComponent,
        
    ],
    imports: [
        DealerRoutingModule,
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        MatSelectModule,
        MatIconModule,
        MatMenuModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        CustomInputsModule,
        MatDividerModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        NgxMaskDirective,
        MatExpansionModule,
        MatTabsModule
    ]
  })

  export class DealerModule { }