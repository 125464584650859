import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import { PageBanner } from '../models/banner/pageBanner';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BannerService extends BaseService<PageBanner> {

  constructor() {
    super()
    this.path = "gateway/banners";
  }

  public bannersSubscribe$: Subject<PageBanner[]> = new Subject<PageBanner[]>(); 

  public getBanners(): Observable<PageBanner[]> {
    return this.http.get<PageBanner[]>(`${this.api}${this.path}/active-images`)
  }

}
