import { Injectable } from "@angular/core";
import { BaseService } from "../@base/base.service";
import { Observable, from, switchMap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WebAuthnService extends BaseService<any> {
  constructor() {
    super();
  }

  public saveCredentialLocalStorage(webAuthnToken) {
    let storageData: any = localStorage.getItem("webauthn");
    let tokens: string[] = [];
    if (storageData) {
      tokens = JSON.parse(storageData);
    }
    tokens.push(webAuthnToken);
    localStorage.setItem("webauthn", JSON.stringify(tokens));
  }

  public getCredentialLocalStorage() {
    let storageData: any = localStorage.getItem("webauthn");
    let tokens: string[] = [];
    if (storageData) {
      tokens = JSON.parse(storageData);
    }
    return tokens;
  }

  public register(userInfo: any): Observable<any> {
    return this.generateRegistrationOptions(userInfo).pipe(
      switchMap(async (response) => {
        // Converta os campos necessários de string para ArrayBuffer
        response.challenge = this.base64ToArrayBuffer(response.challenge);

        if (response.user && response.user.id) {
          response.user.id = this.base64ToArrayBuffer(response.user.id);
        }

        if (response.excludeCredentials) {
          response.excludeCredentials = response.excludeCredentials.map((cred) => {
            cred.id = this.base64ToArrayBuffer(cred.id);
            return cred;
          });
        }

        // Verificar e ajustar rp.id se necessário
        if (!response.rp || !response.rp.id) {
          response.rp = response.rp || {};
          response.rp.id = window.location.hostname;
        }

        const credential = (await navigator.credentials.create({
          publicKey: response,
        })) as PublicKeyCredential;

        const attestationResponse = credential.response as AuthenticatorAttestationResponse;

        const responseComplete = {
          id: this.arrayBufferToBase64Url(credential.rawId),
          rawId: this.arrayBufferToBase64Url(credential.rawId),
          type: credential.type,
          response: {
            clientDataJSON: this.arrayBufferToBase64Url(attestationResponse.clientDataJSON),
            attestationObject: this.arrayBufferToBase64Url(attestationResponse.attestationObject),
          },
          extensions: credential.getClientExtensionResults()
        };

        return this.registrationComplete(userInfo, responseComplete).toPromise();
      }),
      switchMap((response_complete) => {
        this.saveCredentialLocalStorage(response_complete.webauthn_token);
        return from([response_complete]);
      })
    );
  }

  public base64ToArrayBuffer(base64: string): ArrayBuffer {
    // Adiciona preenchimento se necessário
    while (base64.length % 4 !== 0) {
      base64 += '=';
    }
    // Substitui caracteres inválidos
    base64 = base64.replace(/-/g, '+').replace(/_/g, '/');

    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  // Função para converter ArrayBuffer para Base64
  public arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  public hasBiometric() {
    return this.getCredentialLocalStorage().length > 0;
  }

  public authenticate(): Observable<any> {
    const cacheKey = (Math.random() + 1).toString(36).substring(2);
    return this.generateAuthenticationOptions(cacheKey).pipe(
      switchMap(async (response) => {
        // Converta os campos necessários de string para ArrayBuffer
        response.challenge = this.base64ToArrayBuffer(response.challenge);

        if (response.allowCredentials) {
          response.allowCredentials = response.allowCredentials.map((cred) => {
            cred.id = this.base64ToArrayBuffer(cred.id);
            return cred;
          });
        }

        const assertion = (await navigator.credentials.get({
          publicKey: response,
        })) as PublicKeyCredential;

        const assertionResponse = assertion.response as AuthenticatorAssertionResponse;

        const responseComplete = {
          id: this.arrayBufferToBase64Url(assertion.rawId),
          rawId: this.arrayBufferToBase64Url(assertion.rawId),
          type: assertion.type,
          response: {
            clientDataJSON: this.arrayBufferToBase64Url(assertionResponse.clientDataJSON),
            authenticatorData: this.arrayBufferToBase64Url(assertionResponse.authenticatorData),
            signature: this.arrayBufferToBase64Url(assertionResponse.signature),
            userHandle: assertionResponse.userHandle ? this.arrayBufferToBase64Url(assertionResponse.userHandle) : null,
          },
          extensions: assertion.getClientExtensionResults()
        };

        return this.authenticationComplete(cacheKey, responseComplete).toPromise();
      })
    );
  }
  
  private generateRegistrationOptions(
    userInfo: any
  ): Observable<any> {

    const data = {
      displayname: userInfo.username,
      username: userInfo.username
    };

    return this.http.post(
      this.api + "webauthn/registration/options",
      data,
      { headers: this.addHeaders(true), withCredentials: true }
    );
  }

  private generateAuthenticationOptions(
    cacheKey: any
  ): Observable<any> {

    const data = {
      cacheKey: cacheKey,
      tokens: this.getCredentialLocalStorage()
    };

    return this.http.post(
      this.api + "webauthn/authentication/options",
      data,
      { headers: this.addHeaders(true), withCredentials: true }
    );
  }

  private registrationComplete(
    userInfo: any,
    response: any
  ): Observable<any> {

    const data = {
      username: userInfo.username,
      password: userInfo.password,
      response: response
    };

    return this.http.post(
      this.api + "webauthn/registration/complete",
      data,
      { headers: this.addHeaders(true), withCredentials: true }
    );
  }

  private authenticationComplete(
    cacheKey: any,
    response: any
  ): Observable<any> {

    const data = {
      cacheKey: cacheKey,
      response: response,
      tokens: this.getCredentialLocalStorage()
    };

    return this.http.post(
      this.api + "webauthn/authentication/signin",
      data,
      { headers: this.addHeaders(true), withCredentials: true }
    );
  }

  public arrayBufferToBase64Url(buffer: ArrayBuffer): string {
    const binary = String.fromCharCode(...new Uint8Array(buffer));
    return window.btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }
}
