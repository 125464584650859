import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";
import { FileUploadComponent } from "src/app/components/file-upload/file-upload.component";
import { ModalConfirmComponent } from "src/app/components/modal/modal-confirm/modal-confirm.component";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { EnumStatusEnvioFileIncident } from "src/app/enum/incident";
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";
import { CreateFeatureLogRequest } from "src/app/models/Log/CreateFeatureLog";
import {
  AttachFileToIncidentRequest,
  FileIncident,
  IncidentRequest,
  IncidentResponse,
} from "src/app/models/incident/incident";
import { Option } from "src/app/models/option";
import { FileRequest } from "src/app/models/settings/settings";
import { BankService } from "src/app/services/bank.service";
import { IncidentService } from "src/app/services/incident.service";
import { LogService } from "src/app/services/log.service";

const QTD_EXTRA_FILE: number = 10;

@Component({
  selector: "app-modal-warranty-substitution",
  templateUrl: "./modal-warranty-substitution.component.html",
  styleUrls: ["./modal-warranty-substitution.component.scss"],
})
export class ModalWarrantySubstitutionComponent
  extends ModalComponent<ModalWarrantySubstitutionComponent>
  implements OnInit
{
  @ViewChildren("fileComponent") fileComponents: QueryList<FileUploadComponent>;

  public btnCancelarTxt: string = "Cancelar";
  public btnEnviarTxt: string = "Enviar";

  get qtdExtraFileAdded(): number {
    return this.files.filter((x) => x.style == null && x.extra).length;
  }

  public form: FormGroup;

  get isFilesIncomplete() {
    return this.files.find((o) => o.required && o.file == null) ? true : false;
  }

  public qtdExtraFile: number = QTD_EXTRA_FILE;

  public EnumStatusEnvioFileIncident = EnumStatusEnvioFileIncident;
  public files: FileIncident[] = [
    {
      text: "Carta de substituição de garantia: ",
      required: true,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
    },
    {
      text: "Carta de avaliação ou laudo de vistoria:",
      required: false,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
      style: {
        display: "none",
      },
    },
    {
      text: "Boletim de ocorrência:",
      required: false,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
      style: {
        display: "none",
      },
    },
  ];

  public fileTypes: string[] = [];

  public txtBtnCancel: string = "Cancelar";
  public txtBtnDownload: string = "Download exemplo";
  public isLoadingButtonDownload: boolean = false;
  public isLoadingButtonSend: boolean = false;

  public subject: Option;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {subject: Option, documentNumber: string},
    private bankService: BankService,
    private incidentService: IncidentService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    super();

    this.subject = data.subject;
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      isSinistro: [false, [Validators.required]],
      isNew: [true, [Validators.required]],
    });
    this.form.controls["isNew"].valueChanges.subscribe((o) => {
      this.files.find(
        (o) => o.text == "Carta de avaliação ou laudo de vistoria:"
      ).required = !o;
      this.files.find(
        (o) => o.text == "Carta de avaliação ou laudo de vistoria:"
      ).style = o ? { display: "none" } : { display: "flex" };
    });
    this.form.controls["isSinistro"].valueChanges.subscribe((o) => {
      this.files.find((o) => o.text == "Boletim de ocorrência:").required = o;
      this.files.find((o) => o.text == "Boletim de ocorrência:").style = !o
        ? { display: "none" }
        : { display: "flex" };
    });
  }

  public download(): void {
    this.isLoadingButtonDownload = true;

    firstValueFrom(this.bankService.DownloadWarrantySubstitutionFile()).finally(
      () => (this.isLoadingButtonDownload = false)
    );
  }

  public async send(): Promise<void> {
    this.isLoadingButtonSend = true;
    let creteIncidentResponse: IncidentResponse;

    if (this.isFilesIncomplete) {
      return;
    }

    await firstValueFrom(
      this.incidentService.createSync(this.createIncidentObject())
    )
      .then((resp: IncidentResponse) => {
        creteIncidentResponse = resp;
        this.logFeature(
          EnumReportIncident.BANK_WARRANTY_REPLACEMENT,
          EnumProduct.BANK
        );
      })
      .catch((error: HttpErrorResponse) => {
        this.onClose(error);
      });

    if (creteIncidentResponse.statusCode == 200) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].file) {
          this.files[i].statusEnvio = EnumStatusEnvioFileIncident.INICIADO;

          await firstValueFrom(
            this.incidentService.attachFileToIncident(
              this.createAttachFileObject(
                this.files[i],
                creteIncidentResponse.incidentId
              )
            )
          )
            .then(() => {
              this.files[i].statusEnvio =
                EnumStatusEnvioFileIncident.FINALIZADO;
            })
            .catch(
              () =>
                (this.files[i].statusEnvio = EnumStatusEnvioFileIncident.ERRO)
            );
        }
      }
      this.isLoadingButtonSend = false;
    }

    this.onSubmit(true);
  }

  public async onFileUploaded(
    text: string,
    files: FileRequest[]
  ): Promise<void> {
    let file = null;

    if (files !== null) {
      file = files[0];
    }

    this.files.find((f) => f.text == text).file = file?.fileBase64 || null;
    this.files.find((f) => f.text == text).name = file?.fileName || null;

    this.files.find((x) => x.text == text).style = null;
  }

  private createIncidentObject(): IncidentRequest {
    return {
      subject: 15,
      description:
        "SOLICITACAO REALIZADA VIA SITE TRANSACIONAL - Substituição de Garantia solicitada.",
      product: 1,
    };
  }

  private createAttachFileObject(
    file: FileIncident,
    incidentId: string
  ): AttachFileToIncidentRequest {
    return {
      entity: "",
      incidentId: incidentId,
      fileBase64: file.file,
      fileName: file.name,
    };
  }

  public createNewFile(): void {
    if (this.qtdExtraFileAdded == QTD_EXTRA_FILE) {
      return;
    }

    if (this.qtdExtraFileAdded > 0) {
      if (
        this.files[this.files.length - 1].file === null &&
        this.files[this.files.length - 1].style === null
      ) {
        return;
      }
    }

    if (
      this.files.find(
        (x) => x.text === "Arquivo (" + (this.qtdExtraFileAdded + 1) + "):"
      )
    ) {
      return;
    }

    this.files.push({
      text: "Arquivo (" + (this.qtdExtraFileAdded + 1) + "):",
      required: false,
      statusEnvio: EnumStatusEnvioFileIncident.NAO_INICIADO,
      style: { display: "none" },
      extra: true,
    });
  }

  public openLastFileSelector(): void {
    setTimeout(() => {
      this.fileComponents.last.onClickOpenFileSelector();
    }, 1);
  }

  public async onClickBtnClose(): Promise<void> {
    firstValueFrom(
      this.dialog
        .open(ModalConfirmComponent, {
          maxWidth: "900px",
          data: "Tem certeza que deseja cancelar?",
        })
        .afterClosed()
    ).then((resp: boolean) => (resp ? this.onClose(false) : null));
  }
}
