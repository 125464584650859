import { Component, Inject } from '@angular/core';
import { ModalComponent } from '../modal.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss']
})
export class ModalImageComponent extends ModalComponent<ModalImageComponent>{

  public url

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    super()
    this.url = data 
  }

}
