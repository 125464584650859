import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-informations',
  templateUrl: './product-informations.component.html',
  styleUrls: ['./product-informations.component.scss']
})
export class ProductInformationsComponent {

  @Input() name: string
  @Input() type: string
  @Input() src: string;
  @Input() color: string;

}
