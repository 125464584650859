import { Component, Inject, OnInit } from "@angular/core";
import { ModalComponent } from "../../../../components/modal/modal.component";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Option } from "src/app/models/option";
import { CustomValidators } from "src/app/form/custom-validators";
import { firstValueFrom } from "rxjs";
import { IncidentRequest } from "src/app/models/incident/incident";
import { DatePipe } from "@angular/common";
import { IncidentService } from "src/app/services/incident.service";
import { ModalConfirmComponent } from "src/app/components/modal/modal-confirm/modal-confirm.component";
import { EnumProduct } from "src/app/enum/products";
import { MatSelectChange } from "@angular/material/select";
import { EnumReportIncident } from "src/app/enum/report-incident";

@Component({
  selector: "app-modal-bank-travel-authorization",
  templateUrl: "./modal-bank-travel-authorization.component.html",
  styleUrls: ["./modal-bank-travel-authorization.component.scss"],
})
export class ModalBankTravelAuthorizationComponent
  extends ModalComponent<ModalBankTravelAuthorizationComponent>
  implements OnInit
{
  public readonly btnEnviarTxt = "Enviar";
  public readonly btnCancelarTxt = "Cancelar";

  public readonly destinationCountries = [
    "Argentina",
    "Paraguai",
    "Uruguai",
    "Venezuela",
    "Outro",
  ];

  public isSubmitting: boolean = false;
  public subject: Option;
  public form: FormGroup;
  public travelDuration: FormGroup;

  public readonly todayAsDateOnly = this.getDateAsDateOnly(new Date());

  get showIfOutroActive() {
    return this.form.get("destination-manually");
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {subject: Option, documentNumber: string},
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private incidentsService: IncidentService,
    private dialog: MatDialog
  ) {
    super();

    this.subject = data.subject;
  }

  ngOnInit(): void {
    this.travelDuration = this.formBuilder.group({
      startDate: [
        null,
        [
          Validators.required,
          CustomValidators.isDateLowerThanLimit(this.todayAsDateOnly),
        ],
      ],
      endDate: [null, [Validators.required]],
    });

    this.travelDuration.addValidators([
      CustomValidators.isStartDateGreaterThanEndDate("startDate", "endDate"),
      Validators.required,
    ]);

    this.form = this.formBuilder.group({
      destination: [null, [Validators.required]],
      city: ["", [Validators.maxLength(100)]],
      travelDuration: this.travelDuration,
    });
  }

  public override onSubmit(event?: any): void {
    if (!this.form.valid || !this.travelDuration.valid) {
      return;
    }

    const destinationCountry: string =
      this.form.get("destination-manually")?.value ||
      this.form.get("destination").value;
    let city: string = this.form.get("city").value;
    if (city.trim().length == 0) {
      city = "Não informada";
    }

    const period = `${this.datePipe.transform(
      this.travelDuration.get("startDate").value,
      "dd/MM/yyyy"
    )} - ${this.datePipe.transform(
      this.travelDuration.get("endDate").value,
      "dd/MM/yyyy"
    )}`;
    const description = `SOLICITACAO REALIZADA VIA SITE TRANSACIONAL - Autorização de Viagem solicitada com as informações: País de Destino: ${destinationCountry} / Cidade: ${city} / Período: ${period}`;

    const travelAuthorizationIncident: IncidentRequest = {
      description: description,
      product: 1,
      subject: 14,
    };

    this.isSubmitting = true;

    firstValueFrom(this.incidentsService.create(travelAuthorizationIncident))
      .then(() => {
        this.onClose(true);
        this.logFeature(
          EnumReportIncident.BANK_TRAVEL_AUTHORIZATION,
          EnumProduct.BANK
        );
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  private getDateAsDateOnly(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public async onClickBtnClose(): Promise<void> {
    if (this.form.dirty) {
      firstValueFrom(
        this.dialog
          .open(ModalConfirmComponent, {
            maxWidth: "900px",
            data: "Tem certeza que deseja cancelar?",
          })
          .afterClosed()
      ).then((resp: boolean) => (resp ? this.onClose(false) : null));
    } else {
      this.onClose(false);
    }
  }

  public createOrDeleteInputDestinationManually(evento: MatSelectChange): void {
    if (evento.value === "Outro") {
      this.form.addControl(
        "destination-manually",
        new FormControl("", { validators: [Validators.required] })
      );
    } else {
      if (this.form.get("destination-manually")) {
        this.form.removeControl("destination-manually");
      }
    }
  }
}
