<app-section backgroundColor="#fff" [mode]="sectionMode" minHeight="100px">

    <div class="section-content white-content">

        <span *ngIf="!contractSelected" class="no-contract"> Não foi possível identificar nenhum contrato ativo no momento. As funcionalidades estão desabilitadas. </span>

        <div *ngIf="contractSelected" class="white-content-about">
            <div>
                <img src="assets/products-logo/Banco.png" alt='image'>
            </div>

            <div>
                    <div class="user-about">
                        <div class="user-about-info">
                            <div>
                                <span [ngClass]="userAboutInfoClass" class="grey"> Nome: </span>   
                                <span data-cy="bank-name"> {{contractSelected.fullName}} </span>
                            </div>
                            <div>
                                <span *ngIf="!detectar_mobile()"  [ngClass]="userAboutInfoClass"class="grey"> Contrato: </span>
                                <span *ngIf="!detectar_mobile()" data-cy="bank-contractNumber"> {{contractSelected.contractNumber}} </span>
                            </div>
                            <div>
                                <span [ngClass]="userAboutInfoClass"  class="grey"> Modelo: </span>   
                                <span data-cy="bank-product"> {{contractSelected.product}} </span>
                            </div>
                            <div *ngIf="firstDate">
                                <span class="user-about-info-itens-2 grey"> Primeira Parcela:</span>   
                                <span data-cy="bank-date-first"> {{firstDate}} </span>
                            </div>
                            <div *ngIf="LastDate">
                                <span class="user-about-info-itens-2 grey"> Última Parcela: </span>   
                                <span data-cy="bank-date-last"> {{LastDate}}  </span>
                            </div>

                        </div>
        
                    </div>     
            </div>
        </div>



        <ng-content select=".bank-header" class="bank-header">

        </ng-content>

    </div>

</app-section>

<ng-content *ngIf="contractSelected" class="bank-content" select=".bank-content">
</ng-content>
