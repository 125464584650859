<app-modal title="Aviso" data-cy="modal-notification">
  <div
    data-cy="modal-notification-content"
    class="modal-content modal-notification-content"
  >
    <img
      *ngIf="notification.imageUrl"
      [src]="notification.imageUrl"
      class="image-on-content"
      width="100%"
    />

    <app-video-player [videoUrl]="this.notification.videoUrl"></app-video-player>

    <div class="content">
      <span class="estricta bolder title"> {{ notification.title }}</span>

      <span>
        {{ notification.content }}
      </span>
    </div>
  </div>

  <div class="modal-bottom">
    <app-button [datacy]="txtBtnClose" color="primary" (onClick)="onClose()">
      <span class="button-label">{{ txtBtnClose }}</span>
    </app-button>
  </div>
</app-modal>
