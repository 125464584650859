<app-bank-header>

    <div class="bank-content">

        <div class="section-content">
            <app-card width="400px">
                <div class="card" *ngIf="errorMessage">
                  <span class="text">{{errorMessage}}</span>
                </div>

                <div class="card" *ngIf="!errorMessage">

                    <span class="card-title estricta uppercase"> {{title}}</span>
                    <span class="text"> Clique no botão abaixo para fazer o download da 2ª via do seu carnê. </span>

                    <app-button datacy="btn-downloadVoucher"
                                class="margin"
                                color="primary"
                                width="100%"
                                [disabled]="loading"
                                [loading]="loading"
                                (onClick)="downloadVoucher()"
                    >
                        <span class="uppercase estricta text-button">{{txtBtnDownloadVoucher}}</span>
                    </app-button>

                    <div class="card-bottom flex">
                        <div>
                            <mat-icon class="icon">info</mat-icon>
                        </div>
                        <div class="card-bottom-info">
                            <span class="text"> Prezado cliente, pedimos atenção ao fazer o pagamento pelo carnê pois ele inclui também as parcelas que já foram pagas. </span>
                        </div>
                    </div>

                </div>

            </app-card>
        </div>
    </div>

</app-bank-header>
