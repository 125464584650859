import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/@base/base.component';
import { PERMITION_FAQ_ADMIN, PERMITION_FAQ_CLIENT } from 'src/app/const/permitions';
import { FaqItem } from 'src/app/models/faq/faq';

@Component({
  selector: 'app-faq-panel',
  templateUrl: './faq-panel.component.html',
  styleUrls: ['./faq-panel.component.scss']
})
export class FaqPanelComponent extends BaseComponent implements AfterViewInit {
  
  @ViewChild('steps', { static: true }) steps: ElementRef;
  @ViewChild('videoPlayer') videoplayer: ElementRef;

  @Input() faqItem: FaqItem;
  @Input() index: number;

  ngAfterViewInit(): void {
    const markTags = this.steps.nativeElement.querySelectorAll('a');
    
    markTags.forEach((tag: HTMLElement) => {    
      tag.addEventListener('click', (event) => {
        this.goToPage()
      });

      tag.style.color = "blue" 
      tag.style.cursor = "pointer"
      tag.style.fontWeight = "500"
    });
    
  }

  public goToPage(): void {
    if(this.faqItem.permission.filter(x => x !== PERMITION_FAQ_ADMIN && x !== PERMITION_FAQ_CLIENT).some(permission => this.getPermissions().includes(permission))){
      this.router.navigate([this.faqItem.route]);
    } else {
      this.openDialogMessage("Você não possui permissão para acessar essa funcionalidade.")
    }
  }

  public toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
}
