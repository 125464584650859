import { Component, Input } from '@angular/core';
import { OPTIONS_INSURANCE, OPTIONS_REQUEST_INSURANCE } from 'src/app/const/options';
import { EnumProduct } from 'src/app/enum/products';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'app-product-insurance',
  templateUrl: './product-insurance.component.html',
  styleUrls: ['./product-insurance.component.scss']
})
export class ProductInsuranceComponent {
  @Input() showSUHAI: boolean = false;
  public type: EnumProduct = EnumProduct.INSURANCE;
  public options: Option[] = OPTIONS_REQUEST_INSURANCE;
  public subOptions: Option[] = OPTIONS_INSURANCE;
  public combinedOptions: Option[] = [...this.options, ...this.subOptions];
  public subOptionsTitle: string = "SERVIÇOS SUHAI";
  public insuranceOptionsPermition: string[] = this.combinedOptions.map(x => x.permission);
  @Input() policieChosed: number;

}
