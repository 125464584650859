<section>
    <div>
      <span class="title estricta"> Ajuda </span>
      <input [formControl]="filter" placeholder="Filtrar" data-cy="filter" placeholder="&#xF002; Filtrar" style="font-family:Roboto, FontAwesome">
    </div>
  

    <div *ngIf="listConfigUsers.length > 0">
      <span class="estricta bolder uppercase"> Configurações - Usuário </span>
      <app-faq-panel *ngFor="let faqItem of listConfigUsers; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>
    </div>

    <div *ngIf="listConfigMessages.length > 0">
      <span class="estricta bolder uppercase"> Configurações - Mensagens </span>
      <app-faq-panel *ngFor="let faqItem of listConfigMessages; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>
    </div>

    <div *ngIf="listConfigBanners.length > 0">
      <span class="estricta bolder uppercase"> Configurações - Banners </span>
      <app-faq-panel *ngFor="let faqItem of listConfigBanners; let index = index" 
                    [faqItem]="faqItem"
                    [index]="index">
      </app-faq-panel>
    </div>

    <div *ngIf="listConfigProfile.length > 0">
      <span class="estricta bolder uppercase"> Configurações - Perfil de acesso </span>
      <app-faq-panel *ngFor="let faqItem of listConfigProfile; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>  
    </div>

    <div *ngIf="listConfigBilling.length > 0">
      <span class="estricta bolder uppercase"> Configurações - Assessoria de Cobrança </span>
      <app-faq-panel *ngFor="let faqItem of listConfigBilling; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>  
    </div>

    <div *ngIf="listBank.length > 0">
      <span class="estricta bolder uppercase"> Banco </span>
      <app-faq-panel *ngFor="let faqItem of listBank; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>  
    </div>

    <div *ngIf="listRequests.length > 0">
      <span class="estricta bolder uppercase"> Solicitações </span>
      <app-faq-panel *ngFor="let faqItem of listRequests; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel> 
    </div>
 
    <div *ngIf="listConsortium.length > 0">
      <span class="estricta bolder uppercase"> Consórcio </span>
      <app-faq-panel *ngFor="let faqItem of listConsortium; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>  
    </div>
 
    <div *ngIf="listContact.length > 0">
      <span class="estricta bolder uppercase"> Contato </span>
      <app-faq-panel *ngFor="let faqItem of listContact; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>   
    </div>
 
    <div *ngIf="listSystem.length > 0">
      <span class="estricta bolder uppercase"> Sistema </span>
      <app-faq-panel *ngFor="let faqItem of listSystem; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>    
    </div>

    <div *ngIf="listPolicies.length > 0">
      <span class="estricta bolder uppercase"> Políticas </span>
      <app-faq-panel *ngFor="let faqItem of listPolicies; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>      
    </div>

    <div *ngIf="listLinks.length > 0">
      <span class="estricta bolder uppercase"> Links </span>
      <app-faq-panel *ngFor="let faqItem of listLinks; let index = index" 
                     [faqItem]="faqItem"
                     [index]="index">
      </app-faq-panel>       
    </div>




</section>
