import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'barcode'
})
export class BarcodePipe implements PipeTransform {

  transform(value: string): unknown {

    let valorFormatado = value + "";

    valorFormatado = valorFormatado
      .replace(                       
          /(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{11,14})/,
          '$1.$2 $3.$4 $5.$6 $7 $8'
      );

    return valorFormatado;
  }

}
