import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { ProductInsuranceComponent } from './product-categories/product-insurance/product-insurance.component';
import { ProductCreditComponent } from './product-categories/product-credit/product-credit.component';
import { ProductBankComponent } from './product-categories/product-bank/product-bank.component';
import { ProductConsortiumComponent } from './product-categories/product-consortium/product-consortium.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { MatIconModule } from '@angular/material/icon';
import { ProductInformationsComponent } from './product-components/product-informations/product-informations.component';
import { ProductOptionsComponent } from './product-components/product-options/product-options.component';
import { DirectivesModule } from 'src/app/directives/directives.module';


@NgModule({
  declarations: [
    ProductComponent,
    ProductConsortiumComponent,
    ProductBankComponent,
    ProductCreditComponent,
    ProductInsuranceComponent,
    ProductOptionsComponent,
    ProductInformationsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ComponentsModule,
    DirectivesModule
  ],
  exports: [
    ProductComponent,
    ProductConsortiumComponent,
    ProductBankComponent,
    ProductCreditComponent,
    ProductInsuranceComponent
  ]
})
export class ProductModule { }
