import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { RequestsComponent } from './requests/requests.component';
import { CreditComponent } from './credit/credit.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { AuthorizationGuard } from '../guards/authorization.guard';
import { AuthenticationGuard } from '../guards/authentication.guard';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [
  {
    path: "",
    component: PagesComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: "home",
        component: HomeComponent
      },
      {
        path: "requests",
        component: RequestsComponent,
        canActivate: [AuthorizationGuard]
      },
      {
        path: "credit",
        component: CreditComponent,
        canActivate: [AuthorizationGuard]
      },
      {
        path: "insurance",
        component: InsuranceComponent,
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'bank',
        loadChildren: () => import('./bank/bank.module').then(m => m.BankModule),
        canActivate: [AuthorizationGuard],
        canActivateChild: [AuthorizationGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthorizationGuard],
        canActivateChild: [AuthorizationGuard]
      },
      {
        path: "consortium",
        loadChildren: () => import('./consortium/consortium.module').then(m => m.ConsortiumModule),
        canActivate: [AuthorizationGuard],
        canActivateChild: [AuthorizationGuard]
      },
      {
        path: "dealer",
        loadChildren: () => import('./dealer/dealer.module').then(m => m.DealerModule),
        canActivate: [AuthorizationGuard],
        canActivateChild: [AuthorizationGuard]
      },
      {
        path: "faq",
        component: FaqComponent,
        // canActivate: [AuthorizationGuard]
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: '/home',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
