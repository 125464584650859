import { DatePipe } from '@angular/common';
import { combineLatest, firstValueFrom, map } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/@base/base.component';
import { ButtonColors } from 'src/app/components/button/button.component';
import { Payment } from 'src/app/models/bank/payment';
import { orderBy } from 'lodash-es';
import { DealersService } from 'src/app/services/dealers.service';
import { Debtor, DebtorBankSlipRequest, DebtorBankSlipResponse } from 'src/app/models/dealer/dealer';

@Component({
  selector: 'app-debt-settlement-summary',
  templateUrl: './debt-settlement-summary.component.html',
  styleUrls: ['./debt-settlement-summary.component.scss']
})
export class DebtSettlementSummaryComponent extends BaseComponent implements OnChanges{
  @Output() emitDocumentCreated: EventEmitter<void> = new EventEmitter();

  @Input() installments: Payment[] = []
  @Input() totalRows: Payment[] = []
  @Input() debtor: Debtor;
  @Input() increaseDays: number = 0;

  @Input() width: string = "100%";
  @Input() allowPayment: boolean = true;
  @Input() btnColor: ButtonColors = "primary";
  @Input() txtBtnGenerateBill: string = "Gerar Boleto"
  @Input() icon: string = "get_app";
  @Input() allSelected: boolean = false;

  sendSlipByEmail: boolean = false;

  public txtSpanSumInstallment: string = "Valor original";
  public txtSpanSumAddition: string = "Acréscimos";
  public txtSpanSumDiscounts: string = "Descontos";
  public txtSpanSumDifference: string = "Diferença";
  public txtSpanInfoTwo: string = "Lembramos que o prazo para baixa dos pagamentos é de até 3 dias úteis.";
  public txtSpanInfoThree: string = "O seu CPF ou CNPJ é a senha para abrir o arquivo do boleto gerado, utilizando apenas números.";
  public txtSpanTotal: string = "Total a Pagar"
  public barcode: string;
  public txtCopy: string = "Copiar";
  public isExpanded: boolean = false;
  public loading: boolean = false;
  public paymentSlipGenerated: boolean = false;

  constructor(
    private dealersService: DealersService,
    private datePipe: DatePipe,
  ){
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.barcode = null;
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public rowsSelectedSorted(): any[] {
    return orderBy(this.installments, ['number'], ['asc']);
  }

  public sumInstallment(): string {
    return Number.parseFloat(this.installments.reduce((acc, curr) => acc + parseFloat(curr.originalValue), 0)  + "").toFixed(2)
  }

  public sumAddition(): string {
    return Number.parseFloat(this.installments.filter(x => parseFloat(x.discountIncrease) > 0).reduce((acc, curr) => acc + parseFloat(curr.discountIncrease), 0) + "").toFixed(2)
  }

  public sumDiscounts(): string {
    return Number.parseFloat(this.installments.filter(x => parseFloat(x.discountIncrease) < 0).reduce((acc, curr) => acc + parseFloat(curr.discountIncrease), 0)  + "").toFixed(2)
  }

  public sumDifference(): string {
    return (parseFloat(this.sumAddition()) + parseFloat(this.sumDiscounts())).toFixed(2);
  }

  public total(): string {
    return (parseFloat(this.sumInstallment()) + parseFloat(this.sumDifference())).toFixed(2)
  }

  public generatePayment(): void {

    this.loading = true;

    if(!this.allSelected){
      this.openDialogMessage("Existe(m) outra(s) parcela(s) em aberto. O pagamento do(s) boleto(s) selecionado(s) não é garantia de retirada do nome negativado.");
    }
    let bankSlip: DebtorBankSlipRequest = {
      instalments: this.installments.map(x => {
        return {number: x.number, dueDate: x.dueDate, receiptCode: x.receiptCode}
      }),
      clientName: this.debtor.clientName,
      clientDocument: this.debtor.clientDocument,
      contractNumber: this.debtor.contractNumber,
      emailToSendBankSlip: this.sendSlipByEmail ? this.debtor.clientEmail.replace(/\s+/g, '') : null,
      increaseDays: this.increaseDays
    }

    firstValueFrom(this.dealersService.GeneratePayment(bankSlip))
      .then((bankSlipResponse: DebtorBankSlipResponse) => {
        this.paymentSlipGenerated = true;
        this.barcode = bankSlipResponse.barcode;
        
        this.download(bankSlipResponse.base64file,`${this.debtor.contractNumber}_${this.datePipe.transform(new Date(), 'yyyy-MM-dd')}.pdf`, "pdf");
      })
      .then(() => {
        const firstModal$ = firstValueFrom(this.openDialogSucess("Para abrir seu boleto, basta digitar somente os números do CPF ou CNPJ do titular.").afterClosed());
        const insertHistory$ = this.dealersService.insertHistory({
          clientDocument: this.debtor.clientDocument,
          contractNumber: this.debtor.contractNumber,
          commentary: this.getInformations()
        });

        return combineLatest([firstModal$, insertHistory$]).pipe(
          map(([_, historyResult]) => historyResult)
        ).subscribe(() => this.openDialogSucess("Geração de boleto efetuada com sucesso e registrada no histórico de atendimento."));
      })
      .then(() => this.emitDocumentCreated.emit())
      .finally(() => this.loading = false)
  }

  private getInformations() {
    let dueDate = new Date();
    dueDate.setHours(0, 0, 0, 0); 
    dueDate.setDate(dueDate.getDate() + this.increaseDays);

    let summary = `BOLETO GERADO para nova data vencimento: ${this.datePipe.transform(dueDate,"dd/MM/yyyy")}.\n`
    summary += `Parcelas selecionadas: ${this.installments.map(x => x.number).join(", ")}\n`
    summary += `Resumo: \n`

    summary += `- ${this.txtSpanSumInstallment}: ${parseFloat(this.sumInstallment()).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}\n`;
    summary += `- ${this.txtSpanSumAddition}: ${parseFloat(this.sumAddition()).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}\n`;
    summary += `- ${this.txtSpanSumDiscounts}: ${parseFloat(this.sumDiscounts()).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}\n`;
    summary += `- ${this.txtSpanSumDifference}: ${parseFloat(this.sumDifference()).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}\n`;
    summary += `- ${this.txtSpanTotal}: ${parseFloat(this.total()).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}\n`;

    summary += `Código de barras: ${this.barcode}\n`;

    if(this.sendSlipByEmail)
      summary += `Destinatários E-mail: ${this.debtor.clientEmail}`;

    return summary;
  }

  public detectar_mobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  onSendSlipByEmailChange(emailInput: any) {
    if (this.sendSlipByEmail) {
      emailInput.control.markAsTouched();
      emailInput.control.updateValueAndValidity();
    }
  }
}
