import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { EnumProduct } from 'src/app/enum/products';
import { EnumReportIncident } from 'src/app/enum/report-incident';
import { Contract } from 'src/app/models/bank/contract';
import { AnualStatement } from 'src/app/models/bank/statement';
import { Table } from 'src/app/models/table';
import { BankService } from 'src/app/services/bank.service';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-anual-statement',
  templateUrl: './anual-statement.component.html',
  styleUrls: ['./anual-statement.component.scss']
})
export class AnualStatementComponent extends BaseComponent implements OnInit {

  public $subscriptionContract: Subscription;

  public txtBtnDownload: string = "Baixar";
  public table: Table<AnualStatement> = this.createTable();
  public contract: Contract;
  public selectedYear: number;
  public years: number[];
  public downloadFile: boolean = false;

  public loading: boolean = false;

  constructor(
    private bankService: BankService,
    private datePipe: DatePipe
  ){
    super()
  }

  ngOnInit(): void {
    this.createTable();
    this.subscriptionChangeContract()
    this.logger.logPageView("[App] - Anual Statement");
    this.logFeature(EnumReportIncident.BANK_ANUAL_STATEMENT, EnumProduct.BANK);
  }

  ngOnDestroy(): void {
    this.$subscriptionContract.unsubscribe();
  }

  private subscriptionChangeContract(): void {
    this.$subscriptionContract = this.bankService.emitContractSelected.subscribe((contract: Contract) => {
      this.table.data = [];
      this.contract = contract;
      this.getAnualPaymentExtract();
    })    
  }


  private createTable(): Table<AnualStatement> {
    return {
      headers: [
        {
          key: "instalmentNumber",
          value: "Parcela"
        },
        {
          key: "dueDate",
          value: "Vencimento"
        },
        {
          key: "originalValue",
          value: "Valor Original"
        },
        {
          key: "paidValue",
          value: "Valor Pago"
        },
        {
          key: "paymentDate",
          value: "Pagamento"
        },
        {
          key: "status",
          value: "Status"
        }
      ],
      data: [],
      checkbox: false,
      style: {
        data: [
          {
            key: "paymentDate",
            transform: (value) => this.datePipe.transform(value, 'dd/MM/yyyy')
          },
          {
            key: "dueDate",
            transform: (value) => this.datePipe.transform(value, "dd/MM/yyyy")
          },
          {
            key: "originalValue",
            transform: (value: string) => parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          },
          {
            key: "paymentValue",
            transform: (value: string) => parseFloat(value).toLocaleString('pt-br', {style: 'currency', currency: "BRL"})
          },
          {
            key: "status",
            styles: [
              {
                class: { color: "#000", fontWeight: 500 },
                rule: x => x === "Em aberto"
              },
              {
                class: { color: "#f5222d", fontWeight: 500 },
                rule: x => x === "Em atraso"
              },
              {
                class: { color: "#52c41a", fontWeight: 500 },
                rule: x => x === undefined
              }
            ],
            transform: _ => "Liquidado"
          }
        ],
        table: {
          height: "400px",
          align: {
            column: [
              {
                key: "instalmentNumber",
                align: "right"
              },
              {
                key: "dueDate",
                align: "right"
              },
              { 
                key: "originalValue",
                align: "right"
              },
              {
                key: "paymentValue",
                align: "right"
              },
              {
                key: "paymentDate",
                align: "right"
              },
              {
                key: "status",
                align: "right"
              }
            ]
          }
        }
      },
      footer: {
        amount: {
          display: true,
          description: "Total",
          column: "parcela"
        },
        columns:  [
          {
            column: "originalValue",
            operation: "sum",
            transform: (value: string) => parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          },
          {
            column: "paymentValue",
            operation: 'sum',
            transform: (value:string) => parseFloat(value).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
          }
        ]
      },
      messageWithoutDates: "Não há dados para exibir neste momento."
    }
  }


  private getAnualPaymentExtract(): void {
    this.years = this.getYears();
    this.loadData(this.contract.contractNumber, this.selectedYear);
  }

  private getYears(): number[] {
    let years: number[] = []

    const currentYear = new Date().getFullYear() - 1;
    const contractInitYear = new Date(this.contract.initDate).getFullYear();

    for(let i = currentYear; i >= contractInitYear; i--){
      years.push(i);
    }

    this.selectedYear = currentYear;
    return years;
  }

  private loadData(contractNumber: string, year: number): void {
    this.table.data = [];
    this.loading = true;
    firstValueFrom(this.bankService.getAnualPaymentExtract(contractNumber, year))
        .then((resp: AnualStatement[]) => this.updateTable(resp))
        .then(() => this.table = this.table)
        
        .catch((error:HttpErrorResponse) =>  { if (error.status === 422) this.openDialogError("O relatório não pode ser emitido, pois existem parcelas pendentes de pagamento para este ano base.") })
        .finally(() => this.loading = false)
  }

  private updateTable(resp: AnualStatement[]): void {
    this.table.data = resp
  }

  public updateYear(event: any): void {
    this.loadData(this.contract.contractNumber, event.value);
  }

  public downloadAnualStatement() {
    this.downloadFile = true;
    firstValueFrom(this.bankService.AnualPaymentExtractPdf(this.contract.contractNumber, this.selectedYear))
        .then((resp: Blob) => {
          this.download(resp, `Declaracao_Anual_Pagamentos_Yamaha_${this.contract.contractNumber}_${this.selectedYear}.pdf`, "pdf")
          this.logFeature(EnumReportIncident.BANK_DOWNLOAD_ANUAL_STATEMENT, EnumProduct.BANK);
        })
        .catch((error:HttpErrorResponse) =>  { if (error.status === 422) this.openDialogError("O relatório não pode ser emitido, pois existem parcelas pendentes de pagamento para este ano base.") })
        .finally(() => this.downloadFile = false);
  }

}
