export enum EnumBankQuickAccess {
  BILL = '2ª via de Boleto',
  PAYMENT = 'Antecipar Parcelas',
  PAYMENT_BOOKLET = 'Carnê Digital',
  CONTRACT = 'Quitação de Contrato',
  PAYMENT_BOOK = '2ª via do Carnê',
  STATEMENT = 'Extrato Financeiro',
  ANUAL_STATEMENT = 'Declaração Anual',
  CONTRACT_RECEIPT = 'Recibo de Quitação Contratual',
  CONTRACT_BALANCE_DUE = 'Carta de Saldo Devedor Contratual',
  CARTA_SALDO_DEVEDOR = 'Carta de Saldo Devedor',
  LIEN_STATUS = 'Status do Gravame'
}

export enum EnumBankOptions {
  RIGHTS_ASSIGNMENT = 'Cessão de Direitos',
  WARRANTY_REPLACEMENT = 'Substituição de garantia',
  CHANGE_REGISTRATION_DATA = 'Alteração de dados cadastrais',
  PAYMENT_REVERSAL = 'Inversão de pagamento',
  TRAVEL_AUTHORIZATION = 'Autorização de viagem',
}

export enum EnumConsortiumOptions {
  PORTAL = 'Portal do Consórcio',
}

export enum EnumCreditOptions {
  BILL = '2ª via de Boleto',
  DELAYED = 'Boleto em Atraso',
  CANCELLATION = 'Pedido de Cancelamento',
}

export enum EnumRequestInsuranceOptions {
  BILL = '2ª via de Boleto',
  POLICY = '2ª via de Apólice',
  CONTRACT = 'Endosso Alteração de Perfil',
  REGISTRATION = 'Endosso Cadastral',
  REPLACEMENT = 'Endosso Troca de Bem',
}

export enum EnumInsuranceOptions {
  EMIT_BILL = 'Emissão de boleto',
  SINISTER = 'Consulta de sinistros',
  EMIT_POLICY = 'Download da Apólice'
}
