import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import { Notification } from '../models/notification/notification';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService<Notification> {

  constructor() {
    super();
    this.path = "gateway/messages"
  }

  public readMessage(id: number, username: string): Observable<void> {
    return this.http.post<void>(`${this.api}${this.path}/${id}/read`, {})
  }

}
