<div class="content density-lower">

  <section class="backdrop-blur">
    <form [formGroup]="form" class="form-content density-mat-form-3">

      <div class="form-fields">
        <div class="form-field">
          <label> CPF / CNPJ </label>
          <mat-form-field appearance="outline" class="background-white">
            <input type="text" formControlName="username" matInput maxlength="18" placeholder="Sem pontos ou hífens"
              data-cy="document" (keydown.enter)="onSubmit()">

            <mat-error *ngIf="form.get('username').hasError('required')" data-cy="error-username-required">
              <strong> O campo é obrigatório </strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-field">
          <label> Senha </label>
          <mat-form-field appearance="outline" class="background-white">

            <input formControlName="password" matInput [type]="hidePassword ? 'password' : 'text'" maxlength="50"
              data-cy="password" (keydown.enter)="onSubmit()">

            <button mat-icon-button matSuffix data-cy="button-hide-password" (click)="hidePassword = !hidePassword"
              [attr.aria-pressed]="hidePassword">
              <mat-icon class="grey">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>

            <mat-error *ngIf="form.get('password').hasError('required')" data-cy="error-password-required">
              <strong> A senha é obrigatória </strong>
            </mat-error>

          </mat-form-field>
        </div>

        <re-captcha #recaptcha class="flex-center g-recaptcha" siteKey="6LdLTD8pAAAAAOx8qN7-_QmYK2oQsqmKTvNm-hop"
          data-cy="recaptcha"></re-captcha>

      </div>

      <div class="form-buttons">

        <app-button type="submit" (onClick)="onSubmit()" width="100%" [loading]="loading && loginType == 'NORMAL'"
          [disabled]="!form.valid || loading || !recaptchaResolved" color="primary" datacy="button-submit">
          <span class="estricta uppercase bolder"> Entrar </span>
        </app-button>

        <app-button type="submit" *ngIf="facebookLoginEnabled" (onClick)="loginWithFacebook()" width="100%"
          [loading]="loading && loginType == 'FACEBOOK'" [disabled]="loading" color="primary"
          datacy="button-submit-facebook" icon="facebook">
          <span class="estricta uppercase bolder"> Entrar com Facebook </span>
        </app-button>

        <asl-google-signin-button *ngIf="googleLoginEnabled && !(loading && loginType == 'GOOGLE')Jus" type='standard'
          size='large' text="signin_with" width="300"></asl-google-signin-button>

        <app-button type="submit" *ngIf="loading && loginType == 'GOOGLE'" width="100%" [loading]="true"
          [disabled]="true" color="primary" datacy="button-submit-google">
          <span class="estricta uppercase bolder"> Autenticando com Google... </span>
        </app-button>

        <button mat-button color="primary" routerLink="/recover" class="estricta uppercase white-bolder"
          data-cy="button-forgot-password">Esqueci minha senha</button>
      </div>

      <span class="biometric-button" *ngIf="hasBiometric" (click)="onSumitBio()" data-cy="biometric-button">
        <mat-icon class="biometric-icon">fingerprint</mat-icon>
      </span>
      <span *ngIf="detectar_mobile()">
        <button mat-raised-button color="primary" (click)="pwaService.installPwa()" *ngIf="!pwaService.isPwaInstalled()"
          data-cy="button-install-pwa">Instalar Aplicativo</button>
      </span>

      <span class="new-account estricta uppercase">
        <span> Ainda não possui uma conta?</span>
        <span class="white-bolder cursor-pointer" routerLink="/new-account" data-cy="new-account"> Cadastre-se </span>
        <br>
        <span> Ainda não é cliente?</span>
        <span class="white-bolder cursor-pointer" (click)="OpenLearnMore()" data-cy="learn-more"> SAIBA MAIS </span>
      </span>

    </form>



  </section>


</div>
