<app-modal class="density-lower sinister-modal"
           title="Consulta de sinistros"
           data-cy="modal-situation-sinister"
>
  
    <div class="modal-content">
        <div *ngIf="sinister.length > 0" class="sinister-table">
            <app-table [table]="getTable()" class="table">
            </app-table>
        </div>


        <div *ngIf="sinister.length == 0" class="sinister-not-found" data-cy="modal-situation-no-sinister-found">
            Não identificamos a comunicação do Sinistro, caso haja dúvidas, pedimos que entre contato através do endereço eletrônico https://avisarsinistro.suhaiseguradora.com ou telefones 3003-0335 (SP e RJ) / 0800-784-2410 (demais regiões).
        </div>
    </div>
    
    <div class="modal-bottom">
        <app-button datacy="modal-situation-button-close" (onClick)="onClose(true)" color="primary">
            <span class="button-label">Fechar</span>
        </app-button>
    </div>

</app-modal>
