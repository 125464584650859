<div>
    <span class="bar-code-display">
        {{barcode | barcode}}
    </span>
    <button class="bar-code-button" 
            [cdkCopyToClipboard]="barcode"
            [matTooltip]="txtCopy" 
    > 
        <mat-icon>file_copy</mat-icon>
        <span> Copiar </span>
    </button>
</div>