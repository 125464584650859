import { Injectable } from '@angular/core';
import { CurrentUser } from '../models/user/user';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public permissions: string[]
  public user: CurrentUser;

  constructor() { }

  public getPermissions(): string[] {
    return this.permissions || [];
  }

  public setPermissions(permissions: string[]): void {
    this.permissions = permissions
  }

  public getUser(): CurrentUser {
    return this.user
  }

  public setUser(user: CurrentUser): void {
    this.user = user
  }
}
