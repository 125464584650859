import { Component, Inject } from '@angular/core';
import { ModalComponent } from '../modal.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-loading',
  templateUrl: './modal-loading.component.html',
  styleUrls: ['./modal-loading.component.scss']
})
export class ModalLoadingComponent extends ModalComponent<ModalLoadingComponent> {

  public message: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    super()
    this.message = data 
  }

}
