<span class="form-field">
  <label *ngIf="!required" [attr.data-cy]="returnDataCy('label')"> {{ label }} </label>
  <label *ngIf="required" [attr.data-cy]="returnDataCy('label')"> <span class="red">*</span> {{ label }}: </label>
  <mat-form-field appearance="outline" class="example-form-field background-white">
    <mat-date-range-input [formGroup]="formGroup" [rangePicker]="createdAtPicker"
      [comparisonStart]="formGroup.value[startDateName]" [comparisonEnd]="formGroup.value[endDateName]" [max]="max"
      [min]="min">
      <input #inputStart maxlength="10" matStartDate [readonly]="readonly" [formControlName]="startDateName"
        [attr.data-cy]="returnDataCy('startDate')" (keydown)="checkMaskStart($event)" />
      <input #inputEnd maxlength="10" matEndDate [readonly]="readonly" [formControlName]="endDateName"
        [attr.data-cy]="returnDataCy('endDate')" (keydown)="checkMaskEnd($event)" />
    </mat-date-range-input>

    <mat-datepicker-toggle *ngIf="!readonly" matIconSuffix [for]="createdAtPicker"></mat-datepicker-toggle>
    <mat-date-range-picker #createdAtPicker></mat-date-range-picker>

    <mat-error *ngIf="
        formGroup.get(startDateName)?.hasError('required') ||
        formGroup.get(endDateName)?.hasError('required')
      " [attr.data-cy]="returnDataCyError('custom-datapicker-range-required')">
      <strong> Campo obrigatório </strong>
    </mat-error>

    <mat-error *ngIf="formGroup.get(startDateName).value && formGroup.get(endDateName).value && (
             formGroup.get(startDateName)?.hasError('dateLowerThanLimit') ||
             formGroup.get(endDateName)?.hasError('dateGreaterThanLimit') ||
             formGroup.get(startDateName)?.hasError('dateGreaterThanToday') ||
             formGroup.get(endDateName)?.hasError('dateGreaterThanToday'))"
      [attr.data-cy]="returnDataCyError('custom-datapicker-range-end')">
      <strong> Data final inválida </strong>
    </mat-error>

    <mat-error
      *ngIf="formGroup.get(startDateName).value && formGroup.get(endDateName).value && formGroup?.hasError('startGreaterThanEnd')"
      [attr.data-cy]="returnDataCyError('custom-datapicker-range-start-greater-than-end')">
      <strong> Data inicial não pode ser maior que data final </strong>
    </mat-error>

  </mat-form-field>
</span>