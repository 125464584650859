import { Component, OnDestroy, OnInit } from "@angular/core";
import { SectionModeTypes } from "src/app/components/section/section.component";
import { Option } from "src/app/models/option";
import { QUICK_ACCESS_BANK } from "src/app/const/options";
import { Contract } from "src/app/models/bank/contract";
import { Subscription, firstValueFrom } from "rxjs";
import { BankService } from "src/app/services/bank.service";
import { EnumBankQuickAccess } from "src/app/enum/options";
import { BaseComponent, EProduct } from "src/app/@base/base.component";
import { ModalBankLetterDebtorBalanceComponent } from "../../bank-components/modal-bank-letter-debtor-balance/modal-bank-letter-debtor-balance.component";
import { ModalRightsAssignmentComponent } from "src/app/pages/bank/bank-modals/modal-rights-assignment/modal-rights-assignment.component";
import { IncidentService } from "src/app/services/incident.service";
import { EnumIncidentProduct } from "src/app/enum/incident";
import { Table } from "src/app/models/table";
import { TableComponent } from "src/app/components/table/table.component";
import { Incident } from "src/app/models/incident/incident";
import { DatePipe } from "@angular/common";
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";
import { EnumPath } from "src/app/enum/path";
import { ModalLienStatusComponent } from "../../bank-modals/modal-lien-status/modal-lien-status.component";
import { StatusLienResponse as LienStatusResponse } from "src/app/models/bank/payment";
import { HttpErrorResponse } from "@angular/common/http";
@Component({
  selector: "app-home-bank",
  templateUrl: "./home-bank.component.html",
  styleUrls: ["./home-bank.component.scss"],
})
export class HomeBankComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public readonly product = EProduct.Bank;

  public sectionMode: SectionModeTypes = "full";
  public table = this.createTable();

  public bankQuickAccess: Option[] = QUICK_ACCESS_BANK;

  public $contractSubscriber: Subscription;
  public contractSelected: Contract;

  private buttonDownloadContractReceiptLoading: boolean = false;

  constructor(
    private bankService: BankService,
    private incidentService: IncidentService,
    private datePipe: DatePipe
  ) {
    super();
    this.getIncidents();
  }

  ngOnInit() {
    this.$contractSubscriber = this.bankService.emitContractSelected?.subscribe(
      (x: Contract) => {
        this.contractSelected = x;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.$contractSubscriber) {
      this.$contractSubscriber.unsubscribe();
    }
  }

  public getTooltip(option: Option): string {
    return "Clique aqui para acessar " + option.description;
  }

  public showIfHasActiveInstalments(option: string): boolean {
    switch (option) {
      case EnumBankQuickAccess.STATEMENT:
        return true;
      case EnumBankQuickAccess.ANUAL_STATEMENT:
        return true;
      case EnumBankQuickAccess.LIEN_STATUS:
        return true
      case EnumBankQuickAccess.CONTRACT_RECEIPT:
        return !this.contractSelected?.hasActiveInstalments;
      default:
        return this.contractSelected?.hasActiveInstalments;
    }
  }

  public onOptionClicked(option: Option): void {
    switch (option.description) {
      case EnumBankQuickAccess.CONTRACT_RECEIPT:
        this.downloadContractReceipt();
        break;
      case EnumBankQuickAccess.CARTA_SALDO_DEVEDOR:
        const request = this.dialog.open(
          ModalBankLetterDebtorBalanceComponent,
          {
            width: "90vw",
            maxWidth: "800px",
            data: this.contractSelected,
            disableClose: true,
          }
        );

        request.afterClosed().subscribe({
          next: (reload) => {
            if (reload) {
              this.openDialogSucess(
                "Para abrir seu boleto, basta digitar somente os números do CPF ou CNPJ do titular."
              );
            }
          },
        });
        break;

        case EnumBankQuickAccess.LIEN_STATUS:
          this.showModalLien();
          break;
      default:
        this.routerTo(option.path);
    }
  }

  private showModalLien(){
    const modalLoading = this.openDialogLoading();
    firstValueFrom(this.bankService.GetLienStatus(this.contractSelected.contractNumber))
      .then((data: LienStatusResponse) => {
        if (data) {
          this.dialog.open(
            ModalLienStatusComponent,
            {
              width: "45vw",
              maxWidth: "400px",
              data: data.status,
              disableClose: true,
            }
          );
        }
      })
      .catch((error) => {
        if (error.status !== 500) {
          this.openDialogError(error.error, "Atenção");
        }
      })
      .finally(() => {
        modalLoading.close();
        this.logFeature(
          EnumReportIncident.BANK_LIEN_STATUS,
          EnumProduct.BANK
        );
      });
  }

  public async downloadContractReceipt(): Promise<void> {
    this.buttonDownloadContractReceiptLoading = true;

    await firstValueFrom(
      this.bankService.GenerateContractReceiptPdf(
        this.contractSelected.contractNumber
      )
    )
      .then((resp: Blob) =>
        this.download(resp, `ReciboQuitacaoContratual.pdf`, "pdf")
      )
      .catch((error: HttpErrorResponse) => this.openDialogError(error))
      .finally(() => {
        this.buttonDownloadContractReceiptLoading = false;
        this.logFeature(
          EnumReportIncident.BANK_CONTRACT_RECEIPT,
          EnumProduct.BANK
        );
      });
  }

  private routerTo(path: string): void {
    this.router.navigate([path]);
  }

  public getIncidents() {
    if (!this.table.loading) {
      this.table.loading = true;
      firstValueFrom(
        this.incidentService.getIncidentsByProduct(EnumIncidentProduct.BANK)
      ).then((incidents) => {
        this.table.data = incidents;
        this.table.loading = false;
      });
    }
  }

  public buttonOptionLoading(option: Option): boolean {
    switch (option.description) {
      case EnumBankQuickAccess.CONTRACT_RECEIPT:
        return this.buttonDownloadContractReceiptLoading;
      default:
        return false;
    }
  }

  public createTable(): Table<Incident> {
    return {
      headers: [
        {
          key: "incidentNumber",
          value: "N° da Solicitação",
        },
        {
          key: "openingDate",
          value: "Data de abertura",
        },
        {
          key: "product",
          value: "Segmento",
        },
        {
          key: "subject",
          value: "Tipo",
        },
        {
          key: "status",
          value: "Status",
        },
      ],
      data: [],
      style: {
        table: {
          height: "500px",
        },
        data: [
          {
            key: "status",
            styles: [
              {
                class: { color: "#fa8c16", fontWeight: 500 },
                rule: (x) => x === "Em andamento",
              },
              {
                class: { color: "#52c41a", fontWeight: 500 },
                rule: (x) => x === "Resolvido",
              },
            ],
          },
          {
            key: "openingDate",
            transform: (value) =>
              this.datePipe.transform(value, "dd/MM/yyyy HH:mm"),
          },
        ],
      },
      checkbox: false,
    };
  }

  public goToBankExternalLink() {
    this.openInNewTab(EnumPath.INSTITUTIONAL_BANK);
  }
}
