<div *ngIf="eixo == 'horizontal'">
  <ul class="horizontal">

    <li [attr.data-cy]="returnDataCy(eixo, 'Configurações')"
        [showIfPermission]="settingsPermission">
      <a class="estricta uppercase bolder"
         [matMenuTriggerFor]="menu"> Configurações </a>
    </li>

    <ng-container *ngFor="let route of routesMenu()">
      <li [attr.data-cy]="returnDataCy(eixo, route.name)">
        <a class="estricta uppercase bolder"
           *ngIf="!route.path.startsWith('http') else externalLinkHorizontal"
           [showIfPermission]="route.permissions"
           [routerLink]="route.path"
           (click)="closeMenu()"
           > {{route.name}} </a>

        <ng-template #externalLinkHorizontal>
          <a class="estricta uppercase bolder"
          [showIfPermission]="route.permissions"
          target="_blank"
          [href]="route.path"
            (click)="closeMenu()"> {{route.name}} </a>
        </ng-template>
      </li>
    </ng-container>
  </ul>

  <input mat-menu-item cdkFocusInitial type='text' class="hidden"/>

  <mat-menu #menu="matMenu">

    <input mat-menu-item cdkFocusInitial type='text' class="hidden"/>

    <button mat-menu-item *ngFor="let route of routesMenuSettings" [showIfPermission]="route.permissions" [routerLink]="route.path">
      <a class="estricta uppercase bolder" [showIfPermission]="route.permissions">{{route.name}}</a>
    </button>
  </mat-menu>

</div>

<div *ngIf="eixo == 'vertical'">

  <mat-tree [dataSource]="dataSource"
            [treeControl]="treeControl"
            class="tree"
  >

    <mat-tree-node *matTreeNodeDef="let node"
                   matTreeNodeToggle
                   [showIfPermission]="node.permissions"
    >
      <li class="mat-tree-node">

        <a class="estricta uppercase bolder"
           *ngIf="!node.path.startsWith('http') else externalLinkVertical"
           [routerLink]="node.path"
           [attr.data-cy]="returnDataCy(eixo, node.name)"
           (click)="closeMenu()"
           > {{node.name}} </a>

        <ng-template #externalLinkVertical>
          <a class="estricta uppercase bolder"
             [href]="node.path"
             target="_blank"
             [attr.data-cy]="returnDataCy(eixo, node.name)"
             (click)="closeMenu()"> {{node.name}} </a>
        </ng-template>
      </li>
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">

      <li matTreeNodeToggle
          class="mat-nested-tree-item"
          [showIfPermission]="node.permissions"
          [attr.data-cy]="returnDataCy(eixo, node.name)">

        <div class="flex estricta uppercase bolder">
          <a>{{node.name}}</a>
          <mat-icon class="icon-display"> {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}} </mat-icon>
        </div>

        <ul [class]="eixo" [@slideVertical]="treeControl.isExpanded(node) ? 'show' : null">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>

      </li>
    </mat-nested-tree-node>

  </mat-tree>
</div>
