import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, assertInInjectionContext } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Observable, firstValueFrom } from "rxjs";
import { BaseComponent } from "src/app/@base/base.component";
import { ModalConfirmComponent } from "src/app/components/modal/modal-confirm/modal-confirm.component";
import { ModalLoadingComponent } from "src/app/components/modal/modal-loading/modal-loading.component";
import { EnumPath } from "src/app/enum/path";
import { ExternalAuth } from "src/app/models/auth/auth";

import {
  LOCAL_STORAGE_KEY_PERMISSIONS,
  LOCAL_STORAGE_KEY_USER,
} from "src/app/const/localStorageKeys";
import { CurrentUser } from "src/app/models/user/user";
import { ParametersService } from "src/app/services/parameters.service";
import { Parameter } from "src/app/models/parameter/parameter";

@Component({
  selector: "app-external-login",
  template: "<div>{{statusAutomaticLogin}}</div>"
})
export class ExternalLoginComponent extends BaseComponent implements OnInit {

  public statusAutomaticLogin: string = "Carregando...";

  constructor(private activatedRoute: ActivatedRoute, private parametersService: ParametersService,) {
    super()
  }

  async ngOnInit(): Promise<void> {
    let auth: ExternalAuth = {
      authenticationCode: this.activatedRoute.snapshot.paramMap.get("guid")
    };

    let request = await this.authService.externalLogin(auth);

    this.login(request);
  }

  private async login(request: Observable<any>) {

    await firstValueFrom(request)
      .then(async (response: any) => {
        await firstValueFrom(this.authService.createCache());
        await this.requestGetPermissions();
      })
      .then(() => {
        this.router.navigate([EnumPath.DEALERS]);
      })
      .catch(async (httpErrorResponse: HttpErrorResponse) => {
        this.statusAutomaticLogin = `Erro ao realizar login automático: ${httpErrorResponse.error.message}`;
      });
  }

  public async requestGetPermissions(): Promise<void> {
    await firstValueFrom(this.authService.getCurrentUser())
      .then((resp: CurrentUser) => {
        this.setLocalStorageItem(
          LOCAL_STORAGE_KEY_PERMISSIONS,
          resp.permissions
        );
        this.setLocalStorageItem(LOCAL_STORAGE_KEY_USER, resp);

        return resp;
      })
      .then((resp: CurrentUser) => {
        this.setPermissions(resp.permissions);
        this.setUser(resp);
      });
  }


}
