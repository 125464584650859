import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-products',
  templateUrl: './user-products.component.html',
  styleUrls: ['./user-products.component.scss']
})
export class UserProductsComponent{
  @Input() form: FormGroup;

  @Input() banco: string;
  @Input() liberaCred: string;
  @Input() seguros: string;
  @Input() consorcio: string
}
