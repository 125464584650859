<app-modal class="density-lower"
           [title]="title">

    <div class="modal-content">   
        <span class="message" data-cy="modal-confirm-message"> {{message}} </span>        
    </div>        

    <div class="modal-bottom">
        <app-button [datacy]="txtBtnCancel" (onClick)="onClose(false)"><span class="button-label">{{txtBtnCancel}}</span></app-button>
        <app-button [datacy]="txtBtnConfirm" (onClick)="onClose(true)" color="primary"><span class="button-label">{{txtBtnConfirm}}</span></app-button>
    </div>
</app-modal>