import { Component } from '@angular/core';
import { OPTIONS_CREDIT } from 'src/app/const/options';
import { EnumProduct } from 'src/app/enum/products';
import { EnumCreditOptions } from 'src/app/enum/options';
import { Option } from 'src/app/models/option';

@Component({
  selector: 'app-product-credit',
  templateUrl: './product-credit.component.html',
  styleUrls: ['./product-credit.component.scss']
})
export class ProductCreditComponent {
  public type: EnumProduct = EnumProduct.CREDIT;
  public options: Option[] = OPTIONS_CREDIT;
  public creditOptionsPermition: string[] = OPTIONS_CREDIT.map(x => x.permission)
}
