<app-insurance-contracts *ngIf="showSUHAI" [policies]="policies"
  (emitContract)="onContractChanged($event)"></app-insurance-contracts>
<app-section>
    
    <span class="section-title estricta title" *ngIf="showProducts"> MEUS PRODUTOS </span>
    

    <div class="section-content list">
      <div *ngIf="isLoadingSuhai">
        <div class="loading">
            <mat-spinner class="small-spinner" data-cy="loading-suhai" ></mat-spinner> Verificando SUHAI
          </div>
        
      </div>
        <app-product-bank [showIfPermission]="bankPermition" *ngIf="isClientOf(bankProduct)"></app-product-bank>
        <app-product-consortium [showIfPermission]="consortiumPermition" *ngIf="isClientOf(consortiumProduct)"></app-product-consortium>
        <app-product-insurance [showIfPermission]="insurancePermition" *ngIf="isClientOf(insuranceProduct)" [showSUHAI]="showSUHAI" [policieChosed]="policieChosed"></app-product-insurance>        
        <app-product-credit [showIfPermission]="creditPermition" *ngIf="isClientOf(creditProduct)"></app-product-credit>        
    </div>
</app-section>
