<div class="content">

    <div class="visible-es title">
        <span class="estricta name"> {{name}} </span>
        <span class="estricta type" [ngClass]="color"> {{type}} </span>
    </div>

    <img [src]="src">

    <div class="flex">

        <span class="estricta type visible-md" [ngClass]="color"> {{type}} </span>
        <span class="estricta name visible-md"> {{name}} </span>

        <ng-content select=".infos"></ng-content>

    </div>

    <div class="visible-lg">
        <ng-content select=".additional"></ng-content>
    </div>
    
</div>