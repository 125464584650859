import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { EnumProduct } from 'src/app/enum/products';
import { EnumReportIncident } from 'src/app/enum/report-incident';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent<T>{

  public dialogRef = inject(MatDialogRef<T>)
  public logService = inject(LogService);

  @Input() title: string;
  @Input() buttons: boolean = true;
  @Input() disableButtonClose: boolean = false;

  constructor(){

  }

  public onClose(event?: any): void {
    this.dialogRef.close(event)
  }

  public onSubmit(event?: any): void {
    this.dialogRef.close(event)
  }

  public logFeature(message: EnumReportIncident | string, product: EnumProduct) : void{
    firstValueFrom(this.logService.createLog({
      description: message,
      product: product
    }))
  }
}
