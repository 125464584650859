import { animate, state, style, transition, trigger } from '@angular/animations';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BaseComponent } from 'src/app/@base/base.component';
import { PERMITION_REQUESTS, PERMITION_SETTINGS } from 'src/app/const/permitions';
import { ROUTES } from 'src/app/const/routes';
import { EnumPages } from 'src/app/enum/pages';
import { Route } from 'src/app/models/route';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations:[
    trigger('slideVertical', [
      state('*', style( {height: 0} )),
      state('show', style( {height: '*'} )),
      transition('* => *', [animate('600ms cubic-bezier(0.25, 0.8, 0.25, 1)')])
    ])
  ]

})
export class MenuComponent extends BaseComponent {

  @Output() emitCloseMenu: EventEmitter<void> = new EventEmitter();
  @Input() eixo: string = "horizontal";

  public settingsPermission: string = PERMITION_SETTINGS

  public resquests = PERMITION_REQUESTS
  public routesMenuSettings: Route[] = this.routesSetting();

  public treeControl = new NestedTreeControl<Route>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<Route>();
  public isExpanded: boolean = false;

  constructor(){
    super()

    this.dataSource.data = this.routes.filter(x => x.menu).map(x => {
      if(x.name == EnumPages.BANK){
        return {
          ...x,
          children: []
        }
      }

      if(x.name == EnumPages.SETTINGS){
        return {
          ...x,
          children: this.routesSetting()
        }
      }

      return x
    });
  }

  public hasChild = (_: number, node: Route) => !!node.children && node.children.length > 0;

  public routesMenu(): Route[]{
    return this.routes.filter(x => x.menu && x.name != EnumPages.SETTINGS)
  }

  private routesSetting(): Route[] {
    return this.routes.find(x => x.name == EnumPages.SETTINGS).children.filter(x => x.children).flatMap(x => x.children).filter(x => x.path.includes("search") && x.permissions.some(r=> this.getPermissions().includes(r)))
  }

  public returnDataCy(eixo: string, name: string): string {
    return "menu-" + eixo + "-" + name
  }

  public closeMenu(): void {
    this.emitCloseMenu.emit()
  }
}
