import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { EnumPath } from 'src/app/enum/path';
import { CustomValidators } from 'src/app/form/custom-validators';
import { ResetPasswordResponse } from 'src/app/models/auth/auth';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

enum StatusRequest {
  SUCCESS = "1",
  ERROR = "2"
}

@Component({
  selector: 'app-forgot-password-recover',
  templateUrl: './forgot-password-recover.component.html',
  styleUrls: ['./forgot-password-recover.component.scss']
})
export class ForgotPasswordRecoverComponent extends BaseComponent implements OnInit {

  public form: FormGroup;
  public today = new Date();
  public loading: boolean = false;
  public email: string;
  public requestDoneWithSucess: StatusRequest;

  get labelDate() {
    return this.form.get("document").value?.length > 11 ? "Data de constitução" : "Data de nascimento"
  }

  get labelDocument() {
    return this.form.get("document").value?.length > 11 ? "CNPJ" : "CPF"
  }

  constructor(
    private formBuilder: FormBuilder,
  ){
    super()
  }

  ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      birthDate: [null, [Validators.required, CustomValidators.isDateGreaterThanToday()]],
      document: [null, [Validators.required, CustomValidators.isValidCpf(), CustomValidators.isValidCNPJ()]]
    })
  }

  public goToLoginPage(): void {
    this.router.navigate([EnumPath.LOGIN]);
  }

  public recover(): void {
    this.loading = true;

    firstValueFrom(this.authService.resetPassword(this.form.value))
      .then((resp: ResetPasswordResponse) => {this.email = resp.sentTo})
      .then(() => {
        localStorage.removeItem("webauthn");
        this.requestDoneWithSucess = StatusRequest.SUCCESS
  })
      .catch((error: HttpErrorResponse) => this.handleError(error))
      .finally(() => this.loading = false)
  }

  private handleError(error: HttpErrorResponse): void {
    if(error.status == 500) {
      this.openDialogError(error)
    }

    if(error.status == 404) {
      this.requestDoneWithSucess = StatusRequest.ERROR
    }
  }
  
}
