<app-modal class="density-lower" title="Nova Solicitação" data-cy="modal-bank-change-registration-data"
  [disableButtonClose]="isBtnEnviarLoading">
  <div class="modal-content density-mat-form-3">
    <span class="reason estricta uppercase" data-cy="modal-bank-change-registration-data">{{ subject.product + " - " +
      subject.description }}</span>

    <section class="text space-between-top-bottom">
      Selecione a parcela que foi paga por engano e qual seria a parcela correta
      para a abertura da Solicitação de Inversão de Pagamento.
    </section>

    <form (keydown.enter)="$event.preventDefault(); onClickBtnSubmit()" [formGroup]="form">
      <section class="flex">

        <div>
          <mat-label>
            <span class="red">*</span>
            <span>Parcela paga por engano</span>
          </mat-label>

          <mat-form-field appearance="outline" class="background-white">
            <mat-select [placeholder]="isLoadingData ? 'Carregando' : 'Selecione'"
              formControlName="wrongPayedInstallment" data-cy="WrongPayedInstallmentInput">
              <mat-option *ngFor="let payment of advances" [value]="payment['instalmentNumber']">
                {{"Parcela " + payment['instalmentNumber'] + " - Vencto. " + (payment['dueDate'] | date : "dd/MM/yy") +
                " - Valor: " + (payment['originalValue'] | currency: 'BRL') }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('wrongPayedInstallment').hasError('required')">
              <strong> Campo obrigatório </strong>
            </mat-error>
          </mat-form-field>
        </div>


        <div>
          <mat-label>
            <span class="red">*</span>
            <span>Parcela correta </span>
          </mat-label>

          <mat-form-field appearance="outline" class="background-white">
            <mat-select [placeholder]="isLoadingData ? 'Carregando' : 'Selecione'" formControlName="replaceInstallment"
              data-cy="InstallmentToReplaceInput">
              <mat-option *ngFor="let payment of debts" [value]="payment['instalmentNumber']">
                {{"Parcela " + payment['instalmentNumber'] + " - Vencto. " + (payment['dueDate'] | date : "dd/MM/yy") +
                " - Valor: " + (payment['originalValue'] | currency: 'BRL') }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('replaceInstallment').hasError('required')">
              <strong> Campo obrigatório </strong>
            </mat-error>
          </mat-form-field>
        </div>

        <mat-error *ngIf="equalValuesError" data-cy="error-passowrd-confirmPassword-match">
          <strong> Não é possível trocar uma parcela por ela mesma </strong>
        </mat-error>
      </section>
    </form>
  </div>

  <div class="modal-bottom">
    <app-button width="100px" (onClick)="onClickBtnClose()" [disabled]="isBtnEnviarLoading" datacy="Cancelar">
      <span class="button-label"> Cancelar </span>
    </app-button>
    <app-button type="submit" color="primary" [loading]="isBtnEnviarLoading"
      [disabled]="!form.valid || isBtnEnviarLoading" datacy="update-button" (onClick)="onClickBtnSubmit()"
      datacy="Enviar">
      <span class="button-label"> Enviar</span>
    </app-button>
  </div>
</app-modal>