import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsuranceComponent } from './insurance.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ModalSituationSinisterComponent } from './modal-situation-sinister/modal-situation-sinister.component';
import { InsuranceContractsComponent } from './insurance-contracts/insurance-contracts.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    InsuranceComponent,
    ModalSituationSinisterComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    MatProgressSpinnerModule,
  ]
})
export class InsuranceModule { }
