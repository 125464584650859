import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { BaseComponent } from 'src/app/@base/base.component';
import { COOKIE_CONSENT } from 'src/app/const/cookiesKeys';
import { ElementReadyService } from 'src/app/helpers/elemente-ready';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent extends BaseComponent implements AfterViewInit  {
  [x: string]: any;
  public readMore: boolean = false
  constructor(private elementReadyService: ElementReadyService, private renderer: Renderer2) {
    super();
  }


  public consent(): void {
    const elemento = document.querySelector('.pwa-install') as HTMLElement;
    if(elemento){
        this.renderer.setStyle(elemento, 'bottom', `0px`);
    }
    this.setCookieItem(COOKIE_CONSENT, true)
  }

  public isConsent(): boolean {
    return this.getCookieItem(COOKIE_CONSENT)
  }

  public goToLink(url: string){
    if(url){
      window.open(url, "_blank");
    }
  }
  ngAfterViewInit() {
    this.elementReadyService.elementReady();
  }
}
