<mat-sidenav-container>
    <mat-sidenav mode="over" [opened]="stateMenu" fixedInViewport="true" [style.marginTop.rem]="5">
      <app-menu eixo="vertical" (emitCloseMenu)="onClickMenu()"></app-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-carousel [images]="images"></app-carousel>
      <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>

