import {  Component, OnInit } from '@angular/core';
import { BaseComponent } from './@base/base.component';
import { firstValueFrom, } from 'rxjs';
import { LOCAL_STORAGE_KEY_BANNERS, LOCAL_STORAGE_KEY_CONSORTIUM_REQUEST } from './const/localStorageKeys';
import { NavigationEnd } from '@angular/router';
import { ConsortiumService } from './services/consortium.service';
import { Consortium } from './models/consortium/consortium';
import { BannerService } from './services/banner.service';
import {appDynamics} from '../assets/appdynamics.js';
import {environment} from '../environments/environment';


@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-cookie-consent></app-cookie-consent>
    <app-install-pwa></app-install-pwa>
  `
})
export class AppComponent extends BaseComponent implements OnInit {

  public whatsapp: HTMLElement;
  public webchat: HTMLElement;

  constructor(
    private bannerService: BannerService,
    private consortiumServuce: ConsortiumService
  ){
    super()

    appDynamics.init(environment.APP_DYNAMICS_KEY);

    this.setLocalStorageItem(LOCAL_STORAGE_KEY_CONSORTIUM_REQUEST, false)

    this.router.events.subscribe((event: NavigationEnd) => {

      if (!(event instanceof NavigationEnd)) return;
    });
  }

  ngOnInit(): void {
    firstValueFrom(this.bannerService.getBanners())
      .then(resp => {
        if(resp){
          this.setLocalStorageItem(LOCAL_STORAGE_KEY_BANNERS, JSON.stringify(resp))

        }
        this.bannerService.bannersSubscribe$.next(null)
      })
  }

  private redirectConsortium(): void {
    const modalLoading = this.openDialogLoading()

    firstValueFrom(this.consortiumServuce.getRedirectUrl())
    .then((data: Consortium) => {
      if(data){
        this.openInNewTab(data.redirectUrl);
      }
    })
    .catch((error) => {
      if(error.status !== 500){
        this.openDialogError(error, "Atenção");
      }
    })
    .finally(() => {
      modalLoading.close()
      this.setLocalStorageItem(LOCAL_STORAGE_KEY_CONSORTIUM_REQUEST, false)
    });
  }

}
