import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgxFileDropEntry } from "ngx-file-drop";
import { BaseComponent } from "src/app/@base/base.component";

export declare type DisplayOptions = 'area' | 'button';

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent extends BaseComponent {

  @ViewChild("openFileSelectorElement") openFileSelectorElement: ElementRef

  @Output() emitFileUploaded: EventEmitter<any> = new EventEmitter<any>();

  @Input() display: DisplayOptions = "area";
  @Input() uploadDescription: string = "Arraste e solte aqui um arquivo";
  @Input() customDataCy: string = "file-drop";
  @Input() loading: boolean = false;
  @Input() fileTypes: string[] = [];
  @Input() useBase64EncodedFiles: boolean = true;
  @Input() sizeLimit: number = null; //In MB
  @Input() disabled: boolean;

  public files: NgxFileDropEntry[] = [];
  public showButtonUpload: boolean = true;
  public nameDocumentSelected: string;

  public async dropped(files: NgxFileDropEntry[]): Promise<void> {
    let validFiles = files.filter((file) => file.fileEntry.isFile)
    let fileResponses = []

    for(let i = 0; i < validFiles.length; i++) {
      const fileEntry = validFiles[i].fileEntry as FileSystemFileEntry;

      // Force Event loop to wait for promise resolution to continue executing
      fileResponses.push(await new Promise((resolve) => {
        fileEntry.file(f => {
          if(this.sizeLimit !== null) {
            if(this.ToMegabyte(f.size) > this.sizeLimit) {
              this.openDialogMessage("Não é possível fazer o upload deste arquivo. Arquivo com tamanho superior à "+this.sizeLimit+"MB.")
              return;
            }
          }

          this.nameDocumentSelected = f.name
          this.showButtonUpload = false;
          resolve(f)

        })
      }))
    }

    if (this.useBase64EncodedFiles) {
      return this.returnFileRequestList(fileResponses).then((files) => this.emitFileUploaded.emit(files));
      return;
    }

    this.emitFileUploaded.emit(fileResponses);
  }

  public onClickOpenFileSelector(): void {
    if(!this.disabled){
      this.openFileSelectorElement.nativeElement.click()
    }
  }

  public onClickRemoveFileSelected(): void {
    this.nameDocumentSelected = null;
    this.showButtonUpload = true;
    this.emitFileUploaded.emit(null);
  }

  private ToMegabyte(mega: number) {
    return mega / 1024 / 1024;
  }
}
