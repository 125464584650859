import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowIfPermissionDirective } from './show-if-permission.directive';
import { DragDropDirective } from './drag-drop.directive';
import { MultipleEmailValidatorDirective } from './multiple-email-validator.directive';
@NgModule({
  declarations: [
    ShowIfPermissionDirective,
    DragDropDirective,
    MultipleEmailValidatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ShowIfPermissionDirective,
    DragDropDirective,
    MultipleEmailValidatorDirective
  ]
})
export class DirectivesModule { }
