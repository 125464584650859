import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Base } from "../@base/base";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { EnumPath } from "../enum/path";
import { AuthService } from "../services/auth.service";

@Injectable()
export class GuidGuard extends Base {
  constructor(private router: Router, private authService: AuthService) {
    super();
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    await firstValueFrom(this.authService.checkGuid(route.paramMap.get("guid")))
      .then(() => {
        return true;
      })
      .catch(() => {
        this.router.navigate([EnumPath.LOGIN]);
      });

    return true;
  }
}
