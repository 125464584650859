import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { combineLatest, firstValueFrom, map } from "rxjs";
import { BaseComponent } from "src/app/@base/base.component";
import { PERMITION_DEALERS_SETTLEMENT, PERMITION_DEALERS_HISTORY } from "src/app/const/permitions";
import { EnumPath } from "src/app/enum/path";
import { Debtor, DebtsGroupedResponse, ListDebtorsResponse, DebtHandlingRequest, DebtServiceHistoryResponse, ListDebtorsRequest } from "src/app/models/dealer/dealer";
import { Paginator, Table } from "src/app/models/table";
import { DealersService } from "src/app/services/dealers.service";
import { DebtBillingHistoriesModalComponent } from "./debt-billing-histories-modal/debt-billing-histories-modal.component";
import { DatePipe } from '@angular/common';
import { CustomValidators } from 'src/app/form/custom-validators';
import { LOCAL_STORAGE_KEY_DEALERS_DEBT_HANDLE } from 'src/app/const/localStorageKeys';

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.scss'],
  
})
export class DealerComponent extends BaseComponent implements OnInit {

  public loading = false;
  public loadingGrouped = false;
  public loadingButton = false;
  public debtsGrouped: DebtsGroupedResponse;
  public form: FormGroup;
  selected = new FormControl(0);

  public pageSize: number = 10;
  public pageNumber: number = 0;

  public over30Selected: boolean = true;
  public over60Selected: boolean = true;
  public over90Selected: boolean = true;

  get labelDocument() {
    return this.form.get('clientDocument').value?.length > 11 ? 'CNPJ' : 'CPF';
  }

  public periods: string[] = ['31-60', '61-90', '91-360'];

  public table: Table<Debtor> = this.getTable();

  constructor(
    private formBuilder: FormBuilder,
    private dealerService: DealersService,
    private datepipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    
    this.form = this.formBuilder.group({
      clientDocument: ["", 
      [
        CustomValidators.isValidCpf(),
        CustomValidators.isValidCNPJ(),
      ]]
    });

    this.filter(this.selected.value);
    this.getDebtorsGrouped();
  }

  public pageChange(paginator: Paginator): void {
    this.pageNumber = paginator.index;
    this.pageSize = paginator.pageSize;

    if (this.periods.length === 0)
    {
      this.pageNumber = 0;
      this.pageSize = 10;

      this.table.data = [];
      this.table.paginator.length = 0;
      this.table.paginator.index = this.pageNumber;
      this.table.paginator.pageSize = this.pageSize;
      return;
    }

    this.filter(this.selected.value);
  }

  public onShowDebtBillingHistoriesClick(row: Debtor) {

    firstValueFrom(this.dealerService.getDebtServiceHistories(row.clientDocument, row.contractNumber))
      .then((response: DebtServiceHistoryResponse[]) => {
        this.dialog.open(DebtBillingHistoriesModalComponent, { 
          maxHeight: "45vw", 
          maxWidth: "60vw", 
          width: "60vw",
          data: { 
            clientName: row.clientName,
            contractNumber: row.contractNumber, 
            clientDocument: row.clientDocument, 
            histories: response 
          }});
      })
  }

  public filter(tabIndex: number, goToFirstPage: boolean = false ): void {

    if (goToFirstPage) {
      this.pageNumber = 0;
    }
    let dados: ListDebtorsRequest = {
      periodRanges: [ this.periods.at(tabIndex) ],
      clientDocument: this.form.get('clientDocument').value,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }

    this.table.loading = true;
    this.loadingButton = true;

    firstValueFrom(
      this.dealerService.getDebtors(dados)
    )
      .then((resp: ListDebtorsResponse) => {
        this.table.data = resp.items;
        this.table.paginator.length = resp.totalCount;
        this.table.paginator.index = this.pageNumber;
        this.table.paginator.pageSize = this.pageSize;
      })
      .finally(() => {
        this.table.loading = false;
        this.loadingButton = false;
      });
  }

  get getPermissionHistory() {
    return this.getPermissions().includes(PERMITION_DEALERS_HISTORY);
  }

  get getPermissionDebHandle() {
    return this.getPermissions().includes(PERMITION_DEALERS_SETTLEMENT);
  }

  get haveActions() {
    return this.getPermissionDebHandle || this.getPermissionHistory;
  }

  public cleanForm(): void {
    this.form.reset();
    this.form.markAsUntouched();
    this.form.markAsPristine();

    this.form.patchValue({
      clientDocument: "",
    });

    if (this.periods.length > 0)
    {
      this.filter(this.selected.value);
    }
  }

  private getDebtorsGrouped(){
    this.loadingGrouped = true;

    firstValueFrom(this.dealerService.getDebtorsGrouped())
      .then((resp: DebtsGroupedResponse) => this.debtsGrouped = resp)
      .finally(() => this.loadingGrouped = false)
  }

  public insertOrRemovePeriodOver30() {
    if (!this.over30Selected){
      this.over30Selected = true;
      this.periods = this.periods.concat("31-60");
      if(this.periods.length === 1){
        this.filter(this.selected.value);
      }
    } else {
      this.over30Selected = false;
      this.periods = this.periods.filter(p => p !== "31-60");
    }
    

    this.periods.sort((a, b) => {
      let startA = parseInt(a.split('-')[0]);
      let startB = parseInt(b.split('-')[0]);
      return startA - startB;
    });
  }

  public insertOrRemovePeriodOver60() {
    if (!this.over60Selected){
      this.over60Selected = true;
      this.periods = this.periods.concat("61-90");
      if(this.periods.length === 1){
        this.filter(this.selected.value);
      }
    } else {
      this.over60Selected = false;
      this.periods = this.periods.filter(p => p !== "61-90");
    }
    
    this.periods.sort((a, b) => {
      let startA = parseInt(a.split('-')[0]);
      let startB = parseInt(b.split('-')[0]);
      return startA - startB;
    });
  }

  public insertOrRemovePeriodOver90() {
    if (!this.over90Selected){
      this.over90Selected = true;
      this.periods = this.periods.concat("91-360");
      if(this.periods.length === 1){
        this.filter(this.selected.value);
      }
    } else {
      this.over90Selected = false;
      this.periods = this.periods.filter(p => p !== "91-360");
    }
    
   

    this.periods.sort((a, b) => {
      // Extraindo o número inicial de cada intervalo
      let startA = parseInt(a.split('-')[0]);
      let startB = parseInt(b.split('-')[0]);
      return startA - startB;
    });
  }

  public getTable(): Table<Debtor> {
    return {
      headers: [
        {
          key: "debtInService",
          value: "Atendendo",
        },
        {
          key: "lastDebtBillingDate",
          value: "Data",
        },
        {
          key: "lastDebtBillingSummary",
          value: "Último Registro",
        },
        {
          key: "totalAmountDue",
          value: "Inadimplência",
        },
        {
          key: "numberOfOutstandingInstallments",
          value: "Parcelas",
        },
        {
          key: "debtDelayDays",
          value: "Dias",
        },
        {
          key: "codCCY",
          value: "Código CCY",
        },
        {
          key: "dealerDocument",
          value: "CNPJ Concessionária",
        },
        {
          key: "contractNumber",
          value: "Contrato",
        },
        {
          key: "clientDocument",
          value: "CPF/CNPJ",
        },
        {
          key: "clientName",
          value: "Nome",
        },
        
        {
          key: "telephone1",
          value: "Tel. Res.",
        },
        {
          key: "telephone2",
          value: "Tel. Cel",
        },
        {
          key: "telephone3",
          value: "Tel. Com",
        },
        {
          key: "telephone4",
          value: "Tel. Outros",
        },
        {
          key: "clientEmail",
          value: "e-Mail",
        },

        {
          key: "address",
          value: "Endereço",
        },
        {
          key: "district",
          value: "Bairro",
        },
        {
          key: "city",
          value: "Cidade",
        },
        {
          key: "state",
          value: "UF",
        },
        {
          key: "zipCode",
          value: "CEP",
        },
        {
          key: "sellersName",
          value: "Vendedor",
        },
      ],
      data: [],
      style: {
        table: {
          height: "500px",
          align: {
            column: [
              {
                key: "totalAmountDue",
                align: "right",
              }
            ],
          },
        },
        data: [
          {
            key: "lastDebtBillingDate",
            transform: (value) => this.datepipe.transform(value, "dd/MM/yyyy  HH:mm:ss"),
            styles: [{ class: { display: "flex", justifyContent: "center" } }]
            
          },
          {
            key: "debtInService",
            styles: [
              {
                class: { color: "#fa8c16", fontWeight: 500 },
                rule: (x) => x === false,
              },
              {
                class: { color: "#52c41a", fontWeight: 500 },
                rule: (x) => x === true,
              },
            ],
            transform: (value: boolean) => value ? "Sim" : "Não",
          },
          {
            key: "totalAmountDue",
            transform: (value: string) => parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          },
        ],
      },
      checkbox: false,
      actions: this.haveActions && {
        custom: true
      },
      sortEnable: true,
      paginator: {
        index: 0,
        itensPerPage: [10, 15, 20, 50, 100],
        pageSize: 10,
      },
      loading: this.loading,
    };
  }

  public  goToDebtSettlement(row: Debtor) {
    const modalLoading = this.openDialogLoading()
    
    firstValueFrom(this.dealerService.verifyDebtInServiceHandler(row.clientDocument, row.contractNumber))
      .then(inService => {
        
        if(!inService) {
          this.startHandling(row);
          return;
        }

        firstValueFrom(
          this.openDialogConfirm('Outro usuário começou este atendimento. Deseja continuar?').afterClosed()
        ).then((resp: boolean) => {
          if(resp)
            this.startHandling(row);
          else 
            this.filter(this.selected.value);
        })
    })
     .finally(() => modalLoading.close());
  }
  

  public startHandling(row: Debtor){
    const modalLoading = this.openDialogLoading();
    firstValueFrom(this.dealerService.registerStartDebtHandling({clientDocument: row.clientDocument, contractNumber: row.contractNumber}))
      .then(() => {
        this.setLocalStorageItem(LOCAL_STORAGE_KEY_DEALERS_DEBT_HANDLE, row);
        this.router.navigate([EnumPath.DEALERS_DEBT_SETTLEMENT]);
      })
      .catch((error: HttpErrorResponse) => {
        if(error.status != 500) {
          this.openDialogError(error)
        }
      })
      .finally(() => modalLoading.close());
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

