<app-modal class="density-lower" title="NOVA SOLICITAÇÃO" data-cy="modal-bank-letter-debtor-balance-data"
  [disableButtonClose]="loading">
  <div class="modal-content density-mat-form-3">
    <span class="reason estricta " data-cy="modal-bank-letter-debtor-balance-data">BANCO - CARTA DE SALDO DEVEDOR</span>

    <div class="texts">
      <section class="text">
        Informe os dados abaixo para a geração de Carta de Saldo Devedor.
      </section>
      <section class="text">
        Após o processamento serão baixados dois arquivos: a carta de saldo devedor e o seu respectivo boleto em formato
        PDF com vencimento para 10 dias.
      </section>
    </div>

    <form [formGroup]="form" (keydown.enter)="$event.preventDefault(); onSubmit()">
      <section class="fields">
        <div>
          <mat-label><span class="red">*</span> Nome da Seguradora: </mat-label>
          <mat-form-field appearance="outline" class="background-white">
            <input type="text" matInput placeholder="Digite Aqui" formControlName="insuranceName" class="uppercase"
              [maxlength]="100" data-cy="InsuranceNameInput" />
            <mat-error *ngIf="
                form.get('insuranceName').hasError('required') &&
                form.get('insuranceName').touched
              " data-cy="error-insuranceName-required">
              <strong> Campo obrigatório </strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-label><span class="red">*</span>Nº do processo: </mat-label>
          <mat-form-field appearance="outline" class="background-white">
            <input type="text" matInput placeholder="Digite Aqui" formControlName="processNumber" class="uppercase"
              [maxlength]="100" data-cy="ProcessNumberInput" />
            <mat-error *ngIf="
                form.get('processNumber').hasError('required') &&
                form.get('processNumber').touched
              " data-cy="error-processNumber-required">
              <strong> Campo obrigatório </strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-label><span class="red">*</span> Data do Sinistro: </mat-label>
          <app-custom-datapicker [label]="" [required]="true" formControlName="dataInsurance"
            [errors]="form.get('dataInsurance').errors" [control]="form.get('dataInsurance')" datacy="dataInsurance"
            [maxDate]="today">
          </app-custom-datapicker>
        </div>
        <div>
          <mat-label><span class="red">*</span> Cliente/Segurado: </mat-label>
          <mat-form-field appearance="outline" class="background-white">
            <input type="text" matInput placeholder="Digite Aqui" formControlName="costumer" class="uppercase"
              [maxLength]="100" data-cy="CostumerInput" />
            <mat-error *ngIf="
                form.get('costumer').hasError('required') &&
                form.get('costumer').touched
              " data-cy="error-costumer-required">
              <strong> Campo obrigatório </strong>
            </mat-error>
          </mat-form-field>
        </div>
      </section>
    </form>
  </div>

  <div class="modal-bottom">
    <app-button width="100px" (onClick)="onClickBtnClose()" [disabled]="loading" datacy="Cancelar">
      <span class="button-label"> Cancelar </span>
    </app-button>
    <app-button type="submit" color="primary" datacy="update-button" [disabled]="!form.valid || loading" datacy="Enviar"
      [loading]="loading" (onClick)="onSubmit()">
      <span class="button-label"> Enviar </span>
    </app-button>
  </div>
</app-modal>