<button class="notification" [matMenuTriggerFor]="menu" data-cy="Notifications">
    <mat-icon aria-hidden="false" [matBadgeHidden]="qtdUnRead == 0" [matBadge]="qtdUnRead" matBadgeColor="warn" class="icone-notificacao">notifications</mat-icon>
</button>

<mat-menu #menu="matMenu" yPosition="below">

    <div class="title">
        <span class="estricta uppercase"> Notificações </span>
    </div>

    <input mat-menu-item cdkFocusInitial type='text' class="hidden"/>

    <ng-container *ngFor="let notification of notifications; let i = index">
        <button [attr.data-cy]="'Message' + i" mat-menu-item class="item" (click)="openNotification(notification.id)">
            <div class="button-content">
                <span *ngIf="!notification.read" class="dot"></span>
                <span class="item-content" [ngClass]="!notification.read ? 'blue' : ''"> {{notification.title}} </span>
            </div>
        </button>
    </ng-container>

</mat-menu>
