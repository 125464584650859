import { Component } from "@angular/core";
import { Select, SelectOption } from "src/app/models/select";
import { BankService } from "src/app/services/bank.service";
import { Contract } from "src/app/models/bank/contract";
import { BaseComponent } from "src/app/@base/base.component";
import { LOCAL_STORAGE_KEY_CONTRACTS } from "src/app/const/localStorageKeys";
import { EnumPath } from "src/app/enum/path";

@Component({
  selector: "app-bank-contracts",
  templateUrl: "./bank-contracts.component.html",
  styleUrls: ["./bank-contracts.component.scss"],
})
export class BankContractsComponent extends BaseComponent {
  private contracts: Contract[];

  public txtBtnBack: string = "Voltar";

  public select: Select<Contract> = {
    icon: "insert_drive_file",
    options: [],
  };

  constructor(private bankService: BankService) {
    super();
    this.isHome();

    this.contracts =
      this.getLocalStorageItem<Contract[]>(LOCAL_STORAGE_KEY_CONTRACTS) ?? [];

    if (this.contracts.length > 0) {
      this.bankService.createSubject(this.contracts[0]);

      this.select.options = this.contracts.map((x: Contract) => {
        return {
          title: x.contractNumber,
          description: x.product,
          t: x,
        };
      });
    } else {
      this.bankService.createSubject(null);
    }
  }

  public isHome(): boolean {
    if (this.router.url == "/bank") {
      return true;
    }

    return false;
  }

  public back(): void {
    this.location.back();
  }

  public onContractChange(event: SelectOption<Contract>): void {
    let routesWithoutInstallments = [
      EnumPath.BANK_STATEMENT.toString(),
      EnumPath.BANK_ANUAL_STATEMENT.toString(),
      EnumPath.BANK.toString(),
    ];

    if (
      !event.t.hasActiveInstalments &&
      !routesWithoutInstallments.includes(this.router.url)
    ) {
      this.location.back();
    }

    this.bankService.emitContractSelected.next(event.t);
  }
}

