import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, Subscription, filter } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { LOCAL_STORAGE_KEY_BANNERS } from 'src/app/const/localStorageKeys';
import { BannerImage, PageBanner } from 'src/app/models/banner/pageBanner';
import { BannerService } from 'src/app/services/banner.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent extends BaseComponent implements OnDestroy {
  @Output() emitChangeMenu: EventEmitter<any> = new EventEmitter()
  @Input() stateMenu: boolean = false

  public images: BannerImage[] = [];

  public showFiller: boolean = false

  public $subscriptionRoute: Subscription;

  constructor(private bannerService: BannerService) {
    super()

    this.subscriptionChangeBanner();
  }

  ngOnDestroy(): void {
    this.$subscriptionRoute.unsubscribe()
  }

  public onClickMenu(): void {
    this.emitChangeMenu.emit()
  }

  private changeBanner(): void {
    let banner = this.getLocalStorageItem<PageBanner[]>(LOCAL_STORAGE_KEY_BANNERS)?.find(x => this.router.url.includes(x.page))
    
    this.bannerService.bannersSubscribe$.subscribe(() => {
      banner = this.getLocalStorageItem<PageBanner[]>(LOCAL_STORAGE_KEY_BANNERS)?.find(x => this.router.url.includes(x.page))
      this.images = banner ? banner.images : []
    })
    
    this.images = banner ? banner.images : []
  }

  private subscriptionChangeBanner(): void {
    this.changeBanner()

    this.$subscriptionRoute = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.changeBanner()
      });
  }
}
