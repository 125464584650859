<div class="display">

    <div class="img-content">
        <img src="assets/yamaha-servicos-financeiros/yamaha-servicos-financeiros-1.png">
    </div>

    <div class="carousel">
        <app-carousel *ngIf="showBanner()" [images]="banner.images" [fullPage]="true" [showButtons]="true" [useAuthLayoutButtonsPosition]="true"></app-carousel>
    </div>


    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
