import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule, DatePipe } from '@angular/common';
import { BankComponent } from './bank.component';
import { BankRoutingModule } from './bank-routing.module';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { BillComponent } from './bank-categories/bill/bill.component';
import { PaymentComponent } from './bank-categories/payment/payment.component';
import { ContractComponent } from './bank-categories/contract/contract.component';
import { BankHeaderComponent } from './bank-components/bank-header/bank-header.component';
import { PaymentBookComponent } from './bank-categories/payment-book/payment-book.component';
import { HomeBankComponent } from './bank-categories/home-bank/home-bank.component';
import { StatementComponent } from './bank-categories/statement/statement.component';
import { AnualStatementComponent } from './bank-categories/anual-statement/anual-statement.component';
import { BankContractsComponent } from './bank-components/bank-contracts/bank-contracts.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BankSummaryComponent } from './bank-components/bank-summary/bank-summary.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { BankButtonsOptionComponent } from './bank-components/bank-buttons-option/bank-buttons-option.component';
import { ModalRightsAssignmentComponent } from './bank-modals/modal-rights-assignment/modal-rights-assignment.component';
import { ModalBankChangeRegistrationDataComponent } from './bank-components/modal-bank-change-registration-data/modal-bank-change-registration-data.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalBankPaymentReversalComponent } from '../bank/bank-components/modal-bank-payment-reversal/modal-bank-payment-reversal.component'
import { ModalBankTravelAuthorizationComponent } from './bank-components/modal-bank-travel-authorization/modal-bank-travel-authorization.component';
import { ModalWarrantySubstitutionComponent } from './bank-modals/modal-substituicao-garantia/modal-warranty-substitution.component';
import { ModalBankLetterDebtorBalanceComponent } from './bank-components/modal-bank-letter-debtor-balance/modal-bank-letter-debtor-balance.component';
import { CustomInputsModule } from "../../form/custom-inputs/custom-inputs.module";
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { BankAmountComponent } from './bank-components/bank-amount/bank-amount.component';
import { BankChartComponent } from './bank-components/bank-chart/bank-chart.component';
import { ModalLienStatusComponent } from './bank-modals/modal-lien-status/modal-lien-status.component';
import { SecondPaymentComponent } from './bank-categories/second-payment/second-payment.component';


registerLocaleData(localePt, 'pt');

@NgModule({
    declarations: [
        BankComponent,
        BillComponent,
        ContractComponent,
        PaymentComponent,
        HomeBankComponent,
        BankHeaderComponent,
        PaymentBookComponent,
        StatementComponent,
        AnualStatementComponent,
        BankContractsComponent,
        BankSummaryComponent,
        BankButtonsOptionComponent,
        ModalRightsAssignmentComponent,
        ModalBankChangeRegistrationDataComponent,
        ModalBankTravelAuthorizationComponent,
        ModalWarrantySubstitutionComponent,
        ModalBankPaymentReversalComponent,
        ModalBankLetterDebtorBalanceComponent,
        BankAmountComponent,
        BankChartComponent,
        ModalLienStatusComponent,
        SecondPaymentComponent,
    ],
    exports: [BankButtonsOptionComponent],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'pt',
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL',
        },
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        BankRoutingModule,
        RouterModule,
        MatSelectModule,
        MatDividerModule,
        MatIconModule,
        MatChipsModule,
        MatButtonModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        DirectivesModule,
        NgxMaskDirective,
        NgxMaskPipe,
        CustomInputsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        FormsModule,
        MatDialogModule,
        MatInputModule,
        MatRadioModule,
        DatePipe,
    ]
})
export class BankModule { }
