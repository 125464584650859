import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BankSlipRequest, BankSlipResponse, Payment } from 'src/app/models/bank/payment';
import { BankService } from 'src/app/services/bank.service';
import { BaseComponent } from 'src/app/@base/base.component';
import { DatePipe } from '@angular/common';
import { ButtonColors } from 'src/app/components/button/button.component';
import { orderBy } from 'lodash-es';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EnumProduct } from 'src/app/enum/products';
import { EnumBankOptions, EnumBankQuickAccess } from 'src/app/enum/options';
import { EnumReportIncident } from 'src/app/enum/report-incident';
import { LOCAL_STORAGE_KEY_CONTRACTS } from 'src/app/const/localStorageKeys';
import { Contract } from 'src/app/models/bank/contract';

class SucessComponent {}
@Component({
  selector: 'app-bank-summary',
  templateUrl: './bank-summary.component.html',
  styleUrls: ['./bank-summary.component.scss']
})
export class BankSummaryComponent extends BaseComponent implements OnChanges {

  @Output() emitDocumentCreated: EventEmitter<void> = new EventEmitter();

  @Input() installments: Payment[] = []
  @Input() totalRows: Payment[] = []
  @Input() contract: string = "";
  @Input() width: string = "100%";
  @Input() allowPayment: boolean = true;
  @Input() btnColor: ButtonColors = "primary";
  @Input() txtBtnGenerateBill: string = "Gerar Boleto"
  @Input() icon: string = "get_app";
  @Input() allSelected: boolean = false;

  public txtSpanSumInstallment: string = "Valor original";
  public txtSpanSumAddition: string = "Acréscimos";
  public txtSpanSumDiscounts: string = "Descontos";
  public txtSpanSumDifference: string = "Diferença";
  public txtSpanInfoOne: string = "Prezado cliente, este boleto pode ser liquidado na rede bancária, locais conveniados ou ainda através do Internet Banking.";
  public txtSpanInfoTwo: string = "Lembramos que o prazo para baixa dos pagamentos é de até 3 dias úteis.";
  public txtSpanInfoThree: string = "O seu CPF ou CNPJ é a senha para abrir o arquivo do boleto gerado, utilizando apenas números.";
  public txtSpanTotal: string = "Total a Pagar:"
  public barcode: string;
  public txtCopy: string = "Copiar";
  public isExpanded: boolean = false;
  public loading: boolean = false;

  constructor(
    private bankService: BankService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar
  ){
    super()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['contract']){
      if(changes['contract'].firstChange || changes['contract'].currentValue !== changes['contract'].previousValue){
        this.barcode = null;
      }
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public rowsSelectedSorted(): any[] {
    return orderBy(this.installments, ['number'], ['asc']);
  }

  public sumInstallment(): string {
    return Number.parseFloat(this.installments.reduce((acc, curr) => acc + parseFloat(curr.originalValue), 0)  + "").toFixed(2)
  }

  public sumAddition(): string {
    return Number.parseFloat(this.installments.filter(x => parseFloat(x.discountIncrease) > 0).reduce((acc, curr) => acc + parseFloat(curr.discountIncrease), 0) + "").toFixed(2)
  }

  public sumDiscounts(): string {
    return Number.parseFloat(this.installments.filter(x => parseFloat(x.discountIncrease) < 0).reduce((acc, curr) => acc + parseFloat(curr.discountIncrease), 0)  + "").toFixed(2)
  }

  public sumDifference(): string {
    return (parseFloat(this.sumAddition()) + parseFloat(this.sumDiscounts())).toFixed(2);
  }

  public total(): string {
    return (parseFloat(this.sumInstallment()) + parseFloat(this.sumDifference())).toFixed(2)
  }

  public generatePayment(): void {
    let contractObject = this.getLocalStorageItem<Contract>(
      LOCAL_STORAGE_KEY_CONTRACTS
    );

    this.loading = true;

    let bankSlip: BankSlipRequest = {
      instalments: this.installments.map(x => {
        return {dueDate: x.dueDate, receiptCode: x.receiptCode}
      }),
      allSelected: this.allSelected,
      initContractDate: new Date(contractObject[0].initDate)
    }

    firstValueFrom(this.bankService.GeneratePayment<BankSlipRequest, BankSlipResponse>(bankSlip, this.contract))
      .then((bankSlipResponse: BankSlipResponse) => {
        this.barcode = bankSlipResponse.barcode
        this.download(bankSlipResponse.base64file,`${this.contract}_${this.datePipe.transform(new Date(), 'yyyy-MM-dd')}.pdf`, "pdf")
        this.sendLogDescription();
      })
      .then(() => this.openDialogSucess("Para abrir seu boleto, basta digitar somente os números do CPF ou CNPJ do titular."))
      .then(() => this.emitDocumentCreated.emit())
      .finally(() => this.loading = false)
  }


  private sendLogDescription(): void{
    if(this.allSelected){
      this.logFeature(EnumReportIncident.BANK_CONTRACT, EnumProduct.BANK);
    } else {

      if(this.installments.find(x => new Date(x.dueDate) <= new Date(Date.now()))) {
        this.logFeature(EnumReportIncident.BANK_BILL, EnumProduct.BANK);
      }

      if(this.installments.find(x =>new Date(x.dueDate) > new Date(Date.now()))) {
        this.logFeature(EnumReportIncident.BANK_PAYMENT, EnumProduct.BANK);
      }

    }

  }

  public detectar_mobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    else {
      return false;
    }
  }
}
