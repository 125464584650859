import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-modal-lien-status',
  templateUrl: './modal-lien-status.component.html',
  styleUrls: ['./modal-lien-status.component.scss']
})
export class ModalLienStatusComponent extends ModalComponent<ModalLienStatusComponent> {

  public lienStatus: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any){
    super()

    this.lienStatus = data
  }
}
