<ng-container *ngIf="!table.loading; else containerLoading">
  <ng-content select=".table-header" class="header"></ng-content>

  <div class="container" [style.maxHeight]="height" [style.maxWidth]="maxWidth">
    <table mat-table [dataSource]="dataSource" matSort [matSortDisabled]="!table.sortEnable">
      <ng-container *ngFor="let key of keys; let i = index">
        <ng-container *ngIf="key == 'select'" matColumnDef="select">
          <th *matHeaderCellDef mat-header-cell id="select-all" data-cy="th-select">
            <mat-checkbox color="primary" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" (change)="$event ? toggleAllRows() : null">
            </mat-checkbox>
          </th>
          <td *matCellDef="let row" mat-cell class="select" [ngClass]="{ active: selection.isSelected(row) }"
            [attr.data-cy]="'td-select-' + returnLine(row)">
            <mat-checkbox *ngIf="!shouldDisableCheckbox(row)" color="primary" (click)="$event.stopPropagation()"
              (change)="
                $event ? selection.toggle(row) : null;
                onRowChange(row, selection.isSelected(row))
              " [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>

          <td *matFooterCellDef mat-footer-cell class="footer amount" [attr.data-label]="
              values[i] + ' (' + table.footer.amount.description + ')'
            ">
            {{ table.footer.amount.description }}
          </td>
        </ng-container>

        <ng-container *ngIf="key == 'actions'" matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell data-cy="th-actions">Ações</th>

          <td *matCellDef="let element" mat-cell [attr.data-cy]="'td-actions-' + returnLine(element)">
            <button [disabled]="getActionPermission()" mat-icon-button [matMenuTriggerFor]="menu"
              [attr.data-cy]="'td-button-actions-' + returnLine(element)">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <input mat-menu-item cdkFocusInitial type="text" class="hidden" />
              <button *ngIf="getActionPermissionUpdate()"
                [attr.data-cy]="'td-button-actions-edit-' + returnLine(element)" mat-menu-item
                (click)="onActionChange(element)">
                Editar
              </button>
              <button *ngIf="getActionPermissionDelete()" [attr.data-cy]="
                  'td-button-actions-delete-' + returnLine(element)
                " mat-menu-item (click)="onActionDelete(element)">
                Excluir
              </button>
              
              <ng-content *ngTemplateOutlet="optionTemplate; context: { $implicit: element }"></ng-content>  
              
            </mat-menu>            
            
          </td>
        </ng-container>

        <ng-container *ngIf="key == 'buttons'" matColumnDef="buttons">
          <th *matHeaderCellDef mat-header-cell data-cy="'th-buttons">Ações</th>

          <td *matCellDef="let element" mat-cell data-cy="'td-buttons">
            <div class="buttons">
              <button (click)="onActionView(element)" class="buttons-read" mat-icon-button [attr.data-cy]="
                  'td-button-button-visiblity-' + returnLine(element)
                ">
                <mat-icon>visibility</mat-icon>
              </button>

              <button *ngIf="getButtonPermissionDelete()" (click)="onActionDelete(element)" class="buttons-delete"
                mat-icon-button [attr.data-cy]="
                  'td-button-button-delete-' + returnLine(element)
                ">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="key !== 'select' && key !== 'actions' && key !== 'buttons'" matColumnDef="{{ key }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="align(key)" [attr.data-cy]="'th-' + key">
            {{ values[i] }}
          </th>

          <td *matCellDef="let element" mat-cell [attr.data-label]="values[i]" [ngClass]="dataClass(key, element)"
            (click)="singleClick($event)" (dblclick)="doubleClick($event, element)"
            [attr.data-cy]="'td-' + key + '-' + returnLine(element)">
            <div *ngIf="!isEditable(key); else editableLine">
              <span *ngIf="isArray(element[key])" [ngStyle]="dataStyle(key, element)">
                <p *ngFor="let child of element[key]" [ngStyle]="dataChildStyle(key, child)">
                  {{ dataPrefix(key) }} {{ child }}
                </p>
              </span>
              <span *ngIf="!isArray(element[key])" [ngStyle]="dataStyle(key, element)">
                {{ dataPrefix(key) }} {{ render(element[key], key) }}
              </span>
            </div>

            <ng-template #editableLine>
              <input type="text" maxLength="100" matInput [value]="element[key]" [ngStyle]="dataStyle(key, element)"
                [readonly]="getReadOnly(key)" (input)="onDataChange($event, element, key)" />
            </ng-template>
          </td>

          <ng-container *ngIf="table.footer && showAmount(i)">
            <td *matFooterCellDef mat-footer-cell id="amount" class="footer" [attr.data-label]="
                values[i] + ' (' + table.footer.amount.description + ')'
              ">
              {{ table.footer.amount.description }}
            </td>
          </ng-container>

          <ng-container *ngIf="table.footer">
            <td *matFooterCellDef mat-footer-cell class="footer" [ngClass]="align(key)" [attr.data-label]="
                values[i] + ' (' + table.footer.amount.description + ')'
              ">
              <span *ngIf="showFooterData(key)">{{ footerValue(key) }}</span>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="keys; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: keys"></tr>
      <ng-container *ngIf="table.footer">
        <tr mat-footer-row *matFooterRowDef="keys; sticky: true"></tr>
      </ng-container>

      <tr class="mat-row" *matNoDataRow data-cy="no-data-message">
        <td class="mat-cell" [attr.colspan]="keys.length">
          <span class="no-data-message" [attr.data-cy]="noDataMessageCy">{{
            table.messageWithoutDates || "Não há dados para exibir no momento."
            }}</span>
        </td>
      </tr>
    </table>
  </div>
</ng-container>

<div *ngIf="table.paginator" [ngClass]="table.excel && table.data.length > 0 ? 'paginator' : ''">

  <app-button *ngIf="table.excel && table.data.length > 0" [disabled]="table.loadingButtonDownload"
    [loading]="table.loadingButtonDownload" (onClick)="onButtonExcelClicked()" width="100px"
    class="paginator-download-button">
    <div data-cy="export-excel" class="paginator-content">
      <mat-icon title="Export as CSV">save_alt</mat-icon>
      <span class="paginator-text"> Exportar </span>
    </div>
  </app-button>

  <mat-paginator #paginator *ngIf="table.paginator" showFirstLastButtons [length]="table.paginator.length"
    [pageSize]="table.paginator.pageSize" [pageSizeOptions]="table.paginator.itensPerPage"
    (page)="handlePageEvent($event)" data-cy="table-paginator">
  </mat-paginator>
</div>

<ng-template #containerLoading>
  <div class="loading">
    <mat-spinner *ngIf="table.loading"></mat-spinner>
  </div>
</ng-template>